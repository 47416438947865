import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import classNames from "classnames";

import ConnectToSlack from "components/ConnectToSlack";
import ContactSupportLink from "components/ContactSupportLink";
import { useAsyncActionFlash } from "components/WithFlashes";

import MUTATION from "./Mutation.graphql";

import {
  TestSlackIntegration,
  TestSlackIntegrationVariables,
} from "./types/TestSlackIntegration";
import Select from "components/Select";

type IntegrationStatusType = "enabled" | "not enabled" | "auth invalid";

type SlackChannel = { id: string; name: string };

const ConnectTestSlack: React.FunctionComponent<{
  organizationSlug: string;
  status: IntegrationStatusType;
  channels: SlackChannel[];
  hasChannelError: boolean;
}> = ({ organizationSlug, status, channels, hasChannelError }) => {
  const [selectedChannel, setSelectedChannel] = useState<SlackChannel | null>(
    null
  );
  const [testIntegration, { called, loading, error }] = useMutation<
    TestSlackIntegration,
    TestSlackIntegrationVariables
  >(MUTATION);

  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Notification sent",
  });

  if (status === "not enabled") {
    return (
      <>
        <ConnectToSlack />
        <span>
          Connect to Slack to enable notifications for database issues found by
          pganalyze.
        </span>
      </>
    );
  }

  const handleChannelSelected = (selected: SlackChannel | null) => {
    setSelectedChannel(selected);
  };

  const testDisabled = selectedChannel === null || status === "auth invalid";
  const testDescription =
    status === "auth invalid" ? (
      <>
        Reset the integration and re-authenticate with Slack to test
        notifications.
      </>
    ) : hasChannelError ? (
      <>
        <strong>Error</strong>: could not load Slack channels. Reload the page
        to try again or please{" "}
        <ContactSupportLink>contact support</ContactSupportLink>.
      </>
    ) : (
      <>
        Send a test notification to channel{" "}
        <Select
          placeholder="— select a channel —"
          items={channels}
          itemToString={(item) => `#${item.name}`}
          value={selectedChannel}
          onChange={handleChannelSelected}
        />
        .
      </>
    );
  const disabledHint =
    selectedChannel === null && status === "enabled"
      ? "select a channel to send a test notification"
      : undefined;

  const handleTestButtonClick = () => {
    if (testDisabled) {
      return;
    }
    testIntegration({
      variables: {
        organizationSlug,
        channelId: selectedChannel.id,
      },
    });
  };

  return (
    <>
      <button
        disabled={testDisabled}
        title={disabledHint}
        onClick={handleTestButtonClick}
        className={classNames("btn btn-primary", testDisabled && "disabled")}
      >
        Test integration
      </button>
      <span>{testDescription}</span>
    </>
  );
};

export default ConnectTestSlack;
