import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  label: string;
  className?: string;
};

const PanelItem: React.FunctionComponent<Props> = ({
  label,
  children,
  className,
}) => {
  return (
    <div className={classNames(styles.panelItem, className)}>
      <span className={styles.panelItemLabel}>{label}</span>
      <div>{children}</div>
    </div>
  );
};

export default PanelItem;
