import moment, { Moment } from "moment-timezone";

export class PredefinedPeriod {
  constructor(
    readonly key: string,
    readonly label: string,
    readonly duration: number,
    readonly units: moment.unitOfTime.DurationAs
  ) {}

  get range(): [Moment, Moment] {
    const to = moment();
    return [to.clone().subtract(this.duration, this.units), to];
  }

  extendsBefore(ts: Moment): boolean {
    const from = this.range[0];
    return from.isBefore(ts);
  }
}

export const periods: PredefinedPeriod[] = [
  new PredefinedPeriod("30m", "Last 30 minutes", 30, "minutes"),
  new PredefinedPeriod("1h", "Last hour", 1, "hour"),
  new PredefinedPeriod("24h", "Last 24 hours", 24, "hours"),
  new PredefinedPeriod("7d", "Last 7 days", 7, "days"),
  new PredefinedPeriod("14d", "Last 14 days", 14, "days"),
  new PredefinedPeriod("30d", "Last 30 days", 30, "days"),
];

export const findByLabel = (label: string): PredefinedPeriod | undefined => {
  return periods.find((p) => p.label === label);
};

export const findByKey = (key: string): PredefinedPeriod | undefined => {
  return periods.find((p) => p.key === key);
};
