import React from "react";
import moment from "moment-timezone";

import { formatTimestampShort } from "utils/format";

import { ExplainPlanType } from "..";

import ExplainFingerprint from "components/ExplainFingerprint";

type Props = {
  explain: ExplainPlanType;
};

const ExplainContext: React.FunctionComponent<Props> = ({ explain }) => {
  const seenAt = formatTimestampShort(moment.unix(explain.seenAt));
  return (
    <>
      {seenAt}
      {" · "}
      Fingerprint: <ExplainFingerprint explain={explain} />
    </>
  );
};

export default ExplainContext;
