import React from "react";

import { formatBytes, formatPercent } from "utils/format";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";

import {
  SystemStats as SystemDetails,
  SystemStats_info as SystemInfoType,
} from "./types/SystemStats";

type Props = {
  systemDetails: SystemDetails;
};

const SystemStats: React.FunctionComponent<Props> = ({ systemDetails }) => {
  const { stats, info } = systemDetails;
  if (!stats) {
    return (
      <Panel title="System Statistics">
        <PanelSection>No system statistics available.</PanelSection>
      </Panel>
    );
  }

  const systemType = info && info.systemType;
  const {
    cpuHardwareSockets,
    cpuHardwareModel,
    cpuHardwareCoresPerSocket,
    cpuHardwareSpeedMhz,
    schedulerLoadavg1min,
    schedulerLoadavg5min,
    schedulerLoadavg15min,
    memoryTotalBytes,
    memoryApplicationBytes,
    storageBytesTotal,
    storageBytesAvailable,
  } = stats;

  let memoryFreeBytes = stats.memoryFreeBytes;
  if (memoryApplicationBytes && memoryTotalBytes) {
    memoryFreeBytes = memoryTotalBytes - memoryApplicationBytes;
  }

  return (
    <Panel title="System Statistics">
      <PanelTable horizontal={true} borders={true}>
        <tbody>
          {systemType == "amazon_rds" && <AmazonRDSSystemStats info={info} />}
          {!!cpuHardwareSockets &&
            !!cpuHardwareSpeedMhz &&
            !!memoryTotalBytes &&
            !!memoryFreeBytes && (
              <tr>
                <th>CPU</th>
                <td>
                  {cpuHardwareSockets * cpuHardwareCoresPerSocket} x{" "}
                  {cpuHardwareModel || "Unknown Model"} ({cpuHardwareSpeedMhz}{" "}
                  MHz)
                </td>
                <th>Memory</th>
                <td>
                  {formatBytes(memoryTotalBytes)} (
                  {formatPercent(memoryFreeBytes / memoryTotalBytes)} free)
                </td>
              </tr>
            )}
          {!!storageBytesTotal && !!storageBytesAvailable && (
            <tr>
              <th>Disk Space</th>
              <td colSpan={3}>
                {formatBytes(storageBytesTotal)} (
                {formatPercent(storageBytesAvailable / storageBytesTotal)} free)
              </td>
            </tr>
          )}
          {schedulerLoadavg1min !== null &&
            schedulerLoadavg1min !== undefined &&
            schedulerLoadavg5min !== null &&
            schedulerLoadavg5min !== undefined &&
            schedulerLoadavg15min !== null &&
            schedulerLoadavg15min !== undefined && (
              <tr>
                <th>Load Avg</th>
                <td colSpan={3}>
                  {schedulerLoadavg1min.toFixed(2)}{" "}
                  {schedulerLoadavg5min.toFixed(2)}{" "}
                  {schedulerLoadavg15min.toFixed(2)}
                </td>
              </tr>
            )}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

const AmazonRDSSystemStats: React.FunctionComponent<{ info: SystemInfoType }> =
  ({ info }) => {
    const { status, region, instanceId, instanceClass } = info.data;

    return (
      <tr>
        <th>Instance ID</th>
        <td>
          <a
            href={`https://console.aws.amazon.com/rds/home?region=${
              region || "us-east-1"
            }#dbinstance:id=${instanceId || ""}`}
          >
            {instanceId}
          </a>{" "}
          (status: {status})
        </td>
        <th>Instance Class</th>
        <td>{instanceClass}</td>
      </tr>
    );
  };

export default SystemStats;
