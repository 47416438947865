import React from "react";

import {
  AutoSizer,
  Table,
  WindowScroller,
  InfiniteLoader,
  SortDirectionType,
  InfiniteLoaderChildProps,
} from "react-virtualized";

import styles from "./style.module.scss";

export const cellStyles = {
  number: styles.number,
  numberHeader: styles.numberHeader,
  query: styles.query,
};

type DataItemValueType = any;
type DataItemType = { [key: string]: DataItemValueType };

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirectionType;
}

interface Props {
  data: DataItemType[];
  dataSize?: number;
  noRowsText?: string;
  rowHeight?: number;
  headerHeight?: number;
  batchSize?: number;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  fetchMore?: (startIndex: number, offset: number, resolve: () => void) => void;
  handleSort: (opts: SortOptsType) => void;
}

const DEFAULT_BATCH_SIZE = 50;

const PaginatedVirtualTable: React.FunctionComponent<Props> = ({
  data,
  dataSize,
  noRowsText = "No Data",
  rowHeight = 30,
  headerHeight = 25,
  batchSize = DEFAULT_BATCH_SIZE,
  sortBy,
  sortDirection,
  fetchMore,
  handleSort,
  children,
}) => {
  dataSize ??= data.length;
  const noRowsRenderer = (): React.ReactElement => (
    <div className={styles.noRows}>{noRowsText}</div>
  );

  const isRowLoaded = ({ index }: { index: number }): boolean => {
    return index < data.length || data.length < batchSize;
  };

  const loadMoreRows = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number;
    stopIndex: number;
  }): Promise<void> => {
    if (isRowLoaded({ index: stopIndex })) {
      return Promise.resolve();
    }

    return new Promise((resolve: () => void) => {
      if (!fetchMore) {
        return;
      }

      const offset = stopIndex - startIndex + 1;

      fetchMore(startIndex, offset, resolve);
    });
  };

  const renderVirtualTable = ({
    onRowsRendered,
    registerChild,
  }: Partial<InfiniteLoaderChildProps>): React.ReactNode => (
    <WindowScroller>
      {({
        height,
        scrollTop,
      }: {
        height: number;
        scrollTop: number;
      }): React.ReactNode => (
        <AutoSizer disableHeight>
          {({ width }: { width: number }): React.ReactNode => (
            <Table
              headerHeight={headerHeight}
              height={height}
              rowCount={
                dataSize as number /* we fall back to data.length so this will always be defined, but TS complains; revisit */
              }
              rowGetter={({ index }: { index: number }): DataItemValueType =>
                data[index]
              }
              rowHeight={rowHeight}
              className={styles.table}
              width={width}
              sort={handleSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowClassName={({ index }: { index: number }): string =>
                (index % 2 == 0 && styles.oddRow) || ""
              }
              scrollTop={scrollTop}
              autoHeight={true}
              noRowsRenderer={noRowsRenderer}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
            >
              {children}
            </Table>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );

  return fetchMore ? (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={100000}
      minimumBatchSize={batchSize}
    >
      {renderVirtualTable}
    </InfiniteLoader>
  ) : (
    (renderVirtualTable({
      onRowsRendered: undefined,
      registerChild: undefined,
    }) as React.ReactElement)
  );
};

export default PaginatedVirtualTable;
