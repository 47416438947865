import React from "react";
import { useQuery } from "@apollo/client";

import Form from "components/Form";
import Loading from "components/Loading";
import Panel from "../Panel";

import styles from "./style.module.scss";

import QUERY from "./Query.graphql";
import {
  SetAlertPolicy as SetAlertPolicyType,
  SetAlertPolicyVariables,
} from "./types/SetAlertPolicy";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

type Props = {
  organizationSlug: string;
  membershipId: string;
};

const SetAlertPolicy: React.FunctionComponent<Props> = ({
  organizationSlug,
  membershipId,
}) => {
  const { organizationAlertPolicy, organizationMember } = useRoutes();
  const { data, loading, error } = useQuery<
    SetAlertPolicyType,
    SetAlertPolicyVariables
  >(QUERY, { variables: { organizationSlug, membershipId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <Panel title="Your Alert Policy">
      <Form
        action={organizationMember(
          organizationSlug,
          data.getOrganizationMembershipDetails.id
        )}
        method="patch"
        className={styles.setPolicyForm}
        insideSection={true}
      >
        <strong>
          Current Policy:{" "}
          <Link
            to={organizationAlertPolicy(
              organizationSlug,
              data.getOrganizationMembershipDetails.alertPolicy.id
            )}
          >
            {data.getOrganizationMembershipDetails.alertPolicy.policyName}
          </Link>
        </strong>
        <div className="help-block">
          This will only change which alert policy applies to your user in this
          organization.
        </div>
        <div className="form-group">
          <select
            name="membership[alert_policy_id]"
            id="alert_policy"
            className="form-control"
            defaultValue={data.getOrganizationMembershipDetails.alertPolicy.id}
          >
            {data.getAlertPolicies.map((policy) => (
              <option key={policy.id} value={policy.id}>
                {policy.policyName}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-success">
          Change
        </button>
      </Form>
    </Panel>
  );
};

export default SetAlertPolicy;
