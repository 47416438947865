import React from "react";

import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";

import {
  MemberAcceptInvite,
  MemberAcceptInviteVariables,
} from "./types/MemberAcceptInvite";

import MUTATION from "./Mutation.graphql";

const MemberDetails: React.FunctionComponent = () => {
  const { organization } = useRoutes();
  const { slug, inviteToken } = useParams();
  const [acceptInvite, { called, loading, error }] = useMutation<
    MemberAcceptInvite,
    MemberAcceptInviteVariables
  >(MUTATION);

  const handleAcceptClick = () => {
    acceptInvite({
      variables: { organizationSlug: slug, inviteToken },
    });
  };

  if (called && !loading && !error) {
    // force a full-page reload to fetch the new organization data
    window.location.href = organization(slug);
  }

  return (
    <PageContent
      title="Organization Member: Accept Invitation"
      pageCategory="organization-members"
      pageName="accept"
    >
      <Panel title="Accept Invitation">
        <PanelSection>
          You've been invited to join the organization <strong>{slug}</strong>
          <div className="flex justify-end max-w-2xl">
            <button onClick={handleAcceptClick} className="btn btn-success">
              Accept
            </button>
          </div>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default MemberDetails;
