import React, { useState } from "react";

import classNames from "classnames";

import { AnnotatedPlan, Node, RawNode, RawPlan } from "types/explain";

import styles from "./style.module.scss";
import CopyToClipboard from "components/CopyToClipboard";

type Props = {
  annotatedPlan: AnnotatedPlan;
  textPlan: string;
  onHide: () => void;
};

const ExplainViewSource: React.FunctionComponent<Props> = ({
  annotatedPlan,
  textPlan,
  onHide,
}) => {
  const [useJson, setUseJson] = useState(true);

  let formattedSource: string;
  if (useJson) {
    const rawPlan = stripAnnotations(annotatedPlan);
    formattedSource = JSON.stringify(rawPlan, null, 2);
  } else {
    formattedSource = textPlan;
  }

  const handleFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseJson(e.target.value === "json");
  };

  const handleHide = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onHide();
  };

  return (
    <div>
      <div className={styles.header}>
        <a href="" onClick={handleHide}>
          Show Summary & Insights
        </a>
        <div className={styles.actions}>
          Format:
          <label className={styles.sourceKindLabel}>
            <input
              type="radio"
              checked={useJson}
              onChange={handleFormatChange}
              name="usejson"
              value="json"
            />{" "}
            JSON
          </label>{" "}
          <label className={styles.sourceKindLabel}>
            <input
              type="radio"
              checked={!useJson}
              onChange={handleFormatChange}
              name="usejson"
              value="plaintext"
            />{" "}
            Text
          </label>
          <CopyToClipboard
            className={classNames("btn btn-small btn-link", styles.copyBtn)}
            content={formattedSource}
            label="Copy to clipboard"
          />
        </div>
      </div>
      <pre className={styles.source}>{formattedSource}</pre>
    </div>
  );
};

const stripAnnotations: (plan: AnnotatedPlan) => RawPlan = (plan) => {
  const enrichedPlan = plan.plan;
  const stripNode = (node: Node): RawNode => {
    const { extra: _ignored, Plans: children, ...rest } = node;
    const newNode: RawNode = rest;
    if (children) {
      newNode.Plans = children.map((child: Node) => stripNode(child));
    }
    return newNode;
  };
  return enrichedPlan.map((root) => ({
    ...root,
    Plan: stripNode(root.Plan),
  }));
};

export default ExplainViewSource;
