import React from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import { faUserLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "components/Popover";

import {
  ViewPermission,
  ModifyPermission,
  BillingPermission,
  APIPermission,
  ManagePermission,
} from "components/DocsSnippet";
import {
  PERMISSION_FULL,
  PERMISSION_RESTRICTED,
  PERMISSION_NONE,
  groupPermissions,
} from "utils/groupPermissions";
import { GroupedPermission } from "utils/groupPermissions";
import Icon from "components/Icon";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PermissionIcon from "components/PermissionIcon";
import SettingsNav from "components/SettingsNav";

import {
  Members as MemberListType,
  MembersVariables,
  Members_getOrganizationDetails_memberships as OrganizationMembershipType,
  Members_getOrganizationDetails_samlIntegration as SamlIntegrationType,
} from "./types/Members";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import { useAppConfig } from "components/WithAppConfig";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

const MemberList: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { organizationMembersNew } = useRoutes();

  const { data, loading, error } = useQuery<MemberListType, MembersVariables>(
    QUERY,
    {
      variables: { organizationSlug },
    }
  );

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { permittedToInviteMembers, samlIntegration, memberships } =
    data.getOrganizationDetails;

  return (
    <PageContent
      title="Settings"
      pageCategory="organization-members"
      pageName="index"
      featureNav={<SettingsNav />}
    >
      <Panel title="Members">
        <table className={styles.userList}>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th className={styles.permission}>
                View <ViewPermission />
              </th>
              <th className={styles.permission}>
                Modify <ModifyPermission />
              </th>
              <th className={styles.permission}>
                Billing <BillingPermission />
              </th>
              <th className={styles.permission}>
                API <APIPermission />
              </th>
              <th className={styles.permission}>
                Manage <ManagePermission />
              </th>
              <th>{samlIntegration ? `Single Sign-On Required?` : null}</th>
            </tr>
          </thead>
          <tbody>
            {memberships.map((m) => (
              <OrganizationMember
                key={m.id}
                organizationSlug={organizationSlug}
                member={m}
                samlIntegration={samlIntegration}
              />
            ))}
          </tbody>
        </table>
        <PanelSection>
          {permittedToInviteMembers && (
            <Link
              to={organizationMembersNew(organizationSlug)}
              className="btn btn-success"
            >
              Invite New Member
            </Link>
          )}
          {!permittedToInviteMembers && (
            <div>
              You are not allowed to add new members - you do not have the{" "}
              <strong>Manage</strong> permission on this organization.
            </div>
          )}
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

const OrganizationMember: React.FunctionComponent<{
  member: OrganizationMembershipType;
  organizationSlug: string;
  samlIntegration: SamlIntegrationType;
}> = ({ member, organizationSlug, samlIntegration }) => {
  const { emailIntegrationAvailable } = useAppConfig();
  const { organizationMember, organizationResendInvite } = useRoutes();
  const renderGroupedPermission = (permission: GroupedPermission) => {
    switch (permission) {
      case PERMISSION_FULL:
        return <PermissionIcon permitted={true} restricted={false} />;
      case PERMISSION_RESTRICTED:
        return <PermissionIcon permitted={true} restricted={true} />;
      case PERMISSION_NONE:
        return <PermissionIcon permitted={false} restricted={false} />;
    }
  };
  return (
    <tr key={member.id}>
      <td>
        {member.accepted && member.user.avatarUrl && (
          <img
            src={member.user.avatarUrl}
            style={{ width: 30, height: 30 }}
            className={styles.memberImage}
          />
        )}
        {!member.accepted && emailIntegrationAvailable && (
          <Link
            to={organizationResendInvite(organizationSlug, member.id)}
            data-method="post"
            title={`Resend invite to ${member.email}`}
            className={styles.resendInvite}
          >
            <Icon kind="repeat" />
          </Link>
        )}
      </td>
      <td>
        <Link to={organizationMember(organizationSlug, member.id)}>
          {member.accepted ? member.user.fullname || "(unknown)" : "(invited)"}
        </Link>
      </td>
      <td>
        <span>{member.email}</span>
      </td>
      <td className={styles.permission}>
        {renderGroupedPermission(groupPermissions(member.permissions, "view"))}
      </td>
      <td className={styles.permission}>
        {renderGroupedPermission(
          groupPermissions(member.permissions, "modify")
        )}
      </td>
      <td className={styles.permission}>
        {renderGroupedPermission(
          groupPermissions(member.permissions, "billing")
        )}
      </td>
      <td className={styles.permission}>
        {renderGroupedPermission(groupPermissions(member.permissions, "api"))}
      </td>
      <td className={styles.permission}>
        {renderGroupedPermission(
          groupPermissions(member.permissions, "manage")
        )}
      </td>
      <td>
        {samlIntegration ? (
          member.samlUid ? (
            <span>
              <span style={{ marginRight: "6px", fontWeight: 600 }}>Yes</span>
              <Popover
                placement="right"
                content={
                  <>
                    <strong>Identity Provider Username:</strong>
                    <br />
                    {member.samlUid}
                    <br />
                    <br />
                    <strong>Identity Provider Entity ID:</strong>
                    <br />
                    <code>{samlIntegration.idpEntityId}</code>
                  </>
                }
              >
                <span style={{ color: "#dbaa46" }}>
                  <FontAwesomeIcon icon={faUserLock} />
                </span>
              </Popover>
            </span>
          ) : (
            "No"
          )
        ) : null}
      </td>
    </tr>
  );
};

export default MemberList;
