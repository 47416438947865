import React, { useEffect, useState } from "react";

import { orderBy } from "lodash";
import moment from "moment";

import { Flash, FlashLevel, useSetFlashes } from "components/WithFlashes";
import { useAppConfig } from "components/WithAppConfig";
import { formatTimestampShort } from "utils/format";
import { useUnmounted } from "utils/hooks";

const StatusNotice: React.FunctionComponent = () => {
  const setFlashes = useSetFlashes();
  const statusSummary = useStatusPageInfo();

  useEffect(() => {
    // wait until we've fetched the status summary to show something
    if (!statusSummary) {
      return undefined;
    }
    // we only want to show one flash, so prioritize open incidents,
    // then scheduled maintenances, then the most recently resolved
    // incident or maintenance.
    const noticeEvent = getNoticeEvent(statusSummary);
    if (!noticeEvent) {
      return undefined;
    }

    const flashLevel: FlashLevel =
      noticeEvent.resolvedAt == null ? "alert" : "notice";
    const lastStatusFlash: Flash = {
      id: `${noticeEvent.id}-${noticeEvent.status}`,
      level: flashLevel,
      msg: <StatusFlash event={noticeEvent} />,
      persist: true,
      dismissable: true,
    };
    // do not clear any existing flashes
    setFlashes((curr) => curr.concat(lastStatusFlash));

    return () => {
      setFlashes((curr) => curr.filter((flash) => flash !== lastStatusFlash));
    };
  }, [setFlashes, statusSummary]);

  return null;
};

const StatusFlash: React.FunctionComponent<{
  event: IncidentSummary | MaintenanceSummary;
}> = ({ event }) => {
  return (
    <>
      {event.kind === "maintenance" ? (
        event.resolvedAt ? (
          <>
            Scheduled maintenance recently completed at{" "}
            {formatTimestampShort(event.resolvedAt)}.
          </>
        ) : ["in_progress", "verifying"].includes(event.status) ? (
          <>
            Ongoing scheduled maintenance between{" "}
            {formatTimestampShort(event.scheduledFor)} and{" "}
            {formatTimestampShort(event.scheduledUntil)}.
          </>
        ) : (
          <>
            Upcoming scheduled maintenance between{" "}
            {formatTimestampShort(event.scheduledFor)} and{" "}
            {formatTimestampShort(event.scheduledUntil)}.
          </>
        )
      ) : event.kind === "incident" ? (
        event.resolvedAt ? (
          <>
            Recent status incident resolved at{" "}
            {formatTimestampShort(event.resolvedAt)}.
          </>
        ) : (
          <>An ongoing status incident may be affecting some components.</>
        )
      ) : null}{" "}
      Learn more at{" "}
      <a rel="noopener" target="_blank" href={urlFor(event)}>
        status.pganalyze.com
      </a>
      .
    </>
  );
};

type IncidentStatus =
  | "investigating"
  | "identified"
  | "monitoring"
  | "resolved"
  | "postmortem";
type MaintenanceStatus =
  | "scheduled"
  | "in_progress"
  | "verifying"
  | "completed";

export type RawIncidentsResponse = {
  incidents: {
    id: string;
    name: string;
    status: IncidentStatus;
    resolved_at: string;
  }[];
};

export type RawMaintenanceResponse = {
  scheduled_maintenances: {
    id: string;
    name: string;
    scheduled_for: string;
    scheduled_until: string;
    status: MaintenanceStatus;
    resolved_at: string;
  }[];
};

type StatusSummary = {
  incidents: IncidentSummary[];
  maintenances: MaintenanceSummary[];
};

type IncidentSummary = {
  id: string;
  kind: "incident";
  name: string;
  status: IncidentStatus;
  resolvedAt: moment.Moment;
};

type MaintenanceSummary = {
  id: string;
  kind: "maintenance";
  name: string;
  status: MaintenanceStatus;
  resolvedAt: moment.Moment;
  scheduledFor: moment.Moment;
  scheduledUntil: moment.Moment;
};

function urlFor(event: IncidentSummary | MaintenanceSummary): string {
  return `https://status.pganalyze.com/incidents/${event.id}`;
}

export const INCIDENTS_API_URL =
  "https://status.pganalyze.com/api/v2/incidents.json";
export const MAINTENANCES_API_URL =
  "https://status.pganalyze.com/api/v2/scheduled-maintenances.json";

function useStatusPageInfo(): StatusSummary | null {
  const { enterpriseEdition } = useAppConfig();
  const unmounted = useUnmounted();
  const [statusSummary, setStatusSummary] = useState<StatusSummary | null>(
    null
  );

  useEffect(() => {
    if (enterpriseEdition) {
      return;
    }
    const incidentsCall = fetch(INCIDENTS_API_URL).then((res) => res.json());
    const maintenancesCall = fetch(MAINTENANCES_API_URL).then((res) =>
      res.json()
    );
    Promise.all([incidentsCall, maintenancesCall])
      .then(([incidents, maintenances]) => {
        if (unmounted.current) {
          return;
        }
        const summary = transformEventInfo(incidents, maintenances);
        setStatusSummary(summary);
      })
      .catch(() => {
        // ignore this: the status notice is only a convenience
      });
  }, [enterpriseEdition, setStatusSummary, unmounted]);

  return statusSummary;
}

function transformEventInfo(
  incidentsResponse: RawIncidentsResponse,
  maintenancesResponse: RawMaintenanceResponse
): StatusSummary {
  const incidents: IncidentSummary[] = incidentsResponse.incidents.map(
    (incident) => {
      return {
        id: incident.id,
        kind: "incident",
        name: incident.name,
        status: incident.status,
        resolvedAt:
          incident.resolved_at == null
            ? null
            : moment(incident.resolved_at, moment.ISO_8601),
      };
    }
  );
  const maintenances: MaintenanceSummary[] =
    maintenancesResponse.scheduled_maintenances.map((maintenance) => {
      return {
        id: maintenance.id,
        kind: "maintenance",
        name: maintenance.name,
        status: maintenance.status,
        resolvedAt:
          maintenance.resolved_at == null
            ? null
            : moment(maintenance.resolved_at, moment.ISO_8601),
        scheduledFor: moment(maintenance.scheduled_for, moment.ISO_8601),
        scheduledUntil: moment(maintenance.scheduled_until, moment.ISO_8601),
      };
    });
  const now = moment();
  const relevantIncidents = incidents.filter((incident) => {
    return (
      incident.resolvedAt == null ||
      now.diff(incident.resolvedAt, "minutes") < 15
    );
  });
  const relevantMaintenances = maintenances.filter((maintenance) => {
    return (
      maintenance.resolvedAt == null ||
      now.diff(maintenance.resolvedAt, "minutes") < 15
    );
  });

  return {
    incidents: orderBy(relevantIncidents, ["resolvedAt"], ["desc"]),
    maintenances: orderBy(relevantMaintenances, ["resolvedAt"], ["desc"]),
  };
}

function getNoticeEvent(
  summary: StatusSummary
): IncidentSummary | MaintenanceSummary | null {
  // we only want to show one flash, so prioritize open incidents,
  // then scheduled maintenances, then the most recently resolved
  // incident or maintenance.
  const latestIncident = summary.incidents[0];
  const latestMaintenance = summary.maintenances[0];

  if (latestMaintenance == null) {
    return latestIncident;
  }

  if (latestIncident == null) {
    return latestMaintenance;
  }

  return latestIncident.resolvedAt == null
    ? latestIncident
    : latestMaintenance.resolvedAt == null
    ? latestMaintenance
    : latestIncident.resolvedAt.isAfter(latestMaintenance.resolvedAt)
    ? latestIncident
    : latestMaintenance;
}

export default StatusNotice;
