import React from "react";
import { useParams } from "react-router-dom";

import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startCase } from "lodash";
import { useQuery } from "@apollo/client";
import { formatTimestampLong } from "utils/format";
import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { useRoutes } from "utils/routes";

import PanelSection from "components/PanelSection";
import Avatar from "components/Avatar";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import SettingsNav from "components/SettingsNav";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";
import {
  OrganizationIntegrations as OrganizationIntegrationsType,
  OrganizationIntegrationsVariables,
  OrganizationIntegrations_getOrganizationDetails_slackIntegration as SlackIntegrationType,
  OrganizationIntegrations_getOrganizationDetails_pagerdutyIntegration as PagerdutyIntegrationType,
  OrganizationIntegrations_getOrganizationDetails_samlIntegration as SamlIntegrationType,
} from "./types/OrganizationIntegrations";

import styles from "./style.module.scss";
import ResetIntegration from "./ResetIntegration";
import ConnectTestSlack from "./ConnectTestSlack";
import ConnectTestPagerDuty from "./ConnectTestPagerDuty";
import SetupSamlIntegration from "./SetupSamlIntegration";
import ContactSupportLink from "components/ContactSupportLink";
import { useFeature } from "components/OrganizationFeatures";
import Callout from "components/Callout";

type IntegrationType = Omit<SlackIntegrationType, "__typename"> &
  Omit<PagerdutyIntegrationType, "__typename">;
type IntegrationStatusType = "enabled" | "not enabled" | "auth invalid";

const OrganizationIntegrations: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();

  const { data, loading, error } = useQuery<
    OrganizationIntegrationsType,
    OrganizationIntegrationsVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const { permittedToEditOrganization } = data.getOrganizationDetails;

  return (
    <PageContent
      title="Integrations"
      pageCategory="organization"
      pageName="integrations"
      featureNav={<SettingsNav />}
    >
      <SlackPanel
        organizationSlug={organizationSlug}
        integration={data.getOrganizationDetails.slackIntegration}
        canEdit={permittedToEditOrganization}
      />
      <PagerDutyPanel
        organizationSlug={organizationSlug}
        integration={data.getOrganizationDetails.pagerdutyIntegration}
        canEdit={permittedToEditOrganization}
      />
      <SamlPanel
        organizationSlug={organizationSlug}
        integration={data.getOrganizationDetails.samlIntegration}
        isTrial={data.getOrganizationDetails.planInfo.isTrial}
        canEdit={permittedToEditOrganization}
      />
    </PageContent>
  );
};

const IconNotEnabled: React.FunctionComponent = () => (
  <FontAwesomeIcon
    size="sm"
    className={styles.iconNotEnabled}
    icon={faInfoCircle}
  />
);
const IconEnabled: React.FunctionComponent = () => (
  <FontAwesomeIcon
    size="sm"
    className={styles.iconEnabled}
    icon={faCheckCircle}
  />
);
const IconAuthInvalid: React.FunctionComponent = () => (
  <FontAwesomeIcon
    size="sm"
    className={styles.iconAuthInvalid}
    icon={faTimesCircle}
  />
);

const SlackPanel: React.FunctionComponent<{
  organizationSlug: string;
  integration: SlackIntegrationType;
  canEdit: boolean;
}> = ({ organizationSlug, integration, canEdit }) => {
  const status = getIntegrationStatus(integration);
  const StatusIcon = getStatusIcon(status);
  return (
    <Panel
      title={
        <span>
          <StatusIcon /> Slack
        </span>
      }
      secondaryTitle={<IntegrationInfo info={integration} />}
    >
      <PanelSection>
        <IntegrationStatus
          organizationSlug={organizationSlug}
          status={status}
          canEdit={canEdit}
        />
        {canEdit ? (
          <div className={styles.integrationGrid}>
            <ConnectTestSlack
              organizationSlug={organizationSlug}
              hasChannelError={!!integration?.availableChannels.error}
              channels={integration?.availableChannels.channels}
              status={status}
            />
            <ResetIntegration
              integrationId={integration?.id}
              service="Slack"
              disabled={status === "not enabled"}
              alertingIntegration
            />
          </div>
        ) : (
          <div className={styles.noEdit}>
            You are not allowed to edit integration settings for this
            organization.
          </div>
        )}
      </PanelSection>
    </Panel>
  );
};

const PagerDutyPanel: React.FunctionComponent<{
  organizationSlug: string;
  integration: PagerdutyIntegrationType;
  canEdit: boolean;
}> = ({ organizationSlug, integration, canEdit }) => {
  const status = getIntegrationStatus(integration);
  const StatusIcon = getStatusIcon(status);
  return (
    <Panel
      title={
        <span>
          <StatusIcon /> PagerDuty
        </span>
      }
      secondaryTitle={<IntegrationInfo info={integration} />}
    >
      <PanelSection>
        <IntegrationStatus
          organizationSlug={organizationSlug}
          status={status}
          canEdit={canEdit}
        />
        {canEdit ? (
          <div className={styles.integrationGrid}>
            <ConnectTestPagerDuty
              organizationSlug={organizationSlug}
              services={integration?.availableServices}
              status={status}
            />
            <ResetIntegration
              integrationId={integration?.id}
              service="PagerDuty"
              disabled={status === "not enabled"}
              alertingIntegration
            />
          </div>
        ) : (
          <div className={styles.noEdit}>
            You are not allowed to edit integration settings for this
            organization.
          </div>
        )}
      </PanelSection>
    </Panel>
  );
};

const SamlPanel: React.FunctionComponent<{
  organizationSlug: string;
  integration: SamlIntegrationType;
  isTrial: boolean;
  canEdit: boolean;
}> = ({ organizationSlug, integration, isTrial, canEdit }) => {
  const hasSaml = useFeature("saml");
  const status = getIntegrationStatus(integration);
  const StatusIcon = getStatusIcon(status);
  return (
    <Panel
      title={
        <span>
          <StatusIcon /> Single Sign-On with SAML (Okta, etc)
        </span>
      }
      secondaryTitle={<IntegrationInfo info={integration} />}
    >
      {!hasSaml ? (
        <PanelSection>
          This feature is not available{" "}
          {isTrial ? "during your pganalyze trial" : "with your pganalyze plan"}
          . If you have questions about Single Sign-On functionality, please{" "}
          <ContactSupportLink>contact support</ContactSupportLink>.
        </PanelSection>
      ) : (
        <>
          <PanelSection>
            <IntegrationStatus
              organizationSlug={organizationSlug}
              status={status}
              canEdit={false}
            />
          </PanelSection>
          <PanelSection>
            {integration ? (
              <div>
                <strong>IdP Entity ID</strong>
                {": "}
                <code>{integration.idpEntityId}</code>
              </div>
            ) : null}
            {canEdit ? (
              <>
                <div className={styles.integrationGrid}>
                  <ResetIntegration
                    integrationId={integration?.id}
                    service="SAML"
                    disabled={!integration || status === "not enabled"}
                    alertingIntegration={false}
                    hideIfDisabled
                  />
                </div>
                <SetupSamlIntegration
                  organizationSlug={organizationSlug}
                  integration={integration}
                />
              </>
            ) : (
              <div className={styles.noEdit}>
                You are not allowed to edit integration settings for this
                organization.
              </div>
            )}
          </PanelSection>
          <Callout
            className="m-[10px]"
            learnMoreLink="https://pganalyze.com/docs/accounts/sso"
          >
            <ul className="pl-4">
              <li>
                New members will receive the{" "}
                <strong>"View &amp; Modify (All Servers)"</strong> role in this
                organization
              </li>
              <li>
                Any existing username/password users in your organization who
                subsequently authenticate through SSO will be converted to SSO
                accounts tied to your organization
              </li>
              <li>
                User deprovisioning is not yet supported - once access is
                revoked users can't sign in anymore but still show up in
                pganalyze
              </li>
            </ul>
          </Callout>
        </>
      )}
    </Panel>
  );
};

type IntegrationInfo = Pick<IntegrationType, "createdAt" | "integratedBy">;

const IntegrationInfo: React.FunctionComponent<{
  info: IntegrationInfo;
}> = ({ info }) => {
  if (!info) {
    return null;
  }

  const createdAt = formatTimestampLong(moment.unix(info.createdAt));
  const addedBy = info.integratedBy.fullname;
  const addedByAvatarUrl = info.integratedBy.avatarUrl;
  return (
    <span>
      Added {createdAt} by{" "}
      <Avatar name={addedBy} avatarUrl={addedByAvatarUrl} />
    </span>
  );
};

const IntegrationStatus: React.FunctionComponent<{
  organizationSlug: string;
  status: IntegrationStatusType;
  canEdit: boolean;
}> = ({ organizationSlug, status, canEdit }) => {
  const { organizationAlerts } = useRoutes();
  let statusDescription;
  if (status === "enabled") {
    statusDescription = (
      <>
        The integration is enabled.
        {canEdit && (
          <>
            {" "}
            Go to{" "}
            <Link to={organizationAlerts(organizationSlug)}>
              Alerts &amp; Check-Up configuration
            </Link>{" "}
            and select a server to configure it.
          </>
        )}
      </>
    );
  } else if (status === "not enabled") {
    statusDescription = (
      <>
        The integration is not enabled.
        {canEdit && <> Click below to enable it.</>}
      </>
    );
  } else if (status === "auth invalid") {
    statusDescription = (
      <>
        There was a problem with the integration credentials.
        {canEdit && (
          <> Try resetting the integration and configuring it again.</>
        )}
      </>
    );
  }
  const statusLabel = startCase(status);
  return (
    <span>
      <strong>{statusLabel}</strong>
      {": "}
      {statusDescription}
    </span>
  );
};

function getStatusIcon(status: IntegrationStatusType): React.ComponentType {
  if (status === "enabled") {
    return IconEnabled;
  } else if (status === "not enabled") {
    return IconNotEnabled;
  } else if (status === "auth invalid") {
    return IconAuthInvalid;
  } else {
    // should not happen
    return () => null;
  }
}

function getIntegrationStatus(
  integration: Pick<IntegrationType, "authInvalid">
): IntegrationStatusType {
  if (integration?.authInvalid) {
    return "auth invalid";
  } else if (integration) {
    return "enabled";
  } else {
    return "not enabled";
  }
}

export default OrganizationIntegrations;
