import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import Grid, { sortNullsLast } from "components/Grid";

import Loading from "components/Loading";
import Panel from "components/Panel";

import ServerPostgresSettings, {
  PostgresSettingType,
} from "components/ServerPostgresSettings";

import { Link } from "react-router-dom";
import { formatBytes, formatDuration } from "utils/format";
import { useRoutes } from "utils/routes";

import {
  GetVacuumAdvisorData_getIssues as IssueType,
  GetVacuumAdvisorData_getVacuumInsightStatus as InsightStatusType,
} from "../types/GetVacuumAdvisorData";

import QUERY from "./Query.graphql";
import {
  GetPerformanceVacuumSummary,
  GetPerformanceVacuumSummaryVariables,
} from "./types/GetPerformanceVacuumSummary";
import { sortBy } from "lodash";
import PanelTable from "components/PanelTable";
import Tip from "components/Tip";
import SingletonInsightPanel from "../SingletonInsightPanel";
import IssueSummaryBadge from "../IssueSummaryBadge";
import Popover from "components/Popover";
import { makeFilter } from "utils/filter";
import FilterSearch from "components/FilterSearch";

const VacuumAdvisorPerformance: React.FunctionComponent<{
  serverId: string;
  serverCreatedAt: moment.Moment;
  serverName: string;
  blockSize: number;
  issues: IssueType[];
  settings: PostgresSettingType[];
  insightStatus: { [key: string]: InsightStatusType };
}> = ({
  serverId,
  serverCreatedAt,
  serverName,
  blockSize,
  issues,
  settings,
  insightStatus,
}) => {
  const indexPhaseIssue = issues.find(
    (issue) => issue.checkGroupAndName === "vacuum/inefficient_index_phase"
  );

  return (
    <>
      <SingletonInsightPanel
        title="Inefficient Index Phase"
        checkGroupAndName="vacuum/inefficient_index_phase"
        issue={indexPhaseIssue}
        serverId={serverId}
        serverCreatedAt={serverCreatedAt}
        serverName={serverName}
        insightStatus={insightStatus["vacuum/inefficient_index_phase"]}
        summary={
          <>
            Detects when autovacuum runs in this server are forced to perform
            inefficient multiple index scan phases due to limited configured
            memory.
          </>
        }
      />
      <PerformanceSettingsPanel
        serverId={serverId}
        blockSize={blockSize}
        settings={settings}
      />
      <SchemaTablesVacuumSummaryPanel
        serverId={serverId}
        issue={indexPhaseIssue}
      />
    </>
  );
};

type MaxVacuumIO = {
  maxBytesReadPerMs: number | undefined;
  maxBytesWrittenPerMs: number | undefined;

  effectiveVacuumCostDelay: number | undefined;
  effectiveVacuumCostLimit: number | undefined;
  vacuumCostPageMiss: number | undefined;
  vacuumCostPageDirty: number | undefined;
};

/* since the delay value is stored in milliseconds, this is per millisecond */
function calculateMaxVacuumBytesPerMs(
  blockSize: number,
  settings: PostgresSettingType[]
): MaxVacuumIO {
  const avCostDelaySetting =
    settings.find(
      (ps) =>
        ps.name === "autovacuum_vacuum_cost_delay" && ps.resetValue !== "-1"
    ) ?? settings.find((ps) => ps.name === "vacuum_cost_delay");
  const avCostLimitSetting =
    settings.find(
      (ps) =>
        ps.name === "autovacuum_vacuum_cost_limit" && ps.resetValue !== "-1"
    ) ?? settings.find((ps) => ps.name === "vacuum_cost_limit");

  const costPageMissSetting = settings.find(
    (ps) => ps.name === "vacuum_cost_page_miss"
  );
  const costPageDirtySetting = settings.find(
    (ps) => ps.name === "vacuum_cost_page_dirty"
  );

  const avCostDelay = parseInt(avCostDelaySetting?.resetValue, 10);
  const avCostLimit = parseInt(avCostLimitSetting?.resetValue, 10);
  const costPageMiss = parseInt(costPageMissSetting?.resetValue, 10);
  const costPageDirty = parseInt(costPageDirtySetting?.resetValue, 10);

  const result: MaxVacuumIO = {
    maxBytesReadPerMs: undefined,
    maxBytesWrittenPerMs: undefined,

    effectiveVacuumCostDelay: undefinedIfNaN(avCostDelay),
    effectiveVacuumCostLimit: undefinedIfNaN(avCostLimit),
    vacuumCostPageMiss: undefinedIfNaN(costPageMiss),
    vacuumCostPageDirty: undefinedIfNaN(costPageDirty),
  };

  if (
    isNaN(avCostDelay) ||
    isNaN(avCostLimit) ||
    isNaN(costPageMiss) ||
    isNaN(costPageDirty)
  ) {
    return result;
  }

  // This calculates worst-case VACUUM I/O impact, in terms of both reads (for
  // uncached pages that must be scanned) and writes (for pages that VACUUM
  // dirties and will later have to be written out). Each page miss can read
  // blockSize bytes, and, if activity is instantaneous, there can be up to
  // (cost limit / cost of 1 miss) every cost delay. Similarly, each page dirty
  // can write blockSize bytes, and, if activity is instantaneous, there can be
  // up to (cost limit / cost of 1 dirty) every cost delay.
  //
  // Note that these may end up as Infinity if costPageMiss, costPageDirty, or
  // avCostDelay end up as zero.

  const maxReadIo = ((avCostLimit / costPageMiss) * blockSize) / avCostDelay;
  const maxWriteIo = ((avCostLimit / costPageDirty) * blockSize) / avCostDelay;

  return {
    ...result,
    maxBytesReadPerMs: maxReadIo,
    maxBytesWrittenPerMs: maxWriteIo,
  };
}

function undefinedIfNaN(value: number | undefined): number | undefined {
  return isNaN(value) ? undefined : value;
}

const PerformanceSettingsPanel: React.FunctionComponent<{
  serverId: string;
  settings: PostgresSettingType[];
  blockSize: number;
}> = ({ serverId, blockSize, settings }) => {
  const perfSettings = sortBy(
    settings.filter((ps) => {
      return [
        "autovacuum_vacuum_cost_delay",
        "autovacuum_vacuum_cost_limit",
        "autovacuum_work_mem",
        "autovacuum_naptime",
        "autovacuum_max_workers",
      ].includes(ps.name);
    }),
    (ps) => ps.name
  );
  const { maxBytesReadPerMs, maxBytesWrittenPerMs, ...vacuumCostSettings } =
    calculateMaxVacuumBytesPerMs(blockSize, settings);
  const readImpact = isFinite(maxBytesReadPerMs)
    ? `${formatBytes(maxBytesReadPerMs * 1000)} / s`
    : "n/a";
  const writeImpact = isFinite(maxBytesWrittenPerMs)
    ? `${formatBytes(maxBytesWrittenPerMs * 1000)} / s`
    : "n/a";

  const readZeroSettings: React.ReactNode[] = [];
  const writeZeroSettings: React.ReactNode[] = [];
  if (vacuumCostSettings.vacuumCostPageMiss === 0) {
    readZeroSettings.push(<code>vacuum_cost_page_miss</code>);
  }
  if (vacuumCostSettings.vacuumCostPageDirty === 0) {
    writeZeroSettings.push(<code>vacuum_cost_page_dirty</code>);
  }
  if (vacuumCostSettings.effectiveVacuumCostDelay === 0) {
    readZeroSettings.push(<code>autovacuum_vacuum_cost_delay</code>);
    writeZeroSettings.push(<code>autovacuum_vacuum_cost_delay</code>);
  }

  return (
    <Panel title="Performance-Related Config Settings">
      <PanelTable horizontal borders className="border-b border-[#e8e8ee]">
        <tbody>
          <tr>
            <th>
              Max Read I/O Impact{" "}
              <Tip
                className="ml-2"
                content={
                  <>
                    <p>
                      Worst-case impact that autovacuum will have on your
                      database in terms of reads from disk given these
                      configuration settings.
                    </p>
                    <p>
                      The formula considers the maximum number of pages misses
                      that can occur before hitting the cost limit, and
                      calculates that the max impact is that many pages per cost
                      delay interval. I.e.,
                    </p>
                    <p>
                      ((<code>autovacuum_vacuum_cost_limit</code> /{" "}
                      <code>vacuum_cost_page_miss</code>) *{" "}
                      <code>block_size</code>) /{" "}
                      <code>autovacuum_vacuum_cost_delay</code>
                    </p>
                    {isFinite(maxBytesReadPerMs) ? (
                      <p>
                        Note that this is a theoretical maximum. In reality, the
                        impact will be lower since the vacuuming itself will
                        also take time. Activity in all autovacuum workers
                        contributes to the limit (i.e., just adding more workers
                        does not increase I/O impact.)
                      </p>
                    ) : (
                      <p>
                        This could not be calculated for your database because{" "}
                        {readZeroSettings.join(" and ")}
                        {readZeroSettings.length === 1 ? "is" : "are"} set to
                        zero. This can be a legitimate configuration, especially
                        for some specialized Postgres versions like AWS Aurora,
                        but given the formula above it is not possible to
                        calculate an I/O impact for VACUUM with these settings.
                      </p>
                    )}
                  </>
                }
              />
            </th>
            <td>{readImpact}</td>
            <th>
              Max Write I/O Impact{" "}
              <Tip
                className="ml-2"
                content={
                  <>
                    <p>
                      Worst-case impact that autovacuum will have on your
                      database in terms of writes to disk given these
                      configuration settings.
                    </p>
                    <p>
                      The formula considers the maximum number of pages that can
                      be dirtied before hitting the cost limit, and calculates
                      that the max impact is that many pages per cost delay
                      interval. I.e.,
                    </p>
                    <p>
                      ((<code>autovacuum_vacuum_cost_limit</code> /{" "}
                      <code>vacuum_cost_page_dirty</code>) *{" "}
                      <code>block_size</code>) /{" "}
                      <code>autovacuum_vacuum_cost_delay</code>
                    </p>
                    {isFinite(maxBytesWrittenPerMs) ? (
                      <p>
                        Note that this is a theoretical maximum. In reality, the
                        impact will be lower since the vacuuming itself will
                        also take time. Activity in all autovacuum workers
                        contributes to the limit (i.e., just adding more workers
                        does not increase I/O impact.)
                      </p>
                    ) : (
                      <p>
                        This could not be calculated for your database because{" "}
                        {writeZeroSettings.join(" and ")}
                        {writeZeroSettings.length === 1 ? "is" : "are"} set to
                        zero. This can be a legitimate configuration, especially
                        for some specialized Postgres versions like AWS Aurora,
                        but given the formula above it is not possible to
                        calculate an I/O impact for VACUUM with these settings.
                      </p>
                    )}
                  </>
                }
              />
            </th>
            <td>{writeImpact}</td>
          </tr>
        </tbody>
      </PanelTable>
      <ServerPostgresSettings serverId={serverId} settings={perfSettings} />
    </Panel>
  );
};

const SchemaTablesVacuumSummaryPanel: React.FunctionComponent<{
  serverId: string;
  issue: IssueType | null;
}> = ({ serverId, issue }) => {
  const { data, loading, error } = useQuery<
    GetPerformanceVacuumSummary,
    GetPerformanceVacuumSummaryVariables
  >(QUERY, {
    variables: { serverId },
  });
  const { databaseTableVacuum } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");

  const detailsJson = issue ? JSON.parse(issue.detailsJson) : {};
  const tableIds: string[] = detailsJson["affected_table_ids"] ?? [];
  const tablesWithIssues = new Set(tableIds);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const filteredData = data.getSchemaTablesVacuumSummary.filter(
    makeFilter(searchTerm, "databaseName", "schemaName", "tableName")
  );
  return (
    <Panel
      title="Table VACUUM summary"
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        pageSize={10}
        className="grid-cols-[120px_120px_1fr_120px_160px_160px]"
        data={filteredData}
        defaultSortBy="vacuumMaxIndexPhases"
        columns={[
          {
            field: "databaseName",
            header: "Database",
          },
          {
            field: "schemaName",
            header: "Schema",
          },
          {
            field: "tableName",
            header: "Table",
            renderer: function TableCell({ rowData, fieldData }) {
              return (
                <Link
                  to={databaseTableVacuum(
                    rowData.databaseId,
                    rowData.schemaTableId
                  )}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "vacuumCount",
            style: "number",
            header: "Count",
            defaultSortOrder: "desc",
          },
          {
            field: "vacuumMaxIndexPhases",
            style: "number",
            header: "Max Index Phases",
            defaultSortOrder: "desc",
            renderer: function MaxIndexPhases({ fieldData, rowData }) {
              const isAffected = tablesWithIssues.has(rowData.schemaTableId);
              const severity = isAffected ? "warning" : undefined;

              const badge = isAffected ? (
                <Popover
                  content={
                    <>
                      VACUUMs on this table require several index phase passes,
                      which is inefficient: see Inefficient Index Phase insight
                      above.
                    </>
                  }
                >
                  <IssueSummaryBadge className="ml-2" severity={severity} />
                </Popover>
              ) : (
                <IssueSummaryBadge className="ml-2" severity={severity} />
              );

              return (
                <>
                  {fieldData}
                  {badge}
                </>
              );
            },
          },
          {
            field: "vacuumMaxDurationSecs",
            header: "Max Duration",
            defaultSortOrder: "desc",
            toSortable: sortNullsLast,
            renderer: function VacuumDurationCell({ fieldData }) {
              if (fieldData == null) {
                return "-";
              } else {
                return formatDuration(fieldData);
              }
            },
          },
        ]}
      />
    </Panel>
  );
};

export default VacuumAdvisorPerformance;
