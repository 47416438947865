import React from "react";
import { translateX, translate } from "utils/svg";

import classNames from "classnames";

import { formatTime, InteractionPoint, interactionPointsEqual } from "./util";
import styles from "./style.module.scss";

type Props = {
  width: number;
  height: number;
  tipYOffset?: number;
  tipLines: React.ReactElement[];
  hover: InteractionPoint | undefined;
  rangeEndpoint: InteractionPoint | undefined; // If selecting a range and one endpoint has already been selected
};

const Tooltip: React.FunctionComponent<Props> = ({
  width,
  height,
  tipYOffset = 0,
  tipLines,
  hover,
  rangeEndpoint,
}) => {
  if (!hover || hover.nearby.length === 0) {
    return null;
  }
  const tipXVal = hover.bottom.domain;
  const tipX = hover.bottom.range;

  let tipContent;
  let tipHeight;
  let rangeEndpointX;

  const tipLineHeight = 18;
  const tipHeightPadding = 14;
  if (rangeEndpoint && !interactionPointsEqual(hover, rangeEndpoint)) {
    const rangeEndpointVal = rangeEndpoint.bottom.domain;
    rangeEndpointX = rangeEndpoint.bottom.range;
    const [rangeStart, rangeEnd] =
      rangeEndpointVal < tipXVal
        ? [rangeEndpointVal, tipXVal]
        : [tipXVal, rangeEndpointVal];
    tipHeight = 4 * tipLineHeight + tipHeightPadding;
    tipContent = (
      <>
        <div className={styles.tooltipItem}>From:</div>
        <div className={styles.tooltipItem}>
          <span className={styles.tooltipItemRangeEndpoint}>
            {formatTime(rangeStart)}
          </span>
        </div>
        <div className={styles.tooltipItem}>To:</div>
        <div className={styles.tooltipItem}>
          <span className={styles.tooltipItemRangeEndpoint}>
            {formatTime(rangeEnd)}
          </span>
        </div>
      </>
    );
  } else {
    // the + 1 is for the timestamp line
    tipHeight = (tipLines.length + 1) * tipLineHeight + tipHeightPadding;
    tipContent = (
      <>
        <div className={styles.tooltipTime}>{formatTime(tipXVal)}</div>
        {tipLines}
      </>
    );
  }
  const tipPlaceLeft = tipX > width / 2;
  const tipY = tipYOffset + (tipLines.length > 5 ? 0 : 20);
  const tipContainerWidth = tipPlaceLeft ? tipX : width - tipX;
  const tipContainerX = tipPlaceLeft ? 0 : tipX;

  return (
    <>
      {rangeEndpointX && (
        <>
          {/* range selection endpoint line and selected region area */}
          <rect
            x={Math.min(rangeEndpointX, tipX)}
            y={0}
            width={Math.abs(tipX - rangeEndpointX)}
            height={height}
            pointerEvents="none"
            opacity="50%"
            fill="rgba(0, 0, 0, 0.3)"
          />
          <g transform={translateX(rangeEndpointX)} pointerEvents="none">
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={height}
              stroke="darkslategray"
              strokeWidth={1}
            />
          </g>
        </>
      )}
      <g transform={translate(tipContainerX, tipY)} pointerEvents="none">
        {/* tooltip content and its container as an overlay */}
        <foreignObject width={tipContainerWidth} height={tipHeight}>
          <div className={styles.tooltipContainer}>
            <div
              className={classNames(
                styles.tooltip,
                tipPlaceLeft ? styles.tooltipLeft : styles.tooltipRight
              )}
            >
              {tipContent}
            </div>
          </div>
        </foreignObject>
      </g>
      <g transform={translateX(tipX)} pointerEvents="none">
        {/* line indicating hover point */}
        <line
          x1={0}
          y1={0}
          x2={0}
          y2={Math.max(height, tipHeight + tipY)}
          stroke="darkslategray"
          strokeWidth={1}
        />
      </g>
    </>
  );
};

export default Tooltip;
