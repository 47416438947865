import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";

import DocsSnippet from "components/DocsSnippet";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import PanelSection from "components/PanelSection";
import SQL from "components/SQL";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import {
  BackendDetailsWaitEvents,
  BackendDetailsWaitEventsVariables,
} from "./types/BackendDetailsWaitEvents";
import Loading from "components/Loading";

type BackendSnapshotType = {
  id: string;
  collectedAt: number;
  waitEvent: string;
  waitEventType: string;
  queryTextShort: string | null;
};

type Props = {
  databaseId: string;
  backendLocator: string;
};

const eventTypeDocTexts = {
  /* 9.6 only */
  LWLockNamed:
    "The backend is waiting for a specific named lightweight lock. " +
    "Each such lock protects a particular data structure in shared memory. Event contains the name of the lightweight lock.",
  LWLockTranche:
    "The backend is waiting for one of a group of related lightweight locks. " +
    "All locks in the group perform a similar function; Event identifies the general purpose of locks in that group.",
  /* 9.6+ */
  Lock:
    "The server process is waiting for a heavyweight lock. Heavyweight locks, also known as lock manager locks or simply locks, " +
    "primarily protect SQL-visible objects such as tables. However, they are also used to ensure mutual exclusion for certain " +
    "internal operations such as relation extension. Event identifies the type of lock awaited.",
  BufferPin:
    "The server process is waiting for exclusive access to a data buffer. " +
    "Buffer pin waits can be protracted if another process holds an open cursor that last read data from the buffer in question.",
  /* 10+ */
  LWLock:
    "The server process is waiting for a lightweight lock. Most such locks protect a particular data structure " +
    "in shared memory. Event contains a name identifying the purpose of the lightweight lock. " +
    "(Some locks have specific names; others are part of a group of locks each with a similar purpose.)",
  Activity:
    "The server process is idle. This event type indicates a process waiting for activity in its main processing loop. " +
    "Event identifies the specific wait point.",
  Client:
    "The server process is waiting for activity on a socket connected to a user application. " +
    "Thus, the server expects something to happen that is independent of its internal processes. " +
    "Event identifies the specific wait point.",
  Extension:
    "The server process is waiting for some condition defined by an extension module.",
  IPC:
    "The server process is waiting for some interaction with another server process. " +
    "Event identifies the specific wait point.",
  Timeout:
    "The server process is waiting for a timeout to expire. Event identifies the specific wait point.",
  IO: "The server process is waiting for an I/O operation to complete. Event identifies the specific wait point.",
};

const eventDocTexts = {
  /* LWLockNamed */
  ShmemIndexLock: "Waiting to find or allocate space in shared memory.",
  OidGenLock: "Waiting to allocate or assign an OID.",
  XidGenLock: "Waiting to allocate or assign a transaction id.",
  ProcArrayLock:
    "Waiting to access the shared per-process data structures " +
    "(typically, to get a snapshot or report a session's transaction ID).",
  SInvalReadLock:
    "Waiting to retrieve messages from the shared catalog invalidation queue.",
  SInvalWriteLock:
    "Waiting to add a message to the shared catalog invalidation queue.",
  WALBufMappingLock: "Waiting to replace a page in WAL buffers.",
  WALWriteLock: "Waiting for WAL buffers to be written to disk.",
  ControlFileLock:
    "Waiting to read or update the pg_control file or create a new WAL file.",
  CheckpointLock: "Waiting to begin a checkpoint.",
  CLogControlLock: "Waiting to read or update transaction status.",
  XactSLRULock: "Waiting to access the transaction status SLRU cache.",
  SubtransControlLock: "Waiting to read or update sub-transaction information.",
  SubtransSLRULock: "Waiting to access the sub-transaction SLRU cache.",
  MultiXactGenLock: "Waiting to read or update shared multixact state.",
  MultiXactOffsetControlLock:
    "Waiting to read or update multixact offset mappings.",
  MultiXactOffsetSLRULock: "Waiting to access the multixact offset SLRU cache.",
  MultiXactMemberControlLock:
    "Waiting to read or update multixact member mappings.",
  MultiXactMemberSLRULock: "Waiting to access the multixact member SLRU cache.",
  RelCacheInitLock:
    "Waiting to read or update a pg_internal.init relation cache initialization file.",
  CheckpointerCommLock: "Waiting to manage fsync requests.",
  TwoPhaseStateLock:
    "Waiting to read or update the state of prepared transactions.",
  TablespaceCreateLock: "Waiting to create or drop a tablespace.",
  BtreeVacuumLock:
    "Waiting to read or update vacuum-related information for a B-tree index.",
  AddinShmemInitLock:
    "Waiting to manage an extension's space allocation in shared memory.",
  AutovacuumLock:
    "Waiting to read or update the current state of autovacuum workers.",
  AutovacuumScheduleLock:
    "Waiting to ensure that a table selected for autovacuum still needs vacuuming.",
  SyncScanLock:
    "Waiting to select the starting location of a synchronized table scan.",
  RelationMappingLock:
    "Waiting to read or update a pg_filenode.map file " +
    "(used to track the filenode assignments of certain system catalogs).",
  AsyncCtlLock: "Waiting to read or update shared notification state.",
  NotifySLRULock: "Waiting to access the NOTIFY message SLRU cache.",
  AsyncQueueLock: "Waiting to read or update notification messages.",
  NotifyQueueLock: "Waiting to read or update NOTIFY messages.",
  SerializableXactHashLock:
    "Waiting to read or update information about serializable transactions.",
  SerializableFinishedListLock:
    "Waiting to access the list of finished serializable transactions.",
  SerializablePredicateLockListLock:
    "Waiting to perform an operation on a list of locks held by serializable transactions.",
  SerializablePredicateListLock:
    "Waiting to access the list of predicate locks held by serializable transactions.",
  OldSerXidLock:
    "Waiting to read or record conflicting serializable transactions.",
  SerialSLRULock:
    "Waiting to access the serializable transaction conflict SLRU cache.",
  SyncRepLock:
    "Waiting to read or update information about the state of synchronous replication.",
  BackgroundWorkerLock: "Waiting to read or update background worker state.",
  DynamicSharedMemoryControlLock:
    "Waiting to read or update dynamic shared memory allocation information.",
  AutoFileLock: "Waiting to update the postgresql.auto.conf file.",
  ReplicationSlotAllocationLock:
    "Waiting to allocate or free a replication slot.",
  ReplicationSlotControlLock:
    "Waiting to read or update replication slot state.",
  CommitTsControlLock:
    "Waiting to read or update transaction commit timestamps.",
  CommitTsSLRULock: "Waiting to access the commit timestamp SLRU cache.",
  CommitTsLock:
    "Waiting to read or update the last value set for a transaction commit timestamp.",
  ReplicationOriginLock: "Waiting to create, drop or use a replication origin.",
  MultiXactTruncationLock: "Waiting to read or truncate multixact information.",
  OldSnapshotTimeMapLock:
    "Waiting to read or update old snapshot control information.",
  BackendRandomLock: "Waiting to generate a random number.",
  LogicalRepWorkerLock:
    "Waiting to read or update the state of logical replication workers.",
  CLogTruncationLock:
    "Waiting to truncate the write-ahead log or waiting for write-ahead log truncation to finish.",
  XactTruncationLock:
    "Waiting to execute pg_xact_status or update the oldest transaction ID available to it.",
  WrapLimitsVacuum:
    "Waiting to update limits on transaction id and multixact consumption.",
  NotifyQueueTailLock: "Waiting to update limit on NOTIFY message storage.",
  /* LWLockTranche */
  clog: "Waiting for I/O on a clog (transaction status) buffer.",
  XactBuffer: "Waiting for I/O on a transaction status SLRU buffer.",
  commit_timestamp: "Waiting for I/O on commit timestamp buffer.",
  CommitTsBuffer: "Waiting for I/O on a commit timestamp SLRU buffer.",
  subtrans: "Waiting for I/O a subtransaction buffer.",
  SubtransBuffer: "Waiting for I/O on a sub-transaction SLRU buffer.",
  multixact_offset: "Waiting for I/O on a multixact offset buffer.",
  MultiXactOffsetBuffer: "Waiting for I/O on a multixact offset SLRU buffer.",
  multixact_member: "Waiting for I/O on a multixact_member buffer.",
  MultiXactMemberBuffer: "Waiting for I/O on a multixact member SLRU buffer.",
  async: "Waiting for I/O on an async (notify) buffer.",
  NotifyBuffer: "Waiting for I/O on a NOTIFY message SLRU buffer.",
  oldserxid: "Waiting to I/O on an oldserxid buffer.",
  SerialBuffer:
    "Waiting for I/O on a serializable transaction conflict SLRU buffer.",
  wal_insert: "Waiting to insert WAL into a memory buffer.",
  WALInsert: "Waiting to insert WAL data into a memory buffer.",
  buffer_content: "Waiting to read or write a data page in memory.",
  BufferContent: "Waiting to access a data page in memory.",
  buffer_io: "Waiting for I/O on a data page.",
  BufferIO: "Waiting for I/O on a data page.",
  replication_origin: "Waiting to read or update the replication progress.",
  replication_slot_io: "Waiting for I/O on a replication slot.",
  proc: "Waiting to read or update the fast-path lock information.",
  LockFastPath:
    "Waiting to read or update a process' fast-path lock information.",
  buffer_mapping:
    "Waiting to associate a data block with a buffer in the buffer pool.",
  BufferMapping:
    "Waiting to associate a data block with a buffer in the buffer pool.",
  lock_manager:
    "Waiting to add or examine locks for backends, or waiting to join or exit a locking group (used by parallel query).",
  LockManager: "Waiting to read or update information about heavyweight locks.",
  predicate_lock_manager:
    "Waiting to add or examine predicate lock information.",
  PredicateLockManager:
    "Waiting to access predicate lock information used by serializable transactions.",
  parallel_query_dsa:
    "Waiting for parallel query dynamic shared memory allocation lock.",
  ParallelQueryDSA:
    "Waiting for parallel query dynamic shared memory allocation.",
  tbm: "Waiting for TBM shared iterator lock.",
  SharedTidBitmap:
    "Waiting to access a shared TID bitmap during a parallel bitmap index scan.",
  parallel_append:
    "Waiting to choose the next subplan during Parallel Append plan execution.",
  ParallelAppend:
    "Waiting to choose the next subplan during Parallel Append plan execution.",
  parallel_hash_join:
    "Waiting to allocate or exchange a chunk of memory or update counters during Parallel Hash plan execution.",
  ParallelHashJoin:
    "Waiting to synchronize workers during Parallel Hash Join plan execution.",
  session_dsa: "Waiting for parallel query dynamic shared memory allocation.",
  PerSessionDSA: "Waiting for parallel query dynamic shared memory allocation.",
  session_record_table:
    "Waiting to access a parallel query's information about composite types.",
  PerSessionRecordType:
    "Waiting to access a parallel query's information about composite types.",
  session_typmod_table:
    "Waiting to access a parallel query's information about type modifiers that identify anonymous record types.",
  PerSessionRecordTypmod:
    "Waiting to access a parallel query's information about type modifiers that identify anonymous record types.",
  serializable_xact:
    "Waiting to access the list of predicate locks held by the current serializable transaction during a parallel query.",
  PerXactPredicateList:
    "Waiting to access the list of predicate locks held by the current serializable transaction during a parallel query.",
  shared_tuplestore:
    "Waiting to access a shared tuple store during parallel query.",
  SharedTupleStore:
    "Waiting to access a shared tuple store during parallel query.",
  /* Lock */
  relation: "Waiting to acquire a lock on a relation.",
  extend: "Waiting to extend a relation.",
  page: "Waiting to acquire a lock on a page of a relation.",
  tuple: "Waiting to acquire a lock on a tuple.",
  transactionid: "Waiting for a transaction to finish.",
  virtualxid: "Waiting to acquire a virtual transaction ID lock.",
  "speculative token": "Waiting to acquire a speculative insertion lock.",
  spectoken: "Waiting to acquire a speculative insertion lock.",
  object: "Waiting to acquire a lock on a non-relation database object.",
  userlock: "Waiting to acquire a userlock.",
  advisory: "Waiting to acquire an advisory user lock.",
  frozenid:
    "Waiting to update pg_database.datfrozenxid and pg_database.datminmxid.",
  /* BufferPin */
  BufferPin: "Waiting to acquire an exclusive pin on a buffer.",
  /* Activity */
  ArchiverMain: "Waiting in main loop of archiver process.",
  AutoVacuumMain: "Waiting in main loop of autovacuum launcher process.",
  BgWriterHibernate: "Waiting in background writer process, hibernating.",
  BgWriterMain: "Waiting in main loop of background writer process.",
  CheckpointerMain: "Waiting in main loop of checkpointer process.",
  LogicalApplyMain:
    "Waiting in main loop of logical replication apply process.",
  LogicalLauncherMain:
    "Waiting in main loop of logical replication launcher process.",
  PgStatMain: "Waiting in main loop of statistics collector process.",
  RecoveryWalAll:
    "Waiting in main loop of startup process for WAL to arrive, during streaming recovery.",
  RecoveryWalStream: "Waiting for WAL from a stream at recovery.",
  SysLoggerMain: "Waiting in main loop of syslogger process.",
  WalReceiverMain: "Waiting in main loop of WAL receiver process.",
  WalSenderMain: "Waiting in main loop of WAL sender process.",
  WalWriterMain: "Waiting in main loop of WAL writer process.",
  /* Client */
  ClientRead: "Waiting to read data from the client.",
  ClientWrite: "Waiting to write data to the client.",
  GSSOpenServer:
    "Waiting to read data from the client while establishing a GSSAPI session.",
  LibPQWalReceiverConnect:
    "Waiting in WAL receiver to establish connection to remote server.",
  LibPQWalReceiverReceive:
    "Waiting in WAL receiver to receive data from remote server.",
  SSLOpenServer: "Waiting for SSL while attempting connection.",
  WalReceiverWaitStart:
    "Waiting for startup process to send initial data for streaming replication.",
  WalSenderWaitForWAL: "Waiting for WAL to be flushed in WAL sender process.",
  WalSenderWriteData:
    "Waiting for any activity when processing replies from WAL receiver in WAL sender process.",
  /* Extension */
  Extension: "Waiting in an extension.",
  /* IPC */
  AppendReady: "Waiting for subplan nodes of an Append plan node to be ready.",
  BackendTermination: "Waiting for the termination of another backend.",
  BackupWaitWalArchive:
    "Waiting for WAL files required for the backup to be successfully archived.",
  BgWorkerShutdown: "Waiting for background worker to shut down.",
  BgWorkerStartup: "Waiting for background worker to start up.",
  BtreePage:
    "Waiting for the page number needed to continue a parallel B-tree scan to become available.",
  CheckpointDone: "Waiting for a checkpoint to complete.",
  CheckpointStart: "Waiting for a checkpoint to start.",
  ClogGroupUpdate:
    "Waiting for group leader to update transaction status at transaction end.",
  ExecuteGather:
    "Waiting for activity from a child process when executing a Gather plan node.",
  "Hash/Batch/Allocating":
    "Waiting for an elected Parallel Hash participant to allocate a hash table.",
  HashBatchAllocate:
    "Waiting for an elected Parallel Hash participant to allocate a hash table.",
  "Hash/Batch/Electing":
    "Electing a Parallel Hash participant to allocate a hash table.",
  HashBatchElect:
    "Waiting to elect a Parallel Hash participant to allocate a hash table.",
  "Hash/Batch/Loading":
    "Waiting for other Parallel Hash participants to finish loading a hash table.",
  HashBatchLoad:
    "Waiting for other Parallel Hash participants to finish loading a hash table.",
  "Hash/Build/Allocating":
    "Waiting for an elected Parallel Hash participant to allocate the initial hash table.",
  HashBuildAllocate:
    "Waiting for an elected Parallel Hash participant to allocate the initial hash table.",
  "Hash/Build/Electing":
    "Electing a Parallel Hash participant to allocate the initial hash table.",
  HashBuildElect:
    "Waiting to elect a Parallel Hash participant to allocate the initial hash table.",
  "Hash/Build/HashingInner":
    "Waiting for other Parallel Hash participants to finish hashing the inner relation.",
  HashBuildHashInner:
    "Waiting for other Parallel Hash participants to finish hashing the inner relation.",
  "Hash/Build/HashingOuter":
    "Waiting for other Parallel Hash participants to finish partitioning the outer relation.",
  HashBuildHashOuter:
    "Waiting for other Parallel Hash participants to finish partitioning the outer relation.",
  "Hash/GrowBatches/Allocating":
    "Waiting for an elected Parallel Hash participant to allocate more batches.",
  HashGrowBatchesAllocate:
    "Waiting for an elected Parallel Hash participant to allocate more batches.",
  "Hash/GrowBatches/Deciding":
    "Electing a Parallel Hash participant to decide on future batch growth.",
  HashGrowBatchesDecide:
    "Waiting to elect a Parallel Hash participant to decide on future batch growth.",
  "Hash/GrowBatches/Electing":
    "Electing a Parallel Hash participant to allocate more batches.",
  HashGrowBatchesElect:
    "Waiting to elect a Parallel Hash participant to allocate more batches.",
  "Hash/GrowBatches/Finishing":
    "Waiting for an elected Parallel Hash participant to decide on future batch growth.",
  HashGrowBatchesFinish:
    "Waiting for an elected Parallel Hash participant to decide on future batch growth.",
  "Hash/GrowBatches/Repartitioning":
    "Waiting for other Parallel Hash participants to finishing repartitioning.",
  HashGrowBatchesRepartition:
    "Waiting for other Parallel Hash participants to finish repartitioning.",
  "Hash/GrowBuckets/Allocating":
    "Waiting for an elected Parallel Hash participant to finish allocating more buckets.",
  HashGrowBucketsAllocate:
    "Waiting for an elected Parallel Hash participant to finish allocating more buckets.",
  "Hash/GrowBuckets/Electing":
    "Electing a Parallel Hash participant to allocate more buckets.",
  HashGrowBucketsElect:
    "Waiting to elect a Parallel Hash participant to allocate more buckets.",
  "Hash/GrowBuckets/Reinserting":
    "Waiting for other Parallel Hash participants to finish inserting tuples into new buckets.",
  HashGrowBucketsReinsert:
    "Waiting for other Parallel Hash participants to finish inserting tuples into new buckets.",
  LogicalSyncData:
    "Waiting for a logical replication remote server to send data for initial table synchronization.",
  LogicalSyncStateChange:
    "Waiting for a logical replication remote server to change state.",
  MessageQueueInternal:
    "Waiting for other process to be attached in shared message queue.",
  MessageQueuePutMessage:
    "Waiting for another process to be attached to a shared message queue.",
  MessageQueueReceive: "Waiting to receive bytes from a shared message queue.",
  MessageQueueSend: "Waiting to send bytes to a shared message queue.",
  ParallelBitmapScan: "Waiting for parallel bitmap scan to become initialized.",
  ParallelCreateIndexScan:
    "Waiting for parallel CREATE INDEX workers to finish heap scan.",
  ParallelFinish: "Waiting for parallel workers to finish computing.",
  ProcArrayGroupUpdate:
    "Waiting for the group leader to clear the transaction ID at end of a parallel operation.",
  RecoveryConflictSnapshot:
    "Waiting for recovery conflict resolution on a vacuum cleanup.",
  RecoveryConflictTablespace:
    "Waiting for recovery conflict resolution on dropping tablespace.",
  RecoveryPause: "Waiting for recovery to be resumed.",
  ReplicationOriginDrop:
    "Waiting for a replication origin to become inactive so it can be dropped.",
  ReplicationSlotDrop:
    "Waiting for a replication slot to become inactive so it can be dropped.",
  SafeSnapshot:
    "Waiting to obtain a valid snapshot for a READ ONLY DEFERRABLE transaction.",
  SyncRep:
    "Waiting for confirmation from a remote server during synchronous replication.",
  XactGroupUpdate:
    "Waiting for the group leader to update transaction status at end of a parallel operation.",
  /* Timeout */
  BaseBackupThrottle: "Waiting during base backup when throttling activity.",
  PgSleep: "Waiting due to a call to pg_sleep or a sibling function.",
  RecoveryApplyDelay:
    "Waiting to apply WAL during recovery because of a delay setting.",
  RecoveryRetrieveRetryInterval:
    "Waiting when WAL data is not available from any kind of sources (pg_wal, archive or stream) " +
    "before trying again to retrieve WAL data, at recovery.",
  RegisterSyncRequest:
    "Waiting while sending synchronization requests to the checkpointer, because the request queue is full.",
  VacuumDelay: "Waiting in a cost-based vacuum delay point.",
  VacuumTruncate:
    "Waiting to acquire an exclusive lock to truncate off any empty pages at the end of a table vacuumed.",
  /* IO */
  BufFileRead: "Waiting for a read from a buffered file.",
  BufFileWrite: "Waiting for a write to a buffered file.",
  ControlFileRead: "Waiting for a read from the pg_control file.",
  ControlFileSync: "Waiting for the pg_control file to reach stable storage.",
  ControlFileSyncUpdate:
    "Waiting for an update to the pg_control file to reach stable storage.",
  ControlFileWrite: "Waiting for a write to the pg_control file.",
  ControlFileWriteUpdate: "Waiting for a write to update the pg_control file.",
  CopyFileRead: "Waiting for a read during a file copy operation.",
  CopyFileWrite: "Waiting for a write during a file copy operation.",
  DataFileExtend: "Waiting for a relation data file to be extended.",
  DataFileFlush: "Waiting for a relation data file to reach stable storage.",
  DataFileImmediateSync:
    "Waiting for an immediate synchronization of a relation data file to stable storage.",
  DataFilePrefetch:
    "Waiting for an asynchronous prefetch from a relation data file.",
  DataFileRead: "Waiting for a read from a relation data file.",
  DataFileSync:
    "Waiting for changes to a relation data file to reach stable storage.",
  DataFileTruncate: "Waiting for a relation data file to be truncated.",
  DataFileWrite: "Waiting for a write to a relation data file.",
  DSMFillZeroWrite:
    "Waiting to fill a dynamic shared memory backing file with zeroes.",
  LockFileAddToDataDirRead:
    "Waiting for a read while adding a line to the data directory lock file.",
  LockFileAddToDataDirSync:
    "Waiting for data to reach stable storage while adding a line to the data directory lock file.",
  LockFileAddToDataDirWrite:
    "Waiting for a write while adding a line to the data directory lock file.",
  LockFileCreateRead:
    "Waiting to read while creating the data directory lock file.",
  LockFileCreateSync:
    "Waiting for data to reach stable storage while creating the data directory lock file.",
  LockFileCreateWrite:
    "Waiting for a write while creating the data directory lock file.",
  LockFileReCheckDataDirRead:
    "Waiting for a read during recheck of the data directory lock file.",
  LogicalRewriteCheckpointSync:
    "Waiting for logical rewrite mappings to reach stable storage during a checkpoint.",
  LogicalRewriteMappingSync:
    "Waiting for mapping data to reach stable storage during a logical rewrite.",
  LogicalRewriteMappingWrite:
    "Waiting for a write of mapping data during a logical rewrite.",
  LogicalRewriteSync:
    "Waiting for logical rewrite mappings to reach stable storage.",
  LogicalRewriteWrite: "Waiting for a write of logical rewrite mappings.",
  RelationMapRead: "Waiting for a read of the relation map file.",
  RelationMapSync: "Waiting for the relation map file to reach stable storage.",
  RelationMapWrite: "Waiting for a write to the relation map file.",
  ReorderBufferRead: "Waiting for a read during reorder buffer management.",
  ReorderBufferWrite: "Waiting for a write during reorder buffer management.",
  ReorderLogicalMappingRead:
    "Waiting for a read of a logical mapping during reorder buffer management.",
  ReplicationSlotRead:
    "Waiting for a read from a replication slot control file.",
  ReplicationSlotRestoreSync:
    "Waiting for a replication slot control file to reach stable storage while restoring it to memory.",
  ReplicationSlotSync:
    "Waiting for a replication slot control file to reach stable storage.",
  ReplicationSlotWrite:
    "Waiting for a write to a replication slot control file.",
  SLRUFlushSync:
    "Waiting for SLRU data to reach stable storage during a checkpoint or database shutdown.",
  SLRURead: "Waiting for a read of an SLRU page.",
  SLRUSync:
    "Waiting for SLRU data to reach stable storage following a page write.",
  SLRUWrite: "Waiting for a write of an SLRU page.",
  SnapbuildRead:
    "Waiting for a read of a serialized historical catalog snapshot.",
  SnapbuildSync:
    "Waiting for a serialized historical catalog snapshot to reach stable storage.",
  SnapbuildWrite:
    "Waiting for a write of a serialized historical catalog snapshot.",
  TimelineHistoryFileSync:
    "Waiting for a timeline history file received via streaming replication to reach stable storage.",
  TimelineHistoryFileWrite:
    "Waiting for a write of a timeline history file received via streaming replication.",
  TimelineHistoryRead: "Waiting for a read of a timeline history file.",
  TimelineHistorySync:
    "Waiting for a newly created timeline history file to reach stable storage.",
  TimelineHistoryWrite:
    "Waiting for a write of a newly created timeline history file.",
  TwophaseFileRead: "Waiting for a read of a two phase state file.",
  TwophaseFileSync:
    "Waiting for a two phase state file to reach stable storage.",
  TwophaseFileWrite: "Waiting for a write of a two phase state file.",
  WALBootstrapSync:
    "Waiting for WAL to reach stable storage during bootstrapping.",
  WALBootstrapWrite: "Waiting for a write of a WAL page during bootstrapping.",
  WALCopyRead:
    "Waiting for a read when creating a new WAL segment by copying an existing one.",
  WALCopySync:
    "Waiting a new WAL segment created by copying an existing one to reach stable storage.",
  WALCopyWrite:
    "Waiting for a write when creating a new WAL segment by copying an existing one.",
  WALInitSync:
    "Waiting for a newly initialized WAL file to reach stable storage.",
  WALInitWrite: "Waiting for a write while initializing a new WAL file.",
  WALRead: "Waiting for a read from a WAL file.",
  WALSenderTimelineHistoryRead:
    "Waiting for a read from a timeline history file during a walsender timeline command.",
  WALSyncMethodAssign:
    "Waiting for data to reach stable storage while assigning a new WAL sync method.",
  WALWrite: "Waiting for a write to a WAL file.",
  ProcSignalBarrier:
    "Waiting for a barrier event to be processed by all backends.",
};

const WaitEvents: React.FunctionComponent<Props> = ({
  databaseId,
  backendLocator,
}) => {
  const { data, loading, error } = useQuery<
    BackendDetailsWaitEvents,
    BackendDetailsWaitEventsVariables
  >(QUERY, {
    variables: {
      databaseId,
      backendLocator,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const snapshots = sortBy(
    data.getBackendDetails.snapshots,
    (s: BackendSnapshotType): number => -s.collectedAt
  );

  return (
    <Panel title="Wait Events">
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>Seen At</th>
            <th>Event Type</th>
            <th>Event</th>
            <th>Query</th>
          </tr>
        </thead>
        <tbody>
          {snapshots.map(
            (s: BackendSnapshotType): React.ReactNode => (
              <WaitEventSnapshot snapshot={s} key={s.id} />
            )
          )}
        </tbody>
      </PanelTable>
      <PanelSection>
        <strong>Hint:</strong> Wait events are sampled every 10 seconds and
        require Postgres 9.6 or newer.
      </PanelSection>
    </Panel>
  );
};

const WaitEventSnapshot: React.FunctionComponent<{
  snapshot: BackendSnapshotType;
}> = ({ snapshot }) => {
  return (
    <tr>
      <td>{moment.unix(snapshot.collectedAt).format("ll LTS z")}</td>
      <td>
        {snapshot.waitEventType}
        {eventTypeDocTexts[snapshot.waitEventType] && (
          <span className={styles.docSnippet}>
            <DocsSnippet
              href="/docs/wait_events"
              title={snapshot.waitEventType}
              content={eventTypeDocTexts[snapshot.waitEventType]}
            />
          </span>
        )}
      </td>
      <td>
        {snapshot.waitEvent}
        {eventDocTexts[snapshot.waitEvent] && (
          <span className={styles.docSnippet}>
            <DocsSnippet
              href="/docs/wait_events"
              title={snapshot.waitEvent}
              content={eventDocTexts[snapshot.waitEvent]}
            />
          </span>
        )}
      </td>
      <td>
        {(snapshot.queryTextShort && (
          <SQL inline sql={snapshot.queryTextShort} />
        )) ||
          "-"}
      </td>
    </tr>
  );
};

export default WaitEvents;
