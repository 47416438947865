import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import moment from "moment-timezone";

import { useRoutes } from "utils/routes";
import CheckStatusBadge from "components/CheckStatusBadge";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelVirtualTable from "components/PanelVirtualTable";
import PanelTitleSearch from "components/PanelTitleSearch";
import SQL from "components/SQL";

import {
  ResolvedIssueList as ResolvedIssueListType,
  ResolvedIssueListVariables,
  ResolvedIssueList_getIssues as IssueType,
} from "./types/ResolvedIssueList";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

type Props = {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
  checkGroupAndName: string;
  startTs?: number;
  endTs?: number;
};

const ISSUE_SEVERITY_TO_ORDER = {
  critical: 3,
  warning: 2,
  info: 1,
  okay: 0,
};

const ResolvedIssueList: React.FunctionComponent<Props> = ({
  organizationSlug,
  serverId,
  databaseId,
  checkGroupAndName,
  startTs,
  endTs,
}) => {
  const { server, database, serverIssue, databaseIssue } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  const now = useMemo(() => moment(), []);
  const { data, loading, error } = useQuery<
    ResolvedIssueListType,
    ResolvedIssueListVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      serverId,
      databaseId,
      checkGroupsAndNames: [checkGroupAndName],
      state: "resolved",
      startTs: startTs || now.clone().subtract(7, "d").unix(),
      endTs: endTs || now.unix(),
    },
  });
  if (loading || error || !data) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );
  const issueUrl = (issue: IssueType): string => {
    return issue.database
      ? databaseIssue(issue.database.id, issue.id, checkGroupAndName)
      : serverIssue(issue.server.humanId, issue.id, checkGroupAndName);
  };

  const issues: IssueType[] = data.getIssues
    .map((i) => ({
      serverName: i.server.name,
      databaseName: i.database?.datname,
      severityOrder: ISSUE_SEVERITY_TO_ORDER[i.severity],
      triggeredAt: i.createdAt,
      resolvedAt: i.updatedAt,
      duration: i.updatedAt - i.createdAt,
      ...i,
    }))
    .filter((i) => {
      return (
        searchTerm === "" ||
        i.severity.toLowerCase().includes(searchTerm) ||
        i.serverName.toLowerCase().includes(searchTerm) ||
        i.databaseName?.toLowerCase().includes(searchTerm) ||
        i.description?.toLowerCase().includes(searchTerm) ||
        i.queryText?.toLowerCase().includes(searchTerm)
      );
    });

  return (
    <Panel title="Resolved In Last 7 Days" secondaryTitle={titleSearch}>
      <PanelVirtualTable
        data={issues}
        initialSortBy="resolvedAt"
        initialSortDirection={SortDirection.DESC}
        rowHeight={issues[0]?.queryText ? 50 : 30}
      >
        <Column
          dataKey="severityOrder"
          label="Severity"
          width={80}
          cellRenderer={({
            rowData,
          }: {
            rowData: IssueType;
          }): React.ReactNode => <CheckStatusBadge status={rowData.severity} />}
        />
        <Column
          dataKey="serverName"
          label="Server"
          width={100}
          cellRenderer={({
            rowData,
            cellData,
          }: {
            rowData: IssueType;
            cellData?: string;
          }): React.ReactNode => (
            <Link to={server(rowData.server.humanId)}>{cellData}</Link>
          )}
        />
        <Column
          dataKey="databaseName"
          label="Database"
          width={100}
          cellRenderer={({
            rowData,
            cellData,
          }: {
            rowData: IssueType;
            cellData?: string;
          }): React.ReactNode =>
            rowData.database ? (
              <Link to={database(rowData.database.id)}>{cellData}</Link>
            ) : (
              "n/a"
            )
          }
        />
        <Column
          dataKey="description"
          label="Description"
          width={200}
          flexGrow={1}
          cellRenderer={({
            rowData,
            cellData,
          }: {
            rowData: IssueType;
            cellData?: string;
          }): React.ReactNode => {
            const { queryText } = rowData;

            return (
              <Link to={issueUrl(rowData)}>
                {cellData}
                {queryText && (
                  <div className={styles.issueQuery}>
                    <SQL inline sql={queryText} />
                  </div>
                )}
              </Link>
            );
          }}
        />
        <Column
          dataKey="triggeredAt"
          label="First Triggered At"
          width={200}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode =>
            cellData ? moment.unix(cellData).format("ll LTS z") : "n/a"
          }
        />
        <Column
          dataKey="duration"
          label="Duration"
          width={150}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode =>
            cellData ? moment.duration(cellData * 1000).humanize() : "n/a"
          }
        />
        <Column
          dataKey="resolvedAt"
          label="Resolved At"
          width={200}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode =>
            cellData ? moment.unix(cellData).format("ll LTS z") : "n/a"
          }
        />
      </PanelVirtualTable>
    </Panel>
  );
};

export default ResolvedIssueList;
