import React from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import toPairs from "lodash/toPairs";
import moment, { Moment } from "moment-timezone";
import Color from "color";
import { formatBytes } from "utils/format";

import PanelSection from "components/PanelSection";
import withLoading from "decorators/withLoading";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

import { CheckpointStarting as CheckpointStartingType } from "./types/CheckpointStarting";
import TimeBucketBar from "components/TimeBucketBar";
import { useRoutes } from "utils/routes";

const REASON_DETAILS = {
  time: {
    title: "time",
    color: Color("#8FBC8F"),
  },
  xlog: {
    title: "xlog",
    color: Color("#FF7F50"),
  },
  unknown: {
    title: "Unknown",
    color: Color("#ccc"),
  },
};

type LogStatsType = {
  timebucket: number;
  classification: string;
  count: number;
};

type Props = {
  data: CheckpointStartingType;
  databaseId: string;
  serverId: string;
  startTs: number;
  endTs: number;
  highlightedTime: moment.Moment | null | undefined;
};

const CheckpointStarting: React.FunctionComponent<Props> = ({
  data,
  serverId,
  startTs,
  endTs,
  highlightedTime,
}) => {
  const { serverConfigSetting } = useRoutes();
  const timeMin = startTs;
  const timeMax = endTs;

  const classificationDetailsKeys = Object.keys(REASON_DETAILS);
  const logStats = data.getLogAnalysisCheckpointStarting.reasons;

  let logStatsByClassification = toPairs(
    groupBy(logStats, (l: LogStatsType): string => l.classification)
  );
  logStatsByClassification = sortBy(logStatsByClassification, ([c]): number => {
    const idx = classificationDetailsKeys.indexOf(c);
    return (idx === -1 && Number.MAX_VALUE) || idx;
  });

  return (
    <PanelSection>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              <strong>Reason for Checkpoint Starting:</strong>
            </td>
          </tr>
          {logStatsByClassification.map(([classification, stats]) => (
            <ClassificationLogStats
              key={classification}
              {...{ stats, classification, timeMax, timeMin, highlightedTime }}
            />
          ))}
          <tr>
            <td className={styles.configSettingsTitle}>
              <strong>Related Config Parameters:</strong>
            </td>
          </tr>
          <tr>
            <td className={styles.configSetting}>
              <Link
                to={serverConfigSetting(
                  serverId,
                  "checkpoint_completion_target"
                )}
              >
                checkpoint_completion_target
              </Link>{" "}
              = {data.checkpointCompletionTargetSetting?.value ?? "n/a"}
            </td>
          </tr>
          <tr>
            <td className={styles.configSetting}>
              <Link to={serverConfigSetting(serverId, "checkpoint_timeout")}>
                checkpoint_timeout
              </Link>{" "}
              = {data.checkpointTimeoutSetting?.value ?? "n/a"}
            </td>
          </tr>
          {data.maxWalSizeSetting && (
            <tr>
              <td className={styles.configSetting}>
                <Link to={serverConfigSetting(serverId, "max_wal_size")}>
                  max_wal_size
                </Link>{" "}
                = {data.maxWalSizeSetting.value}
              </td>
            </tr>
          )}
          {data.checkpointSegments && (
            <tr>
              <td className={styles.configSetting}>
                <Link to={serverConfigSetting(serverId, "checkpoint_segments")}>
                  checkpoint_segments
                </Link>{" "}
                = {data.checkpointSegments.value} (
                {formatBytes(
                  parseInt(data.checkpointSegments.value, 10) * 16 * 1024 * 1024
                )}
                )
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </PanelSection>
  );
};

const ClassificationLogStats: React.FunctionComponent<{
  stats: Array<LogStatsType>;
  classification: string;
  timeMin: number;
  timeMax: number;
  highlightedTime: moment.Moment;
}> = ({ stats, classification, timeMin, timeMax, highlightedTime }) => {
  const maxCount = maxBy(stats, (g: LogStatsType): number => g.count).count;
  const totalCount = sumBy(stats, (g: LogStatsType): number => g.count);
  const details = REASON_DETAILS[classification];

  const buckets = stats
    .filter((group) => group.timebucket >= timeMin)
    .map((group) => {
      const start = moment.unix(group.timebucket);
      return {
        start,
        value: group.count,
      };
    });
  const representativeGroup = stats[0];
  const TimeBucketTip: React.FunctionComponent<{
    start: Moment;
    end: Moment;
    value: number;
  }> = ({ start, end, value }) => (
    <div>
      <strong>{value}</strong> &times;{" "}
      {(details && details.title) || representativeGroup.classification}
      <div className={styles.groupInfoTime}>
        {start.format("MMM DD hh:mma")}
        &mdash;
        {end.format("hh:mma zz")}
      </div>
    </div>
  );
  const from = moment.unix(timeMin);
  const to = moment.unix(timeMax);
  const bucketSize = moment.duration(
    to.diff(from, "days", true) <= 3 ? 10 : 60,
    "minutes"
  );
  return (
    <>
      <tr key={classification + "_title"}>
        <td className={styles.classificationTitle}>
          {totalCount} &times; {(details && details.title) || classification}
        </td>
      </tr>
      <tr key={classification}>
        <td className={styles.classificationGroups}>
          <TimeBucketBar
            buckets={buckets}
            bucketSize={bucketSize}
            maxValue={maxCount}
            color={details && details.color}
            from={from}
            to={to}
            highlight={highlightedTime}
            tip={TimeBucketTip}
          />
        </td>
      </tr>
    </>
  );
};

export default flowRight(graphql(QUERY), withLoading)(CheckpointStarting);
