import React from "react";

import { line, area } from "d3-shape";
import { extent } from "d3-array";

import {
  Datum,
  StackedDatum,
  xValue,
  yValue,
  yBottom,
  yTop,
  SeriesProps,
  StackedSeriesProps,
  defined,
  DatumRef,
} from "./util";

type HoverProps = {
  hover: DatumRef;
  tooltipX: number;
};

export const HoverMarker: React.FunctionComponent<SeriesProps & HoverProps> = ({
  xScale,
  yScale,
  hover,
  tooltipX,
}) => {
  const xVal = xScale(xValue(hover.datum));
  const yVal = yScale(yValue(hover.datum) || 0);
  if (xVal !== tooltipX) {
    return null; // https://github.com/pganalyze/pganalyze/pull/1509#discussion_r679516193
  }
  return (
    <circle
      cx={xVal}
      cy={yVal}
      r={3}
      stroke="darkslategray"
      strokeWidth={1}
      fill="white"
    />
  );
};

export const StackedHoverMarker: React.FunctionComponent<
  StackedSeriesProps & HoverProps
> = ({ data, xScale, yScale, hover }) => {
  const stackedDatum = data[hover.index];
  const xVal = xScale(xValue(stackedDatum));
  const yVal = yScale(yTop(stackedDatum) || 0);
  return (
    <circle
      cx={xVal}
      cy={yVal}
      r={3}
      stroke="darkslategray"
      strokeWidth={1}
      fill="white"
    />
  );
};

type SeriesRendererType<T> = React.FunctionComponent<SeriesProps<T>>;
type StackedSeriesRendererType<T> = React.FunctionComponent<
  StackedSeriesProps<T>
>;

export const LineSeries: SeriesRendererType<never> = ({
  data,
  xScale,
  yScale,
  fill,
  className,
}) => {
  const linePath = line<Datum>()
    .defined(defined)
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yValue(d)!))(data);

  return (
    <path
      className={className}
      stroke={fill}
      strokeWidth={2}
      fill="none"
      d={linePath || ""}
    />
  );
};

// ThresholdSeries should take a single value instead, but for now,
// just treat it as a line series to avoid special cases
export const ThresholdSeries: SeriesRendererType<never> = ({
  data,
  xScale,
  yScale,
  stroke,
  className,
}) => {
  const linePath = line<Datum>()
    .defined(defined)
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yValue(d)!))(data);

  return (
    <path
      className={className}
      stroke={stroke}
      strokeWidth={2}
      fill="none"
      strokeDasharray="4"
      d={linePath || ""}
    />
  );
};

export const AreaSeries: StackedSeriesRendererType<never> & {
  stacked?: boolean;
} = ({ data, xScale, yScale, fill, className }) => {
  const areaPath = area<StackedDatum>()
    .defined(defined)
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yBottom(d)!))
    .y1((d) => yScale(yTop(d)!))(data);

  return (
    <path className={className} stroke="none" fill={fill} d={areaPath || ""} />
  );
};

AreaSeries.stacked = true;

export const ThresholdAreaSeries: StackedSeriesRendererType<ThresholdAreaSeriesOpts> & {
  stacked?: boolean;
} = ({ data, opts, xScale, yScale, fill, className }) => {
  const { mode, thresholdAt } = opts || {};

  const areaPath = area<StackedDatum>()
    .defined(
      mode === "under"
        ? defined
        : (d: StackedDatum, i: number): boolean =>
            defined(d) && thresholdAt !== undefined && yTop(d)! > thresholdAt(i)
    )
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yBottom(d)!))
    .y1((d) => yScale(yTop(d)!))(data);

  return (
    <path className={className} stroke="none" fill={fill} d={areaPath || ""} />
  );
};

export type ThresholdAreaSeriesOpts = {
  mode: "over" | "under";
  thresholdAt: (i: number) => number;
};

ThresholdAreaSeries.stacked = true;

const barPadding = 2;
const minBarWidth = 3;
const maxBarWidth = 30;

export const BarSeries: StackedSeriesRendererType<never> & {
  stacked: boolean;
  xPadding: number;
} = ({ data, xScale, yScale, fill, className }) => {
  const [xMin, xMax] = extent(xScale.range());
  const [, yMax] = extent(yScale.range());

  // Return early in case we have no data (extent returns undefined in these cases)
  if (xMax === undefined || xMin === undefined || yMax === undefined) {
    return null;
  }

  const pxPerDatapoint = (xMax - xMin) / data.length;
  const pixelsPerBar = pxPerDatapoint - barPadding;
  const barWidth = Math.min(maxBarWidth, Math.max(minBarWidth, pixelsPerBar));

  const bars = data.filter(defined).map((d: StackedDatum, i: number) => {
    const y = yScale(yTop(d)!);
    const height = yMax - yScale(yTop(d)! - yBottom(d)!);
    return (
      <rect
        className={className}
        key={i}
        x={xScale(xValue(d)) - barWidth / 2}
        y={y}
        width={barWidth}
        height={height}
        fill={fill}
      />
    );
  });

  return <>{bars}</>;
};
BarSeries.xPadding = maxBarWidth / 2 + barPadding;
BarSeries.stacked = true;

export const ScatterSeries: SeriesRendererType<never> = ({
  data,
  xScale,
  yScale,
  fill,
  className,
}) => {
  const circles = data.filter(defined).map((d: Datum, i: number) => {
    return (
      <circle
        key={i}
        cx={xScale(xValue(d))}
        cy={yScale(yValue(d)!)}
        r={3}
        fill={fill}
      />
    );
  });

  return <g className={className}>{circles}</g>;
};
