import React from "react";
import moment from "moment-timezone";

import styles from "./style.module.scss";

type Props = {
  lastReceivedSnapshotAt: number | null | undefined;
  lastTestSnapshotAt: number | null | undefined;
};

class SnapshotStatus extends React.Component<Props> {
  render() {
    const { lastReceivedSnapshotAt, lastTestSnapshotAt } = this.props;

    if (!lastReceivedSnapshotAt && !lastTestSnapshotAt) {
      return (
        <span>
          <i className="circle" />
          <span>No Snapshots received</span>
        </span>
      );
    }

    const ts = moment(
      (lastReceivedSnapshotAt || lastTestSnapshotAt || 0) * 1000
    );

    return (
      <span>
        {moment().diff(ts, "hours") <= 1 && <i className="circle green" />}
        {moment().diff(ts, "hours") > 1 && <i className="circle orange" />}
        &nbsp;
        <span>
          {ts.fromNow()}
          <span className={styles.snapshotTimestamp}>
            {" "}
            · {ts.format("YYYY-MM-DD HH:mm:ss ZZ")}
          </span>
          {!lastReceivedSnapshotAt && (
            <span className={styles.snapshotTimestamp}> · Test Snapshot</span>
          )}
        </span>
      </span>
    );
  }
}

export default SnapshotStatus;
