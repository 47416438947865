import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { formatEstimatedCount } from "utils/format";
import { useDateRange } from "components/WithDateRange";
import { useFeature } from "components/OrganizationFeatures";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import LogLines from "components/LogLines";
import PageContent from "components/PageContent";
import PageIssueList from "components/PageIssueList";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import QuerySamples from "./QuerySamples";
import WaitEvents from "./WaitEvents";

import {
  BackendDetails as BackendDetailsType,
  BackendDetailsVariables,
} from "./types/BackendDetails";
import QUERY from "./Query.graphql";

type Props = {
  tab: string;
};

const BackendDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const { databaseId, backendLocator } = useParams();
  const serverHasLogsFeature = useFeature("logs");
  const [{ from, to }] = useDateRange();
  const {
    serverRole,
    databaseBackend,
    databaseBackendQueries,
    databaseBackendWaitEvents,
    databaseBackendLogs,
  } = useRoutes();
  const { data, loading, error } = useQuery<
    BackendDetailsType,
    BackendDetailsVariables
  >(QUERY, {
    variables: {
      databaseId,
      backendLocator,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const serverId = data.getServerDetails.humanId;

  // In some cases backends may not be in the system, e.g. Postmaster or Checkpointer
  // before Postgres 10
  if (!data.getBackendDetails) {
    const pid = parseInt(backendLocator);

    return (
      <PageContent
        title={`Connection Trace for PID ${pid}`}
        pageCategory="backends"
        pageName="show"
        pageTab={tab}
      >
        <Panel title="Overview">
          <PanelSection>
            <strong>Error:</strong> No backend data available. This might be due
            to a mistyped URL, or if you are looking at a Postgres-internal
            process.
          </PanelSection>
        </Panel>
        {serverHasLogsFeature && (
          <Panel title="Log Entries">
            <LogLines
              serverId={serverId}
              databaseId={databaseId}
              backendPid={pid}
              occurredAfter={from.unix()}
              occurredBefore={to.unix()}
            />
          </Panel>
        )}
      </PageContent>
    );
  }

  const {
    id,
    pid,
    backendStart,
    lastActivityAt,
    postgresRole,
    queryCount,
    waitEventCount,
    logLineCount,
  } = data.getBackendDetails;

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseBackend(databaseId, backendLocator)}>
        Overview
      </PageNavLink>
      <PageNavLink to={databaseBackendQueries(databaseId, backendLocator)}>
        Query Samples <small>{formatEstimatedCount(queryCount, 25)}</small>
      </PageNavLink>
      <PageNavLink to={databaseBackendWaitEvents(databaseId, backendLocator)}>
        Wait Events{" "}
        <small>
          {formatEstimatedCount(waitEventCount, 100) || waitEventCount}
        </small>
      </PageNavLink>
      {serverHasLogsFeature && (
        <PageNavLink to={databaseBackendLogs(databaseId, backendLocator)}>
          Log Entries{" "}
          <small>
            {formatEstimatedCount(logLineCount, 100) || logLineCount}
          </small>
        </PageNavLink>
      )}
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title={`Connection Trace for PID ${pid}`}
      pageCategory="backends"
      pageName="show"
      featureNav={featureNav}
    >
      <PageIssueList
        serverId={serverId}
        referentId={id}
        referentType="Backend"
      />
      {tab == "overview" && (
        <Panel title="Overview">
          <PanelTable horizontal={true} borders={true}>
            <tbody>
              <tr>
                <th>PID</th>
                <td>{pid}</td>
                <th>Start Time</th>
                <td>
                  {(backendStart &&
                    moment.unix(backendStart).format("ll LTS z")) ||
                    "unknown"}
                </td>
              </tr>
              <tr>
                <th>Role</th>
                <td>
                  {postgresRole && (
                    <Link to={serverRole(serverId, postgresRole.id)}>
                      {postgresRole.name}
                    </Link>
                  )}
                </td>
                <th>Last Activity</th>
                <td>
                  {(lastActivityAt &&
                    moment.unix(lastActivityAt).format("ll LTS z")) ||
                    "unknown"}
                </td>
              </tr>
            </tbody>
          </PanelTable>
        </Panel>
      )}
      {tab == "queries" && (
        <QuerySamples databaseId={databaseId} backendLocator={backendLocator} />
      )}
      {tab == "wait_events" && (
        <WaitEvents databaseId={databaseId} backendLocator={backendLocator} />
      )}
      {tab == "logs" && (
        <Panel title="Log Entries">
          <LogLines
            serverId={serverId}
            backendPid={pid}
            occurredAfter={backendStart ? backendStart - 10 : null}
          />
        </Panel>
      )}
    </PageContent>
  );
};

export default BackendDetails;
