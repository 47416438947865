import moment, { Moment, MomentZone } from "moment-timezone";
import chrono from "chrono-node";

export const toEndOfDayMoment = (day: Date, notAfter?: Moment): Moment => {
  const result = moment([
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
  ]).endOf("day");
  if (notAfter) {
    return moment.min(result, notAfter);
  }
  return result;
};

export const toStartOfDayMoment = (day: Date, notBefore?: Moment): Moment => {
  const result = moment([
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
  ]).startOf("day");
  if (notBefore) {
    return moment.max(result, notBefore);
  }
  return result;
};

export const toStarting10m = (m: Moment): Moment => {
  const roundedMinutes = Math.round(m.minutes() / 10) * 10;
  return m.clone().minutes(roundedMinutes).seconds(0);
};

export const age = (m: Moment): string => {
  const now = moment();
  const units = now.diff(m, "hours", true) > 24 ? "days" : "hours";
  const diff = now.diff(m, units);
  const unitLabel = units == "days" ? "d" : "h";
  return `${diff}${unitLabel}`;
};

export const parseFreeformTs = (value: string): Moment | undefined => {
  const prepared = prepareFreeformTs(value);
  const parsed = chrono.parse(prepared);
  if (parsed.length !== 1) {
    return undefined;
  }
  const parsedDateInfo = parsed[0].start;
  const tentativeDate = parsedDateInfo.date();
  const zone = (moment as unknown as { defaultZone: MomentZone }).defaultZone;
  const offset = zone.utcOffset(tentativeDate.getTime());
  parsedDateInfo.imply("timezoneOffset", -offset);

  return moment(parsedDateInfo.date());
};

const prepareFreeformTs = (value: string): string => {
  // chrono-node does not like the default Postgres timestamp format
  //  - it expects a 'T' between date and time, not whitespace, but
  //    only when a time zone specifier is present
  //  - it expects no more than four digits of fractional seconds (v2
  //     fixes this but has other breaking changes)
  //
  // E.g.,
  //
  //   postgres:    2014-11-30 08:15:30.666666-05:30
  //   chrono-node: 2014-11-30T08:15:30.6666-05:30
  //
  // We can check if we're being passed a Postgres-like timestamp and
  // adjust it before handing it off to chrono-node to get more flexible
  // parsing, which is handy if you're pasting a timestamp into the input.
  const match = value.match(
    /^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2}(?:\.\d{1,4})?)\d{0,2}([+-]\d{2}(?::\d{2})?)?/
  );
  if (!match) {
    return value;
  }
  const [, date, time, tz] = match;
  if (tz) {
    return `${date}T${time}${tz}`;
  } else {
    return `${date} ${time}`;
  }
};
