export type VacuumIssueCategory = "bloat" | "freezing" | "performance";

type IssueOrIssueCountType = {
  checkGroupAndName: string;
};

export function isCategory(
  category: VacuumIssueCategory
): (issue: IssueOrIssueCountType) => boolean {
  switch (category) {
    case "bloat":
      return (issue) =>
        [
          "vacuum/xmin_horizon",
          "vacuum/insufficient_vacuum_frequency",
        ].includes(issue.checkGroupAndName);
    case "performance":
      return (issue) =>
        issue.checkGroupAndName === "vacuum/inefficient_index_phase";
    case "freezing":
      return (issue) =>
        ["vacuum/txid_wraparound", "vacuum/mxid_wraparound"].includes(
          issue.checkGroupAndName
        );
  }
}
