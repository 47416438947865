import React from "react";

import classNames from "classnames";

import styles from "./style.module.scss";

const PanelSection: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames(styles.section, className)}>{children}</div>
  );
};

export default PanelSection;
