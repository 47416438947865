import React from "react";

import classNames from "classnames";

import PanelTitle from "components/PanelTitle";

import styles from "./style.module.scss";

type Props = {
  id?: string;
  children: React.ReactNode;
  title: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  secondaryTitleClassName?: string;
  className?: string;
};

const Panel: React.FunctionComponent<Props> = ({
  id,
  children,
  title,
  secondaryTitle,
  secondaryTitleClassName,
  className,
}) => {
  return (
    <div id={id} className={classNames(styles.panel, className)}>
      <PanelTitle
        title={title}
        secondaryTitle={secondaryTitle}
        secondaryTitleClassName={secondaryTitleClassName}
      />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Panel;
