import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Button from "components/Button";
import Tip from "components/Tip";

import styles from "./style.module.scss";
import { ExpandToggle } from "components/Icons";

const SchemaTableScanDetails: React.FunctionComponent<{
  databaseId?: string;
  whereClause: string[];
  joinClause: string[];
  orderByClause?: string;
  referencedQueries?: (number | string)[];
}> = ({ databaseId, whereClause, joinClause, referencedQueries }) => {
  const { databaseQuery } = useRoutes();
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setExpanded((currExp) => !currExp);
  };
  return (
    <>
      <Button
        bare
        onClick={handleToggleExpanded}
        className={styles.toggleButton}
      >
        <ExpandToggle expanded={expanded} />
      </Button>
      <span className="font-mono">
        {whereClause.join(" AND ")}
        {whereClause.length > 0 && joinClause.length > 0 && (
          <span className={styles.scanFullSqlConjunction}> AND </span>
        )}
        {joinClause.join(" AND ")}
      </span>
      {expanded && (
        <div className="ml-5">
          <div className={styles.scanDetailsGrid}>
            <div className={styles.scanDetailsKeyword}>
              WHERE clause
              <Tip
                className="ml-2"
                content="Conditions on what data this scan returns"
              />
            </div>
            <div className="font-mono whitespace-normal">
              {combinePredicates(whereClause)}
            </div>
            <div className={styles.scanDetailsKeyword}>
              JOIN clause
              <Tip
                className="ml-2"
                content="Conditions on how this scan joins with other tables"
              />
            </div>
            <div className="font-mono whitespace-normal">
              {combinePredicates(joinClause)}
            </div>
          </div>
          {referencedQueries && (
            <div className={styles.scanReferencedQueriesBox}>
              <span className={styles.panelHeading}>Referenced Queries</span>
              {referencedQueries.map((qId) => (
                <Link
                  key={qId}
                  className="mr-1"
                  to={databaseQuery(databaseId, String(qId))}
                >
                  #{qId}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

function combinePredicates(clauses: string[]): string {
  return clauses.length > 0 ? clauses.join(" AND ") : "-";
}

export default SchemaTableScanDetails;
