import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";
import { SortDirection } from "types/graphql-global-types";

import Loading from "components/Loading";
import PaginatedVirtualTable from "components/PaginatedVirtualTable";

import {
  SchemaFunctionList,
  SchemaFunctionListVariables,
  SchemaFunctionList_getSchemaFunctions,
  SchemaFunctionList_getSchemaFunctions_issues,
} from "./types/SchemaFunctionList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  searchTerm: string | null;
  databaseId: string;
}

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaFunction: SchemaFunctionList_getSchemaFunctions;
}> = ({ databaseId, schemaFunction }) => {
  const { databaseFunction } = useRoutes();
  return (
    <span>
      <Link to={databaseFunction(databaseId, schemaFunction.id)}>
        {schemaFunction.functionName}
      </Link>
      {schemaFunction.issues.map(
        (i: SchemaFunctionList_getSchemaFunctions_issues): React.ReactNode => (
          <Link
            to={databaseFunction(databaseId, schemaFunction.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        )
      )}
    </span>
  );
};

// Handle the mapping between the union type (react-virtualized) and enum in GraphQL
const sortDirectionStrToEnum: { [key in "ASC" | "DESC"]: SortDirection } = {
  ASC: SortDirection.ASC,
  DESC: SortDirection.DESC,
};
const sortDirectionEnumToStr: {
  [key in keyof typeof SortDirection]: "ASC" | "DESC";
} = {
  [SortDirection.ASC]: "ASC",
  [SortDirection.DESC]: "DESC",
};

const Table: React.FunctionComponent<Props> = (props) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: "functionName",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaFunctionList,
    SchemaFunctionListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: 50,
      filter: props.searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId: props.databaseId,
    },
  });
  if (loading || error || !data.getSchemaFunctions) {
    return <Loading error={!!error} />;
  }

  const schemaFunctions = data.getSchemaFunctions;

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: SchemaFunctionList,
        { fetchMoreResult }: { fetchMoreResult: SchemaFunctionList }
      ): SchemaFunctionList => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getSchemaFunctions: [
            ...prev.getSchemaFunctions,
            ...fetchMoreResult.getSchemaFunctions,
          ],
        });
      },
    });
  };

  const handleSort = ({ sortBy, sortDirection }: SortOptsType) => {
    setSortOpts({
      sortBy,
      sortDirection: sortDirectionStrToEnum[sortDirection],
    });
  };

  return (
    <PaginatedVirtualTable
      data={schemaFunctions}
      fetchMore={fetchMoreData}
      sortBy={sortOpts.sortBy}
      sortDirection={sortDirectionEnumToStr[sortOpts.sortDirection]}
      handleSort={handleSort}
    >
      <Column dataKey="schemaName" label="Schema" width={120} />
      <Column
        dataKey="functionName"
        label="Function Name"
        width={120}
        flexGrow={1}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaFunctionList_getSchemaFunctions;
        }): SchemaFunctionList_getSchemaFunctions => rowData}
        cellRenderer={({
          cellData,
        }: {
          cellData?: SchemaFunctionList_getSchemaFunctions;
        }): React.ReactNode => (
          <NameAndIssuesCell
            databaseId={props.databaseId}
            schemaFunction={cellData}
          />
        )}
      />
      <Column dataKey="language" label="Language" width={120} />
      <Column
        dataKey="securityDefiner"
        label="Security Definer"
        width={160}
        defaultSortDirection={"DESC"}
        cellRenderer={({ cellData }: { cellData?: boolean }): React.ReactNode =>
          (cellData && "Yes") || "No"
        }
      />
    </PaginatedVirtualTable>
  );
};

export default Table;
