import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import classNames from "classnames";

import { formatMs } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ExpandableSQL from "components/ExpandableSQL";

import {
  QuerySamples as QuerySamplesType,
  QuerySamplesVariables,
  QuerySamples_getQuerySamples as QuerySampleType,
} from "./types/QuerySamples";

import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

import styles from "./style.module.scss";

type SampleProps = {
  sample: QuerySampleType;
};

const QuerySample: React.FunctionComponent<SampleProps> = ({ sample }) => {
  const queryText = sample.queryText;

  return (
    <Panel
      title="Query Sample"
      key={sample.id}
      secondaryTitle={moment.unix(sample.occurredAt).format("lll z")}
    >
      <PanelSection>
        <ExpandableSQL sql={queryText} />
      </PanelSection>
      {sample.parameters.length > 0 && (
        <PanelSection>
          <pre className={classNames(styles.parameters, "noborder")}>
            {sample.parameters
              .map(
                (p: string, index: number): React.ReactNode =>
                  `$${index + 1} = ${p === null ? "NULL" : `'${p}'`}`
              )
              .join(", ")}
          </pre>
        </PanelSection>
      )}
      <PanelSection>
        <strong>Runtime:</strong> {formatMs(sample.runtimeMs)}
      </PanelSection>
    </Panel>
  );
};

type Props = {
  databaseId: string;
  queryId: string;
};

const QuerySamples: React.FunctionComponent<Props> = ({
  databaseId,
  queryId,
}) => {
  const { serverConfigSetting } = useRoutes();
  const { data, loading, error } = useQuery<
    QuerySamplesType,
    QuerySamplesVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const samples = data.getQuerySamples;
  const serverId = data.getServerDetails.humanId;

  if (samples.length === 0) {
    return (
      <Panel title="No Samples Collected">
        <PanelSection>
          <p>
            <strong>Error:</strong> No query samples collected in the last 24
            hours.
          </p>
          <p>
            Query samples include all parameters necessary to run EXPLAIN, and
            are collected from your logfiles when Log Insights is enabled.
          </p>
          <p>
            Please note that only query executions that exceed your{" "}
            <Link
              to={serverConfigSetting(serverId, "log_min_duration_statement")}
            >
              log_min_duration_statement
            </Link>{" "}
            setting will be collected from your log files.
          </p>
        </PanelSection>
      </Panel>
    );
  }

  return (
    <>
      {samples.map(
        (sample: QuerySampleType): React.ReactNode => (
          <QuerySample key={sample.id} sample={sample} />
        )
      )}
    </>
  );
};

export default QuerySamples;
