import React from "react";

import { MDXProvider } from "@mdx-js/react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faQuestionCircle,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

import { makeSmartAnchor } from "../../../../docs/components/SmartAnchor";

import Null from "../../../../docs/components/Null";
import { WithCodeBlock } from "../../../../docs/components/CodeBlock";
import WithIcons, { IconProps } from "../../../../docs/components/WithIcons";
import WithDescriptionPopup, {
  DescriptionPopupProps,
} from "../../../../docs/components/WithDescriptionPopup";

import Popover from "components/Popover";
import CopyCodeBlock from "components/CopyCodeBlock";

const DocsMDXProvider: React.FunctionComponent = ({ children }) => {
  const SmartAnchor = makeSmartAnchor(Link, true);

  const mdxComponents = {
    img: DocsImage,
    a: SmartAnchor,
    Link: SmartAnchor,
    PublicOnly: Null,
    InAppOnly: React.Fragment,
    CodeBlock: CopyCodeBlock,
    pre: CopyCodeBlock,
    h1: "h2" as any,
    h2: "h3" as any,
    h3: "h4" as any,
    h4: "h5" as any,
    h5: "h6" as any,
    h6: "span" as any,
  };
  return (
    <WithCodeBlock component={CopyCodeBlock}>
      <WithDescriptionPopup popup={DescriptionPopup}>
        <WithIcons
          icons={{
            okay: OkayIcon,
            changeRequired: ChangeRequiredIcon,
            info: InfoIcon,
            externalLink: ExternalLinkIcon,
            secure: SecureIcon,
          }}
        >
          <MDXProvider components={mdxComponents}>{children}</MDXProvider>
        </WithIcons>
      </WithDescriptionPopup>
    </WithCodeBlock>
  );
};

const OkayIcon: React.FunctionComponent<IconProps> = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faCheckCircle} />
);
const ChangeRequiredIcon: React.FunctionComponent<IconProps> = ({
  className,
}) => <FontAwesomeIcon className={className} icon={faExclamationCircle} />;
const InfoIcon: React.FunctionComponent<IconProps> = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faInfoCircle} />
);
const ExternalLinkIcon: React.FunctionComponent<IconProps> = ({
  className,
}) => <FontAwesomeIcon className={className} icon={faExternalLink} />;

const SecureIcon: React.FunctionComponent<IconProps> = ({ className }) => (
  <FontAwesomeIcon className={className} icon={faLock} />
);

const DescriptionPopup: React.FunctionComponent<DescriptionPopupProps> = ({
  className,
  info,
}) => {
  return (
    <Popover title="Description" content={info}>
      <FontAwesomeIcon className={className} icon={faQuestionCircle} />
    </Popover>
  );
};

type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const DocsImage: React.FunctionComponent<ImgProps> = (props) => {
  return <img style={{ maxWidth: "100%", border: "none" }} {...props} />;
};

export default DocsMDXProvider;
