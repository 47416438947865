import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Icon from "components/Icon";

import { OrganizationSelect_organizations as OrganizationType } from "./types/OrganizationSelect";

import styles from "../style.module.scss";
import { useRoutes } from "utils/routes";
import {
  useCurrentOrganization,
  useCurrentUser,
} from "components/WithCurrentOrganization";

const OrganizationSelect: React.FunctionComponent = () => {
  const { organizations } = useCurrentUser();
  const currentOrganization = useCurrentOrganization(true);
  const organizationSlug = currentOrganization?.slug;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [organizationSlug]);

  return (
    <li className={styles.selectMenu} data-select-menu="organization">
      <a
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          setOpen(!open);
        }}
      >
        <small>Organization</small>
        <div className={styles.selectMenuCurrent}>
          {currentOrganization && (
            <span key="name">{currentOrganization.name}</span>
          )}
          {!currentOrganization && [<span key="name">(no organization)</span>]}
          {!open && <Icon kind="caret-down" />}
          {open && <Icon kind="caret-up" />}
        </div>
      </a>
      {open && (
        <ul className={styles.selectMenuList}>
          {organizations.map((o) => (
            <OrganizationItem
              key={o.id}
              organization={o}
              onClick={() => {
                setOpen(!open);
              }}
            />
          ))}
          <AddOrganization />
        </ul>
      )}
    </li>
  );
};

const AddOrganization: React.FunctionComponent = () => {
  const { organizationsNew } = useRoutes();
  return (
    <li key="new">
      <Link className={styles.selectMenuListItem} to={organizationsNew()}>
        <Icon kind="plus" />
        <span>Add Organization</span>
      </Link>
    </li>
  );
};

const OrganizationItem: React.FunctionComponent<{
  organization: OrganizationType;
  onClick: () => void;
}> = ({ organization, onClick }) => {
  const { organization: organizationRoute } = useRoutes();
  return (
    <li key={organization.id}>
      <Link
        className={styles.selectMenuListItem}
        to={organizationRoute(organization.slug)}
        onClick={onClick}
      >
        <span key="name">{organization.name}</span>
      </Link>
    </li>
  );
};

export default OrganizationSelect;
