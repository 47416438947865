---
title: 'Step 2: Create Monitoring User'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import Step02CreateMonitoringUserAzure from "./_02_create_monitoring_user.mdx"

import GeneratePassword from "../../components/GeneratePassword"

<GeneratePassword>
  {password => <Step02CreateMonitoringUserAzure password={password} />}
</GeneratePassword>
