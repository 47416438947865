import React from "react";
import { useQuery } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";

import CheckUpIssueList from "components/CheckUpIssueList";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";

import CheckConfig from "components/CheckConfig";
import CheckConfigList from "components/CheckConfigList";
import ResolvedIssueList from "components/ResolvedIssueList";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import {
  CheckUpDetails as CheckUpDetailsType,
  CheckUpDetailsVariables,
} from "./types/CheckUpDetails";
import { useRoutes } from "utils/routes";
import styles from "./style.module.scss";
import { checkFrequency, checkTitle } from "../../../../docs/util/checks";
import { getCheckDescription } from "../../../../docs/components/CheckDocumentation";
import QUERY from "./Query.graphql";

type Props = {
  tab: string;
};

const CheckUpDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const {
    slug: organizationSlug,
    serverId: serverIdFromRoute,
    databaseId,
    checkGroup,
    checkName,
  } = useParams();
  const checkGroupAndName = `${checkGroup}/${checkName}`;
  const {
    organizationChecks,
    organizationCheck,
    organizationCheckAcknowledged,
    organizationCheckResolved,
    organizationCheckConfigure,
    serverCheck,
    serverCheckAcknowledged,
    serverCheckResolved,
    serverCheckConfigure,
    databaseCheck,
    databaseCheckAcknowledged,
    databaseCheckResolved,
  } = useRoutes();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(
    serverIdFromRoute,
    databaseId
  );
  const { data, loading, error } = useQuery<
    CheckUpDetailsType,
    CheckUpDetailsVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      serverId,
      databaseId,
      checkGroupAndName,
    },
  });
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }

  if (data.getChecks.length === 0) {
    return <Navigate to={organizationChecks(organizationSlug)} replace />;
  }

  const { triggeredIssueCount, acknowledgedIssueCount, resolvedIssueCount7d } =
    data.getChecks[0];

  const featureInfo = (
    <>
      <p>
        <strong>Check Frequency:</strong>{" "}
        {checkFrequency(checkGroup, checkName)}
      </p>
      <div className="row">
        <div className="col-sm-9">
          <div
            dangerouslySetInnerHTML={{
              __html: getCheckDescription(checkGroup, checkName),
            }}
            className={styles.checkDescription}
          />
        </div>
      </div>
    </>
  );

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink
        to={
          databaseId
            ? databaseCheck(databaseId, checkGroupAndName)
            : serverId
            ? serverCheck(serverId, checkGroupAndName)
            : organizationCheck(organizationSlug, checkGroupAndName)
        }
      >
        Triggered <small>{triggeredIssueCount}</small>
      </PageNavLink>
      <PageNavLink
        to={
          databaseId
            ? databaseCheckAcknowledged(databaseId, checkGroupAndName)
            : serverId
            ? serverCheckAcknowledged(serverId, checkGroupAndName)
            : organizationCheckAcknowledged(organizationSlug, checkGroupAndName)
        }
      >
        Acknowledged <small>{acknowledgedIssueCount}</small>
      </PageNavLink>
      <PageNavLink
        to={
          databaseId
            ? databaseCheckResolved(databaseId, checkGroupAndName)
            : serverId
            ? serverCheckResolved(serverId, checkGroupAndName)
            : organizationCheckResolved(organizationSlug, checkGroupAndName)
        }
      >
        Resolved <small>{resolvedIssueCount7d}</small>
      </PageNavLink>
      <PageNavLink
        to={
          serverId
            ? serverCheckConfigure(serverId, checkGroupAndName)
            : organizationCheckConfigure(organizationSlug, checkGroupAndName)
        }
      >
        Configure
      </PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <PageContent
      title={`Check: ${checkTitle(checkGroup, checkName)}`}
      pageCategory="checks"
      pageName="show"
      pageTab={tab}
      pageProps={{ check: checkGroupAndName }}
      featureInfo={featureInfo}
      featureNav={featureNav}
    >
      {tab === "triggered" && (
        <CheckUpIssueList
          organizationSlug={organizationSlug}
          serverId={serverId}
          databaseId={databaseId}
          checkGroupAndName={checkGroupAndName}
          state="triggered"
        />
      )}
      {tab === "acknowledged" && (
        <CheckUpIssueList
          organizationSlug={organizationSlug}
          serverId={serverId}
          databaseId={databaseId}
          checkGroupAndName={checkGroupAndName}
          state="acknowledged"
        />
      )}
      {tab === "resolved" && (
        <ResolvedIssueList
          organizationSlug={organizationSlug}
          serverId={serverId}
          databaseId={databaseId}
          checkGroupAndName={checkGroupAndName}
        />
      )}
      {tab === "configure" && serverId && (
        <CheckConfig
          serverId={serverId}
          checkGroupAndName={checkGroupAndName}
        />
      )}
      {tab === "configure" && !serverId && (
        <CheckConfigList
          organizationSlug={organizationSlug}
          checkGroupAndName={checkGroupAndName}
        />
      )}
    </PageContent>
  );
};

export default CheckUpDetails;
