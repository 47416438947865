import React from "react";

import classNames from "classnames";

import { csrfTag } from "utils/csrf";

import styles from "./style.module.scss";

type Props = {
  action: string;
  method?: string;
  children?: React.ReactNode;
  id?: string;
  insideSection?: boolean;
  formRef?: (a: HTMLFormElement | null) => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => boolean;
  wide?: boolean;
  eventConversion?: string;
  eventCategory?: string;
  eventLabel?: string;
  className?: string;
};

const Form: React.FunctionComponent<Props> = ({
  action,
  method,
  children,
  id,
  insideSection,
  formRef,
  onSubmit,
  wide,
  eventConversion,
  eventCategory,
  eventLabel,
  className,
}) => {
  function handleSumbit(e: React.FormEvent<HTMLFormElement>): boolean {
    if (onSubmit) {
      const result = onSubmit(e);
      if (!result) {
        // This 'return false' should be sufficient without the preventDefault, but
        // the form still ends up being submitted. This may be an issue with Rails JS.
        e.preventDefault();
        return false;
      }
    }

    if (window.gtag && eventCategory) {
      window.gtag("event", "submitted", {
        event_category: eventCategory,
        event_label: eventLabel,
      });
    }

    if (window.gtag && eventConversion) {
      window.gtag("event", "conversion", {
        send_to: eventConversion,
      });
    }

    return true;
  }

  return (
    <form
      className={classNames(
        (insideSection && styles.inline) ||
          (wide && styles.wide) ||
          styles.slim,
        className
      )}
      action={action}
      acceptCharset="UTF-8"
      id={id}
      ref={formRef}
      method={method == "get" ? "get" : "post"}
      onSubmit={handleSumbit}
    >
      {method != "get" && method != "post" && (
        <input type="hidden" name="_method" value={method} />
      )}
      <input name="utf8" type="hidden" value="&#x2713;" />
      {csrfTag()}
      {children}
    </form>
  );
};

export default Form;
