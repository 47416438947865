import React from "react";

import { Link } from "react-router-dom";

import { Node } from "types/explain";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

const IndexInfo: React.FunctionComponent<{
  node: Node;
  databaseId: string;
}> = ({ node, databaseId }) => {
  const indName = node["Index Name"];
  if (!indName) {
    return null;
  }
  const title = `using ${indName}`;
  return (
    <div className={styles.relInfo} title={title}>
      using{" "}
      <LinkIndex databaseId={databaseId} node={node}>
        <span className={styles.relName}>{indName}</span>
      </LinkIndex>
    </div>
  );
};

const LinkIndex: React.FunctionComponent<{
  databaseId: string;
  node: Node;
}> = ({ databaseId, node, children }) => {
  const { databaseIndex } = useRoutes();
  const indexId = node.extra.index_id;
  if (!indexId) {
    return children as React.ReactElement;
  }
  return <Link to={databaseIndex(databaseId, indexId)}>{children}</Link>;
};

export default IndexInfo;
