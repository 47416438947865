import React from "react";
import { useQuery } from "@apollo/client";

import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";

import {
  QueryOverviewGraph as QueryOverviewGraphType,
  QueryOverviewGraphVariables,
} from "./types/QueryOverviewGraph";
import QUERY from "./Query.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  databaseId: string;
  className?: string;
};

const QueryOverviewGraph: React.FunctionComponent<Props> = ({
  databaseId,
  className,
}) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    QueryOverviewGraphType,
    QueryOverviewGraphVariables
  >(QUERY, {
    variables: {
      databaseId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getQueryOverviewStats == null;

  return (
    <GraphSection
      noData={noData}
      loading={loading}
      error={error}
      className={className}
    >
      <DateRangeGraph
        data={data?.getQueryOverviewStats as unknown as Data}
        axes={{
          left: {
            format: (y) => y + " ms",
            tipFormat: (y) => y.toFixed(2) + " ms",
          },
        }}
        series={[
          {
            key: "avgTime",
            label: "Median",
            tipLabel: "Median",
          },
          {
            key: "pct90th",
            defaultDisabled: true,
            label: "90th Percentile",
            tipLabel: "P90",
          },
          {
            key: "pct95th",
            label: "95th Percentile",
            tipLabel: "P95",
          },
          {
            key: "pct98th",
            defaultDisabled: true,
            label: "98th Percentile",
            tipLabel: "P98",
          },
          {
            key: "pct99th",
            defaultDisabled: true,
            label: "99th Percentile",
            tipLabel: "P99",
          },
          { key: "avgIotime", label: "Avg I/O Time" },
        ]}
      />
    </GraphSection>
  );
};

export default QueryOverviewGraph;
