import React, { useState } from "react";

import moment, { Moment } from "moment-timezone";
import Color from "color";
import { scaleTime } from "d3";
import { Link } from "react-router-dom";

import { translate } from "utils/svg";

import Popover from "components/Popover";

import styles from "./style.module.scss";

export type TimeBucket = {
  start: Moment;
  value: number;
  url?: string;
};

type Props = {
  from: Moment;
  to: Moment;
  highlight?: Moment;
  buckets: TimeBucket[];
  bucketSize: moment.Duration;
  maxValue: number;
  color?: Color;
  tip?: React.ComponentType<{ start: Moment; end: Moment; value: number }>;
};

const TimeBucketBar: React.FunctionComponent<Props> = (props) => {
  const [currAnchor, setCurrAnchor] = useState<Element>(undefined);
  const [currBucket, setCurrBucket] = useState<TimeBucket>(undefined);

  const handleBucketHover = (
    b: TimeBucket,
    e: React.MouseEvent<Element>
  ): void => {
    setCurrAnchor(e ? e.currentTarget : null);
    setCurrBucket(b);
  };
  const BucketTip = props.tip;
  return (
    <div className={styles.wrapper}>
      {props.tip && (
        <Popover
          content={
            currBucket && (
              <BucketTip
                start={currBucket.start}
                end={currBucket.start.clone().add(props.bucketSize)}
                value={currBucket.value}
              />
            )
          }
          anchor={currAnchor}
          placement="top"
        />
      )}
      <TimeBucketBarContents {...props} onBucketHover={handleBucketHover} />
    </div>
  );
};

const TimeBucketBarContents: React.FunctionComponent<
  Props & {
    onBucketHover: (b: TimeBucket, e: React.MouseEvent<Element>) => void;
  }
> = React.memo(
  ({
    from,
    to,
    highlight,
    buckets,
    bucketSize,
    maxValue,
    color: rawColor,
    onBucketHover,
  }) => {
    const width = 800;
    const height = 30;
    const viewBox = (width: number, height: number) => {
      return `0 0 ${width} ${height}`;
    };
    const scale = scaleTime().domain([from, to]).range([0, width]);

    const barXPadding = 1;
    // N.B.: unless highlighted; then zero
    const barYPadding = 2;
    const minBarWidth = 2;
    // When rendering the bars, we want to leave a little space to visually
    // distinguish one bar from the next. However, when interacting with them
    // via hover or anchor links, the gaps in between would become an interactive
    // dead zone for no good reason. Don't subtract the padding when defining
    // the interactive area to avoid this.
    const barInteractiveWidth =
      scale(from.clone().add(bucketSize)) - scale(from);
    const barWidth = Math.max(barInteractiveWidth - barXPadding, minBarWidth);
    const handleBucketLeave = () => {
      onBucketHover(null, null);
    };

    const color = rawColor || Color("#999");
    return (
      <svg
        width="100%"
        height="100%"
        viewBox={viewBox(width, height)}
        preserveAspectRatio="none"
        onMouseLeave={handleBucketLeave}
      >
        {/* If there are missing buckets, add this to avoid leaving the
          last-hovered bucket's tip showing when hovering over the "holes" */}
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          fill="transparent"
          onMouseEnter={handleBucketLeave}
        />
        {buckets.map((b) => {
          const barColor = color.alpha(0.1 + (b.value / maxValue) * 0.9);
          const bucketUpTo = b.start.clone().add(bucketSize);
          const highlighted =
            highlight &&
            Math.abs(bucketUpTo.diff(highlight, "minutes", true)) < 5;

          const x = scale(b.start);
          // cut off last bar width if it's going to go past 100%
          const actualBarWidth = Math.min(barWidth, width - x - barXPadding);
          const highlightWidth = Math.max(actualBarWidth * 0.1, minBarWidth);
          const highlightX = actualBarWidth - highlightWidth;
          if (actualBarWidth <= 0) {
            // don't draw empty bars, which can happen if the last partial bar
            // starts right at the edge
            return null;
          }

          const y = highlighted ? 0 : barYPadding;
          const barHeight = height - (highlighted ? 0 : barYPadding * 2);

          const handleBucketEnter = (e: React.MouseEvent<SVGElement>) => {
            onBucketHover(b, e);
          };
          return (
            <g key={b.start.valueOf()} transform={translate(x, y)}>
              <g pointerEvents="none">
                <rect
                  fill={barColor.string()}
                  width={actualBarWidth}
                  height={barHeight}
                />
                {highlighted && (
                  <rect
                    x={highlightX}
                    fill="rgba(0,0,0,0.5)"
                    width={highlightWidth}
                    height={barHeight}
                  />
                )}
              </g>
              <MaybeLink to={b.url}>
                <rect
                  width={barInteractiveWidth}
                  height={barHeight}
                  fill="transparent"
                  onMouseEnter={handleBucketEnter}
                />
              </MaybeLink>
            </g>
          );
        })}
      </svg>
    );
  }
);
TimeBucketBarContents.displayName = "memo(TimeBucketBarContents)";

const MaybeLink: React.FunctionComponent<{ to: string | undefined }> = ({
  to,
  children,
}) => {
  return to ? (
    <Link to={to}>{children}</Link>
  ) : (
    (children as React.ReactElement)
  );
};

export default TimeBucketBar;
