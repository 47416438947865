import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Column } from "react-virtualized";

import { SortDirection } from "types/graphql-global-types";

import ContactSupportLink from "components/ContactSupportLink";
import PanelSection from "components/PanelSection";
import PanelItem from "components/PanelItem";
import PanelItems from "components/PanelItems";
import PageContent from "components/PageContent";
import PanelVirtualTable from "components/PanelVirtualTable";
import Panel from "components/Panel";
import { CHECK_INFO } from "../CheckList";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";

import {
  AlertConfigDetails,
  AlertConfigDetailsVariables,
  AlertConfigDetails_getOrganizationDetails_slackIntegration_availableChannels_channels as SlackChannel,
  AlertConfigDetails_getOrganizationDetails_pagerdutyIntegration_availableServices as PagerDutyService,
} from "./types/AlertConfigDetails";

import {
  ConfigureServerNotifications,
  ConfigureServerNotificationsVariables,
} from "./types/ConfigureServerNotifications";

import { NotificationConfigAttributes } from "./../../types/graphql-global-types";

import styles from "./style.module.scss";
import Loading from "components/Loading";
import { useAsyncActionFlash } from "components/WithFlashes";
import { checkMaxSeverity } from "../../../../docs/util/checks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { useRoutes } from "utils/routes";
import Select from "components/Select";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

type Check = {
  check: string;
  title: string;
  titleDisplay: React.ReactNode;
};

const AlertConfig: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { slug: organizationSlug } = useCurrentOrganization();

  const { data, loading, error } = useQuery<
    AlertConfigDetails,
    AlertConfigDetailsVariables
  >(QUERY, {
    variables: { organizationSlug, serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const orgDetails = data.getOrganizationDetails;
  const serverDetails = data.getServerDetails;

  const hasAnyIntegration =
    !!orgDetails.slackIntegration || !!orgDetails.pagerdutyIntegration;

  return (
    <PageContent
      title="Alert Configuration"
      pageCategory="alerts"
      pageName="config"
    >
      <Panel title="Check alerting">
        {hasAnyIntegration ? (
          <AlertConfigContent
            serverId={serverId}
            organizationDetails={orgDetails}
            serverDetails={serverDetails}
          />
        ) : (
          <AlertConfigBlankSlate organizationSlug={organizationSlug} />
        )}
      </Panel>
    </PageContent>
  );
};

const AlertConfigContent: React.FunctionComponent<{
  serverId: string;
  organizationDetails: AlertConfigDetails["getOrganizationDetails"];
  serverDetails: AlertConfigDetails["getServerDetails"];
}> = ({ serverId, organizationDetails, serverDetails }) => {
  const [selectedSlackChannel, setSelectedSlackChannel] =
    useState<SlackChannel | null>(null);
  const [selectedSlackChecks, setSelectedSlackChecks] = useState<string[]>([]);

  const [selectedPdService, setSelectedPdService] =
    useState<PagerDutyService | null>(null);
  const [selectedPdChecks, setSelectedPdChecks] = useState<string[]>([]);

  const [
    configureServerIntegration,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useMutation<
    ConfigureServerNotifications,
    ConfigureServerNotificationsVariables
  >(MUTATION);
  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success: "Configuration changes saved",
  });

  const hasSlackIntegration = !!organizationDetails.slackIntegration;
  const hasSlackChannelError =
    !!organizationDetails.slackIntegration?.availableChannels?.error;
  const channels =
    organizationDetails.slackIntegration?.availableChannels?.channels;
  const currSlackConfig = serverDetails.notificationConfig?.slack;

  const hasPagerDutyIntegration = !!organizationDetails.pagerdutyIntegration;
  const services = organizationDetails.pagerdutyIntegration?.availableServices;
  const currPdConfig = serverDetails.notificationConfig?.pagerduty;

  useEffect(() => {
    if (!currSlackConfig) {
      return;
    }
    if (currSlackConfig.channelId && !hasSlackChannelError) {
      const channel = channels?.find(
        (ch) => ch.id === currSlackConfig.channelId
      );
      setSelectedSlackChannel(channel);
    }
    setSelectedSlackChecks(currSlackConfig.checks);
  }, [channels, currSlackConfig, hasSlackChannelError]);
  useEffect(() => {
    if (!currPdConfig) {
      return;
    }
    if (currPdConfig.serviceId) {
      const service = services?.find(
        (service) => service.id === currPdConfig.serviceId
      );
      setSelectedPdService(service);
    }
    setSelectedPdChecks(currPdConfig.checks);
  }, [services, currPdConfig]);

  const handleSlackChannelSelected = (channel: SlackChannel | null) => {
    setSelectedSlackChannel(channel);
  };
  const handleSlackCheckToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clickedCheck = e.target.dataset.check;
    setSelectedSlackChecks(
      e.target.checked ? enableCheck(clickedCheck) : disableCheck(clickedCheck)
    );
  };

  const handlePdServiceSelected = (service: PagerDutyService | null) => {
    setSelectedPdService(service);
  };
  const handlePdCheckToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clickedCheck = e.target.dataset.check;
    setSelectedPdChecks(
      e.target.checked ? enableCheck(clickedCheck) : disableCheck(clickedCheck)
    );
  };

  const tableData = Object.entries(CHECK_INFO).map(([check, info]) => {
    return {
      check,
      ...info,
    };
  });
  const slackConfigChanged =
    !hasSlackChannelError &&
    (currSlackConfig?.channelId !== selectedSlackChannel?.id ||
      !checksEqual(selectedSlackChecks, currSlackConfig?.checks));

  const pdConfigChanged =
    currPdConfig?.serviceId !== selectedPdService?.id ||
    !checksEqual(selectedPdChecks, currPdConfig?.checks);
  const saveDisabled =
    (!slackConfigChanged && !pdConfigChanged) || mutationLoading;
  const saveChangesTitle = saveDisabled ? "Configuration unchanged" : undefined;
  const handleSave = () => {
    const notificationConfig: NotificationConfigAttributes = {};
    if (slackConfigChanged) {
      notificationConfig.slack = {
        channelId: selectedSlackChannel?.id,
        channelName: selectedSlackChannel?.name,
        checks: selectedSlackChecks,
      };
    }
    if (pdConfigChanged) {
      notificationConfig.pagerduty = {
        serviceId: selectedPdService?.id,
        serviceName: selectedPdService?.name,
        checks: selectedPdChecks,
      };
    }

    configureServerIntegration({
      variables: {
        serverId,
        notificationConfig,
      },
    });
  };
  return (
    <>
      <PanelSection>
        <PanelItems>
          {hasSlackIntegration && (
            <PanelItem
              label="Slack channel"
              className={styles.integrationConfig}
            >
              <SelectSlackChannel
                options={channels}
                value={selectedSlackChannel}
                onChange={handleSlackChannelSelected}
                hasError={hasSlackChannelError}
              />
            </PanelItem>
          )}
          {hasPagerDutyIntegration && (
            <PanelItem
              label="PagerDuty service"
              className={styles.integrationConfig}
            >
              <Select
                placeholder="— none: alerts disabled —"
                items={services}
                itemToString={(item) => item.name}
                value={selectedPdService}
                onChange={handlePdServiceSelected}
              />
              <div className={styles.integrationConfigNote}>
                <small>PagerDuty alerts occur only for critical issues</small>
              </div>
            </PanelItem>
          )}
        </PanelItems>
      </PanelSection>
      <PanelVirtualTable
        data={tableData}
        initialSortBy="checkTitle"
        initialSortDirection={SortDirection.DESC}
      >
        <Column
          dataKey="checkTitle"
          label="Check"
          width={350}
          flexGrow={1}
          cellRenderer={({ rowData }: { rowData: Check }): React.ReactNode => (
            <Link to="#">{rowData.titleDisplay}</Link>
          )}
        />
        {hasSlackIntegration && (
          <Column
            dataKey="check"
            label="Slack Alert"
            disableSort
            width={160}
            cellRenderer={({
              rowData,
            }: {
              rowData: { check: string };
            }): React.ReactNode => {
              const checkboxDisabled =
                hasSlackChannelError || selectedSlackChannel === null;
              const title = !checkboxDisabled
                ? undefined
                : hasSlackChannelError
                ? "cannot change slack configuration if channels cannot be loaded"
                : "select channel to enable alerts";

              const check = rowData.check;
              const checkEnabled = selectedSlackChecks.includes(check);
              return (
                <div className={styles.checkCheckbox}>
                  <input
                    type="checkbox"
                    data-check={check}
                    disabled={checkboxDisabled}
                    title={title}
                    onChange={handleSlackCheckToggled}
                    checked={checkEnabled}
                  />
                </div>
              );
            }}
          />
        )}
        {hasPagerDutyIntegration && (
          <Column
            dataKey="check"
            label="PagerDuty Alert"
            disableSort
            width={160}
            cellRenderer={({
              rowData,
            }: {
              rowData: { check: string };
            }): React.ReactNode => {
              const check = rowData.check;
              const noServiceSelected = selectedPdService === null;
              const [checkGroup, checkName] = check.split("/");
              const notSevereEnough =
                checkMaxSeverity(checkGroup, checkName) !== "critical";
              const checkboxDisabled = noServiceSelected || notSevereEnough;
              const title = noServiceSelected
                ? "select service to enable alerts"
                : notSevereEnough
                ? "this check never reaches 'critical' severity"
                : undefined;
              const checkEnabled = selectedPdChecks.includes(check);
              return (
                <div className={styles.checkCheckbox}>
                  <input
                    type="checkbox"
                    data-check={check}
                    disabled={checkboxDisabled}
                    title={title}
                    onChange={handlePdCheckToggled}
                    checked={checkEnabled}
                  />
                </div>
              );
            }}
          />
        )}
      </PanelVirtualTable>
      <PanelSection>
        <button
          title={saveChangesTitle}
          disabled={saveDisabled}
          onClick={handleSave}
          className="btn btn-success"
        >
          Save Changes
        </button>
      </PanelSection>
    </>
  );
};

const SelectSlackChannel: React.FunctionComponent<{
  options: SlackChannel[];
  value: SlackChannel;
  onChange: (channel: SlackChannel | null) => void;
  hasError: boolean;
}> = ({ options, value, onChange, hasError }) => {
  return hasError ? (
    <>
      <div>
        <strong>Error</strong>: could not load Slack channels
      </div>
      <div>
        <small>
          Reload the page to try again or please{" "}
          <ContactSupportLink>contact support</ContactSupportLink>
        </small>
      </div>
    </>
  ) : (
    <>
      <Select
        placeholder="— none: alerts disabled —"
        items={options}
        itemToString={(item) => `#${item.name}`}
        value={value}
        onChange={onChange}
      />
      <div className={styles.integrationConfigNote}>
        <small>Slack notifications occur for issues of any severity</small>
      </div>
    </>
  );
};

const IconConfigure = () => <FontAwesomeIcon icon={faCog} />;

const AlertConfigBlankSlate: React.FunctionComponent<{
  organizationSlug: string;
}> = ({ organizationSlug }) => {
  const { organizationIntegrations } = useRoutes();
  return (
    <PanelSection>
      <div className={styles.blankSlateBlurb}>
        No alerting integrations configured for this organization.
      </div>
      <div>
        <IconConfigure />{" "}
        <Link to={organizationIntegrations(organizationSlug)}>
          Manage alerting integrations
        </Link>
      </div>
    </PanelSection>
  );
};

function enableCheck(check: string): (prev: string[]) => string[] {
  return (prev) => {
    if (prev.includes(check)) {
      return prev;
    }
    return prev.concat(check);
  };
}

function disableCheck(check: string): (prev: string[]) => string[] {
  return (prev) => {
    if (prev.includes(check)) {
      return prev.filter((c) => c !== check);
    }
    return prev;
  };
}

function checksEqual(set1: string[], set2: string[]): boolean {
  const len1 = set1?.length ?? 0;
  const len2 = set2?.length ?? 0;
  if (len1 === 0 && len2 === 0) {
    return true;
  }
  if (len1 !== len2) {
    return false;
  }

  const sorted1 = set1.slice().sort();
  const sorted2 = set2.slice().sort();
  return sorted1.every((elem, idx) => elem === sorted2[idx]);
}

export default AlertConfig;
