import React from "react";

import { formatNumber, formatBytes, formatMs } from "utils/format";

import { Plan } from "types/explain";
import Icon from "components/Icon";

import styles from "./style.module.scss";

type Props = {
  plan: Plan;
  runtime: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
};

const ExplainStatsAndOptions: React.FunctionComponent<Props> = ({
  plan,
  runtime,
  ioBlocks,
  blockSize,
  ioMs,
}) => {
  const firstPlan = plan[0];
  const topNode = firstPlan.Plan;
  const totCost =
    "Total Cost" in topNode ? formatNumber(topNode["Total Cost"]) : "N/A";

  const options = {
    Analyze: "Actual Rows" in topNode,
    Verbose: "Output" in topNode,
    Costs: "Total Cost" in topNode,
    Buffers: "Shared Hit Blocks" in topNode,
    Timing: "Actual Total Time" in topNode,
    Summary: "Planning Time" in firstPlan,
  };
  return (
    <div>
      <dl className={styles.explainStats}>
        <div>
          <dd>{formatMs(runtime)}</dd>
          <dt>Runtime</dt>
        </div>
        <div>
          <dd>{ioMs !== null ? formatMs(ioMs) : "-"}</dd>
          <dt>I/O Read Time</dt>
        </div>
        <div>
          <dd>{ioBlocks !== null ? formatBytes(ioBlocks * blockSize) : "-"}</dd>
          <dt>Read From Disk</dt>
        </div>
        <div>
          <dd>{totCost}</dd>
          <dt>Total Est. Cost</dt>
        </div>
      </dl>
      <dl className={styles.explainOptions}>
        {Object.entries(options).map(([option, on]) => {
          return (
            <React.Fragment key={option}>
              <div className={styles.explainOptionItem}>
                <dd>{on ? <Icon kind="check" /> : <Icon kind="times" />}</dd>
                <dt>{option}</dt>
              </div>
            </React.Fragment>
          );
        })}
      </dl>
    </div>
  );
};

export default ExplainStatsAndOptions;
