import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import {
  formatBytes,
  formatMs,
  formatNumber,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import Identicon from "components/Identicon";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";

import styles from "./style.module.scss";
import moment from "moment-timezone";
import { useRoutes } from "utils/routes";
import { Column } from "react-virtualized";

interface Explain {
  id: string;
  humanId: string;
  fingerprint: string;
  seenAt: number;
  planNodeTypes: string[];
  querySample: {
    runtimeMs: number;
  };
  query: {
    id: string;
  };
  totalCost: number;
  totalBlkReadTime: number;
  totalSharedBlksRead: number;
}

type FlatExplain = Omit<Explain, "querySample"> & {
  runtimeMs: number;
  pctIoTime: number;
};

type Props = {
  databaseId: string;
  explains: Explain[];
  blockSize: number;
};

const ExplainTable: React.FunctionComponent<Props> = ({
  databaseId,
  explains,
  blockSize,
}) => {
  const { databaseQueryExplain } = useRoutes();
  const explainData = useMemo(() => {
    return explains.map<FlatExplain>((explain) => {
      const { querySample, ...rest } = explain;
      return {
        ...rest,
        runtimeMs: querySample && querySample.runtimeMs,
        pctIoTime:
          querySample && explain.totalBlkReadTime != null
            ? explain.totalBlkReadTime / querySample.runtimeMs
            : null,
      };
    });
  }, [explains]);
  return (
    <PanelVirtualTable
      initialSortBy={"seenAt"}
      initialSortDirection={"DESC"}
      data={explainData}
      noRowsText="No EXPLAIN plans found"
    >
      <Column
        dataKey="seenAt"
        label="Executed at"
        width={200}
        cellDataGetter={({ rowData }) => ({
          href:
            rowData.query &&
            databaseQueryExplain(databaseId, rowData.query.id, rowData.humanId),
          timestamp: moment.unix(rowData.seenAt),
        })}
        cellRenderer={({ cellData }) => (
          <Link to={cellData.href}>
            {formatTimestampShort(cellData.timestamp)}
          </Link>
        )}
      />
      <Column
        dataKey="fingerprint"
        label="Plan"
        width={100}
        cellRenderer={({ cellData }) => (
          <>
            <Identicon identity={cellData} />
            <span title={cellData}>{cellData.substring(0, 7)}</span>
          </>
        )}
      />
      <Column
        dataKey="totalCost"
        label="Est. Cost"
        width={100}
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        cellRenderer={({ cellData }) =>
          cellData ? formatNumber(cellData) : "-"
        }
      />
      <Column
        dataKey="runtimeMs"
        label="Runtime"
        width={100}
        flexGrow={1}
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        cellRenderer={({ cellData }) => (cellData ? formatMs(cellData) : "-")}
      />
      <Column
        dataKey="totalBlkReadTime"
        label="I/O Read Time"
        width={100}
        flexGrow={1}
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        cellRenderer={({ cellData }) => {
          return cellData != null ? formatMs(cellData) : "-";
        }}
      />
      <Column
        dataKey="pctIoTime"
        width={50}
        disableSort
        cellRenderer={({ cellData }) => {
          if (cellData == null) {
            return "-";
          }
          return (
            <span
              className={classNames({
                [styles.redHighlight]: cellData > 0.5,
              })}
            >
              {formatPercent(cellData, 0)}
            </span>
          );
        }}
      />
      <Column
        dataKey="totalSharedBlksRead"
        label="Read From Disk"
        width={100}
        flexGrow={1}
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        cellDataGetter={({ rowData }) =>
          rowData.totalSharedBlksRead != null &&
          rowData.totalSharedBlksRead * blockSize
        }
        cellRenderer={({ cellData }) =>
          cellData != null ? formatBytes(cellData) : "-"
        }
      />
      <Column
        dataKey="planNodeTypes"
        label="Plan Nodes"
        disableSort
        width={200}
        flexGrow={2}
        className={styles.planNodesColumn}
        headerClassName={styles.planNodesColumnHeader}
        cellDataGetter={({ rowData }) => {
          const threshold = 5;
          const shortlistLen = 3;
          const nodeCount =
            rowData.planNodeTypes && rowData.planNodeTypes.length;
          const ellide = nodeCount && nodeCount > threshold;
          const nodes = ellide
            ? rowData.planNodeTypes.slice(0, shortlistLen)
            : rowData.planNodeTypes;
          const extraCount = nodeCount && Math.max(0, nodeCount - shortlistLen);
          return {
            nodes,
            extraCount,
          };
        }}
        cellRenderer={({ cellData }) => {
          const { nodes, extraCount } = cellData;
          if (!nodes) {
            return "-";
          }
          return (
            <>
              {nodes.join(" · ")}
              {extraCount ? ` +${extraCount} more` : ""}
            </>
          );
        }}
      />
    </PanelVirtualTable>
  );
};

export default ExplainTable;
