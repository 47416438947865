import React from "react";

import { Moment } from "moment-timezone";
import classNames from "classnames";

import { formatTimestampShort } from "utils/format";
import { parseFreeformTs } from "utils/date";

import styles from "./style.module.scss";

export type Props = {
  name?: string;
  className?: string;
  placeholder?: string;
  label: string;
  value: string;
  onChange: (value: string, parsed: Moment | undefined) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onEnterKey?: () => void;
  error?: string;
};

const TimestampTextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      className,
      placeholder = "e.g. 8:55am, march 5 03:05am UTC or yesterday at 5pm",
      label,
      value,
      onChange,
      onFocus,
      onEnterKey,
      error,
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value;
      if (newValue !== value) {
        const timestamp = parseFreeformTs(newValue);
        onChange(newValue, timestamp);
      }
    };
    const handleKeyDown = onEnterKey
      ? (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            onEnterKey();
          }
        }
      : undefined;
    const timestamp = parseFreeformTs(value);
    const displayError =
      error || (timestamp ? undefined : "invalid time format");
    const hint = displayError || formatTimestampShort(timestamp);
    return (
      <label className={className}>
        <div className={styles.labelAndHint}>
          <span className={styles.labelText}>{label}</span>
          <span
            className={classNames(styles.hint, {
              [styles.errorHint]: displayError,
              [styles.validTimeHint]: !displayError,
            })}
          >
            {hint}
          </span>
        </div>
        <input
          name={name}
          className={classNames("form-control", styles.input)}
          ref={ref}
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      </label>
    );
  }
);

TimestampTextInput.displayName = "TimestampTextInput";

export default TimestampTextInput;
