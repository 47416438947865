import React from "react";

import { Node } from "types/explain";

import styles from "./style.module.scss";

const CTEInfo: React.FunctionComponent<{
  node: Node;
}> = ({ node }) => {
  const isCTE = node["CTE Name"] != null;
  const isCTESubplan = (node["Subplan Name"] as string)?.startsWith("CTE ");

  if (!isCTE && !isCTESubplan) {
    return null;
  }

  const cteName = node["CTE Name"];
  const cteSubplanName = String(node["Subplan Name"]);
  return (
    <div className={styles.cteInfo}>
      {isCTESubplan && <span title={cteSubplanName}>{cteSubplanName}</span>}
      {isCTE && <span title={cteName}>{cteName}</span>}
    </div>
  );
};

export default CTEInfo;
