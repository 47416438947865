import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Column, SortDirection } from "react-virtualized";

import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelVirtualTable from "components/PanelVirtualTable";

import QUERY from "./Query.graphql";
import {
  AlertPolicyList as AlertPolicyListType,
  AlertPolicyListVariables,
  AlertPolicyList_getAlertPolicies as AlertPolicy,
} from "components/AlertPolicyList/types/AlertPolicyList";

type Props = {
  organizationSlug: string;
};

const AlertPolicyList: React.FunctionComponent<Props> = ({
  organizationSlug,
}) => {
  const { organizationAlertPolicy } = useRoutes();
  const { data, loading, error } = useQuery<
    AlertPolicyListType,
    AlertPolicyListVariables
  >(QUERY, { variables: { organizationSlug } });
  if (loading || error || !data) {
    return <Loading error={!!error} />;
  }

  return (
    <Panel title="E-mail Alert Policies">
      <PanelVirtualTable
        data={data.getAlertPolicies}
        initialSortBy="assignedCount"
        initialSortDirection={SortDirection.DESC}
      >
        <Column
          dataKey="policyName"
          label="Alert Policy Name"
          width={200}
          flexGrow={1}
          cellRenderer={({
            rowData,
            cellData,
          }: {
            rowData: AlertPolicy;
            cellData?: string;
          }): React.ReactNode => (
            <Link to={organizationAlertPolicy(organizationSlug, rowData.id)}>
              {cellData}
            </Link>
          )}
        />
        <Column dataKey="assignedCount" label="Assigned Members" width={200} />
      </PanelVirtualTable>
    </Panel>
  );
};

export default AlertPolicyList;
