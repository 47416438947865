import React, { useState } from "react";

import pluralize from "pluralize";
import classNames from "classnames";

import { formatApproximateNumber, formatNumber } from "utils/format";

import CopyToClipboard from "components/CopyToClipboard";
import Icon from "components/Icon";
import { IssueType as SummaryIssueType } from "components/IndexAdvisorIssueSummaryCard";
import IndexAdvisorRelevantScans from "components/IndexAdvisorRelevantScans";
import Tip from "components/Tip";
import Button from "components/Button";

import styles from "./style.module.scss";
import SQL from "components/SQL";
import { ExpandToggle } from "components/Icons";

type IssueType = SummaryIssueType & {
  references: {
    kind: string;
    detailsJson: string;
  }[];
};

const IndexAdvisorIssueCore: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const scans = issue.references
    .filter((ref) => ref.kind === "SchemaTableScan")
    .map((scan) => {
      const details = JSON.parse(scan.detailsJson);

      return {
        combinedSql: details.combined_sql,
        whereClause: details.where_clause,
        joinClause: details.join_clause,
        oldCost: details.old_cost,
        newCost: details.new_cost,
        scansPerMin: details.estimated_scans_per_minute,
        costImprovement: details.weighted_cost_improvement,
        referencedQueries: details.referenced_query_ids,
      };
    });

  return (
    <>
      <div className="flex m-4 gap-8">
        <MissingIndexSummaryCard issue={issue} />
        <IssueCostSummary issue={issue} />
      </div>
      <IndexAdvisorRelevantScans scans={scans} databaseId={issue.databaseId} />
    </>
  );
};

const IssueCostSummary: React.FunctionComponent<{ issue: IssueType }> = ({
  issue,
}) => {
  const issueDetails = JSON.parse(issue.detailsJson);
  const scanCount = issueDetails.scan_count;
  const queryCount = issueDetails.query_count;
  const queryImprovementFactor = formatApproximateNumber(
    issueDetails.aggregate_improvement
  );
  const costImprovementTip = `This is a weighted average of the estimated cost
    improvement across ${pluralize("scan", scanCount, true)} from ${pluralize(
    "query",
    queryCount,
    true
  )},
    weighting by avg scans / minute`;

  const writeOverhead =
    issueDetails.index_write_overhead == null ? (
      "n/a"
    ) : (
      <span className="text-[#C22426]">
        +{formatNumber(issueDetails.index_write_overhead, 2)}
      </span>
    );
  const writeOverheadTip = `Adding this index is expected to increase the cost
    of writes to this table by this many bytes for every byte written to the table`;

  return (
    <div className="min-w-max">
      <div className="text-[18px] text-[#555555] font-medium mb-1">
        Cost Improvement
      </div>
      <table className="leading-loose">
        <tbody>
          <tr className="border-b">
            <th className="font-normal">
              Weighted Improvement <Tip content={costImprovementTip} />
            </th>
            <td className="text-right pl-[100px] text-[#43962a]">
              {queryImprovementFactor}×
            </td>
          </tr>
          <tr>
            <th className="font-normal">
              Index Write Overhead <Tip content={writeOverheadTip} />
            </th>
            <td className="text-right pl-[100px]">{writeOverhead}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const HowToTestDirections: React.FunctionComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setExpanded((currExp) => !currExp);
  };

  const docsUrl =
    "https://pganalyze.com/docs/index-advisor/reason-about-insights";

  return (
    <>
      <Button bare onClick={handleToggleExpanded}>
        <ExpandToggle className="w-2 mr-1" expanded={expanded} />
        <span className="text-[18px] text-[#555555]">
          How to test the insight
        </span>
      </Button>
      {expanded && (
        <>
          <ol className={classNames("-ml-4 mb-4", styles.howToTestList)}>
            <li>
              Pick one or more of the affected queries mentioned by the Index
              Advisor
            </li>
            <li>
              Run “EXPLAIN (ANALYZE, BUFFERS) SELECT …” for each of the queries,
              twice
            </li>
            <li>
              Note down the “execution time” of the second invocation (to avoid
              cold caches affecting the test)
            </li>
            <li>
              Additionally, note down the sum of the top level numbers of
              “shared blocks read”, “shared blocks hit” (this represents how
              much data the query was loading) and “cost”
            </li>
            <li>
              Create the recommended index by copying the “CREATE INDEX” command
            </li>
            <li>
              Re-run the EXPLAIN (ANALYZE, BUFFERS) test, and compare the
              numbers
            </li>
          </ol>
          <div className="mt-3 ml-3">
            <a href={docsUrl} className={styles.learnMoreLink}>
              <Icon kind="book" /> Learn more in documentation
            </a>
          </div>
        </>
      )}
    </>
  );
};

const MissingIndexSummaryCard: React.FunctionComponent<{ issue: IssueType }> =
  ({ issue }) => {
    return (
      <div>
        <IndexDDLBlock ddl={getIndexDdl(issue)} />
        <div className="py-3">
          We recommend testing insights in pre-production or staging
          environments first before deploying changes to production. If
          possible, it is advisable to use a copy of the production database for
          your tests, otherwise you may not see a representative performance
          improvement or query plan change.
        </div>
        <HowToTestDirections />
      </div>
    );
  };

const IndexDDLBlock: React.FunctionComponent<{ ddl: string }> = ({ ddl }) => {
  return (
    <div className={styles.ddlBlock}>
      <SQL className="text-base" inline sql={ddl} />
      <div className={styles.copyIndexCmd}>
        <CopyToClipboard label="Copy CREATE INDEX command" content={ddl} />
      </div>
    </div>
  );
};

function getIndexDdl(issue: IssueType): string {
  const tableName = issue.descriptionReferences.find(
    (ref) => ref.param === "table"
  ).name;
  const indexDef = issue.descriptionReferences.find(
    (ref) => ref.param === "index_definition"
  ).name;

  return `CREATE INDEX CONCURRENTLY ON ${tableName} USING ${indexDef};`;
}

export default IndexAdvisorIssueCore;
