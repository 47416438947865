import React, { useState } from "react";
import pluralize from "pluralize";
import countBy from "lodash/countBy";
import sortBy from "lodash/sortBy";
import classNames from "classnames";

import { IssueType } from "types/Issue";
import Issue from "components/Issue";

import styles from "./style.module.scss";

type Props = {
  serverId?: string;
  databaseId?: string;
  issues: IssueType[];
  verbose?: boolean;
  showNoIssuesText?: boolean;
  showAll?: boolean;
};

const SEVERITY_TO_SORT_ORDER = {
  critical: 0,
  warning: 1,
  info: 2,
};

const IssueList: React.FunctionComponent<Props> = ({
  issues,
  showAll,
  verbose,
  databaseId,
  serverId,
}) => {
  const [showAcknowledged, setshowAcknowledged] = useState(false);

  if (issues.length === 0) {
    return null;
  }

  const counts = countBy(issues, (i: IssueType): string => i.state);

  const handleToggleAcknowledged = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setshowAcknowledged((value) => !value);
  };

  if (!showAll) {
    if (showAcknowledged) {
      issues = issues.filter(
        (i: IssueType): boolean => i.state === "acknowledged"
      );
    } else {
      issues = issues.filter(
        (i: IssueType): boolean => i.state === "triggered"
      );
    }
  }

  issues = sortBy(
    issues,
    (i: IssueType): number => SEVERITY_TO_SORT_ORDER[i.severity]
  );

  return (
    <div className={styles.container}>
      {!showAll && (
        <h3
          className={classNames(
            styles.header,
            issues.length == 0 && styles.lastElement
          )}
        >
          {counts["triggered"] && (
            <div>{pluralize("new issue", counts["triggered"] || 0, true)}</div>
          )}
          {counts["acknowledged"] && (
            <a
              href=""
              onClick={handleToggleAcknowledged}
              className={styles.titleAcknowledged}
            >
              {pluralize(
                "acknowledged issue",
                counts["acknowledged"] || 0,
                true
              )}
            </a>
          )}
        </h3>
      )}
      <ul className={styles.issues}>
        {issues.map(
          (i: IssueType): React.ReactNode => (
            <Issue
              key={i.id}
              databaseId={databaseId}
              serverId={serverId}
              issue={i}
              verbose={verbose}
            />
          )
        )}
      </ul>
    </div>
  );
};

export default IssueList;
