import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";

import sortBy from "lodash/sortBy";

import classNames from "classnames";

import styles from "./style.module.scss";
import { cellStyles } from "components/PaginatedVirtualTable";
import Tip from "components/Tip";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

type SortOrder = "asc" | "desc";

export type CellData<T, F extends keyof T> = {
  field: F;
  fieldData: any;
  rowData: T;
};

type GridColumnClassName<T, F extends keyof T> =
  | string
  | ((args: CellData<T, F>) => string);

type StyledGridColumn<T, F extends keyof T> = {
  style?: "number" | "query";
  className?: GridColumnClassName<T, F>;
  headerClassName?: string;
};

export type GridColumn<T, F extends keyof T> = StyledGridColumn<T, F> & {
  header?: React.ReactNode;
  tip?: string;
  field: F;
  title?: boolean | ((args: CellData<T, F>) => string);
  // TODO: using recursive conditional types, it should be possible
  // to define a stricter type for fieldData here.
  // See https://github.com/microsoft/TypeScript/pull/40002
  renderer?: (args: CellData<T, F>) => React.ReactNode;
  toSortable?: (args: CellData<T, F>) => number | string | boolean;
  defaultSortOrder?: SortOrder;
};

type Props<T, F extends keyof T> = {
  className: string;
  data: T[];
  columns: readonly GridColumn<T, F>[];
  defaultSortBy?: F;
  striped?: boolean;
  pageSize?: number;
  cellRenderer?: React.ComponentType<
    CellData<T, F> & {
      row: number;
      column: number;
      title: string;
      className: string;
      children: React.ReactNode;
    }
  >;
};

function Grid<T, F extends keyof T>({
  className,
  data,
  columns,
  defaultSortBy,
  striped,
  pageSize = Infinity,
  cellRenderer,
}: Props<T, F>) {
  const defaultSortGridCol =
    defaultSortBy && columns.find((col) => col.field === defaultSortBy);
  const [sortState, sortData] = useReducer<
    Reducer<GridSortState<T, F>, SortAction<T, F>>
  >(sortStateReducer, {
    data,
    column: defaultSortGridCol,
    order: defaultSortGridCol?.defaultSortOrder ?? "asc",
  });
  // We use an effect instead of simply memoizing a sort, since we need to update the sort when either
  // the data prop or the sort state changes, and we want to maintain a stable sort to improve UX, so
  // we need to sort the previously-sorted data.
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(0);
    sortData({ type: "refresh", data });
  }, [data, sortData]);

  const handleSort = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newSortCol = columns.find(
      (col) => col.field === e.currentTarget.dataset.field
    );
    if (!newSortCol) {
      return;
    }
    sortData({ type: "sort", column: newSortCol });
  };

  let gridData: T[];
  const isPaginated = data.length > pageSize;
  const pageFirstItem = page * pageSize;
  const nextPageFirstItem = (page + 1) * pageSize;
  // N.B.: we need to slice in zero-indexed mode but show labels in one-indexed
  const pageFirstItemLabel = String(pageFirstItem + 1);
  const pageLastItemLabel = String(Math.min(nextPageFirstItem, data.length));
  if (isPaginated) {
    gridData = sortState.data.slice(pageFirstItem, nextPageFirstItem);
  } else {
    gridData = sortState.data.slice();
  }

  const pagePrevDisabled = pageFirstItem === 0;
  const pageNextDisabled = nextPageFirstItem >= data.length;

  const handleShowPrev = () => {
    setPage((p) => p - 1);
  };
  const handleShowNext = () => {
    setPage((p) => p + 1);
  };

  if (gridData.length === 0) {
    return <div className={styles.cell}>No data available</div>;
  }

  return (
    <>
      <HeightWrapper isPaginated={isPaginated} page={page}>
        <div className={classNames(styles.grid, className)}>
          {columns.map((col, i) => {
            const headerClassName = getHeaderClassName(col);
            const isSortedByThis = col.field === sortState.column?.field;
            return (
              <Button
                bare
                key={i}
                data-field={col.field}
                className={classNames(
                  styles.headingCell,
                  "text-left",
                  headerClassName
                )}
                onClick={handleSort}
              >
                {col.header ?? col.field}
                {col.tip && (
                  <span className={styles.headingTip}>
                    {" "}
                    <Tip content={col.tip} />
                  </span>
                )}
                {isSortedByThis &&
                  (sortState.order === "asc" ? (
                    <SortCaretUp />
                  ) : (
                    <SortCaretDown />
                  ))}
              </Button>
            );
          })}
          {gridData.map((row, i) => {
            const isLastRow = i === gridData.length - 1;
            return (
              <React.Fragment key={i}>
                {columns.map((col, j) => {
                  const cellArgs = {
                    field: col.field,
                    fieldData: row[col.field],
                    rowData: row,
                  };
                  const cellClassName = getClassName(
                    col,
                    cellArgs.field,
                    cellArgs.fieldData,
                    cellArgs.rowData
                  );
                  const renderer = col.renderer ?? defaultRenderer;
                  const title =
                    col.title === true
                      ? String(cellArgs.fieldData)
                      : typeof col.title === "function"
                      ? col.title(cellArgs)
                      : undefined;

                  const commonProps = {
                    key: j,
                    title: title,
                    className: classNames(
                      styles.cell,
                      striped
                        ? i % 2 === 1 && styles.cellAlt
                        : !isLastRow && styles.cellBordered,
                      cellClassName
                    ),
                    row: i,
                    column: j,
                  };

                  if (cellRenderer) {
                    const CellRenderer = cellRenderer;
                    return (
                      <CellRenderer
                        key={`r-${i}`}
                        {...commonProps}
                        {...cellArgs}
                      >
                        {renderer(cellArgs)}
                      </CellRenderer>
                    );
                  } else {
                    return (
                      <div key={i} {...commonProps}>
                        {renderer(cellArgs)}
                      </div>
                    );
                  }
                })}
              </React.Fragment>
            );
          })}
        </div>
      </HeightWrapper>
      {isPaginated && (
        <div className="flex pt-[8px] items-center justify-end">
          <span className="mr-[8px]">
            {pageFirstItemLabel}-{pageLastItemLabel} of {data.length}
          </span>
          <Button
            bare
            className="!text-[18px] !py-0 !px-[8px] disabled:text-[#999] disabled:cursor-not-allowed"
            disabled={pagePrevDisabled}
            onClick={handleShowPrev}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            bare
            className="!text-[18px] !py-0 !px-[8px] disabled:text-[#999] disabled:cursor-not-allowed"
            disabled={pageNextDisabled}
            onClick={handleShowNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
      )}
    </>
  );
}

function defaultRenderer<T, F extends keyof T>({
  fieldData,
}: {
  field: F;
  fieldData: any;
  rowData: T;
}): React.ReactNode {
  return String(fieldData);
}

type Sorter<T> =
  | string
  | number
  | symbol
  | ((item: T) => number | string | boolean);

function makeSorter<T, F extends keyof T>(column: GridColumn<T, F>): Sorter<T> {
  if (!column.toSortable) {
    return column.field;
  }
  return (item: T) =>
    column.toSortable({
      field: column.field,
      rowData: item,
      fieldData: item[column.field],
    });
}

type GridSortState<T, F extends keyof T> = {
  data: T[];
  column?: GridColumn<T, F>;
  order?: SortOrder;
};

type SortAction<T, F extends keyof T> =
  | {
      type: "sort";
      column: GridColumn<T, F>;
    }
  | {
      type: "refresh";
      data: T[];
    };

function sortStateReducer<T, F extends keyof T>(
  lastSortState: GridSortState<T, F>,
  action: SortAction<T, F>
): GridSortState<T, F> {
  switch (action.type) {
    case "sort":
      const newSortCol = action.column;
      const newSortOrder =
        newSortCol.field !== lastSortState.column?.field
          ? newSortCol.defaultSortOrder ?? "asc"
          : lastSortState.order === "asc"
          ? "desc"
          : "asc";

      const sortedData = doSort(lastSortState.data, newSortCol, newSortOrder);

      return {
        data: sortedData,
        column: newSortCol,
        order: newSortOrder,
      };
    case "refresh":
      const newSortedData = doSort(
        action.data,
        lastSortState.column,
        lastSortState.order
      );

      const newState = {
        ...lastSortState,
        data: newSortedData,
      };

      return newState;
  }
}

function doSort<T, F extends keyof T>(
  data: T[],
  sortCol?: GridColumn<T, F>,
  sortOrder?: SortOrder
): T[] {
  if (!sortCol) {
    return data;
  }

  const newSorter = makeSorter(sortCol);
  const sortedData = sortBy(data, [newSorter]);
  if (sortOrder === "desc") {
    sortedData.reverse();
  }
  return sortedData;
}

function getClassName<T, F extends keyof T>(
  f: StyledGridColumn<T, F>,
  field: F,
  fieldData: T[F],
  rowData: T
): string | undefined {
  const className =
    typeof f.className === "function"
      ? f.className({ field: field, fieldData: fieldData, rowData: rowData })
      : f.className;
  const builtInStyling =
    f.style === "number"
      ? cellStyles.number
      : f.style === "query"
      ? cellStyles.query
      : undefined;
  return classNames(builtInStyling, className);
}

function getHeaderClassName<T, F extends keyof T>(
  f: StyledGridColumn<T, F>
): string | undefined {
  const builtInStyling =
    f.style === "number"
      ? cellStyles.numberHeader
      : // queries have no special header style
        undefined;

  return classNames(builtInStyling, f.className);
}

const SortCaretUp: React.FunctionComponent = () => {
  return (
    <svg
      className="inline align-middle ReactVirtualized__Table__sortableHeaderIcon ReactVirtualized__Table__sortableHeaderIcon--ASC"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path d="M7 14l5-5 5 5z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
};

const SortCaretDown: React.FunctionComponent = () => {
  return (
    <svg
      className="inline align-middle ReactVirtualized__Table__sortableHeaderIcon ReactVirtualized__Table__sortableHeaderIcon--DESC"
      width="18"
      height="18"
      viewBox="0 0 24 24"
    >
      <path d="M7 10l5 5 5-5z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  );
};

const HeightWrapper: React.FunctionComponent<{
  isPaginated: boolean;
  page: number;
}> = ({ children, isPaginated, page }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperMinHeight, setWrapperMinHeight] = useState(undefined);
  // Set the highest page's height as wrapper's min height to avoid the
  // pagination footer position move between the pages with different heights
  // (e.g. the last page of results has less data/height than others).
  useEffect(() => {
    if (wrapperRef.current) {
      const currHeight = wrapperRef.current.getBoundingClientRect().height;
      if (wrapperMinHeight === undefined || currHeight > wrapperMinHeight) {
        setWrapperMinHeight(currHeight);
      }
    }
  }, [page, wrapperMinHeight]);

  if (!isPaginated) {
    return <>{children}</>;
  }
  return (
    <div ref={wrapperRef} style={{ minHeight: wrapperMinHeight }}>
      {children}
    </div>
  );
};

export const NoDataGridContainer: React.FunctionComponent<{
  className?: string;
}> = ({ className, children }) => {
  return <div className={classNames(styles.cell, className)}>{children}</div>;
};

export function sortNullsLast({
  fieldData,
}: {
  fieldData: number | null;
}): number {
  return fieldData == null ? -Infinity : fieldData;
}

export default Grid;
