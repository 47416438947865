import React from "react";

import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Loading from "components/Loading";
import Panel from "components/Panel";

import QUERY from "./Query.graphql";

import {
  GetIndexAdvisorServerOverview,
  GetIndexAdvisorServerOverviewVariables,
} from "./types/GetIndexAdvisorServerOverview";
import { useCurrentServer } from "components/WithCurrentOrganization";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import { Column } from "react-virtualized";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";
import { formatBytes, formatNumber } from "utils/format";
import { useFeature } from "components/OrganizationFeatures";
import UpgradeRequired from "components/UpgradeRequired";

const IndexAdvisorServerOverview: React.FunctionComponent = () => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  if (!hasIndexAdvisor) {
    return <UpgradeRequired feature="Index Advisor" />;
  }

  return <IndexAdvisorServerOverviewContent />;
};

const IndexAdvisorServerOverviewContent: React.FunctionComponent = () => {
  const { databaseIndexAdvisor } = useRoutes();
  const { humanId: serverId } = useCurrentServer();
  const { data, loading, error } = useQuery<
    GetIndexAdvisorServerOverview,
    GetIndexAdvisorServerOverviewVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const databases = data.getDatabases.map((db) => {
    const { indexWriteOverhead, ...rest } = db;
    return {
      ...rest,
      indexWriteOverhead: indexWriteOverhead.totalWriteOverhead,
    };
  });

  return (
    <PageContent
      title="Index Advisor"
      pageCategory="index-advisor"
      pageName="server-index"
    >
      <Panel title="Databases">
        <PanelVirtualTable data={databases}>
          <Column
            dataKey="datname"
            flexGrow={1}
            width={350}
            label="Name"
            cellRenderer={({ rowData, cellData }) => {
              return (
                <Link to={databaseIndexAdvisor(rowData.id)}>{cellData}</Link>
              );
            }}
          />
          <Column
            dataKey="issueCount"
            width={120}
            label="Insights"
            className={cellStyles.number}
            headerClassName={cellStyles.numberHeader}
            cellRenderer={({ rowData, cellData }) => {
              return (
                <Link to={databaseIndexAdvisor(rowData.id)}>{cellData}</Link>
              );
            }}
          />
          <Column
            dataKey="indexWriteOverhead"
            flexGrow={1}
            width={350}
            label="Index Write Overhead"
            className={cellStyles.number}
            headerClassName={cellStyles.numberHeader}
            cellRenderer={({ cellData }) => {
              return cellData == null ? "n/a" : formatNumber(cellData, 2);
            }}
          />
          <Column
            dataKey="tableCount"
            width={120}
            label="Tables"
            className={cellStyles.number}
            headerClassName={cellStyles.numberHeader}
            cellRenderer={({ cellData }) => {
              const hasFullCount = cellData <= 100;
              return hasFullCount ? cellData : "100+";
            }}
          />
          <Column
            dataKey="totalIndexSize"
            width={120}
            label="Index Size"
            className={cellStyles.number}
            headerClassName={cellStyles.numberHeader}
            cellRenderer={({ rowData, cellData }) => {
              const hasFullSize = rowData.tableCount <= 100;
              const sizeStr = formatBytes(cellData);
              return hasFullSize ? sizeStr : sizeStr + "+";
            }}
          />
        </PanelVirtualTable>
      </Panel>
    </PageContent>
  );
};

export default IndexAdvisorServerOverview;
