import React, { useState } from "react";

import { formatBytes, formatMs } from "utils/format";

import DocsSnippet from "components/DocsSnippet";
import PanelTable from "components/PanelTable";

import { Node } from "types/explain";

import styles from "./style.module.scss";

type Props = {
  node: Node;
  blockSize: number;
};

const IOAndBuffers: React.FunctionComponent<Props> = ({ node, blockSize }) => {
  const formatBlocksAsBytes = getBlocksAsBytesFormatter(blockSize);

  type sourceType = "self" | "cumulative";
  const [source, setSource] = useState<sourceType>("cumulative");
  const handleIOSourceChange = (
    evt: React.FormEvent<HTMLInputElement>
  ): void => {
    setSource(evt.currentTarget.value as sourceType);
  };

  const blocksCost = (prop: string) =>
    cumulativeCostSummary(node, prop, source, formatBlocksAsBytes);
  const timeCost = (prop: string) =>
    cumulativeCostSummary(node, prop, source, formatMs);

  return (
    <div>
      <PanelTable className={styles.table}>
        <thead>
          <tr>
            <th />
            <th>
              Shared{" "}
              <DocsSnippet
                title="Shared"
                content="data from regular tables and indexes"
              />
            </th>
            <th>
              Local{" "}
              <DocsSnippet
                title="Local"
                content="data from temporary tables and indexes"
              />
            </th>
            <th>
              Temp{" "}
              <DocsSnippet
                title="Temp"
                content="short-term working data used during node execution"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              Hit{" "}
              <DocsSnippet
                title="Hit"
                content="blocks found already in cache"
              />
            </th>
            <td>{blocksCost("Shared Hit Blocks")}</td>
            <td>{blocksCost("Local Hit Blocks")}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">
              Read{" "}
              <DocsSnippet
                title="Read"
                content="blocks read from disk (or OS cache)"
              />
            </th>
            <td>{blocksCost("Shared Read Blocks")}</td>
            <td>{blocksCost("Local Read Blocks")}</td>
            <td>{blocksCost("Temp Read Blocks")}</td>
          </tr>
          <tr>
            <th scope="row">
              Dirtied{" "}
              <DocsSnippet
                title="Dirtied"
                content="previously unmodified blocks changed by this query"
              />
            </th>
            <td>{blocksCost("Shared Dirtied Blocks")}</td>
            <td>{blocksCost("Local Dirtied Blocks")}</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">
              Written{" "}
              <DocsSnippet
                title="Written"
                content="previously-dirtied blocks evicted from cache by this query"
              />
            </th>
            <td>{blocksCost("Shared Written Blocks")}</td>
            <td>{blocksCost("Local Written Blocks")}</td>
            <td>{blocksCost("Temp Written Blocks")}</td>
          </tr>
        </tbody>
      </PanelTable>
      <div className={styles.timeAndOptions}>
        <div>
          <strong>I/O Read Time:</strong> {timeCost("I/O Read Time")}
        </div>
        <div className={styles.writeTime}>
          <strong>I/O Write Time:</strong> {timeCost("I/O Write Time")}
        </div>
        {"Plans" in node && (
          <>
            <strong>Show I/O for</strong>
            <div className={styles.sourceSelection}>
              <label className={styles.sourceSelectionLabel}>
                <input
                  type="radio"
                  checked={source === "cumulative"}
                  onChange={handleIOSourceChange}
                  name="iosource"
                  value="cumulative"
                />{" "}
                this node plus children
              </label>{" "}
              <label className={styles.sourceSelectionLabel}>
                <input
                  type="radio"
                  checked={source === "self"}
                  onChange={handleIOSourceChange}
                  name="iosource"
                  value="self"
                />{" "}
                just this node
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const getBlocksAsBytesFormatter = (blockSize: number) => (value: number) =>
  formatBytes(value * blockSize);

function cumulativeCostSummary(
  node: Node,
  prop: string,
  source: "self" | "cumulative",
  format: (a: any) => string
): string {
  const value =
    source === "cumulative" || !node["Plans"]
      ? node[prop]
      : node.extra.self[prop];
  if (value === null || value === undefined) {
    return "-";
  }

  return format(value);
}

export default IOAndBuffers;
