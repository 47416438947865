import React, { useCallback } from "react";

import QUERY from "./Query.graphql";

import {
  IntegrationStatus as IntegrationStatusType,
  IntegrationStatus_getServers,
  IntegrationStatusVariables,
} from "./types/IntegrationStatus";

import { useRoutes } from "utils/routes";
import Poller from "components/Poller";
import { Link } from "react-router-dom";

type IntegrationServer = Omit<IntegrationStatus_getServers, "__typename">;

type Props = {
  organizationSlug: string;
  installType: string;
};

const IntegrationStatus: React.FunctionComponent<Props> = ({
  organizationSlug,
  installType,
}) => {
  const { server: serverRoute } = useRoutes();
  const [knownServerIds, setKnownServerIds] = React.useState<string[] | null>(
    null
  );
  const [lastServers, setLastServers] = React.useState<
    IntegrationServer[] | null
  >(null);

  const handleResult = useCallback(
    (data: IntegrationStatusType) => {
      setKnownServerIds(
        (curr) =>
          curr ?? data.getServers.map((s) => s.id + s.lastTestSnapshotAt)
      );
      setLastServers(data.getServers);
    },
    [setKnownServerIds, setLastServers]
  );

  const newServers = lastServers?.filter(
    (s) => !knownServerIds.includes(s.id + s.lastTestSnapshotAt)
  );
  const server = newServers?.[0];

  if (!server) {
    return (
      <>
        <hr />
        <Poller<IntegrationStatusType, IntegrationStatusVariables>
          query={QUERY}
          queryOpts={{
            variables: { organizationSlug },
          }}
          onResult={handleResult}
          pollingMessage="Waiting for test snapshot (this should take a few seconds after you've performed a test run)..."
          timeoutMessage="The test snapshot is taking longer than expected."
        />
      </>
    );
  }

  return (
    <>
      <p>
        <strong>
          Success - found server <code>{server.name}</code>!
        </strong>
      </p>
      {installType == "package" && <PackageFinalStep />}
      {installType == "docker" && <DockerFinalStep />}
      <p>
        Your setup is complete. The dashboard will start showing data within 15
        minutes.
      </p>
      <Link to={serverRoute(server.humanId)} className="btn btn-success">
        Finish Basic Setup
      </Link>
    </>
  );
};

const PackageFinalStep: React.FunctionComponent = () => {
  return (
    <div>
      <p style={{ marginBottom: "10px" }}>
        The last line of the log output should read{" "}
        <code>Successfully reloaded pganalyze collector</code>, which means that
        the collector background process is now continuously sending statistics
        data to pganalyze.
      </p>
    </div>
  );
};

const DockerFinalStep: React.FunctionComponent = () => {
  return (
    <div>
      <h3>Start collector</h3>
      <p>You can now start the collector in the background:</p>
      <pre>
        <code>
          docker run -d --name pganalyze-collector --env-file
          pganalyze_collector.env quay.io/pganalyze/collector:stable
        </code>
      </pre>
    </div>
  );
};

export default IntegrationStatus;
