import React from "react";

import moment from "moment";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";

import Panel from "components/Panel";
import Loading from "components/Loading";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import { formatBytes } from "utils/format";

import { IssueDetail_getIssue as IssueType } from "../types/IssueDetail";

import QUERY from "./Query.graphql";
import {
  IndexAdvisorExistingIndexes as IndexAdvisorExistingIndexesType,
  IndexAdvisorExistingIndexesVariables,
  IndexAdvisorExistingIndexes_getSchemaTableIndices as ExistingIndexType,
} from "./types/IndexAdvisorExistingIndexes";
import { useRoutes } from "utils/routes";

const IndexAdvisorExistingIndexes: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const issueTable = issue.references.find((ref) => ref.kind === "SchemaTable");
  const { data, loading, error } = useQuery<
    IndexAdvisorExistingIndexesType,
    IndexAdvisorExistingIndexesVariables
  >(QUERY, {
    variables: {
      databaseId: issue.databaseId,
      tableId: (issueTable.referent as { id: string }).id,
    },
  });
  const indexes = data?.getSchemaTableIndices;
  return (
    <Panel title={`Existing Indexes (${indexes?.length ?? "?"})`}>
      {loading || error ? (
        <Loading error={!!error} />
      ) : (
        <IndexAdvisorExistingIndexesTable
          databaseId={issue.databaseId}
          indexes={indexes}
        />
      )}
    </Panel>
  );
};

const IndexAdvisorExistingIndexesTable: React.FunctionComponent<{
  databaseId: string;
  indexes: ExistingIndexType[];
}> = ({ databaseId, indexes }) => {
  const { databaseIndex } = useRoutes();
  return (
    <PanelVirtualTable
      data={indexes}
      initialSortDirection={SortDirection.ASC}
      initialSortBy="indexDefShort"
    >
      <Column
        dataKey="name"
        label="Name"
        width={300}
        cellRenderer={({ rowData, cellData }) => {
          return (
            <Link to={databaseIndex(databaseId, rowData.id)}>{cellData}</Link>
          );
        }}
      />
      <Column
        dataKey="indexDefShort"
        label="Definition"
        width={300}
        flexGrow={1}
        cellRenderer={({ cellData }) => {
          return cellData;
        }}
      />
      <Column dataKey="constraintDef" label="Constraint" width={140} />
      <Column
        dataKey="valid"
        label="Valid?"
        width={80}
        cellRenderer={({ cellData }) => {
          return cellData ? "VALID" : "NOT VALID";
        }}
      />
      <Column
        dataKey="firstSnapshotAt"
        label="First Seen"
        width={120}
        cellRenderer={({ cellData }) => {
          return !!cellData
            ? moment.unix(cellData).fromNow()
            : "Over 30 days ago";
        }}
      />
      <Column
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        dataKey="sizeBytes"
        label="Size"
        width={120}
        cellRenderer={({ cellData }) =>
          cellData == null ? "?" : formatBytes(cellData)
        }
      />
    </PanelVirtualTable>
  );
};

export default IndexAdvisorExistingIndexes;
