import React, { useState } from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { Column, SortDirection } from "react-virtualized";
import flowRight from "lodash/flowRight";

import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import PanelVirtualTable from "components/PanelVirtualTable";
import withLoading from "decorators/withLoading";

import QUERY from "./Query.graphql";

import {
  PostgresRoleList_getPostgresRoles as PostgresRole,
  PostgresRoleList as PostgresRoleListType,
} from "./types/PostgresRoleList";
import { useRoutes } from "utils/routes";

type Props = {
  data: PostgresRoleListType;
  serverId: string;
};

const PostgresRoleList: React.FunctionComponent<Props> = ({
  data,
  serverId,
}) => {
  const { serverRole } = useRoutes();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  let postgresRoles = data.getPostgresRoles;
  if (searchTerm) {
    postgresRoles = postgresRoles.filter(
      (postgresRole: PostgresRole): boolean =>
        postgresRole.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(searchTerm: string) => {
        setSearchTerm(searchTerm);
      }}
    />
  );

  return (
    <Panel title="All Roles" secondaryTitle={titleSearch}>
      <PanelVirtualTable data={postgresRoles} initialSortBy="name">
        <Column
          dataKey="name"
          label="Name"
          width={120}
          flexGrow={1}
          cellDataGetter={({
            rowData,
          }: {
            rowData: PostgresRole;
          }): PostgresRole => rowData}
          cellRenderer={({ cellData }): React.ReactNode => (
            <Link to={serverRole(serverId, cellData.id)}>{cellData.name}</Link>
          )}
        />
        <Column
          dataKey="login"
          label="Login"
          width={120}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "?" : (cellData && "Yes") || "No"
          }
        />
        <Column
          dataKey="superUser"
          label="Super User"
          width={120}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "?" : (cellData && "Yes") || "No"
          }
        />
      </PanelVirtualTable>
    </Panel>
  );
};

export default flowRight(graphql(QUERY), withLoading)(PostgresRoleList);
