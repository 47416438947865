import React, { useCallback, useState } from "react";

import {
  Navigate,
  Route,
  Routes,
  useParams,
  useLocation,
  useMatch,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import * as AmazonRDSTestLogDownload from "../../../../docs/log-insights/setup/amazon-rds/01_test_log_download.mdx";

import * as SelfManagedDetermineLogLocation from "../../../../docs/log-insights/setup/self-managed/01_determine_log_location.mdx";
import * as SelfManagedConfigureCollector from "../../../../docs/log-insights/setup/self-managed/02_configure_collector.mdx";

import * as HerokuAddLogDrain from "../../../../docs/log-insights/setup/heroku-postgres/01_add_log_drain.mdx";
import * as HerokuVerifyLogDrain from "../../../../docs/log-insights/setup/heroku-postgres/02_verify_log_drain.mdx";
import * as HerokuRestartCollector from "../../../../docs/log-insights/setup/heroku-postgres/03_restart_collector.mdx";

import * as CrunchyBridgeCreateHelperFunctionAndTest from "../../../../docs/log-insights/setup/crunchy-bridge/01_create_helper_function_and_test.mdx";

import * as AzureSetUpManagedIdentity from "../../../../docs/log-insights/setup/azure-database/01_set_up_managed_identity.mdx";
import * as AzureSetUpAzureEventHub from "../../../../docs/log-insights/setup/azure-database/02_set_up_azure_event_hub.mdx";
import * as AzureStreamLogsIntoEventHub from "../../../../docs/log-insights/setup/azure-database/03_stream_logs_into_event_hub.mdx";
import * as AzureAdjustLogLinePrefix from "../../../../docs/log-insights/setup/azure-database/04_adjust_log_line_prefix.mdx";
import * as AzureConfigureCollector from "../../../../docs/log-insights/setup/azure-database/05_configure_collector.mdx";

import * as GCPCreateTopicAndSubscriber from "../../../../docs/log-insights/setup/google-cloud-sql/01_create_topic_and_subscriber.mdx";
import * as GCPConfigureLogRouting from "../../../../docs/log-insights/setup/google-cloud-sql/02_configure_log_routing.mdx";
import * as GCPSetUpServiceAccount from "../../../../docs/log-insights/setup/google-cloud-sql/03_set_up_service_account.mdx";
import * as GCPConfigureCollector from "../../../../docs/log-insights/setup/google-cloud-sql/04_configure_collector.mdx";

import * as AivenMoveDatabaseIntoVPC from "../../../../docs/log-insights/setup/aiven/01_move_database_into_vpc.mdx";
import * as AivenConfigureVPCPeering from "../../../../docs/log-insights/setup/aiven/02_configure_vpc_peering.mdx";
import * as AivenConfigureCollector from "../../../../docs/log-insights/setup/aiven/03_configure_collector.mdx";
import * as AivenConfigureServiceIntegration from "../../../../docs/log-insights/setup/aiven/04_configure_service_integration.mdx";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";
import ContactSupportLink from "components/ContactSupportLink";
import LogInsightsIntegrationStatus from "components/LogInsightsIntegrationStatus";
import DocSteps, { StepType } from "components/DocSteps";
import DocsPanel from "components/DocsPanel";
import DocsMDXProvider from "components/DocsMDXProvider";

import QUERY from "./Query.graphql";
import {
  ServerSetupLogInsightsDocs as ServerSetupLogInsightsDocsType,
  ServerSetupLogInsightsDocsVariables,
} from "./types/ServerSetupLogInsightsDocs";

import styles from "./style.module.scss";
import Poller from "components/Poller";

const POLL_INTERVAL_MS = 15000; // 15 seconds
const POLL_TIMEOUT_MS = 900_000; // 15 minutes

const ServerSetupLogInsightsDocs: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const [serverInfo, setServerInfo] =
    useState<ServerSetupLogInsightsDocsType | null>(null);
  const handleResult = useCallback(
    (result: ServerSetupLogInsightsDocsType) => {
      if (result.getServerDetails.lastSnapshotAt) {
        setServerInfo(result);
      }
    },
    [setServerInfo]
  );

  if (!serverInfo) {
    return (
      <SnapshotRequiredContent>
        <Poller<
          ServerSetupLogInsightsDocsType,
          ServerSetupLogInsightsDocsVariables
        >
          query={QUERY}
          queryOpts={{
            variables: { serverId },
            pollInterval: POLL_INTERVAL_MS,
          }}
          onResult={handleResult}
          pollingMessage="Waiting for basic query performance monitoring to be functional before setting up Log Insights. This should take about ten minutes."
          timeoutMessage={
            <>
              Basic query performance integration is taking longer than
              expected. If this continues, please{" "}
              <ContactSupportLink>contact us</ContactSupportLink> for help, and
              include any collector log output.
            </>
          }
          timeout={POLL_TIMEOUT_MS}
        />
      </SnapshotRequiredContent>
    );
  }

  return (
    <ServerSetupLogInsightsContent data={serverInfo} serverId={serverId} />
  );
};

const SnapshotRequiredContent: React.FunctionComponent = ({ children }) => {
  return (
    <PageContent
      title="Set Up Log Insights"
      pageCategory="docs"
      pageName="show"
    >
      <Panel
        title={
          <span className={styles.snapshotRequiredTitle}>
            <FontAwesomeIcon icon={faExclamationCircle} /> Query Performance
            integration required
          </span>
        }
      >
        <PanelSection>{children}</PanelSection>
      </Panel>
    </PageContent>
  );
};

const ServerSetupLogInsightsContent: React.FunctionComponent<{
  serverId: string;
  data: ServerSetupLogInsightsDocsType;
}> = ({ serverId, data }) => {
  const systemType = data.getServerDetails?.systemType;
  const configFromEnv = data.getServerDetails?.configFromEnv;
  const apiKey = data.getApiKeys?.[0]?.token;

  return (
    <DocsMDXProvider>
      <Routes>
        <Route
          index
          element={<RedirectToFirstStep systemType={systemType} />}
        />
        <Route path="amazon_rds">
          <Route
            path="01_test_log_download"
            element={
              <MDXPageContent mdx={AmazonRDSTestLogDownload}>
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="self_managed">
          <Route
            path="01_determine_log_location"
            element={<MDXPageContent mdx={SelfManagedDetermineLogLocation} />}
          />
          <Route
            path="02_configure_collector"
            element={
              <MDXPageContent
                mdx={SelfManagedConfigureCollector}
                apiKey={apiKey}
              >
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="heroku_postgres">
          <Route
            path="01_add_log_drain"
            element={<MDXPageContent mdx={HerokuAddLogDrain} apiKey={apiKey} />}
          />
          <Route
            path="02_verify_log_drain"
            element={<MDXPageContent mdx={HerokuVerifyLogDrain} />}
          />
          <Route
            path="03_restart_collector"
            element={
              <MDXPageContent mdx={HerokuRestartCollector}>
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="crunchy_bridge">
          <Route
            path="01_create_helper_function_and_test"
            element={
              <MDXPageContent
                mdx={CrunchyBridgeCreateHelperFunctionAndTest}
                apiKey={apiKey}
              >
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="azure_database">
          <Route
            path="01_set_up_managed_identity"
            element={<MDXPageContent mdx={AzureSetUpManagedIdentity} />}
          />
          <Route
            path="02_set_up_azure_event_hub"
            element={<MDXPageContent mdx={AzureSetUpAzureEventHub} />}
          />
          <Route
            path="03_stream_logs_into_event_hub"
            element={<MDXPageContent mdx={AzureStreamLogsIntoEventHub} />}
          />
          <Route
            path="04_adjust_log_line_prefix"
            element={<MDXPageContent mdx={AzureAdjustLogLinePrefix} />}
          />
          <Route
            path="05_configure_collector"
            element={
              <MDXPageContent
                mdx={AzureConfigureCollector}
                configFromEnv={configFromEnv}
              >
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="google_cloud_sql">
          <Route
            path="01_create_topic_and_subscriber"
            element={<MDXPageContent mdx={GCPCreateTopicAndSubscriber} />}
          />
          <Route
            path="02_configure_log_routing"
            element={<MDXPageContent mdx={GCPConfigureLogRouting} />}
          />
          <Route
            path="03_set_up_service_account"
            element={<MDXPageContent mdx={GCPSetUpServiceAccount} />}
          />
          <Route
            path="04_configure_collector"
            element={
              <MDXPageContent mdx={GCPConfigureCollector}>
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="aiven">
          <Route
            path="01_move_database_into_vpc"
            element={<MDXPageContent mdx={AivenMoveDatabaseIntoVPC} />}
          />
          <Route
            path="02_configure_vpc_peering"
            element={<MDXPageContent mdx={AivenConfigureVPCPeering} />}
          />
          <Route
            path="03_configure_collector"
            element={<MDXPageContent mdx={AivenConfigureCollector} />}
          />
          <Route
            path="04_configure_service_integration"
            element={
              <MDXPageContent mdx={AivenConfigureServiceIntegration}>
                <LogInsightsIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
      </Routes>
    </DocsMDXProvider>
  );
};

const RedirectToFirstStep: React.FunctionComponent<{
  systemType: string;
}> = ({ systemType }) => {
  const { pathname } = useLocation();
  const installTrack = getInstallTrack(systemType);
  const steps = getLogInsightsSteps(installTrack);
  if (steps.length === 0) {
    return <Loading error />;
  }
  const firstStep = steps[0][1];
  let instructionsPath = pathname;
  if (!instructionsPath.endsWith("/")) {
    instructionsPath += "/";
  }
  instructionsPath += `${installTrack}/${firstStep}`;
  return <Navigate to={instructionsPath} replace />;
};

type MDXComponentType<P> = {
  default: React.ComponentType<P>;
  frontmatter?: Record<string, any>;
};

type MDXPageContentProps<P> = {
  mdx: MDXComponentType<P>;
  children: React.ReactChild;
} & P;

const MDXPageContent: React.FunctionComponent<MDXPageContentProps<any>> = ({
  mdx,
  children,
  ...rest
}) => {
  const ChildComponent = mdx.default;
  const componentTitle = mdx.frontmatter?.install_track_title;
  const match = useMatch("/servers/:id/setup/log-insights/:installTrack/:step");
  const installTrack = match?.params?.installTrack;
  const step = match?.params?.step;

  const panelTitle = (
    <DocSteps steps={getLogInsightsSteps(installTrack)} step={step} />
  );

  return (
    <PageContent
      title={componentTitle ?? "Set Up Log Insights"}
      pageCategory="docs"
      pageName="show"
    >
      <DocsPanel title={panelTitle}>
        <ChildComponent {...rest} />
        {children}
      </DocsPanel>
    </PageContent>
  );
};

const getInstallTrack = (systemType: string): string => {
  switch (systemType) {
    case "heroku":
      return "heroku_postgres";
    case "google_cloudsql":
      return "google_cloud_sql";
    default:
      return systemType;
  }
};

const getLogInsightsSteps = (installTrack: string): StepType[] => {
  switch (installTrack) {
    case "amazon_rds":
      return [["Test log download", "01_test_log_download"]];
    case "self_managed":
      return [
        ["Determine log location", "01_determine_log_location"],
        ["Configure collector", "02_configure_collector"],
      ];
    case "heroku_postgres":
      return [
        ["Add log drain", "01_add_log_drain"],
        ["Verify log drain", "02_verify_log_drain"],
        ["Restart collector", "03_restart_collector"],
      ];
    case "crunchy_bridge":
      return [
        [
          "Create helper function and test",
          "01_create_helper_function_and_test",
        ],
      ];
    case "azure_database":
      return [
        ["Set up managed identity", "01_set_up_managed_identity"],
        ["Set up Event Hub", "02_set_up_azure_event_hub"],
        ["Stream logs to Event Hub", "03_stream_logs_into_event_hub"],
        ["Adjust log_line_prefix", "04_adjust_log_line_prefix"],
        ["Configure collector", "05_configure_collector"],
      ];
    case "google_cloud_sql":
      return [
        ["Create topic and subscriber", "01_create_topic_and_subscriber"],
        ["Configure log routing", "02_configure_log_routing"],
        ["Set up Service Account", "03_set_up_service_account"],
        ["Configure collector", "04_configure_collector"],
      ];
    case "aiven":
      return [
        ["Move database into VPC", "01_move_database_into_vpc"],
        ["Configure VPC peering", "02_configure_vpc_peering"],
        ["Configure collector", "03_configure_collector"],
        ["Configure service integration", "04_configure_service_integration"],
      ];
    default:
      return [];
  }
};

export default ServerSetupLogInsightsDocs;
