import React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import {
  VacuumTimeline as VacuumTimelineType,
  VacuumTimelineVariables,
} from "./types/VacuumTimeline";
import {
  adaptVacuumRunData,
  VacuumEntry,
} from "components/VacuumTimelineChart/util";
import VacuumTimelineChart from "components/VacuumTimelineChart";
import Loading from "components/Loading";
import Panel from "components/Panel";
import { useDateRange } from "components/WithDateRange";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

import QUERY from "./Query.graphql";

const VacuumTimeline: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const [{ from: start, to: end }] = useDateRange();
  const navigate = useNavigate();
  const { serverVacuum } = useRoutes();

  const timeframeDays = end.diff(start, "days", true);
  const secondaryTitle = (
    <span>
      <strong>Hint:</strong> The Timeline only shows VACUUMs that run for at
      least {timeframeDays <= 1 ? "10 minutes" : "1 hour"}.{" "}
    </span>
  );

  const minDurationSecs = timeframeDays <= 1 ? 60 : 3600;
  const { data, loading, error } = useQuery<
    VacuumTimelineType,
    VacuumTimelineVariables
  >(QUERY, {
    variables: {
      serverId,
      minDurationSecs,
      startTs: start.unix(),
      endTs: end.unix(),
    },
  });
  if (loading || error) {
    return (
      <Panel title="Timeline" secondaryTitle={secondaryTitle}>
        <Loading error={!!error} />
      </Panel>
    );
  }

  const runsByLane = adaptVacuumRunData(
    data.getVacuumRuns.vacuumRuns,
    start,
    end
  );
  const handleRunClick = (run: VacuumEntry) => {
    navigate(serverVacuum(serverId, run.vacuumIdentity));
  };
  return (
    <Panel title="Timeline" secondaryTitle={secondaryTitle}>
      <div className={styles.timelineChartWrapper}>
        <VacuumTimelineChart
          data={runsByLane}
          databaseId={databaseId}
          start={start}
          end={end}
          onClick={handleRunClick}
        />
      </div>
    </Panel>
  );
};

export default VacuumTimeline;
