import React from "react";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";

const LoadingErrorBoundary: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<Loading error />}>
    {children}
  </Sentry.ErrorBoundary>
);

export default LoadingErrorBoundary;
