import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";
import { SortDirection } from "types/graphql-global-types";

import { formatBytes } from "utils/format";
import Loading from "components/Loading";
import PaginatedVirtualTable, {
  cellStyles,
} from "components/PaginatedVirtualTable";

import {
  SchemaViewList,
  SchemaViewListVariables,
  SchemaViewList_getSchemaViews,
  SchemaViewList_getSchemaViews_issues,
} from "./types/SchemaViewList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  searchTerm: string | null;
  databaseId: string;
}

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaView: SchemaViewList_getSchemaViews;
}> = ({ databaseId, schemaView }) => {
  const { databaseView } = useRoutes();
  return (
    <span>
      <Link to={databaseView(databaseId, schemaView.id)}>
        {schemaView.viewName}
      </Link>
      {schemaView.issues.map(
        (i: SchemaViewList_getSchemaViews_issues): React.ReactNode => (
          <Link
            to={databaseView(databaseId, schemaView.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        )
      )}
    </span>
  );
};

// Handle the mapping between the union type (react-virtualized) and enum in GraphQL
const sortDirectionStrToEnum: { [key in "ASC" | "DESC"]: SortDirection } = {
  ASC: SortDirection.ASC,
  DESC: SortDirection.DESC,
};
const sortDirectionEnumToStr: {
  [key in keyof typeof SortDirection]: "ASC" | "DESC";
} = {
  [SortDirection.ASC]: "ASC",
  [SortDirection.DESC]: "DESC",
};

const Table: React.FunctionComponent<Props> = (props) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: "viewName",
    sortDirection: SortDirection.ASC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaViewList,
    SchemaViewListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: 50,
      filter: props.searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId: props.databaseId,
    },
  });
  if (loading || error || !data.getSchemaViews) {
    return <Loading error={!!error} />;
  }

  const schemaViews = data.getSchemaViews;

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: SchemaViewList,
        { fetchMoreResult }: { fetchMoreResult: SchemaViewList }
      ): SchemaViewList => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getSchemaViews: [
            ...prev.getSchemaViews,
            ...fetchMoreResult.getSchemaViews,
          ],
        });
      },
    });
  };

  const handleSort = ({ sortBy, sortDirection }: SortOptsType) => {
    setSortOpts({
      sortBy,
      sortDirection: sortDirectionStrToEnum[sortDirection],
    });
  };

  return (
    <PaginatedVirtualTable
      data={schemaViews}
      fetchMore={fetchMoreData}
      sortBy={sortOpts.sortBy}
      sortDirection={sortDirectionEnumToStr[sortOpts.sortDirection]}
      handleSort={handleSort}
    >
      <Column dataKey="schemaName" label="Schema" width={120} />
      <Column
        dataKey="viewName"
        label="View Name"
        width={200}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaViewList_getSchemaViews;
        }): SchemaViewList_getSchemaViews => rowData}
        cellRenderer={({
          cellData,
        }: {
          cellData?: SchemaViewList_getSchemaViews;
        }): React.ReactNode => (
          <NameAndIssuesCell
            databaseId={props.databaseId}
            schemaView={cellData}
          />
        )}
      />
      <Column
        dataKey="viewDefinition"
        label="Definition"
        className={cellStyles.query}
        width={180}
        flexGrow={1}
        cellRenderer={({ cellData }: { cellData?: string }): React.ReactNode =>
          cellData.trim()
        }
      />
      <Column
        dataKey="materializedView"
        label="Materialized"
        width={120}
        defaultSortDirection={"DESC"}
        cellRenderer={({ cellData }: { cellData?: boolean }): React.ReactNode =>
          (cellData && "Yes") || "No"
        }
      />
      <Column
        dataKey="sizeBytes"
        label="Data Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={100}
        defaultSortDirection={"DESC"}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
    </PaginatedVirtualTable>
  );
};

export default Table;
