import React from "react";

import classNames from "classnames";
import { useMutation } from "@apollo/client";

import { useAsyncActionFlash } from "components/WithFlashes";

import {
  ResetOrganizationIntegration,
  ResetOrganizationIntegrationVariables,
} from "./types/ResetOrganizationIntegration";

import MUTATION from "./Mutation.graphql";

const ResetIntegration: React.FunctionComponent<{
  service: string;
  integrationId: string;
  disabled: boolean;
  alertingIntegration: boolean;
  hideIfDisabled?: boolean;
}> = ({
  service,
  integrationId,
  disabled,
  alertingIntegration,
  hideIfDisabled,
}) => {
  const [resetIntegration, { called, loading, error }] = useMutation<
    ResetOrganizationIntegration,
    ResetOrganizationIntegrationVariables
  >(MUTATION);

  useAsyncActionFlash({
    called,
    loading,
    error: error?.message,
    success: "Reset complete!",
  });

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    resetIntegration({
      variables: {
        integrationId,
      },
    });
  };

  if (hideIfDisabled && disabled) {
    return null;
  }

  return (
    <>
      <button
        disabled={disabled}
        onClick={handleButtonClick}
        className={classNames("btn btn-primary", disabled && "disabled")}
      >
        Reset integration
      </button>
      {disabled ? (
        <span>You must configure the integration before you can reset it.</span>
      ) : (
        <span>
          After a reset, you will need to re-authenticate with {service} in
          order to use it with pganalyze again.
          {alertingIntegration
            ? "Configuration settings for alerting will be maintained."
            : null}
        </span>
      )}
    </>
  );
};

export default ResetIntegration;
