import * as React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";
import classNames from "classnames";

import PaginatedVirtualTable, {
  cellStyles,
} from "components/PaginatedVirtualTable";
import { SortDirection } from "types/graphql-global-types";
import { formatMs, formatPercent } from "utils/format";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

interface QueryType {
  id: string;
  postgresRole: null | {
    id: string;
    name: string;
  };
  postgresRoleName?: null | string;
  truncatedQuery: string;
  normalizedQuery: string;
  avgTime: number;
  callsPerMinute: number;
  bufferHitRatio: number;
  pctOfTotal: number;
}

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  serverId: string;
  queries: Array<QueryType>;
  searchTerm: null | string;
  compare: boolean;
  showingTable?: boolean;
  setSortOpts: (opts: SortOptsType) => void;
  sortOpts: SortOptsType;
  fetchMoreData: (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => void;
}

interface AvgTimeTrend {
  avgTime: number;
  avgTimeDelta: number;
}

interface CallsTrend {
  callsPerMinute: number;
  callsPerMinuteDelta: number;
}

// Handle the mapping between the union type (react-virtualized) and enum in GraphQL
const sortDirectionStrToEnum: { [key in "ASC" | "DESC"]: SortDirection } = {
  ASC: SortDirection.ASC,
  DESC: SortDirection.DESC,
};
const sortDirectionEnumToStr: {
  [key in keyof typeof SortDirection]: "ASC" | "DESC";
} = {
  [SortDirection.ASC]: "ASC",
  [SortDirection.DESC]: "DESC",
};

// Need to turn this into a data loading for the query statistics, similar to
// SchemaTableList - however this is more complicated because this table is
// used twice, once for the query overview, and once for the per-table queries
const QueryStatsTable: React.FunctionComponent<Props> = (props) => {
  const { databaseId, serverId, compare } = props;
  const { databaseQuery, serverRole } = useRoutes();

  const queries: Array<QueryType> = props.queries.map(
    (q: QueryType): QueryType => {
      return {
        postgresRoleName: (q.postgresRole && q.postgresRole.name) || null,
        ...q,
      };
    }
  );

  const handleSort = ({ sortBy, sortDirection }: SortOptsType) => {
    props.setSortOpts({
      sortBy,
      sortDirection: sortDirectionStrToEnum[sortDirection],
    });
  };

  const avgTimeTrendDataGetter = ({
    rowData,
  }: {
    rowData: any;
  }): AvgTimeTrend | null => {
    const { avgTime, avgTimeDelta } = rowData;
    if (avgTime == null || avgTimeDelta == null) {
      return null;
    }
    return {
      avgTime,
      avgTimeDelta,
    };
  };

  const avgTimeTrendRenderer = ({
    cellData,
  }: {
    cellData?: AvgTimeTrend;
  }): React.ReactNode => {
    if (cellData === null) {
      return "n/a";
    } else {
      const { avgTime, avgTimeDelta } = cellData;

      const value = avgTime;
      const delta = avgTimeDelta;
      if (delta === null) {
        return "n/a";
      }
      const prevValue = value - delta;
      const pctDelta = (value - prevValue) / prevValue;
      if (delta === 0) {
        return "(no change)";
      }
      const sign = delta > 0 ? "+" : "";
      const pctChange = sign + formatPercent(pctDelta);
      const absChange = sign + formatMs(delta);
      return (
        <span
          title={pctChange}
          className={classNames(
            delta < 0 && styles.deltaDown,
            delta > 0 && styles.deltaUp
          )}
        >
          {absChange}
        </span>
      );
    }
  };

  const callsTrendDataGetter = ({
    rowData,
  }: {
    rowData: any;
  }): CallsTrend | null => {
    const { callsPerMinute, callsPerMinuteDelta } = rowData;
    if (callsPerMinute == null || callsPerMinuteDelta == null) {
      return null;
    }
    return {
      callsPerMinute,
      callsPerMinuteDelta,
    };
  };

  const callsTrendRenderer = ({
    cellData,
  }: {
    cellData?: CallsTrend;
  }): React.ReactNode => {
    if (cellData === null) {
      return "n/a";
    } else {
      const { callsPerMinute, callsPerMinuteDelta } = cellData;

      const value = callsPerMinute;
      const delta = callsPerMinuteDelta;
      if (delta === null) {
        return "n/a";
      }
      const prevValue = value - delta;
      const pctDelta = (value - prevValue) / prevValue;
      if (delta === 0) {
        return "(no change)";
      }
      const sign = delta > 0 ? "+" : "";
      const pctChange = sign + formatPercent(pctDelta);
      const absChange = sign + delta.toFixed(2);
      return (
        <span
          title={pctChange}
          className={classNames(
            delta < 0 && styles.deltaDown,
            delta > 0 && styles.deltaUp
          )}
        >
          {absChange}
        </span>
      );
    }
  };

  return (
    <PaginatedVirtualTable
      data={queries}
      fetchMore={props.fetchMoreData}
      sortBy={props.sortOpts.sortBy}
      sortDirection={sortDirectionEnumToStr[props.sortOpts.sortDirection]}
      handleSort={handleSort}
      noRowsText="No queries matched"
      batchSize={100}
    >
      <Column
        dataKey="normalizedQuery"
        label="Query"
        width={200}
        flexGrow={1}
        className={cellStyles.query}
        cellDataGetter={({ rowData }) => ({
          href: databaseQuery(databaseId, rowData.id),
          truncatedQuery: rowData.truncatedQuery,
          normalizedQuery: rowData.normalizedQuery,
        })}
        cellRenderer={({ cellData }) => (
          <Link to={cellData.href} title={cellData.normalizedQuery}>
            {cellData.truncatedQuery}
          </Link>
        )}
      />
      <Column
        dataKey="postgresRoleName"
        label="Role"
        width={120}
        cellRenderer={({ cellData, rowData }) => {
          if (cellData) {
            return (
              <Link to={serverRole(serverId, rowData.postgresRole.id)}>
                {cellData}
              </Link>
            );
          } else {
            return "n/a";
          }
        }}
      />
      <Column
        dataKey="avgTime"
        label="Avg Time (ms)"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        defaultSortDirection={SortDirection.DESC}
        width={110}
        cellRenderer={({ cellData }) => formatMs(cellData)}
      />
      {compare && (
        <Column
          dataKey="avgTimeDelta"
          label="7 Day Trend"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={110}
          cellDataGetter={avgTimeTrendDataGetter}
          cellRenderer={avgTimeTrendRenderer}
        />
      )}
      <Column
        dataKey="callsPerMinute"
        label="Calls / min"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        defaultSortDirection={SortDirection.DESC}
        width={100}
        cellRenderer={({ cellData }) => cellData.toFixed(2)}
      />
      {compare && (
        <Column
          dataKey="callsPerMinuteDelta"
          label="7 Day Trend"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={110}
          cellDataGetter={callsTrendDataGetter}
          cellRenderer={callsTrendRenderer}
        />
      )}
      {!props.showingTable && (
        <Column
          dataKey="pctOfTotalIo"
          label="% of All I/O"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={95}
          cellRenderer={({ cellData }) =>
            (cellData !== null && formatPercent(cellData / 100.0)) || "n/a"
          }
        />
      )}
      <Column
        dataKey="pctOfTotal"
        label="% of All Runtime"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        defaultSortDirection={SortDirection.DESC}
        width={(props.showingTable && 200) || 140}
        cellRenderer={({ cellData }) => formatPercent(cellData / 100.0)}
      />
    </PaginatedVirtualTable>
  );
};

export default QueryStatsTable;
