import React, { useMemo } from "react";

import { Moment } from "moment-timezone";
import classNames from "classnames";

import {
  Scale,
  Layout,
  xValue,
  Datum,
  ScreenLocation,
  yValue,
  InteractionPoint,
  DatumRef,
  formatTimeAdaptive,
} from "../Graph/util";
import { scaleTime, bisector, bisectLeft } from "d3";
import { VacuumActivity, VacuumEntry } from "./util";
import { colors, isDark } from "utils/colors";
import { translateX, translate, translateY } from "utils/svg";
import { useDimensions } from "utils/hooks";

import styles from "./style.module.scss";
import LoadingErrorBoundary from "../LoadingErrorBoundary";
import Axis from "../Graph/Axis";

import Mouse from "../Graph/Mouse";
import Tooltip from "../Graph/Tooltip";

export type Props = {
  databaseId?: string;
  data: VacuumActivity;
  start: Moment;
  end: Moment;
  width: number;
  onClick: (v: VacuumEntry) => void;
  onRangeSelected?: (from: number, to: number) => void;
};

const OTHER_DB_BAR_STYLE: Partial<React.SVGProps<SVGRectElement>> = {
  strokeDasharray: "4 4",
  fill: "white",
  stroke: "gray",
  strokeWidth: 1,
};

const VacuumTimelineChart: React.FunctionComponent<Omit<Props, "width">> = (
  props
) => {
  const [ref, dimensions] = useDimensions<HTMLDivElement>();
  return (
    <div ref={ref} className={styles.vacuumTimelineWrapper}>
      {dimensions && (
        <LoadingErrorBoundary>
          <VacuumTimelineImpl width={dimensions.width} {...props} />
        </LoadingErrorBoundary>
      )}
    </div>
  );
};

const VacuumTimelineImpl: React.FunctionComponent<Props> = ({
  databaseId,
  start,
  end,
  data,
  width,
  onClick,
}) => {
  const laneLabelWidth = 120;
  const laneHeight = 32;
  const laneWidth = width - laneLabelWidth;
  const scale = scaleTime()
    .domain([start.valueOf(), end.valueOf()])
    .range([0, laneWidth]);

  const laneCount = Object.keys(data.lanes).length;
  const axisHeight = 40;
  const freqLegendHeight = 20;
  const foregroundHeight = laneHeight * laneCount;
  const height = foregroundHeight + axisHeight + freqLegendHeight;

  const freqLegendDims: Layout = {
    x: laneLabelWidth,
    y: 0,
    size: {
      width: laneWidth,
      height: freqLegendHeight,
    },
  };

  const laneLabelDims: Layout = {
    x: 0,
    y: freqLegendDims.size.height,
    size: {
      width: laneLabelWidth,
      height: foregroundHeight,
    },
  };

  const laneDims: Layout = {
    x: laneLabelDims.x + laneLabelDims.size.width,
    y: freqLegendDims.size.height,
    size: {
      width: width - laneLabelDims.size.width,
      height: foregroundHeight,
    },
  };

  const laneAxisDims: Layout = {
    x: laneDims.x,
    y: laneDims.y + laneDims.size.height,
    size: {
      width: laneDims.size.width,
      height: axisHeight,
    },
  };

  const findActiveVacuum = (datum: Datum): VacuumEntry | undefined => {
    const xVal = xValue(datum);
    const laneIdx = yValue(datum);
    const laneActivity = Object.values(data.lanes)[laneIdx];

    return laneActivity.find(
      (v: VacuumEntry): boolean => v.adjustedStartTs === xVal
    );
  };

  const handleVacuumClick = (d: InteractionPoint): void => {
    const clickLaneIdx = d.left.domain;
    const nearbyInLane = d.nearby.find(
      (item: DatumRef): boolean => yValue(item.datum) === clickLaneIdx
    );
    if (nearbyInLane) {
      const vacuum = findActiveVacuum(nearbyInLane.datum);
      if (vacuum) {
        onClick(vacuum);
      }
    }
  };

  const pointerXStops = useMemo(() => {
    const startTs = start.valueOf();
    const endTs = end.valueOf();
    const stopDelta = (endTs - startTs) / 100;
    const stops = [];
    let curr = startTs;
    while (curr < endTs) {
      stops.push(curr);
      const next = curr + stopDelta;
      const skipped = Object.values(data.lanes).reduce<number[]>(
        (result, laneActivity) => {
          const skippedInLane = laneActivity.filter(
            (e) => e.adjustedStartTs > curr && e.adjustedEndTs < next
          );
          return result.concat(
            skippedInLane.map((e) => (e.adjustedStartTs + e.adjustedEndTs) / 2)
          );
        },
        []
      );
      skipped.forEach((ts) => stops.push(ts));
      curr = next;
    }
    return stops;
  }, [start, end, data]);

  const findXStop = (loc: ScreenLocation): number => {
    const locXHoverTs = scale.invert(loc.x).getTime();
    const locIdx = Math.max(0, bisectLeft(pointerXStops, locXHoverTs));
    return pointerXStops[locIdx];
  };

  const mapLocationToInteractionPoint = (
    loc: ScreenLocation
  ): InteractionPoint => {
    const locLaneIdx = Math.floor((loc.y / foregroundHeight) * laneCount);
    const lanes = Object.keys(data.lanes);
    const locLane = {
      domain: locLaneIdx,
      range: locLaneIdx * laneHeight + laneHeight / 2,
    };

    const locXts = findXStop(loc);
    const nearbyData = Object.entries(data.lanes)
      .map(([laneId, laneActivity]) => {
        const vacBisector = bisector(
          (e: VacuumEntry): number => e.startTs
        ).right;
        const vacIdx = vacBisector(laneActivity, locXts) - 1;
        const locVacuum = laneActivity[Math.max(vacIdx, 0)];
        if (
          locVacuum.adjustedEndTs < locXts ||
          locVacuum.adjustedStartTs > locXts
        ) {
          // ignore vacuums that have ended or have not started
          return undefined;
        }
        return {
          series: laneId,
          index: vacIdx,
          datum: [locVacuum.adjustedStartTs, lanes.indexOf(laneId)] as [
            number,
            number
          ],
        };
      })
      .filter(Boolean);

    return {
      bottom: {
        domain: locXts,
        range: loc.x,
      },
      left: locLane,
      nearby: nearbyData,
    };
  };

  return (
    <svg width={width} height={height} preserveAspectRatio="none">
      <rect fill="white" width={width} height={height} pointerEvents="none" />
      <g transform={translate(freqLegendDims.x, freqLegendDims.y)}>
        <ColorScaleLegend
          {...freqLegendDims.size}
          hasDatabaseFocus={databaseId != null}
        />
      </g>
      {Object.entries(data.lanes).map(([lane, activity], i) => {
        const laneY = laneDims.y + i * laneHeight;
        return (
          <g key={lane} transform={translateY(laneY)} pointerEvents="none">
            <g
              transform={translate(
                laneLabelDims.x + laneLabelDims.size.width / 2,
                laneHeight / 2
              )}
            >
              <text dominantBaseline="middle" textAnchor="middle">
                {lane}
              </text>
            </g>
            <g transform={translateX(laneDims.x)}>
              <VacuumTimelineLane
                databaseId={databaseId}
                key={lane}
                xScale={scale}
                width={laneWidth}
                height={laneHeight}
                vacuumFrequencies={data.tableVacuumFrequencyRanks}
                activity={activity}
              />
            </g>
          </g>
        );
      })}
      <line
        x1={laneDims.x + 0.5}
        x2={laneDims.x + 0.5}
        y1={laneDims.y}
        y2={laneDims.y + laneDims.size.height}
        stroke="black"
        strokeWidth={1}
        pointerEvents="none"
      />
      <g transform={translate(laneAxisDims.x, laneAxisDims.y)}>
        <Axis
          placement="bottom"
          scale={scale}
          {...laneAxisDims.size}
          tickFormat={formatTimeAdaptive}
        />
      </g>
      <g transform={translate(laneDims.x, laneDims.y)}>
        <Mouse
          {...laneDims.size}
          mapLocationToInteractionPoint={mapLocationToInteractionPoint}
          onClick={handleVacuumClick}
        >
          {(hover, rangeEndpoint) => {
            if (!hover) {
              return null;
            }
            const hoverLane = Object.keys(data.lanes)[hover.left.domain];
            const vacuums = hover.nearby.map((d) => findActiveVacuum(d.datum));
            const tipLines = vacuums.map((v) => {
              const active = v.laneId === hoverLane;
              return (
                <div key={v.vacuumIdentity} className={styles.tooltipItem}>
                  <div
                    className={classNames(
                      styles.tooltipItemValue,
                      styles.vacuumTimelineTooltipValue,
                      {
                        [styles.vacuumTimelineTooltipHover]: active,
                      }
                    )}
                  >
                    on {v.tableName}
                  </div>
                </div>
              );
            });
            return (
              <Tooltip
                {...laneDims.size}
                tipLines={tipLines}
                tipYOffset={-15}
                hover={hover}
                rangeEndpoint={rangeEndpoint}
              />
            );
          }}
        </Mouse>
      </g>
    </svg>
  );
};

const VacuumTimelineLane: React.FunctionComponent<{
  databaseId?: string;
  xScale: Scale;
  width: number;
  height: number;
  vacuumFrequencies: VacuumActivity["tableVacuumFrequencyRanks"];
  activity: VacuumEntry[];
}> = ({ databaseId, activity, xScale, height, vacuumFrequencies }) => {
  return (
    <g>
      {activity.map((item) => {
        const startX = xScale(item.adjustedStartTs);
        // subtract a bit from the end to avoid having sequential bars run together
        const barWidth = xScale(item.adjustedEndTs) - startX - 1;
        const vPadding = 2;
        const barHeight = height - vPadding * 2;
        const showLabel = barWidth > 100;
        const label = item.tableName;
        const { fill, ...otherProps } = getVacuumRectProps(
          item,
          databaseId,
          vacuumFrequencies
        );

        return (
          <g key={startX}>
            <rect
              rx="3px"
              x={startX}
              y={vPadding}
              width={barWidth}
              height={barHeight}
              fill={fill}
              {...otherProps}
            />
            {showLabel && (
              <g transform={translateX(startX)}>
                <foreignObject width={barWidth} height={height}>
                  <div className={styles.vacuumTimelineBarWrapper}>
                    <div
                      className={classNames(styles.vacuumTimelineBar, {
                        [styles.vacuumTimelineBarWhiteLabel]: isDark(fill),
                      })}
                    >
                      {label}
                    </div>
                  </div>
                </foreignObject>
              </g>
            )}
          </g>
        );
      })}
    </g>
  );
};

function getVacuumRectProps(
  item: VacuumEntry,
  databaseId: string,
  vacuumFrequencies: VacuumActivity["tableVacuumFrequencyRanks"]
): Partial<React.SVGProps<SVGRectElement>> {
  // If a database id was specified, but this bar does not belong to that id, we
  // want the styling to reflect that.
  if (databaseId != null && item.databaseId != databaseId) {
    return OTHER_DB_BAR_STYLE;
  }

  const leastFrequentColor = colors[colors.length - 1];
  const freqRank = vacuumFrequencies[item.tableId];
  const fill = freqRank < colors.length ? colors[freqRank] : leastFrequentColor;
  return { fill };
}

const ColorScaleLegend: React.FunctionComponent<{
  hasDatabaseFocus: boolean;
  width: number;
  height: number;
}> = ({ hasDatabaseFocus, width, height }) => {
  const labelWidth = 150;
  const vPadding = 5;
  const legendWidth = Math.min(width - 2 * labelWidth, 200);
  const legendChunkWidth = legendWidth / colors.length;
  const legendHeight = height - 2 * vPadding;
  const legendX = width / 2 - legendWidth / 2;

  return (
    <>
      <text
        y={height / 2}
        x={legendX - 3}
        dominantBaseline="middle"
        textAnchor="end"
        fontSize="smaller"
      >
        most frequent
      </text>
      {colors.map((c, i) => {
        return (
          <rect
            key={i}
            x={legendX + i * legendChunkWidth}
            y={vPadding}
            fill={c}
            width={legendChunkWidth}
            height={legendHeight}
          />
        );
      })}
      <text
        y={height / 2}
        x={legendX + legendWidth + 3}
        dominantBaseline="middle"
        textAnchor="start"
        fontSize="smaller"
      >
        least frequent
      </text>
      {hasDatabaseFocus && (
        <>
          <rect
            x={legendX + legendWidth + 100}
            y={vPadding}
            width={legendChunkWidth}
            height={legendHeight}
            {...OTHER_DB_BAR_STYLE}
          />
          <text
            y={height / 2}
            x={legendX + legendWidth + 100 + legendChunkWidth + 3}
            dominantBaseline="middle"
            textAnchor="start"
            fontSize="smaller"
          >
            other database
          </text>
        </>
      )}
    </>
  );
};

export default VacuumTimelineChart;
