import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";

import QUERY from "./Query.graphql";
import { Data, InteractionPoint } from "components/Graph/util";
import Graph from "components/Graph";
import { BarSeries } from "components/Graph/Series";
import { connectionGraphColors } from "utils/palette";
import { useDateRange } from "components/WithDateRange";

import {
  ConnectionGraph as ConnectionGraphType,
  ConnectionGraphVariables,
  ConnectionGraph_getBackendCounts,
} from "./types/ConnectionGraph";
import Loading from "components/Loading";

type BackendCountType = Omit<ConnectionGraph_getBackendCounts, "__typename">;

type GroupedBackendCountType = {
  collectedAt: number;
  total: number;
  waitingForLock: number;
  active: number;
  idleInTransaction: number;
  idle: number;
  other: number;
};

type Props = {
  databaseId?: string;
  serverId?: string;
  onClick: (collectedAt: Date) => void;
};

const ConnectionGraph: React.FunctionComponent<Props> = ({
  databaseId,
  serverId,
  onClick,
}) => {
  const [{ from, to }] = useDateRange();

  const { data, loading, error } = useQuery<
    ConnectionGraphType,
    ConnectionGraphVariables
  >(QUERY, {
    variables: {
      databaseId,
      serverId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <ConnectionGraphDisplay data={data} onClick={onClick} />;
};

type DisplayProps = Pick<Props, "onClick"> & {
  data: {
    getBackendCounts: Array<BackendCountType>;
  };
};

const ConnectionGraphDisplay: React.FunctionComponent<DisplayProps> = ({
  data,
  onClick,
}) => {
  const newData = useMemo(() => {
    let maxTotalConnections = 0;
    const groupedBackendCounts = Object.values(
      groupBy(
        data.getBackendCounts,
        (d: BackendCountType): number => d.collectedAt
      )
    ).map((counts: Array<BackendCountType>): GroupedBackendCountType => {
      const collectedAt = counts[0].collectedAt;
      const total = sumBy(counts, (c: BackendCountType): number => c.count);
      maxTotalConnections = Math.max(maxTotalConnections, total);
      return {
        collectedAt: Math.floor(collectedAt),
        waitingForLock: sumBy(
          counts,
          (c: BackendCountType): number =>
            (c.state == "ACTIVE" && c.waitingForLock && c.count) || 0
        ),
        active: sumBy(
          counts,
          (c: BackendCountType): number =>
            (c.state == "ACTIVE" && !c.waitingForLock && c.count) || 0
        ),
        idle: sumBy(
          counts,
          (c: BackendCountType): number => (c.state == "IDLE" && c.count) || 0
        ),
        idleInTransaction: sumBy(
          counts,
          (c: BackendCountType): number =>
            (c.state == "IDLE_IN_TRANSACTION" && c.count) || 0
        ),
        other: sumBy(
          counts,
          (c: BackendCountType): number =>
            (c.state != "ACTIVE" &&
              c.state != "IDLE" &&
              c.state != "IDLE_IN_TRANSACTION" &&
              c.count) ||
            0
        ),
        total: total,
      };
    });

    const newGroupedBackendCounts = groupedBackendCounts.reduce<Data>(
      (d: Data, curr: GroupedBackendCountType) => {
        return backendStates.reduce((state, currKey) => {
          state[currKey].push([curr.collectedAt, curr[currKey]]);
          return state;
        }, d);
      },
      backendStates.reduce((emptyState, currKey) => {
        emptyState[currKey] = [];
        return emptyState;
      }, {})
    );
    return newGroupedBackendCounts;
  }, [data]);

  return (
    <Graph
      data={newData}
      onClick={
        onClick
          ? (d: InteractionPoint) => onClick(moment(d.bottom.domain).toDate())
          : undefined
      }
      series={backendStates.map((s) => {
        return {
          key: s,
          type: BarSeries,
          label: labelFor(s),
          tipLabel: tipLabelFor(s),
          color: fillFor(s),
        };
      })}
    />
  );
};

const backendStates = [
  "idle",
  "idleInTransaction",
  "waitingForLock",
  "active",
  "other",
];
const labelFor = (state: string) => {
  return {
    waitingForLock: "Waiting for Lock",
    active: "Active",
    idleInTransaction: "Idle in Transaction",
    idle: "Idle",
    other: "Other",
  }[state];
};
const tipLabelFor = (state: string) => {
  return {
    waitingForLock: "Waiting",
    active: "Active",
    idleInTransaction: "Idle in Tx",
    idle: "Idle",
    other: "Other",
  }[state];
};
const fillFor = (state: string) => {
  return {
    waitingForLock: connectionGraphColors.countWaitingForLock,
    active: connectionGraphColors.countActive,
    idleInTransaction: connectionGraphColors.countIdleInTransaction,
    idle: connectionGraphColors.countIdle,
    other: connectionGraphColors.countOther,
  }[state];
};

export default ConnectionGraph;
