import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Tip from "components/Tip";

import {
  SchemaTableColumns as SchemaTableColumnsType,
  SchemaTableColumnsVariables,
} from "./types/SchemaTableColumns";
import QUERY from "./Query.graphql";
import ShowFlash from "components/ShowFlash";
import { formatNumber, formatPercent } from "utils/format";

type Props = {
  databaseId: string;
  tableId: string;
};

const SchemaTableColumns: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
}) => {
  const { data, loading, error } = useQuery<
    SchemaTableColumnsType,
    SchemaTableColumnsVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const columns = data.getSchemaTableColumns;
  const hasColumnStats = data.getDatabaseDetails.hasColumnStats;
  const hasInheritedColStats = columns.some((col) => !!col.lastStatsInherited);

  return (
    <Panel title="Columns">
      {!hasColumnStats && (
        <ShowFlash
          level="notice"
          msg={
            <>
              Your database appears to be missing column stats monitoring helper
              functions, so column statistics are not available. Please review
              the relevant{" "}
              <a
                target="_blank"
                href="https://pganalyze.com/docs/install/troubleshooting/column_stats_helper"
              >
                troubleshooting documentation
              </a>
              .
            </>
          }
        />
      )}
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            {hasColumnStats && (
              <>
                <th className="!text-right">
                  Null %{" "}
                  <Tip content="Fraction of the rows in this table that have a NULL value in this column." />
                </th>
                <th className="!text-right">
                  Avg. Size{" "}
                  <Tip content="Average size, in bytes, of the values in this column across all the rows in this table (excluding NULLs)." />
                </th>
                {hasInheritedColStats && (
                  <>
                    <th className="!text-right">
                      Full Null %{" "}
                      <Tip content="Fraction of the rows in this table and all child tables that have a NULL value in this column." />
                    </th>
                    <th className="!text-right">
                      Full Avg. Size{" "}
                      <Tip content="Average size, in bytes, of the values in this column across all the rows in this table and all child tables (excluding NULLs)." />
                    </th>
                  </>
                )}
              </>
            )}
            <th>Modifiers</th>
          </tr>
        </thead>
        <tbody>
          {columns.map(
            (column): React.ReactNode => (
              <tr key={column.name}>
                <td>
                  <strong>{column.name}</strong>
                </td>
                <td>{column.dataType}</td>
                {hasColumnStats && (
                  <>
                    <td className="number">
                      {column.lastStats
                        ? formatPercent(column.lastStats.nullFrac, 2)
                        : "n/a"}
                    </td>
                    <td className="number">
                      {column.lastStats
                        ? formatNumber(column.lastStats.avgWidth)
                        : "n/a"}
                    </td>
                    {hasInheritedColStats && (
                      <>
                        <td className="number">
                          {column.lastStatsInherited
                            ? formatPercent(
                                column.lastStatsInherited.nullFrac,
                                2
                              )
                            : "n/a"}
                        </td>
                        <td className="number">
                          {column.lastStatsInherited
                            ? formatNumber(column.lastStatsInherited.avgWidth)
                            : "n/a"}
                        </td>
                      </>
                    )}
                  </>
                )}
                <td className="definition">
                  <span>{column.notNull && "NOT NULL "}</span>
                  <span>
                    {column.defaultValue && `DEFAULT ${column.defaultValue}`}
                  </span>
                </td>
              </tr>
            )
          )}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default SchemaTableColumns;
