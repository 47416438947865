import React from "react";

import DatabaseSelect from "./DatabaseSelect";
import ServerSelect from "./ServerSelect";

import styles from "./style.module.scss";
import {
  useCurrentOrganization,
  useCurrentServer,
} from "components/WithCurrentOrganization";

type Props = {
  pageControls?: React.ReactNode;
};

const PageHeaderNav: React.FunctionComponent<Props> = ({ pageControls }) => {
  return (
    <div className={styles.headerNav}>
      <PageHeaderNavSelects />
      {pageControls && <div>{pageControls}</div>}
    </div>
  );
};

const PageHeaderNavSelects: React.FunctionComponent = ({}) => {
  const currentOrganization = useCurrentOrganization(true);
  const currentServer = useCurrentServer(true);
  if (!currentOrganization) {
    return null;
  }
  const { servers, permittedToCreateServers } = currentOrganization;

  if (servers.length === 0 && !permittedToCreateServers) {
    return null;
  }

  return (
    <div className={styles.selectContainer}>
      <ServerSelect
        servers={servers}
        organizationSlug={currentOrganization.slug}
        currentServerId={currentServer?.humanId}
      />
      {currentServer && <DatabaseSelect />}
    </div>
  );
};

export default PageHeaderNav;
