---
title: 'Step 1: Create Monitoring User'
install_track_title: Installation Guide (Google Cloud SQL and AlloyDB)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import Step01CreateMonitoringUserGCP from "./_01_create_monitoring_user.mdx"

import GeneratePassword from "../../components/GeneratePassword"

<GeneratePassword>
  {password => <Step01CreateMonitoringUserGCP password={password} />}
</GeneratePassword>
