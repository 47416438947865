import React from "react";

import styles from "./style.module.scss";

export type Props = {
  title: React.ReactNode;
  secondaryTitle?: React.ReactNode;
  secondaryTitleClassName?: string;
};

const PanelTitle: React.FunctionComponent<Props> = ({
  title,
  secondaryTitle,
  secondaryTitleClassName,
}) => {
  return (
    <h2 className={styles.title}>
      {title}
      {secondaryTitle && (
        <small className={secondaryTitleClassName || styles.secondaryTitle}>
          {secondaryTitle}
        </small>
      )}
    </h2>
  );
};

export default PanelTitle;
