import React from "react";

import styles from "./style.module.scss";

import WithNodeSelection, { useSelectedNode } from "./WithNodeSelection";
import NodeDetails from "./NodeDetails";
import Node from "./Node";

import { AnnotatedPlan, Node as NodeType, Annotation } from "types/explain";

type Props = {
  plan: AnnotatedPlan;
  databaseId: string;
  blockSize: number;
};

const ExplainDetails: React.FunctionComponent<Props> = ({
  plan,
  databaseId,
  blockSize,
}) => {
  const root = plan.plan[0].Plan;
  return (
    <div className={styles.explainDetails}>
      <WithNodeSelection plan={plan.plan} initialValue={root.extra.id}>
        <Explain plan={plan} databaseId={databaseId} />
        <SelectedNodeDetails
          databaseId={databaseId}
          blockSize={blockSize}
          annotations={plan.annotations}
        />
      </WithNodeSelection>
    </div>
  );
};

type ExplainProps = {
  plan: AnnotatedPlan;
  databaseId: string;
};

const Explain: React.FunctionComponent<ExplainProps> = ({
  plan,
  databaseId,
}) => {
  const root = plan.plan[0].Plan;
  const annotations = plan.annotations;
  const [, setSelectedNode] = useSelectedNode();

  const handleNodeClick = (node: NodeType): void => {
    setSelectedNode(node.extra.id);
  };

  return (
    <Node
      node={root}
      databaseId={databaseId}
      annotations={annotations}
      onNodeClick={handleNodeClick}
    />
  );
};

const SelectedNodeDetails: React.FunctionComponent<{
  databaseId: string;
  blockSize: number;
  annotations: Annotation[];
}> = ({ databaseId, blockSize, annotations }) => {
  const [selectedNode] = useSelectedNode();

  const nodeAnnotations = annotations.filter(
    (a) => a.node === selectedNode?.node.extra.id
  );

  return (
    <NodeDetails
      databaseId={databaseId}
      blockSize={blockSize}
      node={selectedNode?.node}
      annotations={nodeAnnotations}
    />
  );
};

export default ExplainDetails;
