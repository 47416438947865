import React, { useEffect, useRef, useReducer, useState } from "react";
import moment, { Moment } from "moment-timezone";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarEdit } from "@fortawesome/pro-light-svg-icons";
import DayPicker, { DayModifiers } from "react-day-picker";

import Dropdown from "components/Dropdown";
import Icon from "components/Icon";

import "react-day-picker/lib/style.css";
import styles from "./style.module.scss";

import {
  formatTimestampShorter,
  formatDateShort,
  formatTimeShort,
} from "utils/format";
import { toStartOfDayMoment, toEndOfDayMoment, age } from "utils/date";
import TimestampTextInput from "components/TimestampTextInput";
import { DateRange } from "components/WithDateRange";
import { periods, findByKey } from "utils/dateRange";

type Props = {
  from: Moment;
  to: Moment;
  label?: string;
  onChange?: (newRange: Pick<DateRange, "from" | "to" | "label">) => void;
  earliest: Moment;
};

type DateTimeRangeSelection = {
  rangeLabel?: string;
  fromLabel: string;
  fromValue: Moment | undefined;
  toLabel: string;
  toValue: Moment | undefined;
};

const rangeFromMoments = (
  start: Moment,
  end: Moment,
  label?: string
): DateTimeRangeSelection => {
  return {
    rangeLabel: label,
    fromLabel: formatTimestampShorter(start),
    fromValue: start,
    toLabel: formatTimestampShorter(end),
    toValue: end,
  };
};

const momentChanged = (curr: Moment, next: Moment): boolean => {
  return next && (!curr || !curr.isSame(next));
};

const labelChanged = (curr: string, next: string): boolean => {
  return next !== undefined && curr !== next;
};

const DateRangeSelector: React.FunctionComponent<Props> = ({
  from,
  to,
  label,
  onChange,
  earliest,
}) => {
  const [range, setRange] = useReducer<
    (
      curr: DateTimeRangeSelection,
      update: Partial<DateTimeRangeSelection>
    ) => DateTimeRangeSelection
  >((curr, next) => {
    // avoid unnecessary state updates when nothing has changed
    const newRange = { ...curr };
    const rangeLabelChanged = curr.rangeLabel !== newRange.rangeLabel;
    const fromChanged =
      momentChanged(curr.fromValue, next.fromValue) ||
      labelChanged(curr.fromLabel, next.fromLabel);
    const toChanged =
      momentChanged(curr.toValue, next.toValue) ||
      labelChanged(curr.toLabel, next.toLabel);
    if (!fromChanged && !toChanged && !rangeLabelChanged) {
      return curr;
    }

    if (fromChanged) {
      newRange.fromValue = next.fromValue;
      newRange.fromLabel =
        next.fromLabel !== undefined
          ? next.fromLabel
          : formatTimestampShorter(next.fromValue);
    }
    if (toChanged) {
      newRange.toValue = next.toValue;
      newRange.toLabel =
        next.toLabel !== undefined
          ? next.toLabel
          : formatTimestampShorter(next.toValue);
    }
    if (rangeLabelChanged) {
      newRange.rangeLabel = next.rangeLabel;
    }

    return newRange;
  }, rangeFromMoments(from, to, label));

  // Ensure that any external changes to props override our internal state.
  // Note that this means parent components *must* avoid unnecessary re-renders
  // of this component or its state may be reset while the user is interacting
  // with it.
  useEffect(() => {
    setRange(rangeFromMoments(from, to, label));
  }, [from, to, label]);

  return (
    <Dropdown
      className={styles.triggerDropdown}
      trigger={({ toggleOpen, open }) => {
        const handleTriggerDivClick = () => {
          toggleOpen();
        };

        return (
          <div className={styles.trigger} onClick={handleTriggerDivClick}>
            <FontAwesomeIcon
              icon={faCalendarEdit}
              className={styles.triggerIcon}
            />
            <DateRangeLabel from={from} to={to} label={label} />
            <Icon kind={open ? "caret-up" : "caret-down"} />
          </div>
        );
      }}
    >
      {({ setClosed }) => {
        const handleApply = (
          newFrom: Moment,
          newTo: Moment,
          newLabel: string
        ) => {
          setClosed();
          setRange({ fromValue: newFrom, toValue: newTo });
          onChange &&
            onChange({
              from: newFrom || from,
              to: newTo || to,
              label: newLabel,
            });
        };
        const handleCancel = () => {
          setClosed();
          setRange({ fromValue: from, toValue: to });
        };

        return (
          <DropdownContent
            earliest={earliest}
            range={range}
            setRange={setRange}
            onApply={handleApply}
            onCancel={handleCancel}
          />
        );
      }}
    </Dropdown>
  );
};

const DateRangeLabel: React.FunctionComponent<{
  from: Moment;
  to: Moment;
  label: string;
}> = ({ from, to, label }) => {
  return (
    <div
      className={classNames(styles.displayDateRange, {
        [styles.displayDateRangeTimestamps]: !label,
      })}
    >
      {label ? (
        <span className={styles.displayDateRangeLabel}>{label}</span>
      ) : (
        <>
          <DateRangeLabelDateTime ts={from} />
          <div className={styles.displayDateRangeSeparator}> – </div>
          <DateRangeLabelDateTime ts={to} />
        </>
      )}
    </div>
  );
};

const DateRangeLabelDateTime: React.FunctionComponent<{
  ts: Moment;
}> = ({ ts }) => {
  return (
    <div>
      <div className={styles.displayDateRangeDate}>{formatDateShort(ts)}</div>
      <div className={styles.displayDateRangeTime}>{formatTimeShort(ts)}</div>
    </div>
  );
};

type ProspectiveRange = {
  start: Date;
  end: Date;
};

const DropdownContent: React.FunctionComponent<{
  earliest: Moment;
  range: DateTimeRangeSelection;
  setRange: (value: Partial<DateTimeRangeSelection>) => void;
  onApply: (from: Moment, to: Moment, label: string) => void;
  onCancel: () => void;
}> = ({ earliest, range, setRange, onApply, onCancel }) => {
  const fromRef = useRef<HTMLInputElement>();
  const toRef = useRef<HTMLInputElement>();
  const [focused, setFocused] = useState<"from" | "to">("from");
  const [prospectiveRange, setProspectiveRange] = useState<
    ProspectiveRange | undefined
  >(undefined);

  useEffect(() => {
    // focus the from input when the dropdown opens
    fromRef.current.focus();
  }, [fromRef]);

  const handleTimestampInputFocus = (
    e: React.FocusEvent<HTMLInputElement>
  ): void => {
    setFocused(e.currentTarget.name as "from" | "to");
  };

  const { fromValue, toValue } = range;
  const now = moment();

  const rangeInverted = fromValue && toValue && fromValue.isAfter(toValue);
  let fromError: string;
  if (!fromValue) {
    fromError = "unrecognized date";
  } else if (fromValue.isBefore(earliest)) {
    fromError = `must be less than ${age(earliest)} ago`;
  } else if (fromValue.isAfter(now)) {
    fromError = "must be in the past";
  } else if (rangeInverted) {
    fromError = "must be before to date";
  }

  let toError: string;
  if (!toValue) {
    toError = "unrecognized date";
  } else if (toValue.isBefore(earliest)) {
    toError = `must be less than ${age(earliest)} ago`;
  } else if (toValue.isAfter(now)) {
    toError = "must be in the past";
  } else if (rangeInverted) {
    toError = "must be after from date";
  }

  const handleFromChange = (label: string, value: Moment | undefined): void => {
    setRange({ fromLabel: label, fromValue: value });
  };

  const handleToChange = (label: string, value: Moment | undefined): void => {
    setRange({ toLabel: label, toValue: value });
  };

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    // if outside of possible range, ignore
    if (modifiers.disabled) {
      return;
    }

    if (focused === "from") {
      // If we clicked on "from" we always reset "to" as well (to the end
      // of the same day)--we assume that you want to set a new range, not
      // just adjust the current one
      const newFrom = toStartOfDayMoment(day, earliest);
      const newTo = toEndOfDayMoment(day, now);
      setRange({ fromValue: newFrom, toValue: newTo });
      toRef.current.focus();
    } else {
      // If we clicked on "to" and it's before the current "from", invert
      // the range. The hover behavior hints that this is what happens.
      const isInverted = fromValue.isAfter(day);
      const newTo = toEndOfDayMoment(
        isInverted ? fromValue.toDate() : day,
        now
      );
      const newFrom = isInverted
        ? toStartOfDayMoment(day, earliest)
        : undefined;
      if (fromError) {
        // if the from value is not valid when a new to value is clicked,
        // we prompt the user to adjust it instead of committing
        setRange({ fromValue: newFrom, toValue: newTo });
        fromRef.current.focus();
      } else {
        onApply(newFrom || fromValue, newTo, null);
      }
    }
  };

  const handleDayEnter = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    const { fromValue, toValue } = range;

    let otherEndpoint: Date;
    const newRange =
      focused !== "to" || fromError || !fromValue.isSame(toValue, "day");
    if (newRange) {
      // we are starting a new range; ignore what's currently selected
      otherEndpoint = day;
    } else {
      otherEndpoint = fromError ? toValue.toDate() : fromValue.toDate();
    }

    const prospectiveStart = day <= otherEndpoint ? day : otherEndpoint;
    const prospectiveEnd = prospectiveStart === day ? otherEndpoint : day;

    setProspectiveRange({
      start: prospectiveStart,
      end: prospectiveEnd,
    });
  };

  const handleDayLeave = () => {
    setProspectiveRange(undefined);
  };

  const handlePeriodClick = onApply;

  const handleApply =
    fromError || toError
      ? undefined
      : () => {
          onApply(fromValue, toValue, null);
        };

  const handleCancel = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onCancel();
  };

  const start = fromError ? undefined : fromValue.toDate();
  const end = toError ? undefined : toValue.toDate();
  return (
    <>
      <div className={styles.periodAndTimestampInput}>
        <div className={styles.periods}>
          <DateRangeSelectionPeriods
            earliest={earliest}
            onPeriodClick={handlePeriodClick}
          />
        </div>
        <div className={styles.rangeEndpointWrapper}>
          <TimestampTextInput
            className={styles.rangeEndpointInput}
            name="from"
            ref={fromRef}
            label="From:"
            value={range.fromLabel}
            onChange={handleFromChange}
            onFocus={handleTimestampInputFocus}
            onEnterKey={handleApply}
            error={fromError}
          />
          <TimestampTextInput
            className={styles.rangeEndpointInput}
            name="to"
            ref={toRef}
            label="To:"
            value={range.toLabel}
            onChange={handleToChange}
            onFocus={handleTimestampInputFocus}
            onEnterKey={handleApply}
            error={toError}
          />
          <div>
            <button
              className="btn btn-success"
              disabled={!!(fromError || toError)}
              onClick={handleApply}
            >
              Apply
            </button>
            <a
              href=""
              className={styles.rangeEndpointsCancelBtn}
              onClick={handleCancel}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
      <DayPicker
        className={classNames("dateRangePicker", styles.dayPicker)}
        numberOfMonths={2}
        initialMonth={earliest.toDate()}
        modifiers={{
          disabled: {
            after: now.toDate(),
            before: earliest.toDate(),
          },
          selected: (day: Date): boolean => {
            const m = moment(day);
            const inRange =
              start &&
              m.isSameOrAfter(start, "day") &&
              end &&
              m.isSameOrBefore(end, "day");
            const overlapsProspective =
              prospectiveRange &&
              m.isSameOrAfter(prospectiveRange.start, "day") &&
              m.isSameOrBefore(prospectiveRange.end, "day");
            return inRange && !overlapsProspective;
          },
          start:
            prospectiveRange &&
            moment(prospectiveRange.start).isBefore(start, "day") &&
            moment(prospectiveRange.end).isSameOrAfter(start, "day")
              ? undefined
              : start,
          end:
            prospectiveRange &&
            moment(prospectiveRange.end).isAfter(end, "day") &&
            moment(prospectiveRange.start).isSameOrBefore(end, "day")
              ? undefined
              : end,
          prospectiveRange: prospectiveRange && {
            from: prospectiveRange.start,
            to: prospectiveRange.end,
          },
          prospectiveStart:
            prospectiveRange &&
            (!start ||
              moment(prospectiveRange.start).isSameOrBefore(start, "day") ||
              moment(prospectiveRange.start).isAfter(end, "day"))
              ? prospectiveRange.start
              : undefined,
          prospectiveEnd:
            prospectiveRange &&
            (!end ||
              moment(prospectiveRange.end).isSameOrAfter(end, "day") ||
              moment(prospectiveRange.end).isBefore(start, "day"))
              ? prospectiveRange.end
              : undefined,
        }}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayEnter}
        onDayMouseLeave={handleDayLeave}
      />
    </>
  );
};

const DateRangeSelectionPeriods: React.FunctionComponent<{
  earliest: Moment;
  onPeriodClick: (start: Moment, end: Moment, label: string) => void;
}> = ({ earliest, onPeriodClick }) => {
  const handlePeriodClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const clickedPeriod = e.currentTarget.dataset.periodkey;
    const period = findByKey(clickedPeriod);
    if (!period) {
      // this should not happen
      console.error("could not find period", clickedPeriod);
      return;
    }
    const [fromValue, toValue] = period.range;

    onPeriodClick(fromValue, toValue, period.label);
  };

  return (
    <>
      {periods
        .filter((p) => p.key !== "30m")
        .map((period) => {
          const available = !period.extendsBefore(earliest);
          return (
            <a
              key={period.label}
              data-periodkey={period.key}
              href="#"
              className={classNames(
                styles.predefinedPeriod,
                available
                  ? styles.predefinedPeriodActive
                  : styles.predefinedPeriodDisabled
              )}
              onClick={available ? handlePeriodClick : undefined}
            >
              {period.label}
            </a>
          );
        })}
    </>
  );
};

export default DateRangeSelector;
