import React from "react";
import { useQuery } from "@apollo/client";

import IssueList from "components/IssueList";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  CheckUpIssueList as CheckUpIssueListType,
  CheckUpIssueListVariables,
  CheckUpIssueList_getIssues as IssueType,
} from "./types/CheckUpIssueList";
import {
  CheckUpIssueListDatabase as CheckUpIssueListDatabaseType,
  CheckUpIssueListDatabaseVariables,
} from "./types/CheckUpIssueListDatabase";
import QUERY from "./Query.graphql";
import QUERY_DATABASE from "./Query.database.graphql";
import styles from "./style.module.scss";

type Props = {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
  checkGroupAndName: string;
  state: string;
};

const CheckUpIssueList: React.FunctionComponent<Props> = (props) => {
  if (props.databaseId) {
    return <CheckUpIssueListDatabase {...props} />;
  }
  return <GroupedCheckUpIssueList {...props} />;
};

const CheckUpIssueListDatabase: React.FunctionComponent<Props> = ({
  serverId,
  databaseId,
  checkGroupAndName,
  state,
}) => {
  const { data, loading, error } = useQuery<
    CheckUpIssueListDatabaseType,
    CheckUpIssueListDatabaseVariables
  >(QUERY_DATABASE, {
    variables: {
      serverId,
      databaseId,
      checkGroupsAndNames: [checkGroupAndName],
      state,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const issues = data.getIssues;

  if (issues.length === 0) {
    return (
      <Panel title="All Issues">
        <PanelSection>No issues found.</PanelSection>
      </Panel>
    );
  }

  return (
    <Panel title="All Issues">
      <IssueList
        databaseId={databaseId}
        serverId={serverId}
        issues={issues}
        showAll
        verbose
      />
    </Panel>
  );
};

const GroupedCheckUpIssueList: React.FunctionComponent<Props> = ({
  organizationSlug: organizationSlugFromProps,
  serverId,
  checkGroupAndName,
  state,
}) => {
  const { data, loading, error } = useQuery<
    CheckUpIssueListType,
    CheckUpIssueListVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlugFromProps,
      serverId,
      checkGroupsAndNames: [checkGroupAndName],
      state,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const issues = data.getIssues;

  if (issues.length === 0) {
    return (
      <Panel title="All Issues">
        <PanelSection>No issues found.</PanelSection>
      </Panel>
    );
  }

  const groupedIssues = issues.reduce<Map<string, IssueType[]>>(
    (out, issue) => {
      const key = issue.server.id + ":" + (issue.database?.id || "");
      if (!out.has(key)) {
        out.set(key, []);
      }
      out.set(key, out.get(key).concat([issue]));
      return out;
    },
    new Map()
  );

  return (
    <Panel title="All Issues">
      {Array.from(groupedIssues.keys()).map((key: string) => {
        const issues = groupedIssues.get(key);
        const server = issues[0].server;
        const database = issues[0].database;
        return (
          <React.Fragment key={key}>
            <h3 className={styles.header}>
              Server: {server.name}
              {database ? `, Database: ${database.datname}` : ""}
            </h3>
            <IssueList
              serverId={server.humanId}
              databaseId={database?.id}
              issues={issues}
              showAll
              verbose
            />
          </React.Fragment>
        );
      })}
    </Panel>
  );
};

export default CheckUpIssueList;
