import React, { useContext, useMemo } from "react";

import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";
import { useRouteTopLevelReference } from "utils/useRouteTopLevelReference";
import { useUserPreferences } from "utils/hooks";

import QUERY from "./Query.graphql";

import {
  WithCurrentOrganization as WithCurrentUserType,
  WithCurrentOrganization_getCurrentUserDetails_organizations as WithCurrentOrganizationType,
  WithCurrentOrganization_getCurrentUserDetails_organizations_servers as WithCurrentServerType,
} from "./types/WithCurrentOrganization";

type CurrentUserType = Omit<
  WithCurrentUserType["getCurrentUserDetails"],
  "__typename"
>;

type CurrentOrganizationType = Omit<
  WithCurrentOrganizationType,
  "__typename"
> | null;

type CurrentServerType = Omit<WithCurrentServerType, "__typename"> | null;

type ContextValue =
  | [
      CurrentUserType,
      CurrentOrganizationType | undefined,
      CurrentServerType | undefined
    ]
  | undefined;

export const CurrentOrganizationContext =
  React.createContext<ContextValue>(undefined);

const WithCurrentOrganization: React.FunctionComponent = ({ children }) => {
  const { loading, data, error } = useQuery<WithCurrentUserType, never>(QUERY);

  const {
    organizationSlug: organizationSlugFromRoute,
    serverId: serverIdFromRoute,
    databaseId,
  } = useRouteTopLevelReference();
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(
    serverIdFromRoute,
    databaseId
  );

  const ctxValue = useMemo<ContextValue>(() => {
    if (!serverIdLoaded || loading || error || !data) {
      return undefined;
    }

    const { organizations } = data.getCurrentUserDetails;

    let organizationSlug = organizationSlugFromRoute;
    if (!organizationSlug && serverId) {
      for (const o of organizations) {
        if (o.servers.findIndex((s) => s.humanId == serverId) != -1) {
          organizationSlug = o.slug;
          break;
        }
      }
    }

    const currentOrganization = organizationSlug
      ? organizations.find((o) => o.slug === organizationSlug)
      : null;

    const currentServer =
      currentOrganization && serverId
        ? currentOrganization.servers.find((s) => s.humanId === serverId)
        : null;
    return [data.getCurrentUserDetails, currentOrganization, currentServer];
  }, [
    data,
    organizationSlugFromRoute,
    serverId,
    serverIdLoaded,
    loading,
    error,
  ]);

  if (ctxValue == undefined) {
    return <Loading error={!!error} />;
  }

  return (
    <CurrentOrganizationContext.Provider value={ctxValue}>
      {children}
    </CurrentOrganizationContext.Provider>
  );
};

export function useCurrentOrganization(
  optional = false
): CurrentOrganizationType | undefined {
  const ctx = useContext(CurrentOrganizationContext);
  if (!ctx) {
    throw new Error(
      "useCurrentOrganization(): must have ancestor WithCurrentOrganization component"
    );
  }
  const [, currentOrganization] = ctx;
  if (!currentOrganization && !optional) {
    throw new Error(
      "useCurrentOrganization(): current organization required but none set"
    );
  }

  return currentOrganization;
}

export function useCurrentServer(
  optional = false
): CurrentServerType | undefined {
  const ctx = useContext(CurrentOrganizationContext);
  if (!ctx) {
    throw new Error(
      "useCurrentServer(): must have ancestor WithCurrentOrganization component"
    );
  }
  const [, , currentServer] = ctx;
  if (!currentServer && !optional) {
    throw new Error("useCurrentServer(): current server required but none set");
  }

  return currentServer;
}

export function useCurrentUser(): CurrentUserType {
  const ctx = useContext(CurrentOrganizationContext);
  if (!ctx) {
    throw new Error(
      "useCurrentUser(): must have ancestor WithCurrentOrganization component"
    );
  }
  const [currentUser] = ctx;
  return currentUser;
}

export function useFallbackServerId(): string | undefined {
  const [serverSelect] = useUserPreferences("serverSelect");
  const currentOrganization = useCurrentOrganization(true);
  const organizationSlug = currentOrganization?.slug;
  const recentServerIds = useMemo(() => {
    if (!organizationSlug) {
      return [];
    }
    return (serverSelect.recentServerIds || {})[organizationSlug] || [];
  }, [serverSelect.recentServerIds, organizationSlug]);

  if (!currentOrganization) {
    return undefined;
  }

  const servers = [...currentOrganization.servers].sort((a, b) => {
    if (
      recentServerIds.includes(a.humanId) &&
      recentServerIds.includes(b.humanId)
    ) {
      return (
        recentServerIds.indexOf(a.humanId) - recentServerIds.indexOf(b.humanId)
      );
    }
    if (recentServerIds.includes(a.humanId)) {
      return -1;
    }
    if (recentServerIds.includes(b.humanId)) {
      return 1;
    }
    if (a.hasRecentSnapshot && !b.hasRecentSnapshot) {
      return -1;
    }
    if (!a.hasRecentSnapshot && b.hasRecentSnapshot) {
      return 1;
    }
    return 0;
  });
  return servers[0]?.humanId;
}

export default WithCurrentOrganization;
