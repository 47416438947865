import React, { useState } from "react";
import ReactDOM from "react-dom";

import classNames from "classnames";
import { usePopper } from "react-popper";

import styles from "./style.module.scss";
import { VirtualElement, Placement } from "@popperjs/core";

type Props = {
  // Two ways to position:
  //  - children, which will be wrapped in an inline-block with onenter/leave
  //    show/hide of pop-up (the shortcut ergonomic option)
  //  - real or virtual "anchor" reference to existing element (for more
  //    control; pass null to hide)
  children?: React.ReactNode;
  anchor?: Element | VirtualElement;
  content: React.ReactNode;
  title?: React.ReactNode;
  placement?: Placement;
  // If children are passed in, this can configure their wrapper
  wrapperClassName?: string;
  popupClassName?: string;
  portal?: Element;
};

const Popover: React.FunctionComponent<Props> = ({
  placement = "auto",
  children,
  content,
  title,
  anchor,
  wrapperClassName,
  popupClassName,
  portal,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    children ? referenceElement : anchor,
    popperElement,
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        { name: "offset", options: { offset: [0, 8] } },
      ],
      placement,
      strategy: "fixed",
    }
  );
  if (content === undefined || content === false) {
    // Streamline having an optional Popover
    return (children ?? null) as React.ReactElement;
  }
  const handleOpen = (e: React.MouseEvent<HTMLDivElement>): void => {
    setReferenceElement(e.currentTarget);
  };
  const handleClose = (): void => {
    setReferenceElement(null);
  };
  const show = !!referenceElement || !!anchor;

  const popEl = (
    <div
      ref={setPopperElement}
      className={classNames(styles.popover, popupClassName)}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {title && <div className={styles.popoverTitle}>{title}</div>}
      {content && <div className={styles.popoverContent}>{content}</div>}
      {
        <div
          ref={setArrowElement}
          className={styles.arrow}
          style={popperStyles.arrow}
        />
      }
    </div>
  );

  return (
    <>
      {!!children && (
        <div
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          className={classNames(styles.wrapper, wrapperClassName)}
        >
          {children}
        </div>
      )}
      {show && (portal ? ReactDOM.createPortal(popEl, portal) : popEl)}
    </>
  );
};

export default Popover;
