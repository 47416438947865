import React from "react";
import { useQuery } from "@apollo/client";

import { formatNumber } from "utils/format";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";
import {
  UsageStats as UsageStatsType,
  UsageStatsVariables,
} from "./types/UsageStats";

import styles from "./style.module.scss";

type Props = {
  organizationSlug: string;
};

const UsageStats: React.FunctionComponent<Props> = ({ organizationSlug }) => {
  const { loading, error, data } = useQuery<
    UsageStatsType,
    UsageStatsVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const {
    userCount,
    userLimit,
    serverWithRecentDataCount: serverCount,
    serverLimit,
  } = data.getOrganizationDetails;

  return (
    <Panel title="Usage Statistics">
      <PanelTable horizontal={true} borders={true}>
        <tbody>
          <tr>
            <th>Users</th>
            <td>
              <span
                className={
                  (userCount == userLimit && styles.orange) ||
                  (userLimit && userCount > userLimit && styles.red) ||
                  ""
                }
              >
                {formatNumber(userCount)}
              </span>
              <span>
                {" "}
                / {(userLimit && formatNumber(userLimit)) || "Unlimited"}
              </span>
            </td>
            <th>Servers</th>
            <td>
              <span
                className={
                  (serverCount == serverLimit && styles.orange) ||
                  (serverLimit && serverCount > serverLimit && styles.red) ||
                  ""
                }
              >
                {formatNumber(serverCount)}
              </span>
              {serverLimit && <span> / {formatNumber(serverLimit)}</span>}
            </td>
          </tr>
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default UsageStats;
