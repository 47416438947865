import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AutoSizer, Table, Column } from "react-virtualized";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import { formatPercent } from "utils/format";

import {
  SequenceReport as SequenceReportType,
  SequenceReportVariables,
} from "./types/SequenceReport";
import QUERY from "./Query.graphql";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

const SequenceReport: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const { data, loading, error } = useQuery<
    SequenceReportType,
    SequenceReportVariables
  >(QUERY, {
    variables: {
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { columns, sequences } = data.getSequenceReport;

  return (
    <PageContent
      title="Sequence Report"
      pageCategory="reports"
      pageName="bloat"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Panel title="Data Types of Sequence-based or Referencing Columns">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    headerHeight={25}
                    height={400}
                    rowCount={columns.length}
                    rowGetter={({ index }) => columns[index]}
                    rowHeight={25}
                    width={width}
                  >
                    <Column
                      dataKey="database"
                      label="Database"
                      width={90}
                      cellRenderer={({ cellData }) =>
                        cellData && cellData.datname
                      }
                    />
                    <Column
                      dataKey="schema_name"
                      label="Schema"
                      width={90}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="table"
                      label="Table"
                      flexGrow={1}
                      width={200}
                      cellRenderer={({ cellData }) => (
                        <TableName databaseId={databaseId} table={cellData} />
                      )}
                    />
                    <Column
                      dataKey="column_name"
                      label="Column"
                      width={140}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="data_type"
                      label="Data Type"
                      width={90}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="sequence_name"
                      label="Sequence"
                      flexGrow={1}
                      width={200}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="last_sequence_value"
                      label="Last Seq. Value"
                      width={130}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="max_column_value"
                      label="Max Col. Value"
                      width={160}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="pct_used"
                      label="% Used"
                      width={160}
                      cellRenderer={({ cellData }) => formatPercent(cellData)}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Panel>

            <Panel title="Sequences">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    headerHeight={25}
                    height={400}
                    rowCount={sequences.length}
                    rowGetter={({ index }) => sequences[index]}
                    rowHeight={25}
                    width={width}
                  >
                    <Column
                      dataKey="database"
                      label="Database"
                      width={90}
                      cellRenderer={({ cellData }) =>
                        cellData && cellData.datname
                      }
                    />
                    <Column
                      dataKey="schema_name"
                      label="Schema"
                      width={90}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="sequence_name"
                      label="Sequence"
                      flexGrow={1}
                      width={200}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="last_sequence_value"
                      label="Last Seq. Value"
                      width={130}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="max_sequence_value"
                      label="Max Seq. Value"
                      width={160}
                      cellRenderer={({ cellData }) => cellData}
                    />
                    <Column
                      dataKey="pct_used"
                      label="% Used"
                      width={160}
                      cellRenderer={({ cellData }) => formatPercent(cellData)}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Panel>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

const TableName: React.FunctionComponent<{
  table: any;
  databaseId: string;
}> = ({ databaseId, table }) => {
  const { databaseTable } = useRoutes();
  if (table.id) {
    return (
      <span>
        <Link to={databaseTable(databaseId, table.id)}>{table.tableName}</Link>
      </span>
    );
  } else if (table.tableName) {
    return <span>{table.tableName}</span>;
  } else {
    return <span>Unknown</span>;
  }
};

export default SequenceReport;
