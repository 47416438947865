import React from "react";

import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { formatBytes, formatNumber } from "utils/format";
import { useRoutes } from "utils/routes";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";
import Grid from "components/Grid";
import SchemaTableScanDetails from "components/SchemaTableScanDetails";
import IndexAdvisorIssueSummaryList from "components/IndexAdvisorIssueSummaryList";

import QUERY from "./Query.graphql";

import {
  QueryDetailIndexAdvisorInfo,
  QueryDetailIndexAdvisorInfoVariables,
} from "./types/QueryDetailIndexAdvisorInfo";
import { IssueType } from "components/IndexAdvisorIssueSummaryCard";

import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  databaseId: string;
  queryId: string;
  issues: IssueType[];
};

const QueryDetailsIndexAdvisorV2: React.FunctionComponent<Props> = ({
  databaseId,
  queryId,
  issues,
}) => {
  const { data, loading, error } = useQuery<
    QueryDetailIndexAdvisorInfo,
    QueryDetailIndexAdvisorInfoVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <>
      {issues.length > 0 && (
        <Panel title="Insights">
          <IndexAdvisorIssueSummaryList issues={issues} showTable />
        </Panel>
      )}
      <Panel title="Scans">
        <ScanGrid
          databaseId={databaseId}
          scans={data.getSchemaTableScansForQuery}
        />
      </Panel>
    </>
  );
};

const ScanGrid: React.FunctionComponent<{
  databaseId: string;
  scans: QueryDetailIndexAdvisorInfo["getSchemaTableScansForQuery"];
}> = ({ databaseId, scans }) => {
  const { databaseTable } = useRoutes();
  if (scans.length === 0) {
    // TODO: this may indicate an error in Index Advisor analysis, but right now we can't distinguish
    // that from the case of a query not scanning any tables: e.g., SELECT * FROM udf()
    return <PanelSection>No scans found in this query.</PanelSection>;
  }
  const scanData = scans.map((scan) => {
    const whereClause = scan.whereClauses.map((c) => c.clauseSql);
    const joinClause = scan.joinClauses.map((c) => c.clauseSql);
    return {
      fullTableName: scan.table.schemaName + "." + scan.table.tableName,
      table: scan.table,
      tableSize: scan.table.lastStats?.dataSizeBytes,
      scanMethod: scan.genericPlanScanMethod,
      totalCost: scan.genericPlanTotalCost,
      combinedSql: whereClause.concat(joinClause).join(" AND "),
      whereClause,
      joinClause,
    };
  });
  return (
    <Grid
      className={styles.scanGrid}
      data={scanData}
      columns={[
        {
          field: "fullTableName",
          header: "Table",
          renderer: function TableCell({ rowData }) {
            return (
              <Link to={databaseTable(databaseId, rowData.table.id)}>
                {rowData.table.schemaName}.{rowData.table.tableName}
              </Link>
            );
          },
        },
        {
          field: "combinedSql",
          header: "Scan Expression",
          renderer: function ScanDetailsCell({ rowData }) {
            return (
              <SchemaTableScanDetails
                databaseId={databaseId}
                whereClause={rowData.whereClause}
                joinClause={rowData.joinClause}
              />
            );
          },
        },
        {
          field: "scanMethod",
          header: "Scan Method",
          title: true,
          renderer: function ScanMethodCell({ fieldData }) {
            const [scanMethodIcon, scanMethodClassname] =
              getScanMethodInfo(fieldData);

            return (
              <>
                <FontAwesomeIcon
                  className={scanMethodClassname}
                  icon={scanMethodIcon}
                />{" "}
                {fieldData}
              </>
            );
          },
        },
        {
          field: "totalCost",
          header: "Cost",
          style: "number",
          renderer: ({ fieldData }) => formatNumber(fieldData, 2),
        },
        {
          field: "tableSize",
          header: "Table Size",
          style: "number",
          renderer: function TableSizeCell({ fieldData }) {
            return fieldData == null ? "n/a" : formatBytes(fieldData);
          },
        },
      ]}
    />
  );
};

function getScanMethodInfo(
  fieldData: string
): [icon: IconProp, className: string] {
  if (["Seq Scan", "Parallel Seq Scan"].includes(fieldData)) {
    return [faExclamationCircle, "text-[#C22426]"];
  } else if (["Append", "Merge Append"].includes(fieldData)) {
    return [faQuestionCircle, "text-[#777777]"];
  } else {
    return [faCheckCircle, "text-[#43962A]"];
  }
}

export default QueryDetailsIndexAdvisorV2;
