import React, { useEffect } from "react";

import {
  Link,
  Routes,
  Route,
  useLocation,
  useParams,
  useMatch,
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import * as DocsInstall from "../../../../docs/install.mdx";

import * as AmazonRDSConfigureRDSInstance from "../../../../docs/install/amazon_rds/01_configure_rds_instance.mdx";
import * as AmazonRDSCreateMonitoringUser from "../../../../docs/install/amazon_rds/02_create_monitoring_user.mdx";
import * as AmazonRDSSetupIAMPolicy from "../../../../docs/install/amazon_rds/03_setup_iam_policy.mdx";
import * as AmazonRDSInstallCollector from "../../../../docs/install/amazon_rds/04_install_collector.mdx";
import * as AmazonRDSInstallCollectorEC2 from "../../../../docs/install/amazon_rds/04_install_collector_ec2.mdx";
import * as AmazonRDSInstallCollectorECS from "../../../../docs/install/amazon_rds/04_install_collector_ecs.mdx";
import * as AmazonRDSInstallCollectorDocker from "../../../../docs/install/amazon_rds/04_install_collector_docker.mdx";
import * as AmazonRDSConfigureTheCollector from "../../../../docs/install/amazon_rds/05_configure_the_collector.mdx";
import * as AmazonRDSConfigureTheCollectorEC2 from "../../../../docs/install/amazon_rds/05_configure_the_collector_ec2.mdx";
import * as AmazonRDSConfigureTheCollectorECS from "../../../../docs/install/amazon_rds/05_configure_the_collector_ecs.mdx";
import * as AmazonRDSConfigureTheCollectorDocker from "../../../../docs/install/amazon_rds/05_configure_the_collector_docker.mdx";

import * as AzureConfigureAzureInstance from "../../../../docs/install/azure_database/01_configure_azure_instance.mdx";
import * as AzureCreateMonitoringUser from "../../../../docs/install/azure_database/02_create_monitoring_user.mdx";
import * as AzureInstallTheCollector from "../../../../docs/install/azure_database/03_install_the_collector.mdx";
import * as AzureInstallTheCollectorDeb from "../../../../docs/install/azure_database/03_install_the_collector_deb.mdx";
import * as AzureInstallTheCollectorDocker from "../../../../docs/install/azure_database/03_install_the_collector_docker.mdx";
import * as AzureInstallTheCollectorYum from "../../../../docs/install/azure_database/03_install_the_collector_yum.mdx";
import * as AzureConfigureTheCollector from "../../../../docs/install/azure_database/04_configure_the_collector.mdx";
import * as AzureConfigureTheCollectorDocker from "../../../../docs/install/azure_database/04_configure_the_collector_docker.mdx";
import * as AzureConfigureTheCollectorPackage from "../../../../docs/install/azure_database/04_configure_the_collector_package.mdx";

import * as GCPCreateMonitoringUser from "../../../../docs/install/google_cloud_sql/01_create_monitoring_user.mdx";
import * as GCPInstallTheCollector from "../../../../docs/install/google_cloud_sql/02_install_the_collector.mdx";
import * as GCPInstallTheCollectorDeb from "../../../../docs/install/google_cloud_sql/02_install_the_collector_deb.mdx";
import * as GCPInstallTheCollectorDocker from "../../../../docs/install/google_cloud_sql/02_install_the_collector_docker.mdx";
import * as GCPInstallTheCollectorYum from "../../../../docs/install/google_cloud_sql/02_install_the_collector_yum.mdx";
import * as GCPConfigureTheCollector from "../../../../docs/install/google_cloud_sql/03_configure_the_collector.mdx";
import * as GCPConfigureTheCollectorDocker from "../../../../docs/install/google_cloud_sql/03_configure_the_collector_docker.mdx";
import * as GCPConfigureTheCollectorPackage from "../../../../docs/install/google_cloud_sql/03_configure_the_collector_package.mdx";

import * as HerokuDeployTheCollector from "../../../../docs/install/heroku_postgres/01_deploy_the_collector.mdx";
import * as HerokuAttachDatabases from "../../../../docs/install/heroku_postgres/02_attach_databases.mdx";
import * as HerokuVerifyConfiguration from "../../../../docs/install/heroku_postgres/03_verify_configuration.mdx";

import * as CrunchyBridgeDeployTheCollector from "../../../../docs/install/crunchy_bridge/01_deploy_the_collector.mdx";

import * as AivenCreateMonitoringUser from "../../../../docs/install/aiven/01_create_monitoring_user.mdx";
import * as AivenEnablePgStatStatements from "../../../../docs/install/aiven/02_enable_pg_stat_statements.mdx";
import * as AivenCreatePgStatStatementsHelpers from "../../../../docs/install/aiven/03_create_pg_stat_statements_helpers.mdx";
import * as AivenInstallTheCollector from "../../../../docs/install/aiven/04_install_the_collector.mdx";
import * as AivenInstallTheCollectorPackage from "../../../../docs/install/aiven/04_install_the_collector_package.mdx";
import * as AivenInstallTheCollectorDocker from "../../../../docs/install/aiven/04_install_the_collector_docker.mdx";
import * as AivenConfigureTheCollector from "../../../../docs/install/aiven/05_configure_the_collector.mdx";
import * as AivenConfigureTheCollectorPackage from "../../../../docs/install/aiven/05_configure_the_collector_package.mdx";
import * as AivenConfigureTheCollectorDocker from "../../../../docs/install/aiven/05_configure_the_collector_docker.mdx";

import * as SelfManagedChooseSetupMethod from "../../../../docs/install/self_managed/00_choose_setup_method.mdx";
import * as SelfManagedCreateMonitoringUser from "../../../../docs/install/self_managed/01_create_monitoring_user.mdx";
import * as SelfManagedGuidedSetup from "../../../../docs/install/self_managed/01_guided_setup.mdx";
import * as SelfManagedEnablePgStatStatements from "../../../../docs/install/self_managed/02_enable_pg_stat_statements.mdx";
import * as SelfManagedEnablePgStatStatementsDeb from "../../../../docs/install/self_managed/02_enable_pg_stat_statements_deb.mdx";
import * as SelfManagedEnablePgStatStatementsDocker from "../../../../docs/install/self_managed/02_enable_pg_stat_statements_docker.mdx";
import * as SelfManagedEnablePgStatStatementsYum from "../../../../docs/install/self_managed/02_enable_pg_stat_statements_yum.mdx";
import * as SelfManagedInstallTheCollector from "../../../../docs/install/self_managed/03_install_the_collector.mdx";
import * as SelfManagedInstallTheCollectorDeb from "../../../../docs/install/self_managed/03_install_the_collector_deb.mdx";
import * as SelfManagedInstallTheCollectorDocker from "../../../../docs/install/self_managed/03_install_the_collector_docker.mdx";
import * as SelfManagedInstallTheCollectorYum from "../../../../docs/install/self_managed/03_install_the_collector_yum.mdx";
import * as SelfManagedConfigureTheCollector from "../../../../docs/install/self_managed/04_configure_the_collector.mdx";
import * as SelfManagedConfigureTheCollectorDocker from "../../../../docs/install/self_managed/04_configure_the_collector_docker.mdx";
import * as SelfManagedConfigureTheCollectorPackage from "../../../../docs/install/self_managed/04_configure_the_collector_package.mdx";

import DocsPanel from "components/DocsPanel";
import PageContent from "components/PageContent";
import IntegrationStatus from "components/IntegrationStatus";
import DocSteps, { StepType } from "components/DocSteps";
import DocsMDXProvider from "components/DocsMDXProvider";
import { useRoutes } from "utils/routes";

import QUERY from "./Query.graphql";
import { SetupApiKeysVariables, SetupApiKeys } from "./types/SetupApiKeys";

import MUTATION from "./Mutation.graphql";
import {
  UpdateIntegrationStatus,
  UpdateIntegrationStatusVariables,
} from "./types/UpdateIntegrationStatus";

const ServerInstallDocs: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const { pathname } = useLocation();
  const atInstallDocsRoot = pathname.endsWith("/servers/install");
  const installStep = pathname
    .replace(/.*\/servers\/install\//, "")
    .replace("/", "_");

  const [updateIntegrationStatus] = useMutation<
    UpdateIntegrationStatus,
    UpdateIntegrationStatusVariables
  >(MUTATION, { ignoreResults: true });

  useEffect(() => {
    if (!atInstallDocsRoot) {
      updateIntegrationStatus({
        variables: {
          organizationSlug,
          step: installStep,
        },
      });
    }
  }, [
    updateIntegrationStatus,
    organizationSlug,
    atInstallDocsRoot,
    installStep,
  ]);

  // N.B.: this query is only to give clearer instructions by inlining the API key,
  // so if it fails or while it's still loading, we can still show the documentation
  const { data } = useQuery<SetupApiKeys, SetupApiKeysVariables>(QUERY, {
    variables: { organizationSlug },
  });
  const apiKey = data?.getApiKeys?.[0]?.token;

  return (
    <DocsMDXProvider>
      <Routes>
        <Route path="install" element={<MDXPageContent mdx={DocsInstall} />} />
        <Route path="install/*">
          <Route
            path="amazon_rds/*"
            element={
              <AmazonRDSDocs
                apiKey={apiKey}
                organizationSlug={organizationSlug}
              />
            }
          />
          <Route
            path="azure_database/*"
            element={
              <AzureDocs apiKey={apiKey} organizationSlug={organizationSlug} />
            }
          />
          <Route
            path="google_cloud_sql/*"
            element={
              <GCPDocs apiKey={apiKey} organizationSlug={organizationSlug} />
            }
          />
          <Route
            path="heroku_postgres/*"
            element={
              <HerokuDocs apiKey={apiKey} organizationSlug={organizationSlug} />
            }
          />
          <Route
            path="crunchy_bridge/*"
            element={
              <CrunchyBridgeDocs
                apiKey={apiKey}
                organizationSlug={organizationSlug}
              />
            }
          />
          <Route
            path="aiven/*"
            element={
              <AivenDocs apiKey={apiKey} organizationSlug={organizationSlug} />
            }
          />
          <Route
            path="self_managed/*"
            element={
              <SelfManagedDocs
                apiKey={apiKey}
                organizationSlug={organizationSlug}
              />
            }
          />
        </Route>
      </Routes>
    </DocsMDXProvider>
  );
};

type MDXComponentType<P> = {
  default: React.ComponentType<P>;
  frontmatter?: Record<string, any>;
};

type MDXPageContentProps<P> = {
  mdx: MDXComponentType<P>;
  children: React.ReactChild;
} & P;

const MDXPageContent: React.FunctionComponent<MDXPageContentProps<any>> = ({
  mdx,
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const atInstallDocsRoot = pathname.endsWith("/servers/install");
  const match = useMatch(
    "/organizations/:org/servers/install/:systemType/:step"
  );
  const step = match?.params?.step;
  const systemType = match?.params?.systemType;
  const ChildComponent = mdx.default;
  const componentTitle = mdx.frontmatter?.install_track_title;
  const panelTitle = !step ? (
    "Select Your Environment"
  ) : /choose_setup_method/.test(step) ? (
    "Choose Setup Method"
  ) : /guided_setup/.test(step) ? (
    "Guided Setup"
  ) : (
    <DocSteps steps={getInstallSteps(systemType)} step={step} />
  );

  return (
    <PageContent
      title={componentTitle ?? "Get Started"}
      featureInfo={
        atInstallDocsRoot ? null : (
          <Link to="../..">&#10094; Back to Overview</Link>
        )
      }
      pageCategory="docs"
      pageName="show"
    >
      <DocsPanel title={panelTitle}>
        <ChildComponent {...rest} />
        {children}
      </DocsPanel>
    </PageContent>
  );
};

type DocsProps = {
  organizationSlug: string;
  apiKey: string;
};

const AmazonRDSDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  const { organizationMembers } = useRoutes();
  const inviteLink = organizationMembers(organizationSlug);
  return (
    <Routes>
      <Route
        path="01_configure_rds_instance"
        element={<MDXPageContent mdx={AmazonRDSConfigureRDSInstance} />}
      />
      <Route
        path="02_create_monitoring_user"
        element={<MDXPageContent mdx={AmazonRDSCreateMonitoringUser} />}
      />
      <Route
        path="03_setup_iam_policy"
        element={<MDXPageContent mdx={AmazonRDSSetupIAMPolicy} />}
      />
      <Route
        path="04_install_collector"
        element={<MDXPageContent mdx={AmazonRDSInstallCollector} />}
      />
      <Route
        path="04_install_collector_ec2"
        element={
          <MDXPageContent mdx={AmazonRDSInstallCollectorEC2} apiKey={apiKey} />
        }
      />
      <Route
        path="04_install_collector_ecs"
        element={<MDXPageContent mdx={AmazonRDSInstallCollectorECS} />}
      />
      <Route
        path="04_install_collector_docker"
        element={<MDXPageContent mdx={AmazonRDSInstallCollectorDocker} />}
      />
      <Route
        path="05_configure_the_collector"
        element={<MDXPageContent mdx={AmazonRDSConfigureTheCollector} />}
      />
      <Route
        path="05_configure_the_collector_ec2"
        element={
          <MDXPageContent
            mdx={AmazonRDSConfigureTheCollectorEC2}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="05_configure_the_collector_ecs"
        element={
          <MDXPageContent
            mdx={AmazonRDSConfigureTheCollectorECS}
            inviteLink={inviteLink}
            apiKey={apiKey}
          />
        }
      />
      <Route
        path="05_configure_the_collector_docker"
        element={
          <MDXPageContent
            mdx={AmazonRDSConfigureTheCollectorDocker}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="docker"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const AzureDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="01_configure_azure_instance"
        element={<MDXPageContent mdx={AzureConfigureAzureInstance} />}
      />
      <Route
        path="02_create_monitoring_user"
        element={<MDXPageContent mdx={AzureCreateMonitoringUser} />}
      />
      <Route
        path="03_install_the_collector"
        element={<MDXPageContent mdx={AzureInstallTheCollector} />}
      />
      <Route
        path="03_install_the_collector_deb"
        element={
          <MDXPageContent mdx={AzureInstallTheCollectorDeb} apiKey={apiKey} />
        }
      />
      <Route
        path="03_install_the_collector_docker"
        element={<MDXPageContent mdx={AzureInstallTheCollectorDocker} />}
      />
      <Route
        path="03_install_the_collector_yum"
        element={
          <MDXPageContent mdx={AzureInstallTheCollectorYum} apiKey={apiKey} />
        }
      />
      <Route
        path="04_configure_the_collector"
        element={
          <MDXPageContent mdx={AzureConfigureTheCollector} apiKey={apiKey} />
        }
      />
      <Route
        path="04_configure_the_collector_docker"
        element={
          <MDXPageContent
            mdx={AzureConfigureTheCollectorDocker}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="docker"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="04_configure_the_collector_package"
        element={
          <MDXPageContent
            mdx={AzureConfigureTheCollectorPackage}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const GCPDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="01_create_monitoring_user"
        element={<MDXPageContent mdx={GCPCreateMonitoringUser} />}
      />
      <Route
        path="02_install_the_collector"
        element={<MDXPageContent mdx={GCPInstallTheCollector} />}
      />
      <Route
        path="02_install_the_collector_deb"
        element={
          <MDXPageContent mdx={GCPInstallTheCollectorDeb} apiKey={apiKey} />
        }
      />
      <Route
        path="02_install_the_collector_docker"
        element={<MDXPageContent mdx={GCPInstallTheCollectorDocker} />}
      />
      <Route
        path="02_install_the_collector_yum"
        element={
          <MDXPageContent mdx={GCPInstallTheCollectorYum} apiKey={apiKey} />
        }
      />
      <Route
        path="03_configure_the_collector"
        element={
          <MDXPageContent mdx={GCPConfigureTheCollector} apiKey={apiKey} />
        }
      />
      <Route
        path="03_configure_the_collector_docker"
        element={
          <MDXPageContent mdx={GCPConfigureTheCollectorDocker} apiKey={apiKey}>
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="docker"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="03_configure_the_collector_package"
        element={
          <MDXPageContent mdx={GCPConfigureTheCollectorPackage} apiKey={apiKey}>
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const HerokuDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="01_deploy_the_collector"
        element={
          <MDXPageContent
            mdx={HerokuDeployTheCollector}
            apiKey={apiKey}
            organizationSlug={organizationSlug}
          />
        }
      />
      <Route
        path="02_attach_databases"
        element={
          <MDXPageContent
            mdx={HerokuAttachDatabases}
            organizationSlug={organizationSlug}
          />
        }
      />
      <Route
        path="03_verify_configuration"
        element={
          <MDXPageContent
            mdx={HerokuVerifyConfiguration}
            organizationSlug={organizationSlug}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="heroku"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const CrunchyBridgeDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="01_deploy_the_collector"
        element={
          <MDXPageContent
            mdx={CrunchyBridgeDeployTheCollector}
            apiKey={apiKey}
            organizationSlug={organizationSlug}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="crunchy_bridge"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const AivenDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="01_create_monitoring_user"
        element={<MDXPageContent mdx={AivenCreateMonitoringUser} />}
      />
      <Route
        path="02_enable_pg_stat_statements"
        element={<MDXPageContent mdx={AivenEnablePgStatStatements} />}
      />
      <Route
        path="03_create_pg_stat_statements_helpers"
        element={<MDXPageContent mdx={AivenCreatePgStatStatementsHelpers} />}
      />
      <Route
        path="04_install_the_collector"
        element={<MDXPageContent mdx={AivenInstallTheCollector} />}
      />
      <Route
        path="04_install_the_collector_package"
        element={
          <MDXPageContent
            mdx={AivenInstallTheCollectorPackage}
            apiKey={apiKey}
          />
        }
      />
      <Route
        path="04_install_the_collector_docker"
        element={<MDXPageContent mdx={AivenInstallTheCollectorDocker} />}
      />
      <Route
        path="05_configure_the_collector"
        element={
          <MDXPageContent mdx={AivenConfigureTheCollector} apiKey={apiKey} />
        }
      />
      <Route
        path="05_configure_the_collector_docker"
        element={
          <MDXPageContent
            mdx={AivenConfigureTheCollectorDocker}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="docker"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="05_configure_the_collector_package"
        element={
          <MDXPageContent
            mdx={AivenConfigureTheCollectorPackage}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const SelfManagedDocs: React.FunctionComponent<DocsProps> = ({
  apiKey,
  organizationSlug,
}) => {
  return (
    <Routes>
      <Route
        path="00_choose_setup_method"
        element={<MDXPageContent mdx={SelfManagedChooseSetupMethod} />}
      />
      <Route
        path="01_guided_setup"
        element={
          <MDXPageContent mdx={SelfManagedGuidedSetup} apiKey={apiKey}>
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="01_create_monitoring_user"
        element={<MDXPageContent mdx={SelfManagedCreateMonitoringUser} />}
      />
      <Route
        path="02_enable_pg_stat_statements"
        element={<MDXPageContent mdx={SelfManagedEnablePgStatStatements} />}
      />
      <Route
        path="02_enable_pg_stat_statements_deb"
        element={<MDXPageContent mdx={SelfManagedEnablePgStatStatementsDeb} />}
      />
      <Route
        path="02_enable_pg_stat_statements_docker"
        element={
          <MDXPageContent mdx={SelfManagedEnablePgStatStatementsDocker} />
        }
      />
      <Route
        path="02_enable_pg_stat_statements_yum"
        element={<MDXPageContent mdx={SelfManagedEnablePgStatStatementsYum} />}
      />
      <Route
        path="03_install_the_collector"
        element={<MDXPageContent mdx={SelfManagedInstallTheCollector} />}
      />
      <Route
        path="03_install_the_collector_deb"
        element={
          <MDXPageContent
            mdx={SelfManagedInstallTheCollectorDeb}
            apiKey={apiKey}
          />
        }
      />
      <Route
        path="03_install_the_collector_docker"
        element={<MDXPageContent mdx={SelfManagedInstallTheCollectorDocker} />}
      />
      <Route
        path="03_install_the_collector_yum"
        element={
          <MDXPageContent
            mdx={SelfManagedInstallTheCollectorYum}
            apiKey={apiKey}
          />
        }
      />
      <Route
        path="04_configure_the_collector"
        element={
          <MDXPageContent
            mdx={SelfManagedConfigureTheCollector}
            apiKey={apiKey}
          />
        }
      />
      <Route
        path="04_configure_the_collector_docker"
        element={
          <MDXPageContent
            mdx={SelfManagedConfigureTheCollectorDocker}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="docker"
            />
          </MDXPageContent>
        }
      />
      <Route
        path="04_configure_the_collector_package"
        element={
          <MDXPageContent
            mdx={SelfManagedConfigureTheCollectorPackage}
            apiKey={apiKey}
          >
            <IntegrationStatus
              organizationSlug={organizationSlug}
              installType="package"
            />
          </MDXPageContent>
        }
      />
    </Routes>
  );
};

const getInstallSteps = (systemType: string): StepType[] => {
  switch (systemType) {
    case "amazon_rds":
      return [
        ["Configure RDS instance", "01_configure_rds_instance"],
        ["Create monitoring user", "02_create_monitoring_user"],
        ["Set up IAM policy", "03_setup_iam_policy"],
        ["Install collector", "04_install_collector"],
        ["Configure collector", "05_configure_the_collector"],
      ];
    case "self_managed":
      return [
        ["Create the monitoring user", "01_create_monitoring_user"],
        ["Enable pg_stat_statements", "02_enable_pg_stat_statements"],
        ["Install the collector", "03_install_the_collector"],
        ["Configure the collector", "04_configure_the_collector"],
      ];
    case "heroku_postgres":
      return [
        ["Deploy the collector", "01_deploy_the_collector"],
        ["Attach databases", "02_attach_databases"],
        ["Install the collector", "03_verify_configuration"],
      ];
    case "crunchy_bridge":
      return [["Deploy the collector", "01_deploy_the_collector"]];
    case "aiven":
      return [
        ["Create the monitoring user", "01_create_monitoring_user"],
        ["Enable pg_stat_statements", "02_enable_pg_stat_statements"],
        ["Create helpers", "03_create_pg_stat_statements_helpers"],
        ["Install the collector", "04_install_the_collector"],
        ["Configure the collector", "05_configure_the_collector"],
      ];
    case "azure_database":
      return [
        ["Configure Azure instance", "01_configure_azure_instance"],
        ["Create the monitoring user", "02_create_monitoring_user"],
        ["Install the collector", "03_install_the_collector"],
        ["Configure the collector", "04_configure_the_collector"],
      ];
    case "google_cloud_sql":
      return [
        ["Create the monitoring user", "01_create_monitoring_user"],
        ["Install the collector", "02_install_the_collector"],
        ["Configure the collector", "03_configure_the_collector"],
      ];
    default:
      return [];
  }
};

export default ServerInstallDocs;
