import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Panel from "components/Panel";

import { useDateRange } from "components/WithDateRange";
import { Data, Datum, SeriesConfig } from "components/Graph/util";
import Loading from "components/Loading";
import DateRangeBar from "components/DateRangeBar";
import GraphSection from "components/Graph/GraphSection";
import { AreaSeries } from "components/Graph/Series";
import DateRangeGraph from "components/Graph/DateRangeGraph";

import {
  ServerQueryOverview as ServerQueryOverviewType,
  ServerQueryOverviewVariables,
} from "./types/ServerQueryOverview";
import Table from "./Table";
import QUERY from "./Query.graphql";

const ServerQueryOverview: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    ServerQueryOverviewType,
    ServerQueryOverviewVariables
  >(QUERY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <PageContent
      title="Query Performance"
      pageControls={<DateRangeBar />}
      pageCategory="queries"
      pageName="index_server"
    >
      <Panel title="Database Queries">
        <Graph data={data} />
        <Table data={data} />
      </Panel>
    </PageContent>
  );
};

const Graph: React.FunctionComponent<{ data: ServerQueryOverviewType }> = ({
  data,
}) => {
  const noData = data.getQueryOverviewStatsByDatabase == null;
  const graphData: Data = {};
  const series: SeriesConfig[] = [];

  if (data?.getQueryOverviewStatsByDatabase) {
    data.getQueryOverviewStatsByDatabase.forEach((d) => {
      graphData[d.database.id] = d.queriesPerSecondSeries as unknown as Datum[];
      series.push({
        key: d.database.id,
        label: d.database.datname,
        type: AreaSeries,
      });
    });
  }

  return (
    <GraphSection noData={noData}>
      <DateRangeGraph
        data={graphData}
        axes={{
          left: {
            format: (y) => y + " QPS",
            tipFormat: (y) => y.toFixed(2) + " QPS",
          },
        }}
        series={series}
      />
    </GraphSection>
  );
};

export default ServerQueryOverview;
