import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";
import { SortDirection } from "types/graphql-global-types";

import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";

import {
  formatBytes,
  formatPercent,
  formatNumber,
  formatBytesTrend,
  formatBytesTrendDetail,
} from "utils/format";
import Loading from "components/Loading";
import PaginatedVirtualTable, {
  cellStyles,
} from "components/PaginatedVirtualTable";

import {
  SchemaTableList,
  SchemaTableListVariables,
  SchemaTableList_getSchemaTables,
  SchemaTableList_getSchemaTables_issues,
} from "./types/SchemaTableList";
import { useRoutes } from "utils/routes";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  parentTableId?: string;
}

interface TableSizeTrend {
  sizeBytes: number;
  sizeBytes7dAgo: number;
  snapshotAt: number;
  snapshotAt7dAgo: number;
}

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaTable: SchemaTableList_getSchemaTables;
}> = ({ databaseId, schemaTable }) => {
  const { databaseTable } = useRoutes();
  return (
    <span>
      <Link to={databaseTable(databaseId, schemaTable.id)}>
        {schemaTable.tableName}
      </Link>
      {schemaTable.issues.map(
        (i: SchemaTableList_getSchemaTables_issues): React.ReactNode => (
          <Link
            to={databaseTable(databaseId, schemaTable.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        )
      )}
    </span>
  );
};

// Handle the mapping between the union type (react-virtualized) and enum in GraphQL
const sortDirectionStrToEnum: { [key in "ASC" | "DESC"]: SortDirection } = {
  ASC: SortDirection.ASC,
  DESC: SortDirection.DESC,
};
const sortDirectionEnumToStr: {
  [key in keyof typeof SortDirection]: "ASC" | "DESC";
} = {
  [SortDirection.ASC]: "ASC",
  [SortDirection.DESC]: "DESC",
};

const Table: React.FunctionComponent<Props> = ({
  databaseId,
  parentTableId,
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [includePartitions, setIncludePartitions] =
    React.useState<boolean>(false);

  const secondaryTitle = (
    <>
      {!parentTableId && (
        <div className={styles.showIncludePartitions}>
          <input
            type="checkbox"
            checked={includePartitions}
            id="include_partitions"
            onChange={(evt) => setIncludePartitions(evt.target.checked)}
          />
          <label htmlFor="include_partitions">Show table partitions</label>
        </div>
      )}
      <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
    </>
  );

  return (
    <Panel
      title={parentTableId ? "Table Partitions" : "All Tables"}
      secondaryTitle={secondaryTitle}
    >
      <TableContent
        databaseId={databaseId}
        parentTableId={parentTableId}
        searchTerm={searchTerm}
        includePartitions={includePartitions}
      />
    </Panel>
  );
};

const TableContent: React.FunctionComponent<{
  databaseId: string;
  parentTableId: string | undefined;
  searchTerm: string;
  includePartitions: boolean;
}> = ({ databaseId, parentTableId, searchTerm, includePartitions }) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: parentTableId ? "tableName" : "sizeBytes",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaTableList,
    SchemaTableListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: 50,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId: databaseId,
      parentTableId: parentTableId,
      includePartitions: parentTableId ? null : includePartitions,
    },
  });
  if (error || loading || !data) {
    return <Loading error={!!error} />;
  }

  const schemaTables = data.getSchemaTables;

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: SchemaTableList,
        { fetchMoreResult }: { fetchMoreResult: SchemaTableList }
      ): SchemaTableList => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getSchemaTables: [
            ...prev.getSchemaTables,
            ...fetchMoreResult.getSchemaTables,
          ],
        });
      },
    });
  };

  const handleSort = ({ sortBy, sortDirection }: SortOptsType) => {
    setSortOpts({
      sortBy,
      sortDirection: sortDirectionStrToEnum[sortDirection],
    });
  };

  const sizeTrendDataGetter = ({
    rowData,
  }: {
    rowData: SchemaTableList_getSchemaTables;
  }): TableSizeTrend | null => {
    const { lastStats, stats7dAgo } = rowData;
    if (lastStats === null || stats7dAgo === null) {
      return null;
    }
    const { dataSizeBytes: sizeBytes, snapshotAt } = lastStats;
    const { dataSizeBytes: sizeBytes7dAgo, snapshotAt: snapshotAt7dAgo } =
      stats7dAgo;
    if (
      sizeBytes == null ||
      snapshotAt == null ||
      sizeBytes7dAgo == null ||
      snapshotAt7dAgo == null
    ) {
      return null;
    }
    return {
      sizeBytes,
      sizeBytes7dAgo,
      snapshotAt,
      snapshotAt7dAgo,
    };
  };

  const sizeTrendRenderer = ({
    cellData,
  }: {
    cellData?: TableSizeTrend;
  }): React.ReactNode => {
    if (!cellData) {
      return "n/a";
    }

    const { sizeBytes, sizeBytes7dAgo, snapshotAt, snapshotAt7dAgo } = cellData;
    const content = formatBytesTrend(sizeBytes, sizeBytes7dAgo);
    const tooltip = formatBytesTrendDetail(
      sizeBytes,
      sizeBytes7dAgo,
      snapshotAt,
      snapshotAt7dAgo
    );

    return <span title={tooltip}>{content}</span>;
  };

  return (
    <PaginatedVirtualTable
      data={schemaTables}
      fetchMore={fetchMoreData}
      sortBy={sortOpts.sortBy}
      sortDirection={sortDirectionEnumToStr[sortOpts.sortDirection]}
      handleSort={handleSort}
      noRowsText={
        parentTableId
          ? "No partitions or table inheritance children found"
          : "No tables found"
      }
    >
      {!parentTableId && (
        <Column dataKey="schemaName" label="Schema" width={120} />
      )}
      <Column
        dataKey="tableName"
        label="Name"
        width={120}
        flexGrow={1}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaTableList_getSchemaTables;
        }): SchemaTableList_getSchemaTables => rowData}
        cellRenderer={({
          cellData,
        }: {
          cellData?: SchemaTableList_getSchemaTables;
        }): React.ReactNode =>
          cellData ? (
            <NameAndIssuesCell databaseId={databaseId} schemaTable={cellData} />
          ) : (
            "n/a"
          )
        }
      />
      {parentTableId && (
        <Column
          dataKey="partitionBoundary"
          label="Partition Boundary"
          className={cellStyles.query}
          width={240}
          flexGrow={1}
        />
      )}
      <Column
        dataKey="liveTuples"
        label="Est. Visible Rows"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={180}
        defaultSortDirection={"DESC"}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaTableList_getSchemaTables;
        }): number | null | undefined => rowData.lastStats?.liveTuples}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === undefined ? "n/a" : formatNumber(cellData)
        }
      />
      <Column
        dataKey="deadTupleRatio"
        label="Dead Row %"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={120}
        defaultSortDirection={"DESC"}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaTableList_getSchemaTables;
        }): number | null | undefined => rowData.lastStats?.deadTupleRatio}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === undefined ? "n/a" : formatPercent(cellData)
        }
      />
      <Column
        dataKey="sizeBytes"
        label="Data Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={100}
        defaultSortDirection={"DESC"}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaTableList_getSchemaTables;
        }): number | null | undefined => rowData.lastStats?.dataSizeBytes}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === undefined ? "n/a" : formatBytes(cellData)
        }
      />
      <Column
        dataKey="sizeBytesTrend"
        label="7 Day Trend"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={180}
        defaultSortDirection={"DESC"}
        cellDataGetter={sizeTrendDataGetter}
        cellRenderer={sizeTrendRenderer}
      />
      <Column
        dataKey="indexSizeBytes"
        label="Index Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={100}
        defaultSortDirection={"DESC"}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaTableList_getSchemaTables;
        }): number | null | undefined => rowData.lastStats?.indexSizeBytes}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === undefined ? "n/a" : formatBytes(cellData)
        }
      />
    </PaginatedVirtualTable>
  );
};

export default Table;
