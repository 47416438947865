import React, { useEffect, useState } from "react";

import { SortDirection, SortDirectionType } from "react-virtualized";
import sortBy from "lodash/sortBy";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import Button from "components/Button";
import PaginatedVirtualTable from "components/PaginatedVirtualTable";

// We re-export this since before these two components had identical versions
// of these styles, so consumers may be importing them from either.
export { cellStyles } from "components/PaginatedVirtualTable";

import styles from "./style.module.scss";

type DataItemValueType = any;

type DataItemType = {
  [a: string]: DataItemValueType;
};

type SortOptsType = {
  sortBy?: string;
  sortDirection?: SortDirectionType;
};

type Props = {
  data: DataItemType[];
  initialSortBy?: string;
  initialSortDirection?: SortDirectionType;
  noRowsText?: string;
  rowHeight?: number;
  headerHeight?: number;
  pageSize?: number;
};

const PanelVirtualTable: React.FunctionComponent<Props> = ({
  data,
  initialSortBy,
  initialSortDirection,
  noRowsText = "No Data",
  rowHeight = 30,
  headerHeight = 25,
  children,
  pageSize = Infinity,
}) => {
  const [sortOpts, setSortOpts] = useState({
    sortField: initialSortBy,
    sortDirection: initialSortDirection,
  });
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(0);
  }, [sortOpts]);
  const { sortField, sortDirection } = sortOpts;

  const handleSort = (opts: SortOptsType) => {
    setSortOpts({ sortField: opts.sortBy, sortDirection: opts.sortDirection });
  };

  let tableData: DataItemType[];
  if (sortField) {
    tableData = sortBy(
      data,
      (q: DataItemType): DataItemValueType => q[sortField]
    );
  } else {
    tableData = data.slice();
  }
  if (sortDirection == SortDirection.DESC) {
    tableData.reverse();
  }

  const isPaginated = data.length > pageSize;
  const pageFirstItem = page * pageSize;
  const nextPageFirstItem = (page + 1) * pageSize;
  // N.B.: we need to slice in zero-indexed mode but show labels in one-indexed
  const pageFirstItemLabel = String(pageFirstItem + 1);
  const pageLastItemLabel = String(
    Math.min(nextPageFirstItem, tableData.length)
  );
  if (isPaginated) {
    tableData = tableData.slice(pageFirstItem, nextPageFirstItem);
  }

  const pagePrevDisabled = pageFirstItem === 0;
  const pageNextDisabled = nextPageFirstItem >= data.length;

  const handleShowPrev = () => {
    setPage((p) => p - 1);
  };
  const handleShowNext = () => {
    setPage((p) => p + 1);
  };

  // We want to make sure the pagination footer does not "jump" when the
  // last page of results has less data than other pages.
  const Wrapper: React.ComponentType = isPaginated
    ? function EnsureStableHeight({ children }) {
        return (
          <div style={{ minHeight: headerHeight + pageSize * rowHeight }}>
            {children}
          </div>
        );
      }
    : React.Fragment;

  return (
    <>
      <Wrapper>
        <PaginatedVirtualTable
          data={tableData}
          noRowsText={noRowsText}
          handleSort={handleSort}
          sortBy={sortField}
          sortDirection={sortDirection}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
        >
          {children}
        </PaginatedVirtualTable>
      </Wrapper>
      {isPaginated && (
        <div className={styles.paginationFooter}>
          <span className={styles.pageIndicator}>
            {pageFirstItemLabel}-{pageLastItemLabel} of {data.length}
          </span>
          <Button
            bare
            className={styles.paginationButton}
            disabled={pagePrevDisabled}
            onClick={handleShowPrev}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            bare
            className={styles.paginationButton}
            disabled={pageNextDisabled}
            onClick={handleShowNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
      )}
    </>
  );
};

export default PanelVirtualTable;
