---
title: 'Step 1: Move Database into VPC'
install_track_title: 'Log Insights: Setup (Aiven)'
backlink_href: /docs/log-insights/setup
backlink_title: 'Log Insights: Setup'
---

import imgAivenCreateVpc from './aiven-create-vpc.png'
import imgAivenMigrateVpc from './aiven-migrate-vpc.png'

export const ImgAivenCreateVpc = () => <div style={{display: 'flex', justifyContent: 'center'}}><img alt="Create a VPC in Aiven" src={imgAivenCreateVpc} style={{maxWidth: 500}} /></div>

export const ImgAivenMigrateVpc = () => <img alt="Migrate database to a VPC" src={imgAivenMigrateVpc} />

In order to securely deliver logs to the collector, your database must run in an
Aiven VPC peered to the VPC of the EC2 instance or container the collector is
running in. In later steps we refer to this VPC as the "collector VPC". If you
have not done so yet, go to the Aiven console and create a VPC for the project
in the same region where your database is running:

<ImgAivenCreateVpc />

Make sure that the VPC IP range does not overlap with the collector VPC you will
need to peer. For example, if your collector VPC uses the 10.0.0.0/24 range,
selecting 192.168.0.0/24 for your Aiven project VPC makes it possible to peer
the networks.

Then, go to the database details page in the Aiven console click "Migrate Cloud"
to migrate your database to the VPC you just created:

<ImgAivenMigrateVpc />

Rebalancing your database nodes will take a few minutes after migration.

<Link className="btn btn-success" to="02_configure_vpc_peering">
  Continue to Step 2: Configure VPC Peering
</Link>
