import React from "react";

import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faLock } from "@fortawesome/pro-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import TrackedLink from "components/TrackedLink";

export type OnboardingStep =
  | "collector_setup"
  | "collector_waiting"
  | "log_insights_setup"
  | "explain_setup"
  | "done";

const OnboardingProgressPanels: React.FunctionComponent<{
  currentStep: OnboardingStep;
  organizationSlug: string;
  serverId?: string;
  systemType?: string;
}> = ({ currentStep, organizationSlug, serverId, systemType }) => {
  const {
    organizationServersNew,
    serverSetupLogInsights,
    serverSetupAutomatedExplain,
  } = useRoutes();
  const newServerRoute = organizationServersNew(organizationSlug);
  const logInsightsSetupLink = serverId
    ? serverSetupLogInsights(serverId)
    : getPublicLogInsightsSetupLink(systemType);
  const automatedExplainSetupLink = serverId
    ? serverSetupAutomatedExplain(serverId)
    : getPublicAutomatedExplainSetupLink(systemType);

  return (
    <div className={styles.wrapper}>
      <OnboardingStepPanel
        idx={1}
        title="Query Performance"
        subtitle="Discover slow queries"
        imgSrc="/onboarding/one.svg"
        ctaLabel="Start Setup"
        ctaUrl={newServerRoute}
        status={
          currentStep === "collector_setup"
            ? "ready"
            : currentStep === "collector_waiting"
            ? "waiting"
            : "completed"
        }
      >
        <p>
          Connect your database to set up pganalyze and enable the Index
          Advisor. This is just the beginning, there's more to come!
        </p>
      </OnboardingStepPanel>
      <OnboardingStepPanel
        idx={2}
        title="Log Insights"
        subtitle="Reveal hidden details from your logs"
        imgSrc="/onboarding/two.svg"
        ctaLabel="Set up Log Insights"
        ctaUrl={logInsightsSetupLink}
        ctaExternal={!serverId}
        status={
          ["log_insights_setup", "collector_waiting"].includes(currentStep)
            ? "ready"
            : ["explain_setup", "done"].includes(currentStep)
            ? "completed"
            : "locked"
        }
      >
        <p>
          Monitor and analyze your PostgreSQL log files, and get insights into
          how to tune your database.
        </p>
      </OnboardingStepPanel>
      <OnboardingStepPanel
        idx={3}
        title="Automatic EXPLAIN"
        subtitle="Find root causes of performance issues"
        imgSrc="/onboarding/three.svg"
        ctaLabel="Set up Automatic EXPLAIN"
        ctaUrl={automatedExplainSetupLink}
        ctaExternal={!serverId}
        status={
          currentStep === "explain_setup"
            ? "ready"
            : currentStep === "done"
            ? "completed"
            : "locked"
        }
      >
        <p>
          pganalyze automatically analyzes EXPLAIN plans to find the most
          important insights, and helps you discover missing indexes.
        </p>
      </OnboardingStepPanel>
    </div>
  );
};

type StepStatus = "completed" | "waiting" | "ready" | "locked";

const OnboardingStepPanel: React.FunctionComponent<{
  idx: number;
  title: string;
  imgSrc: string;
  subtitle: string;
  status: StepStatus;
  ctaLabel: string;
  ctaUrl: string;
  ctaExternal?: boolean;
}> = ({
  idx,
  title,
  imgSrc,
  subtitle,
  status,
  ctaLabel,
  ctaUrl,
  ctaExternal = false,
  children,
}) => {
  const trackedLinkProps = {
    eventCategory: "Docs Link",
    eventLabel: title,
  };
  return (
    <Panel title={`${idx}. ${title}`} className={styles.stepPanel}>
      <PanelSection className={styles.stepSection}>
        <div className={styles.stepMain}>
          <img
            className={classNames(
              styles.onboardingImg,
              status === "locked" && styles.onboardingLockedImg
            )}
            src={imgSrc}
          />
          <h4 className={styles.stepPanelHeading}>{subtitle}</h4>
          {children}
        </div>
        {status === "waiting" ? (
          <p className={styles.stepDoneBlurb}>
            <FontAwesomeIcon icon={faClock} /> Waiting for data
          </p>
        ) : status === "completed" ? (
          <p className={styles.stepDoneBlurb}>
            <FontAwesomeIcon icon={faCheck} /> {title} already set up!
          </p>
        ) : (
          <TrackedLink
            className={classNames("btn", "btn-success", styles.stepButton)}
            {...trackedLinkProps}
            {...{
              // N.B.: we use a spreading hack here to trick TypeScript because
              // 'disabled' is not a valid anchor property, but we want to preserve
              // standard link behavior here
              disabled: status === "locked",
            }}
            href={status === "ready" ? ctaUrl : ""}
            target={ctaExternal ? "_blank" : undefined}
            title={
              status === "locked"
                ? "you must complete the earlier setup steps first"
                : undefined
            }
          >
            {status === "locked" && <FontAwesomeIcon icon={faLock} />}{" "}
            {ctaLabel}{" "}
            {status === "ready" && ctaExternal && (
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            )}
          </TrackedLink>
        )}
      </PanelSection>
    </Panel>
  );
};

function getPublicLogInsightsSetupLink(systemType: string): string {
  const docSubSlugs = {
    self_managed: "self-managed",
    amazon_rds: "amazon-rds",
    heroku: "heroku-postgres",
    google_cloudsql: "google-cloud-sql",
    azure_database: "azure-database",
    crunchy_bridge: "crunchy-bridge",
    aiven: "aiven",
  };
  return (
    "https://pganalyze.com/docs/log-insights/setup" +
    (systemType ? "/" + docSubSlugs[systemType] : "")
  );
}

function getPublicAutomatedExplainSetupLink(systemType: string): string {
  const docSubPaths = {
    self_managed: "self_managed/01_auto_explain_check",
    amazon_rds: "amazon_rds/01_auto_explain_check",
    heroku: "heroku/01_enable_log_explain",
    google_cloudsql: "google_cloud_sql/01_auto_explain_check",
    azure_database: "log_explain/01_create_helper_functions",
    crunchy_bridge: "crunchy_bridge/01_create_helper_functions",
    aiven: "log_explain/01_create_helper_functions",
  };

  return (
    "https://pganalyze.com/docs/explain/setup" +
    (systemType ? "/" + docSubPaths[systemType] : "")
  );
}

export default OnboardingProgressPanels;
