import React from "react";

import classNames from "classnames";

import { Node, Annotation } from "types/explain";

import TabPanel, { Tab, TabBar, TabContent } from "components/TabPanel";

import RelationInfo from "../RelationInfo";
import IndexInfo from "../IndexInfo";
import NodeHeading from "../NodeHeading";

import Overview from "./Overview";
import IOAndBuffers from "./IOAndBuffers";
import Output from "./Output";
import Source from "./Source";

import styles from "./style.module.scss";

type Props = {
  node: Node;
  databaseId: string;
  blockSize: number;
  annotations: Annotation[];
};

const NodeDetails: React.FunctionComponent<Props> = ({
  node,
  databaseId,
  blockSize,
  annotations,
}) => {
  const hasRelation = "Relation Name" in node;
  const hasIndex = "Index Name" in node;
  const hasEitherRelOrIdx = hasRelation || hasIndex;
  const tabClasses = classNames({ [styles.noTopBorder]: !hasEitherRelOrIdx });

  return (
    <div className={styles.nodeDetailsWrapper}>
      <div className={styles.nodeDetails}>
        <NodeHeading node={node} className={styles.nodeDetailsHeading} />
        {hasEitherRelOrIdx && (
          <NodeSubheading node={node} databaseId={databaseId} />
        )}
        <TabPanel>
          <TabBar>
            <Tab name="Overview" className={tabClasses} />
            <Tab name="I/O & Buffers" className={tabClasses} />
            <Tab name="Output" className={tabClasses} />
            <Tab name="Source" className={tabClasses} />
          </TabBar>
          <TabContent>
            <Overview annotations={annotations} node={node} />
          </TabContent>
          <TabContent>
            <IOAndBuffers node={node} blockSize={blockSize} />
          </TabContent>
          <TabContent>
            <Output node={node} />
          </TabContent>
          <TabContent>
            <Source node={node} />
          </TabContent>
        </TabPanel>
      </div>
    </div>
  );
};

const NodeSubheading: React.FunctionComponent<{
  node: Node;
  databaseId: string;
}> = ({ node, databaseId }) => {
  return (
    <div className={styles.nodeSubheading}>
      <RelationInfo node={node} databaseId={databaseId} />
      <IndexInfo node={node} databaseId={databaseId} />
    </div>
  );
};

export default NodeDetails;
