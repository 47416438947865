import React, { useRef, useLayoutEffect } from "react";

import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { select } from "d3-selection";
import { extent } from "d3";
import { translateX } from "utils/svg";

import { Scale } from "./util";

type Props = {
  placement?: "left" | "bottom" | "right";
  scale: Scale;
  width: number;
  height: number;
  tickFormat?: (value: number) => string;
};

const Axis: React.FunctionComponent<Props> = ({
  placement = "bottom",
  scale,
  width,
  tickFormat,
}) => {
  const ref = useRef<SVGGElement>(null);
  useLayoutEffect(() => {
    const axisFn = {
      bottom: axisBottom,
      left: axisLeft,
      right: axisRight,
    }[placement];

    const host = select(ref.current);
    const axisGenerator = axisFn(scale);
    if (tickFormat) {
      axisGenerator.tickFormat((v: number): string => tickFormat(v));
    }
    const [start, end] = extent(scale.range());
    const pxPerTick = placement === "bottom" ? 80 : 30;
    const tickCount =
      start === undefined || end === undefined
        ? 0
        : Math.ceil((end - start) / pxPerTick);
    axisGenerator.ticks(tickCount);

    host.select("g").remove();
    const group = host.append("g");
    if (placement === "left") {
      // the axis is drawn to the left of the origin, but the placement
      // for a left axis should be along the right edge of the axis space
      group.attr("transform", translateX(width));
    }
    group.call(axisGenerator);
  }, [scale, placement, width, tickFormat]);

  return <g ref={ref} />;
};

export default Axis;
