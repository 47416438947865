import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Tip from "components/Tip";
import { useDateRange } from "components/WithDateRange";

import QUERY from "./Query.graphql";
import {
  XactPerMinGraphVariables,
  XactPerMinGraph as XactPerMinGraphType,
} from "./types/XactPerMinGraph";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import { Data, Datum } from "components/Graph/util";
import { formatDurationPrecise, formatNumber } from "utils/format";

const XactPerMinGraph: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const [{ from: start, to: end }] = useDateRange();

  const { data, loading, error } = useQuery<
    XactPerMinGraphType,
    XactPerMinGraphVariables
  >(QUERY, {
    variables: {
      serverId,
      startTs: start.unix(),
      endTs: end.unix(),
    },
  });
  if (loading || error) {
    return (
      <Panel title="Assigned Transaction IDs per Minute">
        <Loading error={!!error} />
      </Panel>
    );
  }
  const xactPerSecData = data.getServerXactPerSec;

  const xidWraparoundInterval = calculateVacuumInterval(
    xactPerSecData?.autovacuumFreezeMaxAge,
    xactPerSecData?.vacuumFreezeMinAge,
    xactPerSecData?.xactPerSec as unknown as Datum[]
  );
  const mxidWraparoundInterval = calculateVacuumInterval(
    xactPerSecData?.autovacuumMultixactFreezeMaxAge,
    xactPerSecData?.vacuumMultixactFreezeMinAge,
    xactPerSecData?.multixactPerSec as unknown as Datum[]
  );

  const graphData = {
    xactPerMin: xactPerSecData?.xactPerSec.map((val) => {
      return [val[0], val[1] ? val[1] * 60 : val[1]];
    }),
    multixactPerMin: xactPerSecData?.multixactPerSec.map((val) => {
      return [val[0], val[1] ? val[1] * 60 : val[1]];
    }),
  };

  return (
    <Panel title="Assigned Transaction IDs per Minute">
      <>
        <PanelTable horizontal borders>
          <tbody>
            <tr>
              <th>
                Est. Frequency of Transaction ID Wraparound Autovacuums{" "}
                <Tip content="How often an anti-wraparound autovacuum needs to be run at minimum for each table on this server, based on (autovacuum_freeze_max_age - vacuum_freeze_min_age) divided by the average assigned transaction IDs per second." />
              </th>
              <td>{formatDurationPrecise(xidWraparoundInterval)}</td>
            </tr>
            <tr>
              <th>
                Est. Frequency of Multixact ID Wraparound Autovacuums{" "}
                <Tip content="How often an anti-wraparound autovacuum needs to be run at minimum for each table on this server, based on (autovacuum_multixact_freeze_max_age - vacuum_multixact_freeze_min_age) divided by the average assigned MultiXact IDs per second." />
              </th>
              <td>{formatDurationPrecise(mxidWraparoundInterval)}</td>
            </tr>
          </tbody>
        </PanelTable>
        <div className="border-t">
          <GraphSection loading={loading} error={error}>
            <DateRangeGraph
              axes={{
                left: {
                  tipFormat: (y: number): string => formatNumber(y, 2),
                },
              }}
              data={graphData as unknown as Data}
              series={[
                {
                  key: "xactPerMin",
                  label: "Assigned Transaction IDs per Minute",
                  tipLabel: "Transaction IDs per Min",
                },
                {
                  key: "multixactPerMin",
                  label: "Assigned Multixact IDs per Minute",
                  tipLabel: "Multixact IDs per Min",
                },
              ]}
            />
          </GraphSection>
        </div>
      </>
    </Panel>
  );
};

// Calculate the vacuum interval based on TPS, returns in milliseconds
function calculateVacuumInterval(
  maxAge: number,
  minAge: number,
  tpsData?: Datum[]
): number | null {
  if (tpsData === undefined || tpsData === null) {
    return null;
  }

  // Only pick up the value that is not undefined/null/0.
  // Technically 0 can be a proper value, though it's less important in this context.
  const filteredData = tpsData.map((data) => data[1]).filter((data) => !!data);
  if (filteredData.length === 0) {
    return null;
  }

  const avg =
    filteredData.reduce((prev, curr) => prev + curr, 0) / filteredData.length;
  if (avg === 0) {
    return null;
  }

  return ((maxAge - minAge) / avg) * 1000;
}

export default XactPerMinGraph;
