import React from "react";

import { Node } from "types/explain";

import styles from "./style.module.scss";

const Source: React.FunctionComponent<{ node: Node }> = ({ node }) => {
  const { extra: _ignored, Plans: _alsoIgnored, ...source } = node;
  const formattedSource = JSON.stringify(source, null, 2);

  return (
    <div>
      <pre className={styles.source}>{formattedSource}</pre>
    </div>
  );
};

export default Source;
