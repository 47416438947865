import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import QueryStatsTable from "components/QueryStatsTable";
import { SortDirection } from "types/graphql-global-types";

import QueryDataMissingHint from "../QueryDataMissingHint";

import {
  QueryTable as QueryTableType,
  QueryTableVariables,
} from "./types/QueryTable";
import QUERY from "./Query.graphql";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  startTs: number;
  endTs: number;
  statementTypes: Array<string>;
  searchTerm: null | string;
  compare: boolean;
}

const QueryTable: React.FunctionComponent<Props> = ({
  databaseId,
  startTs,
  endTs,
  statementTypes,
  searchTerm,
  compare,
}) => {
  const [sortOpts, setSortOpts] = React.useState<SortOptsType>({
    sortBy: "pctOfTotal",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    QueryTableType,
    QueryTableVariables
  >(QUERY, {
    variables: {
      databaseId,
      startTs,
      endTs,
      statementTypes,
      offset: 0,
      limit: 100,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      compare,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getQueryStats.length === 0) {
    return <QueryDataMissingHint databaseId={databaseId} />;
  }

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: QueryTableType,
        { fetchMoreResult }: { fetchMoreResult: QueryTableType }
      ): QueryTableType => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getQueryStats: [
            ...prev.getQueryStats,
            ...fetchMoreResult.getQueryStats,
          ],
        });
      },
    });
  };

  return (
    <QueryStatsTable
      databaseId={databaseId}
      serverId={data.getServerDetails.humanId}
      queries={data.getQueryStats}
      fetchMoreData={fetchMoreData}
      sortOpts={sortOpts}
      setSortOpts={setSortOpts}
      searchTerm={searchTerm}
      compare={compare}
    />
  );
};

export default QueryTable;
