import React from "react";
import FreezingStatsGrid from "./FreezingStatsGrid";
import XactPerMinGraph from "./XactPerMinGraph";

const FreezingStats: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  return (
    <>
      <XactPerMinGraph serverId={serverId} />
      <FreezingStatsGrid serverId={serverId} databaseId={databaseId} />
    </>
  );
};

export default FreezingStats;
