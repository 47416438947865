import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import classNames from "classnames";
import flowRight from "lodash/flowRight";
import { Link } from "react-router-dom";

import { useFeature } from "components/OrganizationFeatures";
import { useRoutes } from "utils/routes";
import withLoading from "decorators/withLoading";

import {
  DashboardCheckUp,
  DashboardCheckUp_getCheckUpStatus_issueGroups as IssueGroupType,
} from "./types/DashboardCheckUp";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

const CHECK_UP_STATUS_TO_STYLE = {
  critical: styles.checkUpItemCritical,
  warning: styles.checkUpItemWarning,
  info: styles.checkUpItemInfo,
  okay: styles.checkUpItemOkay,
};

type Props = {
  data: DashboardCheckUp;
  databaseId: string;
  organizationSlug: string;
};

const CheckUp: React.FunctionComponent<Props> = ({
  data,
  databaseId,
  organizationSlug,
}) => {
  const {
    databaseCheck,
    databaseChecks,
    organizationSubscriptionComparePlans,
  } = useRoutes();
  const { critical, issueGroups } = data.getCheckUpStatus;
  const enabled = useFeature("checkUp");

  return (
    <div>
      <h4>
        <Link to={databaseChecks(databaseId)}>
          {critical ? (
            <>
              <i
                className={classNames(
                  "fa",
                  "fa-exclamation-circle",
                  styles.checkUpIconRed
                )}
              ></i>
              <span className={styles.checkUpTitleRed}>
                Critical issues found
              </span>
            </>
          ) : (
            <>
              <i
                className={classNames(
                  "fa",
                  "fa-check-circle",
                  styles.checkUpIconGreen
                )}
              ></i>
              <span className={styles.checkUpTitleGreen}>Up &amp; Running</span>
            </>
          )}
        </Link>
      </h4>
      {enabled ? (
        issueGroups.map(
          (ig: IssueGroupType): React.ReactNode => (
            <Link
              className={classNames(CHECK_UP_STATUS_TO_STYLE[ig.status])}
              key={ig.message}
              to={
                ig.checkName === "all"
                  ? databaseChecks(databaseId)
                  : databaseCheck(databaseId, ig.groupName, ig.checkName)
              }
            >
              {ig.status == "okay" && <i className="fa fa-check"></i>}
              {ig.status != "okay" && <i className="fa fa-exclamation"></i>}
              {ig.message}
            </Link>
          )
        )
      ) : (
        <div className={styles.checkUpUnavailable}>
          <p>
            <strong>Check-Up</strong> is not included in your current plan.
            <br />
            <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
              Learn more about our Production plans.
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default flowRight(graphql(QUERY), withLoading)(CheckUp);
