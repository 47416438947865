import React, { useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Grid, { GridColumn, sortNullsLast } from "components/Grid";
import ImpactRenderer, { Impact } from "components/ImpactRenderer";
import Panel from "components/Panel";
import Popover from "components/Popover";

import {
  GetVacuumAdvisorData_getIssues as IssueType,
  GetVacuumAdvisorData_getServerDetails_databasesWithoutColumnStats as DatabaseWithoutColumnStatsType,
  GetVacuumAdvisorData_getVacuumInsightStatus as InsightStatusType,
} from "../types/GetVacuumAdvisorData";

import {
  formatBytes,
  formatNumber,
  formatPartialList,
  formatPercent,
  formatTimestampShorter,
} from "utils/format";

import { PostgresSettingType } from "components/ServerPostgresSettings";
import PostgresSettingsPanel from "components/PostgresSettingsPanel";
import Loading from "components/Loading";

import QUERY from "./Query.graphql";

import {
  GetVacuumAdvisorBloatData,
  GetVacuumAdvisorBloatDataVariables,
  GetVacuumAdvisorBloatData_getSchemaTableListWithVacuumSettings as BloatSchemaTableType,
} from "./types/GetVacuumAdvisorBloatData";
import moment from "moment";
import LooksGood from "../LooksGood";
import InsightDescription from "../InsightDescription";
import SingletonInsightPanel from "../SingletonInsightPanel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons";
import { useDateRange } from "components/WithDateRange";
import XminHorizonGraph from "components/XminHorizonGraph";
import ShowFlash from "components/ShowFlash";
import IssueSummaryBadge from "../IssueSummaryBadge";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";

const VacuumAdvisorBloat: React.FunctionComponent<{
  serverId: string;
  serverName: string;
  serverCreatedAt: moment.Moment;
  databaseId?: string;
  databasesWithoutColumnStats: DatabaseWithoutColumnStatsType;
  issues: IssueType[];
  settings: PostgresSettingType[];
  insightStatus: { [key: string]: InsightStatusType };
}> = ({
  serverId,
  serverName,
  serverCreatedAt,
  databaseId,
  databasesWithoutColumnStats,
  issues,
  settings,
  insightStatus,
}) => {
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery<
    GetVacuumAdvisorBloatData,
    GetVacuumAdvisorBloatDataVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
    },
  });

  const xminHorizonIssue = issues.find(
    (issue) => issue.checkGroupAndName === "vacuum/xmin_horizon"
  );
  const insufficientVacuumFreqIssues = issues.filter(
    (issue) =>
      issue.checkGroupAndName === "vacuum/insufficient_vacuum_frequency" &&
      (databaseId == null || issue.databaseId === databaseId)
  );

  const bloatsettings = sortBy(
    settings.filter((ps) => {
      return [
        "autovacuum_vacuum_scale_factor",
        "autovacuum_vacuum_threshold",
      ].includes(ps.name);
    }),
    (ps) => ps.name
  );
  const config =
    loading || error ? undefined : JSON.parse(data.getCheckConfig.settingsJson);
  const thresholdHours = parseInt(config?.["behind_hours"], 10);

  const xminHorizonPanel = (
    <SingletonInsightPanel
      key="vacuum/xmin_horizon"
      title="VACUUM Blocked by Xmin Horizon"
      checkGroupAndName="vacuum/xmin_horizon"
      serverName={serverName}
      serverCreatedAt={serverCreatedAt}
      issue={xminHorizonIssue}
      serverId={serverId}
      insightStatus={insightStatus["vacuum/xmin_horizon"]}
      summary={
        <>
          Detects when the xmin horizon on a server was assigned too far in the
          past, preventing vacuum from performing necessary cleanup of dead
          rows.
        </>
      }
      details={
        <XminHorizonGraph
          serverId={serverId}
          startTs={from.unix()}
          endTs={to.unix()}
          thresholdHours={thresholdHours}
        />
      }
    />
  );
  const insufficientVacuumPanel = (
    <InsufficientVacuumPanel
      key="vacuum/insufficient_vacuum_frequency"
      serverId={serverId}
      serverName={serverName}
      serverCreatedAt={serverCreatedAt}
      issues={insufficientVacuumFreqIssues}
      insightStatus={insightStatus["vacuum/insufficient_vacuum_frequency"]}
    />
  );
  // Re-order the panels based on the presence of insights: if an xmin horizon insight is
  // present, put that panel first. Otherwise, put the insufficient VACUUM panel first.
  const bloatIssuePanels: React.ReactNode[] = xminHorizonIssue
    ? [xminHorizonPanel, insufficientVacuumPanel]
    : [insufficientVacuumPanel, xminHorizonPanel];

  return (
    <>
      <VacuumAdvisorBloatNotices
        databaseId={databaseId}
        databasesWithoutColumnStats={databasesWithoutColumnStats}
      />
      {bloatIssuePanels}
      <PostgresSettingsPanel
        title="Bloat-Related Config Settings"
        serverId={serverId}
        settings={bloatsettings}
      />
      {loading || error ? (
        <Loading error={!!error} />
      ) : (
        <TableListPanel
          databaseId={databaseId}
          issues={insufficientVacuumFreqIssues}
          tables={data.getSchemaTableListWithVacuumSettings}
        />
      )}
    </>
  );
};

const VacuumAdvisorBloatNotices: React.FunctionComponent<{
  databasesWithoutColumnStats: DatabaseWithoutColumnStatsType;
  databaseId?: string;
}> = ({ databasesWithoutColumnStats, databaseId }) => {
  if (
    !databasesWithoutColumnStats ||
    databasesWithoutColumnStats.totalCount === 0
  ) {
    return null;
  }
  const dbs = databasesWithoutColumnStats.databases;
  if (databaseId && !dbs.find((db) => db.id === databaseId)) {
    return null;
  }
  const dbNames = dbs.map((db) => db.datname);
  const dbList = formatPartialList(
    dbNames,
    databasesWithoutColumnStats.totalCount
  );

  const warningText = databaseId ? (
    <>
      This database appears to be missing column stats monitoring helper
      functions, which can lead to incomplete VACUUM Advisor bloat stats and
      insights.
    </>
  ) : (
    <>
      Some databases on this server ({dbList}) appear to be missing column stats
      monitoring helper functions, which can lead to incomplete VACUUM Advisor
      bloat stats and insights.
    </>
  );

  return (
    <ShowFlash
      level="notice"
      msg={
        <>
          {warningText} Please review the relevant{" "}
          <a
            target="_blank"
            href="https://pganalyze.com/docs/install/troubleshooting/column_stats_helper"
          >
            troubleshooting documentation
          </a>
          .
        </>
      }
    />
  );
};

const InsufficientVacuumPanel: React.FunctionComponent<{
  serverId: string;
  serverCreatedAt: moment.Moment;
  serverName: string;
  issues: IssueType[];
  insightStatus: InsightStatusType;
}> = ({ serverId, serverName, serverCreatedAt, issues, insightStatus }) => {
  const { serverIssue } = useRoutes();
  const newServer = moment().diff(serverCreatedAt, "hours") < 24;

  let title = "Insufficient VACUUM Frequency";
  let content: React.ReactNode;
  if (issues.length > 0) {
    title = `${title} (${issues.length})`;
    const gridData = issues.map((issue) => {
      const details = JSON.parse(issue.detailsJson);

      const currentGrowthBytes = details.current.growth_bytes;

      const currentGrowthPct = details.current.growth_pct;
      const recommendationGrowthPct = details.recommendation.growth_pct;
      // N.B.: we're playing fast and loose with percentages here: this is
      // technically not a percent improvement, but an improvement of this
      // number of percentage points.
      const estImprovement = currentGrowthPct - recommendationGrowthPct;
      const estBloatBytes = details.current.estimated_bloat_bytes;

      const impact = deriveTableGrowthRecommendationImpact(
        currentGrowthPct - recommendationGrowthPct
      );
      const issueTable = issue.descriptionReferences.find(
        (ref) => ref.param === "table"
      ).name;
      return {
        issueId: issue.id,
        impact,
        tableId: issue.groupingKey.table,
        tableName: issueTable,
        tableGrowth: currentGrowthBytes,
        estImprovement,
        estBloatBytes,
      };
    });
    content = (
      <Grid
        className="grid-cols-[80px_3fr_2fr_2fr_2fr]"
        striped
        data={gridData}
        defaultSortBy="impact"
        columns={[
          {
            header: "Impact",
            field: "impact",
            defaultSortOrder: "desc",
            renderer: function Impact({ rowData: { issueId }, fieldData }) {
              return (
                <Popover
                  content={<div>Bloated tables are inefficient to query</div>}
                >
                  <Link
                    to={serverIssue(
                      serverId,
                      issueId,
                      "vacuum/insufficient_vacuum_frequency"
                    )}
                  >
                    <ImpactRenderer impact={fieldData} />
                  </Link>
                </Popover>
              );
            },
          },
          {
            header: "Table",
            field: "tableName",
            renderer: function BloatedTable({
              rowData: { issueId },
              fieldData,
            }) {
              return (
                <Link
                  to={serverIssue(
                    serverId,
                    issueId,
                    "vacuum/insufficient_vacuum_frequency"
                  )}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            header: "Table Growth",
            field: "tableGrowth",
            style: "number",
            renderer: function Growth({ fieldData }) {
              return formatBytes(fieldData);
            },
          },
          {
            header: "Improvement",
            field: "estImprovement",
            style: "number",
            renderer: function Improvement({ fieldData }) {
              return formatPercent(fieldData);
            },
          },
          {
            header: "Bloat",
            field: "estBloatBytes",
            style: "number",
            renderer: function BloatBytes({ fieldData }) {
              return formatBytes(fieldData);
            },
          },
        ]}
      />
    );
  } else if (!insightStatus && newServer) {
    content = (
      <div className="p-2.5 pt-0">
        <FontAwesomeIcon className="text-[#337ab7] mr-2" icon={faLightbulbOn} />
        <span className="font-medium">{serverName}</span> was recently
        onboarded; check back in 24 hours
      </div>
    );
  } else {
    content = (
      <LooksGood
        className="mx-[10px] mb-2"
        lastRunAt={insightStatus?.lastRunAt}
      />
    );
  }

  return (
    <Panel title={title}>
      <InsightDescription docsPath="/docs/checks/vacuum/insufficient_vacuum_frequency">
        Detects when insufficient VACUUM frequency leads to new bloat in a
        table. It analyzes the table statistics from the past seven days and
        calculates the amount of table growth that could have been avoided if
        VACUUM had been performed more frequently. Detection works best after
        removing existing bloat on the table, e.g. through pg_repack.
      </InsightDescription>
      {content}
    </Panel>
  );
};

function deriveTableGrowthRecommendationImpact(growthDeltaPct: number): Impact {
  if (growthDeltaPct > 90) {
    return 5;
  } else if (growthDeltaPct > 50) {
    return 4;
  } else if (growthDeltaPct > 30) {
    return 3;
  } else if (growthDeltaPct > 20) {
    return 2;
  } else {
    return 1;
  }
}

type BloatSchemaTableGridType = Omit<
  BloatSchemaTableType,
  | "estimatedTableBytes"
  | "idealTableBytes"
  | "lastVacuumAt"
  | "lastAutovacuumAt"
> & {
  isAffected: boolean;
  bloatPct: number;
  bloatBytes: number;
  lastVacuumedAt: number;
};

const TableListPanel: React.FunctionComponent<{
  databaseId: string;
  issues: IssueType[];
  tables: BloatSchemaTableType[];
}> = ({ databaseId, issues, tables }) => {
  const { databaseTableVacuum } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");

  const gridData = useMemo(() => {
    const tablesWithIssues = issues.reduce((ids, issue) => {
      issue.references.forEach((ref) => {
        ids.add(ref.referentId);
      });
      return ids;
    }, new Set());

    return tables.map((item) => {
      let lastVacuumedAt = null;
      if (item.lastAutovacuumAt == null && item.lastVacuumAt == null) {
        lastVacuumedAt = null;
      } else if (item.lastAutovacuumAt == null) {
        lastVacuumedAt = item.lastVacuumAt;
      } else if (item.lastVacuumAt == null) {
        lastVacuumedAt = item.lastAutovacuumAt;
      } else {
        lastVacuumedAt = Math.max(item.lastAutovacuumAt, item.lastVacuumAt);
      }

      let bloatBytes = null;
      if (item.estimatedTableBytes != null && item.idealTableBytes != null) {
        bloatBytes = Math.max(
          item.estimatedTableBytes - item.idealTableBytes,
          0
        );
      }

      const idealTableBytes = Math.max(item.idealTableBytes, 0);
      const estTableBytes = Math.max(item.estimatedTableBytes, 0);

      const bloatPct =
        bloatBytes == null
          ? null
          : idealTableBytes === 0 || estTableBytes === 0
          ? 0
          : bloatBytes / estTableBytes;

      return {
        ...item,
        isAffected: tablesWithIssues.has(item.tableId),
        bloatPct,
        bloatBytes,
        lastVacuumedAt,
      };
    });
  }, [tables, issues]);
  const filteredData = gridData.filter(
    makeFilter(searchTerm, "databaseName", "schemaName", "tableName")
  );

  const gridColumns: GridColumn<
    BloatSchemaTableGridType,
    keyof BloatSchemaTableGridType
  >[] = [
    { field: "schemaName", header: "Schema" },
    {
      field: "tableName",
      header: "Table",
      renderer: function TableCell({ rowData, fieldData }) {
        return (
          <Link to={databaseTableVacuum(rowData.databaseId, rowData.tableId)}>
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "tableAutovacuumVacuumScaleFactor",
      header: "Scale Factor",
      style: "number",
      renderer: VacuumScaleFactorRenderer,
    },
    {
      field: "tableAutovacuumVacuumThreshold",
      header: "Threshold",
      style: "number",
      renderer: VacuumThresholdRenderer,
    },
    {
      field: "sizeBytes",
      header: "Data Size",
      style: "number",
      toSortable: sortNullsLast,
      defaultSortOrder: "desc",
      renderer: function SizeBytes({ fieldData }) {
        return fieldData == null ? "n/a" : formatBytes(fieldData);
      },
    },
    {
      field: "bloatBytes",
      header: "Est. Bloat",
      style: "number",
      toSortable: sortNullsLast,
      defaultSortOrder: "desc",
      renderer: function SizeBytes({ fieldData }) {
        return fieldData == null ? "n/a" : formatBytes(fieldData);
      },
    },
    {
      field: "bloatPct",
      header: "Est. Bloat %",
      style: "number",
      toSortable: sortNullsLast,
      defaultSortOrder: "desc",
      renderer: function BloatPct({ fieldData }) {
        return fieldData == null ? "n/a" : formatPercent(fieldData, 0);
      },
    },
    {
      field: "lastVacuumedAt",
      header: "Last Vacuumed",
      toSortable: sortNullsLast,
      renderer: function LastVacuumed({ fieldData }) {
        return fieldData == null
          ? "n/a"
          : formatTimestampShorter(moment.unix(fieldData));
      },
    },
  ];

  let gridColsClass =
    "grid-cols-[minmax(3%,_1fr)_minmax(160px,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(3%,_1fr)_minmax(15%,_1fr)]";
  if (databaseId == null) {
    gridColsClass =
      "grid-cols-[minmax(3%,_1fr)_minmax(3%,_1fr)_minmax(160px,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(5%,_1fr)_minmax(3%,_1fr)_minmax(15%,_1fr)]";
    const dbColumn = {
      field: "databaseName",
      header: "Database",
    } as const;
    gridColumns.unshift(dbColumn);
  }

  return (
    <Panel
      title="Table Bloat Info"
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        className={gridColsClass}
        data={filteredData}
        columns={gridColumns}
        defaultSortBy="bloatBytes"
        pageSize={10}
      />
    </Panel>
  );
};

const DefaultSettingValue: React.FunctionComponent = () => {
  // align default values with numeric values: see discussion in https://github.com/pganalyze/pganalyze/pull/3137
  return <span className="-mr-[2px]">[default]</span>;
};

function VacuumScaleFactorRenderer({
  rowData,
  fieldData,
}: {
  rowData: { isAffected: boolean };
  fieldData: number | null;
}): React.ReactNode {
  const value = fieldData == null ? <DefaultSettingValue /> : fieldData;
  const { isAffected } = rowData;
  return <CheckedSetting isAffected={isAffected}>{value}</CheckedSetting>;
}

function VacuumThresholdRenderer({
  rowData,
  fieldData,
}: {
  rowData: { isAffected: boolean };
  fieldData: number | null;
}): React.ReactNode {
  const value =
    fieldData == null ? <DefaultSettingValue /> : formatNumber(fieldData);
  const { isAffected } = rowData;
  return <CheckedSetting isAffected={isAffected}>{value}</CheckedSetting>;
}

const CheckedSetting: React.FunctionComponent<{
  isAffected: boolean;
}> = ({ isAffected, children }) => {
  const severity = isAffected ? "info" : undefined;
  const badge = isAffected ? (
    <Popover
      content={
        <>
          Bloat on this table is increasing due to insufficient vacuuming: see
          Insufficient VACUUM Frequency insight above.
        </>
      }
    >
      <IssueSummaryBadge className="ml-2" severity={severity} />
    </Popover>
  ) : (
    <IssueSummaryBadge className="ml-2" severity={severity} />
  );
  return (
    <>
      {children}
      {badge}
    </>
  );
};

export default VacuumAdvisorBloat;
