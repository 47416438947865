import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Column, SortDirection } from "react-virtualized";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTitleSearch from "components/PanelTitleSearch";
import PageContent from "components/PageContent";
import SettingsNav from "components/SettingsNav";
import PanelVirtualTable from "components/PanelVirtualTable";
import { useRoutes } from "utils/routes";

import {
  ServerSettingsList as ServerSettingsListType,
  ServerSettingsListVariables,
  ServerSettingsList_getServers as ServerType,
} from "./types/ServerSettingsList";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

type RowDataType = ServerType & {
  collectorVersion: string;
};

const ServerSettingsList: React.FunctionComponent = () => {
  const { slug: organizationSlug } = useParams();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [showInactive, setShowInactive] = useState(false);
  const { data, loading, error } = useQuery<
    ServerSettingsListType,
    ServerSettingsListVariables
  >(QUERY, { variables: { organizationSlug } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  let servers = data.getServers;

  const titleSearch = (
    <>
      <div className={styles.showInactiveControl}>
        <input
          type="checkbox"
          checked={showInactive}
          id="show_inactive"
          onChange={(evt) => setShowInactive(evt.target.checked)}
        />
        <label htmlFor="show_inactive">
          Show servers with no recent statistics data
        </label>
      </div>
      <PanelTitleSearch
        value={searchTerm}
        onChange={(newTerm: string) => {
          setSearchTerm(newTerm);
        }}
      />
    </>
  );

  servers = servers.map((s: ServerType): RowDataType => {
    return {
      ...s,
      collectorVersion: s.lastSnapshot?.collectorVersion,
    };
  });

  if (searchTerm) {
    servers = servers.filter((s: ServerType): boolean =>
      s.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  if (!showInactive) {
    servers = servers.filter((s: ServerType): boolean => s.hasRecentSnapshot);
  }

  return (
    <PageContent
      title="Settings"
      pageCategory="servers"
      pageName="edit"
      featureNav={<SettingsNav />}
    >
      <Panel title="All Servers" secondaryTitle={titleSearch}>
        <PanelSection>Select a server to view settings:</PanelSection>
        <PanelVirtualTable
          data={servers}
          initialSortBy="serverName"
          initialSortDirection={SortDirection.ASC}
        >
          <Column
            dataKey="serverName"
            label="Server"
            width={190}
            flexGrow={1}
            cellDataGetter={({
              rowData,
            }: {
              rowData: RowDataType;
            }): RowDataType => rowData}
            cellRenderer={({ cellData }) => (
              <ServerNameCell rowData={cellData} />
            )}
          />
          <Column dataKey="displaySystemType" label="System type" width={200} />
          <Column
            dataKey="collectorVersion"
            label="Collector version"
            width={200}
          />
          <Column
            dataKey="hasRecentSnapshot"
            label="Has Recent Snapshot?"
            width={200}
            cellRenderer={({ cellData }): React.ReactNode =>
              cellData ? (
                <>
                  <i
                    className="circle okay"
                    key="icon"
                    title="Recently received statistics data"
                  />
                  Yes
                </>
              ) : (
                <>
                  <i
                    className="circle"
                    key="icon"
                    title="No recent statistics data"
                  />
                  No
                </>
              )
            }
          />
        </PanelVirtualTable>
      </Panel>
    </PageContent>
  );
};

const ServerNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { serverEdit } = useRoutes();
  return (
    <span>
      <Link to={serverEdit(rowData.humanId)}>{rowData.name}</Link>
    </span>
  );
};

export default ServerSettingsList;
