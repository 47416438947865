import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import flowRight from "lodash/flowRight";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";

import { formatNumber } from "utils/format";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import withLoading from "decorators/withLoading";

import { ConnectionsPerDatabase_getBackendCountsByDatabase as DatabaseAndCountsType } from "./types/ConnectionsPerDatabase";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
  connectionLimit: number | null | undefined;
} & DatabaseAndCountsType;

type Props = {
  data: {
    getBackendCountsByDatabase: Array<DatabaseAndCountsType>;
  };
  serverId: string;
  timestamp: number;
};

type State = {
  searchTerm: string | null | undefined;
};

class ConnectionsPerDatabase extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: null,
    };
  }

  render() {
    const secondaryTitle = (
      <div className={styles.secondaryTitle}>
        <span className={styles.secondaryTitleTimestamp}>
          {moment.unix(this.props.timestamp).format("ll LTS z")}
        </span>
        <PanelTitleSearch
          value={this.state.searchTerm}
          onChange={(searchTerm: string) => {
            this.setState({ searchTerm });
          }}
        />
      </div>
    );

    return (
      <Panel title="Connections Per Database" secondaryTitle={secondaryTitle}>
        {this.renderTable()}
      </Panel>
    );
  }

  renderTable(): React.ReactNode {
    let data = this.props.data.getBackendCountsByDatabase.map(
      (inputData: DatabaseAndCountsType): RowDataType => {
        return {
          ...inputData,
          databaseId: (inputData.database && inputData.database.id) || null,
          databaseName:
            (inputData.database && inputData.database.datname) || null,
          connectionLimit:
            (inputData.database && inputData.database.connectionLimit) || null,
        };
      }
    );

    if (this.state.searchTerm) {
      data = data.filter(
        (di: RowDataType): boolean =>
          (di.database &&
            di.database.datname
              .toLowerCase()
              .indexOf(this.state.searchTerm.toLowerCase()) !== -1) ||
          false
      );
    }

    data = sortBy(data, (b: RowDataType): number => -b.totalClientCount);

    return (
      <PanelVirtualTable
        data={data}
        initialSortBy="totalClientCount"
        initialSortDirection={SortDirection.DESC}
      >
        <Column
          dataKey="databaseName"
          label="Database"
          width={150}
          flexGrow={1}
          cellDataGetter={({
            rowData,
          }: {
            rowData: RowDataType;
          }): RowDataType => rowData}
          cellRenderer={({ cellData }) => (
            <DatabaseNameCell rowData={cellData} />
          )}
        />
        <Column
          dataKey="waitingForLockCount"
          label="Waiting for Lock"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={120}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="activeCount"
          label="Active"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={100}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="idleInTransactionCount"
          label="Idle in TX"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={100}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="idleCount"
          label="Idle"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={100}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="totalClientCount"
          label="Total"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={100}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="connectionLimit"
          label="Limit"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={100}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
      </PanelVirtualTable>
    );
  }
}

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseBackends } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseBackends(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
      {!rowData.database && <span>unknown</span>}
    </span>
  );
};

export default flowRight(graphql(QUERY), withLoading)(ConnectionsPerDatabase);
