import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import sortBy from "lodash/sortBy";

import { formatNumber, formatMs, formatPercent } from "utils/format";
import { useRoutes } from "utils/routes";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import PanelSection from "components/PanelSection";

import {
  ServerQueryOverview,
  ServerQueryOverview_getQueryOverviewStatsByDatabase as SummaryType,
} from "../types/ServerQueryOverview";
import styles from "./style.module.scss";

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
} & SummaryType;

type Props = {
  data: ServerQueryOverview;
};

const ServerQueryOverviewTable: React.FunctionComponent<Props> = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  let tableData = data.getQueryOverviewStatsByDatabase.map(
    (inputData: SummaryType): RowDataType => {
      return {
        ...inputData,
        databaseId: (inputData.database && inputData.database.id) || null,
        databaseName:
          (inputData.database && inputData.database.datname) || null,
      };
    }
  );

  if (searchTerm) {
    tableData = tableData.filter(
      (di: RowDataType): boolean =>
        di.database?.datname
          ?.toLowerCase()
          ?.includes(searchTerm.toLowerCase()) || false
    );
  }

  tableData = sortBy(
    tableData,
    (b: RowDataType): number => -b.queriesPerSecond
  );

  return (
    <>
      <PanelSection>
        <strong>Select a database to show detailed query statistics:</strong>
        <input
          placeholder="Search..."
          type="text"
          name="filter[search]"
          className={styles.filterSearch}
          onChange={(evt) => setSearchTerm(evt.currentTarget.value)}
        />
      </PanelSection>
      <PanelVirtualTable
        data={tableData}
        initialSortBy="queriesPerSecond"
        initialSortDirection={SortDirection.DESC}
      >
        <Column
          dataKey="databaseName"
          label="Database"
          width={150}
          flexGrow={1}
          cellDataGetter={({
            rowData,
          }: {
            rowData: RowDataType;
          }): RowDataType => rowData}
          cellRenderer={({ cellData }) => (
            <DatabaseNameCell rowData={cellData} />
          )}
        />
        <Column
          dataKey="avgTime"
          label="Avg Time (ms)"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={110}
          cellRenderer={({ cellData }) => formatMs(cellData)}
        />
        <Column
          dataKey="queriesPerSecond"
          label="Queries / Second"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={150}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="pctOfTotalIo"
          label="% of All I/O"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={95}
          cellRenderer={({ cellData }) =>
            (cellData !== null && formatPercent(cellData / 100.0)) || "n/a"
          }
        />
        <Column
          dataKey="pctOfTotal"
          label="% of All Runtime"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          defaultSortDirection={SortDirection.DESC}
          width={140}
          cellRenderer={({ cellData }) => formatPercent(cellData / 100.0)}
        />
      </PanelVirtualTable>
    </>
  );
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseQueries } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseQueries(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
    </span>
  );
};

export default ServerQueryOverviewTable;
