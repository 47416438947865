export const PERMISSION_FULL = "PERMISSION_FULL";
export const PERMISSION_RESTRICTED = "PERMISSION_RESTRICTED";
export const PERMISSION_NONE = "PERMISSION_NONE";

export type GroupedPermission =
  | "PERMISSION_FULL"
  | "PERMISSION_RESTRICTED"
  | "PERMISSION_NONE";

export type PermissionType = {
  id: string;
  restricted: boolean;
  view: boolean;
  modify: boolean;
  billing: boolean;
  api: boolean;
  manage: boolean;
};

export const groupPermissions = (
  permissions: PermissionType[],
  field: string
): GroupedPermission => {
  return permissions.reduce((result: GroupedPermission, permission) => {
    if (permission[field] && result !== PERMISSION_FULL) {
      if (permission.restricted) {
        return PERMISSION_RESTRICTED;
      } else {
        return PERMISSION_FULL;
      }
    } else {
      return result;
    }
  }, PERMISSION_NONE);
};
