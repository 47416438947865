---
title: 'Step 4: Install the Collector'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

## Installing the collector with Docker

This guide assumes that you already have the Docker daemon running on your EC2
instance.

You can then pull the Docker image like this:

```
docker pull quay.io/pganalyze/collector:stable
```

<Link className="btn btn-success" to="05_configure_the_collector_docker">
  Proceed to Step 5: Configure the Collector
</Link>
