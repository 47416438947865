---
title: 'Step 4: Configure the Collector'
install_track_title: Installation Guide (Azure Database for PostgreSQL)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import styles from '../../style.module.scss'

import imgLogoUbuntu from '../../images/logo_ubuntu.png'
import imgLogoRedhat from '../../images/logo_redhat.png'
import imgLogoDocker from '../../images/docker.png'

export const SelectCollectorPlatform = () => {
  return (
    <div className={styles.installChoice}>
      <Link
        className={styles.installChoiceStep}
        to="04_configure_the_collector_package"
      >
        <img src={imgLogoUbuntu} />
        Ubuntu &amp; Debian
      </Link>
      <Link
        className={styles.installChoiceStep}
        to="04_configure_the_collector_package"
      >
        <img src={imgLogoRedhat} />
        RHEL, CentOS &amp; Fedora
      </Link>
      <Link className={styles.installChoiceStep} to="04_configure_the_collector_docker">
        <img src={imgLogoDocker} />
        Docker
      </Link>
    </div>
  )
}

To continue, we need to know where your Postgres is running:

<SelectCollectorPlatform />

<br />
If your Postgres runs somewhere else, <a href="mailto:support@pganalyze.com">
  let us know
</a> & we're happy to help.
