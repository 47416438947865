import React from "react";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import { Data } from "components/Graph/util";
import { AreaSeries } from "components/Graph/Series";
import { useDateRange } from "components/WithDateRange";
import { formatPercent } from "utils/format";

import {
  CPUAndLoadAvg as CPUAndLoadAvgType,
  CPUAndLoadAvgVariables,
} from "./types/CPUAndLoadAvg";
import QUERY from "./Query.graphql";

import {
  CPUAndLoadAvgCPUUtilizationOnly as CPUAndLoadAvgCPUUtilizationOnlyType,
  CPUAndLoadAvgCPUUtilizationOnlyVariables,
} from "./types/CPUAndLoadAvgCPUUtilizationOnly";
import QUERY_CPU_UTILIZATION_ONLY from "./Query.cpuUtilizationOnly.graphql";

import {
  CPUAndLoadAvgLoadAvgOnly as CPUAndLoadAvgLoadAvgOnlyType,
  CPUAndLoadAvgLoadAvgOnlyVariables,
} from "./types/CPUAndLoadAvgLoadAvgOnly";
import QUERY_LOAD_AVG_ONLY from "./Query.loadAvgOnly.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";

type Props = {
  amazonRdsEnhanced: boolean;
  serverId: string;
  systemType: string;
};

const CPUAndLoadAvg: React.FunctionComponent<Props> = ({
  amazonRdsEnhanced,
  serverId,
  systemType,
}) => {
  if (systemType == "amazon_rds" && !amazonRdsEnhanced) {
    return <CPUAndLoadAvgCPUUtilizationOnly serverId={serverId} />;
  }

  if (systemType == "heroku") {
    return <CPUAndLoadAvgLoadAvgOnly serverId={serverId} />;
  }

  return <CPUAndLoadAvgFull serverId={serverId} />;
};

const CPUAndLoadAvgFull: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    CPUAndLoadAvgType,
    CPUAndLoadAvgVariables
  >(QUERY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSystemStats == null;

  return (
    <>
      <Panel title="CPU">
        <GraphSection noData={noData} loading={loading} error={error}>
          <DateRangeGraph
            data={data?.getSystemStats as unknown as Data}
            axes={{
              left: {
                format: (y: number): string => formatPercent(y, 0),
                tipFormat: (y: number): string => formatPercent(y),
              },
            }}
            series={[
              {
                key: "cpuIowait",
                type: AreaSeries,
                label: "IO Wait",
              },
              { key: "cpuUser", type: AreaSeries, label: "User" },
              {
                key: "cpuSystem",
                type: AreaSeries,
                label: "System",
              },
              {
                key: "cpuIrq",
                type: AreaSeries,
                label: "Interrupts",
              },
              {
                key: "cpuSteal",
                type: AreaSeries,
                label: "Steal",
              },
              { key: "cpuIdle", type: AreaSeries, label: "Idle" },
            ]}
          />
        </GraphSection>
      </Panel>
      <Panel title="Load Average">
        <GraphSection noData={noData} loading={loading} error={error}>
          <DateRangeGraph
            data={data?.getSystemStats as unknown as Data}
            axes={{
              left: {
                format: (y: number): string => y.toFixed(2),
              },
            }}
            series={[
              {
                key: "numCores",
                label: "Number of CPU Cores",
                tipLabel: "Cores",
              },
              {
                key: "loadAvg1min",
                label: "Load Avg (1 min)",
                tipLabel: "1m Load",
              },
              {
                key: "loadAvg5min",
                label: "Load Avg (5 min)",
                tipLabel: "5m Load",
              },
              {
                key: "loadAvg15min",
                label: "Load Avg (15 min)",
                tipLabel: "15m Load",
              },
            ]}
          />
        </GraphSection>
      </Panel>
    </>
  );
};

const CPUAndLoadAvgCPUUtilizationOnly: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    CPUAndLoadAvgCPUUtilizationOnlyType,
    CPUAndLoadAvgCPUUtilizationOnlyVariables
  >(QUERY_CPU_UTILIZATION_ONLY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSystemStats == null;

  return (
    <Panel title="CPU">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: (y: number): string => formatPercent(y, 0),
              tipFormat: (y: number): string => formatPercent(y),
            },
          }}
          series={[
            {
              key: "cpuUtilization",
              type: AreaSeries,
              label: "Utilization",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

const CPUAndLoadAvgLoadAvgOnly: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    CPUAndLoadAvgLoadAvgOnlyType,
    CPUAndLoadAvgLoadAvgOnlyVariables
  >(QUERY_LOAD_AVG_ONLY, {
    variables: {
      serverId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const noData = !loading && !error && data.getSystemStats == null;

  return (
    <Panel title="Load Average">
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: (y: number): string => y.toFixed(2),
            },
          }}
          series={[
            {
              key: "loadAvg1min",
              label: "Load Avg (1 min)",
              tipLabel: "1m Load",
            },
            {
              key: "loadAvg5min",
              label: "Load Avg (5 min)",
              tipLabel: "5m Load",
            },
            {
              key: "loadAvg15min",
              label: "Load Avg (15 min)",
              tipLabel: "15m Load",
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

export default CPUAndLoadAvg;
