import React from "react";

import styles from "./style.module.scss";

import pdLogo from "./pd.png";
import slackLogo from "./slack.png";
import poweredByStripe from "./powered_by_stripe.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faDatabase,
  faInfoCircle,
  faTable,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";

export const SlackLogo: React.FunctionComponent = () => (
  <img alt="Slack logo" style={{ width: 18, height: 18 }} src={slackLogo} />
);

export const PagerDutyLogo: React.FunctionComponent = () => (
  <img
    alt="PagerDuty logo"
    className={styles.pdIcon}
    style={{ width: 18, height: 18 }}
    src={pdLogo}
  />
);

export const PoweredByStripeLogo: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ style }) => (
  <a href="https://stripe.com" target="_blank">
    <img
      alt="Powered by Stripe logo"
      className={styles.stripeIcon}
      src={poweredByStripe}
      style={{ ...style, height: 24 }}
    />
  </a>
);

type IconProps = Omit<React.ComponentProps<typeof FontAwesomeIcon>, "icon">;

export const InfoIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const SettingsIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faWrench}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const TableIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faTable}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

export const ServerIcon: React.FunctionComponent<IconProps> = (props) => {
  const { className: propClassName, ...rest } = props;
  return (
    <FontAwesomeIcon
      icon={faDatabase}
      className={classNames(propClassName, "text-slate-600", "text-[12px]")}
      {...rest}
    />
  );
};

type ToggleIconProps = IconProps & {
  expanded: boolean;
};

export const ExpandToggle: React.FunctionComponent<ToggleIconProps> = ({
  expanded,
  ...rest
}) => {
  return (
    <FontAwesomeIcon {...rest} icon={expanded ? faAngleDown : faAngleRight} />
  );
};
