import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";

import { UserPreferences } from "./preferences";
import { LocalStorageSchema, Converters } from "./localStorage";
import { RouteStateContext } from "./routeState";

export function useUnmounted(): { current: boolean } {
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);
  return unmounted;
}

export function usePrevious<T>(value: T): T | undefined {
  const [previous, setPrevious] = useState<T | undefined>(undefined);
  useEffect(() => {
    setPrevious(value);
  }, [value]);
  return previous;
}

export type Dimensions = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
};

export function useDimensions<T extends Element>(): [
  (elem: T) => void,
  Dimensions | undefined
] {
  const resizeTimeoutMillis = 100;
  const [elem, setElem] = useState<T>();
  const [dimensions, setDimensions] = useState<Dimensions | undefined>(
    undefined
  );

  useLayoutEffect(() => {
    let lastResize: number | undefined;
    const handleResize = () => {
      if (lastResize) {
        window.clearTimeout(lastResize);
      }
      lastResize = window.setTimeout(() => {
        if (!elem) {
          return;
        }
        const bounds = elem.getBoundingClientRect();
        setDimensions({
          top: bounds.top,
          right: bounds.right,
          bottom: bounds.bottom,
          left: bounds.left,
          width: bounds.width,
          height: bounds.height,
        });
        lastResize = undefined;
      }, resizeTimeoutMillis);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (lastResize) {
        window.clearTimeout(lastResize);
      }
    };
  }, [elem]);
  return [setElem, dimensions];
}

export const useTimeout = (
  fn: (...args: any[]) => any,
  timeout: number,
  deps: React.DependencyList
) => {
  // since any timeout callback will need to be wrapped in useCallback to be
  // useful, we do that for the caller, so we need to disable it as a callback dep
  const cb = useCallback(fn, deps); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const timeoutId = setTimeout(cb, timeout);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [cb, timeout]);
};

export const useInterval = (
  fn: (...args: any[]) => any,
  interval: number,
  deps: React.DependencyList
) => {
  // same reasoning as useTimeout: the callback will need to be wrapped in useCallback to
  // be useful, so we do that for the caller, so we need to disable it as a callback dep
  const cb = useCallback(fn, deps); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const intervalId = setInterval(cb, interval);
    return () => {
      clearInterval(intervalId);
    };
  }, [cb, interval]);
};

// Returns a function that, when invoked, only executes the provided function
// after a timeout elapses. If invoked again before the timeout elapses, the
// previous invocation is canceled and the timeout is reset. If a different
// function (by reference) is passed in, any previous pending invocation is
// canceled, so callers should use useCallback as appropriate.
export const useDebounced = <T extends unknown[]>(
  fn: (...args: T) => void,
  timeout: number
): ((...args: T) => void) => {
  const lastTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const wrapper = useCallback(
    (...args: T) => {
      if (lastTimeout.current) {
        clearTimeout(lastTimeout.current);
      }
      lastTimeout.current = setTimeout(fn, timeout, ...args);
    },
    [fn, timeout]
  );
  // N.B.: This runs when `wrapper` changes and is here to avoid previous timeouts
  // from previous wrappers firing when the wrapper is changed. However, this effect
  // runs after the host component first renders, so if the wrapper is invoked in
  // the course of that render (immediately after the call to the hook), or during
  // layout, it may end up cancelled before it's actually executed. This is not an
  // intended use case, so it's an acceptable limitation for now.
  useEffect(() => {
    if (lastTimeout.current) {
      clearTimeout(lastTimeout.current);
    }
  }, [wrapper]);

  return timeout > 0 ? wrapper : fn;
};

export const useCycle = (stepCount: number, interval: number): number => {
  const [curr, setCurr] = useState(0);
  useInterval(
    () => {
      setCurr((val) => (val + 1) % stepCount);
    },
    interval,
    [stepCount]
  );
  return curr;
};

type RouteUpdateOpts = { replace?: boolean };
type RouteState<T> = [
  T | undefined,
  (newValue: T | undefined, opts?: RouteUpdateOpts) => void
];

export const useRouteSearchState = <T>(args: {
  key: string;
  // N.B.: *not* URL-encoded
  decode: (encoded: string) => T;
  // N.B.: also *not* URL-encoded
  encode: (value: T) => string;
}): RouteState<T> => {
  const { key, encode, decode } = args;
  const [rawRouteState, updateRawRouteState] = useContext(RouteStateContext);

  const strVal = rawRouteState.search[key];
  const value = useMemo(() => {
    if (strVal == null || strVal === "") {
      return undefined;
    }

    return decode(strVal);
  }, [decode, strVal]);
  const updater = useCallback(
    (newVal: T | undefined, opts?: RouteUpdateOpts): void => {
      const encoded = newVal && encode(newVal);
      if (encoded === strVal) {
        return;
      }
      updateRawRouteState({ search: { [key]: encoded }, ...opts });
    },
    [encode, key, strVal, updateRawRouteState]
  );

  return [value, updater];
};

export const useRouteHashState = <T>(args: {
  decode: (encoded: string) => T;
  encode: (value: T) => string;
}): RouteState<T> => {
  const { encode, decode } = args;
  const [rawRouteState, updateRawRouteState] = useContext(RouteStateContext);
  const strVal = rawRouteState.hash;
  const value = useMemo(() => {
    if (strVal == null || strVal === "") {
      return undefined;
    }

    return decode(strVal);
  }, [decode, strVal]);

  const updater = useCallback(
    (newVal: T | undefined, opts?: RouteUpdateOpts): void => {
      const encoded = newVal && encode(newVal);
      if (encoded === strVal) {
        return;
      }
      updateRawRouteState({ hash: encoded, ...opts });
    },
    [encode, strVal, updateRawRouteState]
  );

  return [value, updater];
};

// Like useState, but backed by LocalStorage. Since we know what we have
// stored, usage must conform to the LocalStorageSchema type. Each keyed
// subsection of localStorage is versioned, may evolve independently, and
// must decode previous values via an explicit Converter.
//
// N.B.: This will *not* update if this hook is used for the same key
// in two different places, or if the localStorage API is used directly
type LSSection<K extends keyof LocalStorageSchema> =
  | LocalStorageSchema[K]["current"]
  | undefined;

export const useLocalStorage = <K extends keyof LocalStorageSchema>(
  key: K
): [
  LSSection<K>,
  (update: LSSection<K> | ((newVal: LSSection<K>) => LSSection<K>)) => void
] => {
  const prefixedKey = "_pga_" + key;
  const [stored, setStored] = useState<LSSection<K>>(() => {
    try {
      const encoded = window.localStorage.getItem(prefixedKey);
      if (encoded === null) {
        return undefined;
      }
      const decoded = JSON.parse(encoded) as LocalStorageSchema[K]["known"];
      const converted = Converters[key].convert(decoded);
      return converted;
    } catch (error) {
      console.error("could not load localStorage value", error);
      return undefined;
    }
  });

  const updateStored = (
    update: LSSection<K> | ((newVal: LSSection<K>) => LSSection<K>)
  ) => {
    try {
      const newValue = typeof update === "function" ? update(stored) : update;
      setStored(newValue);
      if (newValue === undefined) {
        window.localStorage.removeItem(prefixedKey);
        return;
      }

      const version = Converters[key].latest;
      const encoded = JSON.stringify({ ...newValue, version });
      window.localStorage.setItem(prefixedKey, encoded);
    } catch (error) {
      console.error("could not save localStorage value", error);
    }
  };

  return [stored, updateStored];
};

// Retrieve a specific preferences section and its updater. Note that the updater
// is expected to provide the full section value: the new value is not merged with
// the existing one.
export const useUserPreferences = <K extends keyof UserPreferences>(
  section: K
): [
  Partial<UserPreferences[K]>,
  (newValue: Partial<UserPreferences[K]>) => void
] => {
  const [prefs, setPrefs] = useLocalStorage("preferences");
  const updatePrefs = (newValue: Partial<UserPreferences[K]>): void => {
    // TODO: not sure why this cast is necessary: with strictNullChecks enabled,
    // tsc seems to infer the type of `merged` as `{}`.
    const merged = { ...prefs, [section]: newValue } as UserPreferences;
    setPrefs(merged);
  };
  const prefsSection = prefs?.[section] ?? {};
  return [prefsSection, updatePrefs];
};
