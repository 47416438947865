import React, { useMemo } from "react";

import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";

import {
  formatBytes,
  formatDuration,
  formatNumber,
  formatTimeAgo,
  formatTimestampLong,
  formatTimestampShort,
} from "utils/format";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelVirtualTable from "components/PanelVirtualTable";
import SQL from "components/SQL";
import GraphSection from "components/Graph/GraphSection";
import Graph from "components/Graph";

import { Data } from "components/Graph/util";

import SLOW_QUERY_STATS_QUERY from "./Query.slowQueryStats.graphql";

import styles from "./style.module.scss";

import {
  IssueDetail_getIssue as IssueType,
  IssueDetail_getIssue_references_referent_SchemaIndex as IssueReferenceIndex,
  IssueDetail_getIssue_references_referent_Backend as IssueReferenceBackend,
  IssueDetail_getIssue_references_referent_Query as IssueReferenceQuery,
  IssueDetail_getIssue_references_referent_VacuumRun as IssueReferenceVacuumRun,
  IssueDetail_getIssue_references_referent_SchemaTable as IssueReferenceTable,
} from "../types/IssueDetail";
import { ThresholdSeries } from "components/Graph/Series";
import ExpandableSQL from "components/ExpandableSQL";
import { useRoutes } from "utils/routes";
import { retention } from "utils/limits";
import Grid from "components/Grid";
import { formatDurationPrecise } from "utils/format";
import Tip from "components/Tip";
import { labelXminHeldBackBy } from "utils/vacuum";
import XminHorizonGraph from "components/XminHorizonGraph";
import FreezingStatsGraph from "components/VacuumAdvisor/FreezingStats/FreezingStatsGraph";
import capitalize from "lodash/capitalize";

type Props = {
  issue: IssueType;
  checkGroup: string;
  checkName: string;
};

const IssueReferencesPanel: React.FunctionComponent<Props> = ({
  issue,
  checkGroup,
  checkName,
}) => {
  if (issue.references.length === 0) {
    return null;
  }

  switch (checkGroup + "/" + checkName) {
    case "connections/active_query":
      return <ActiveQueryReferencesPanel issue={issue} />;
    case "connections/idle_transaction":
      return <IdleTransactionReferencesPanel issue={issue} />;
    case "connections/blocking_query":
      return <BlockingQueryReferencesPanel issue={issue} />;
    case "queries/slowness":
      return <SlowQueryReferencesPanel issue={issue} />;
    case "schema/index_invalid":
      return <IndexInvalidReferencesPanel issue={issue} />;
    case "schema/index_unused":
      return <IndexUnusedReferencesPanel issue={issue} />;
    case "settings/enable_features":
      return <EnableFeaturesReferencesPanel issue={issue} />;
    case "settings/stats":
      return <StatsReferencesPanel issue={issue} />;
    case "vacuum/inefficient_index_phase":
      return <VacuumInefficientIndexPhaseReferencesPanel issue={issue} />;
    case "vacuum/xmin_horizon":
      return <VacuumXminHorizonReferencesPanel issue={issue} />;
    case "vacuum/txid_wraparound":
      return <VacuumTxidWraparoundReferencesPanel issue={issue} />;
    case "vacuum/mxid_wraparound":
      return <VacuumMxidWraparoundReferencesPanel issue={issue} />;
    default:
      return null;
  }
};

const ActiveQueryReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const refData = useMemo(() => {
    return issue.references.map((ref) => {
      const pid = (ref.referent as IssueReferenceBackend).pid;
      const path = ref.url;
      const details = JSON.parse(ref.detailsJson);
      const queryText = details.query_text ?? ref.queryText;

      return {
        pid,
        path,
        queryAge: details.query_age,
        applicationName: details.application_name,
        queryText,
      };
    });
  }, [issue.references]);
  return (
    <Panel title="Queries">
      <Grid
        className="grid-cols-[80px_100px_140px_1fr]"
        striped
        data={refData}
        columns={[
          {
            field: "pid",
            header: "PID",
            renderer: function PIDCell({ rowData }) {
              return <Link to={rowData.path}>{rowData.pid}</Link>;
            },
          },
          {
            field: "queryAge",
            header: "Duration",
            renderer: ({ fieldData }) => formatDuration(fieldData),
          },
          { field: "applicationName", header: "Application" },
          {
            field: "queryText",
            header: "SQL",
            renderer: function QueryCell({ fieldData }) {
              return fieldData ? (
                <ExpandableSQL sql={fieldData} />
              ) : (
                "<query text not available>"
              );
            },
            tip: "The query may be truncated based on track_activity_query_size",
          },
        ]}
      />
    </Panel>
  );
};

const IdleTransactionReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const refData = useMemo(() => {
    return issue.references.map((ref) => {
      const pid = (ref.referent as IssueReferenceBackend).pid;
      const path = ref.url;
      const details = JSON.parse(ref.detailsJson);
      const xactIdleAge = details.xact_idle_age;

      return {
        pid,
        path,
        xactStartTime: xactIdleAge && moment().subtract(xactIdleAge, "seconds"),
      };
    });
  }, [issue.references]);

  return (
    <Panel title="Idle transactions">
      <PanelVirtualTable data={refData}>
        <Column
          dataKey="pid"
          label="PID"
          width={60}
          cellRenderer={({ rowData }) => {
            return <Link to={rowData.path}>{rowData.pid}</Link>;
          }}
        />
        <Column
          dataKey="xactStartTime"
          label="Transaction start time"
          width={180}
          cellRenderer={({ cellData }) => {
            return formatTimestampLong(cellData);
          }}
        />
        <Column
          dataKey="xactStartTime"
          label="Transaction started"
          width={300}
          flexGrow={1}
          cellRenderer={({ cellData }) => {
            return capitalize(formatTimeAgo(cellData));
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const BlockingQueryReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const { databaseBackends, databaseBackendsAtTime } = useRoutes();
  let databaseBackendsPath = databaseBackends(issue.databaseId);
  if (issue.state == "resolved") {
    // When the issue is already resolved, try to show the link with
    // the time that the issue was happening
    const from = issue.activity.at(0).occurredAt;
    // The last (-1) one is resolved, so exclude that timestamp
    const to = issue.activity.at(-2).occurredAt;
    // Don't set the time if the range is already out of the retention
    if (moment().diff(to, "minutes") < retention.connections.asMinutes()) {
      databaseBackendsPath = databaseBackendsAtTime(issue.databaseId, from, to);
    }
  }
  const refData = useMemo(() => {
    return issue.references.map((ref) => {
      const pid = (ref.referent as IssueReferenceBackend).pid;
      const path = ref.url;
      const details = JSON.parse(ref.detailsJson);
      const queryText = details.query_text ?? ref.queryText;

      return {
        pid,
        path,
        blockedAge: details.blocked_age,
        blockedCount: details.blocked_count,
        applicationName: details.application_name,
        queryText,
      };
    });
  }, [issue.references]);
  return (
    <Panel title="Queries">
      <Grid
        className="grid-cols-[68px_100px_130px_120px_1fr]"
        striped
        data={refData}
        columns={[
          {
            field: "pid",
            header: "PID",
            renderer: function PIDCell({ rowData }) {
              return <Link to={rowData.path}>{rowData.pid}</Link>;
            },
          },
          {
            field: "blockedCount",
            header: "Blocking",
            renderer: function BlockingCell({ fieldData }) {
              return <Link to={databaseBackendsPath}>{fieldData} queries</Link>;
            },
          },
          {
            field: "blockedAge",
            header: "Blocking For",
            renderer: ({ fieldData }) => formatDuration(fieldData),
          },
          { field: "applicationName", header: "Application" },
          {
            field: "queryText",
            header: "SQL",
            renderer: function QueryCell({ fieldData }) {
              return fieldData ? (
                <ExpandableSQL sql={fieldData} />
              ) : (
                "<query text not available>"
              );
            },
            tip: "The query may be truncated based on track_activity_query_size",
          },
        ]}
      />
    </Panel>
  );
};

const VacuumInefficientIndexPhaseReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const { database, databaseTable } = useRoutes();

  const refData = issue.references.map((ref) => {
    const vacuumRun = ref.referent as IssueReferenceVacuumRun;
    const vacuumRunPath = ref.url;
    const details = JSON.parse(ref.detailsJson);

    const databasePath = database(vacuumRun.databaseId);
    const tablePath = databaseTable(
      vacuumRun.databaseId,
      vacuumRun.schemaTableId
    );

    return {
      identity: vacuumRun.identity,
      vacuumRunPath,
      vacuumStart: vacuumRun.vacuumStart,
      vacuumEnd: vacuumRun.vacuumEnd,
      databasePath,
      databaseName: details.database_name,
      schemaName: details.schema_name,
      tableName: details.table_name,
      tablePath,
      indexPhases: details.index_vacuum_count,
    };
  });

  return (
    <Panel title="VACUUM Runs">
      <PanelVirtualTable data={refData}>
        <Column
          dataKey="identity"
          label="ID"
          width={60}
          cellRenderer={({ rowData }) => {
            return <Link to={rowData.vacuumRunPath}>{rowData.identity}</Link>;
          }}
        />
        <Column
          dataKey="vacuumStart"
          label="Start Time"
          width={160}
          cellRenderer={({ cellData }) => {
            return formatTimestampLong(moment.unix(cellData));
          }}
        />
        <Column
          dataKey="vacuumEnd"
          label="End Time"
          width={160}
          cellRenderer={({ cellData }) => {
            return formatTimestampLong(moment.unix(cellData));
          }}
        />
        <Column
          dataKey="databaseName"
          label="Database"
          width={100}
          cellRenderer={({ rowData }) => {
            return (
              <Link to={rowData.databasePath}>{rowData.databaseName}</Link>
            );
          }}
        />
        <Column dataKey="schemaName" label="Schema" width={60} />
        <Column
          dataKey="tableName"
          label="Table"
          flexGrow={1}
          width={120}
          cellRenderer={({ rowData }) => {
            return <Link to={rowData.tablePath}>{rowData.tableName}</Link>;
          }}
        />
        <Column
          dataKey="indexPhases"
          label="Index Phases"
          width={120}
          cellRenderer={({ cellData }) => {
            return formatNumber(cellData);
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const IndexUnusedReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Unused indexes">
      <PanelVirtualTable data={issue.references}>
        <Column
          dataKey="name"
          label="Index"
          width={120}
          flexGrow={1}
          cellDataGetter={({ rowData }) => {
            return {
              name: rowData.name,
              path: rowData.url,
            };
          }}
          cellRenderer={({ cellData }) => {
            return <Link to={cellData.path}>{cellData.name}</Link>;
          }}
        />
        <Column
          dataKey="size"
          label="Size"
          width={80}
          cellDataGetter={({ rowData }) => {
            return (rowData.referent as IssueReferenceIndex).sizeBytes;
          }}
          cellRenderer={({ cellData }) => {
            return formatBytes(cellData);
          }}
        />
        <Column
          dataKey="lastUsedAt"
          label="Last Used"
          width={200}
          cellDataGetter={({ rowData }) => {
            return JSON.parse(rowData.detailsJson).last_used_at;
          }}
          cellRenderer={({ cellData }) => {
            if (cellData == null) {
              return "-";
            }
            return cellData;
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const IndexInvalidReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Invalid indexes">
      <PanelVirtualTable data={issue.references}>
        <Column
          dataKey="name"
          label="Index"
          width={120}
          flexGrow={1}
          cellDataGetter={({ rowData }) => {
            return {
              name: rowData.name,
              path: rowData.url,
            };
          }}
          cellRenderer={({ cellData }) => {
            return <Link to={cellData.path}>{cellData.name}</Link>;
          }}
        />
        <Column
          dataKey="size"
          label="Size"
          width={80}
          cellDataGetter={({ rowData }) => {
            return (rowData.referent as IssueReferenceIndex).sizeBytes;
          }}
          cellRenderer={({ cellData }) => {
            return formatBytes(cellData);
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const SlowQueryReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const slowQueryRef = issue.references[0];
  const slowQueryId = (slowQueryRef.referent as IssueReferenceQuery).id;
  const { data, loading, error } = useQuery(SLOW_QUERY_STATS_QUERY, {
    variables: {
      databaseId: issue.databaseId,
      queryId: slowQueryId,
      endTs: moment().unix(),
      startTs: moment().subtract(1, "day").unix(),
    },
  });
  let result = data?.getQueryDetailStats as unknown as Data;
  const noData = !!result && result.noData != null;

  if (result && !noData) {
    const config = JSON.parse(issue.checkConfig.settingsJson);
    const thresholdMs = config["threshold_ms"];
    result = {
      ...result,
      threshold: result.calls.map(([ts]) => [ts, thresholdMs]),
    };
  }

  const normalizedQuery = data?.getQueryDetails?.normalizedQuery;
  const normalizedQueryScanTokens =
    data?.getQueryDetails?.normalizedQueryScanTokens;
  const hasFullQuery = normalizedQuery && normalizedQueryScanTokens;

  return (
    <Panel title="Slow Query">
      <PanelSection>
        {hasFullQuery ? (
          <ExpandableSQL
            sql={normalizedQuery}
            databaseId={issue.databaseId}
            scanTokens={normalizedQueryScanTokens}
          />
        ) : (
          <SQL sql={slowQueryRef.queryText} />
        )}
      </PanelSection>
      <GraphSection noData={noData} loading={loading} error={error}>
        <Graph
          data={result}
          axes={{
            left: {
              format: (y: number): string => y + " ms",
              tipFormat: (y: number): string => y.toFixed(1) + " ms",
            },
            right: {
              tipFormat: (y: number): string => y.toFixed(1) + "/min",
            },
          }}
          series={[
            {
              type: ThresholdSeries,
              key: "threshold",
              label: "Check Threshold",
              tipLabel: "Threshold",
              color: "red",
            },
            { key: "avgTime", color: "yellowGreen", label: "Avg Total Time" },
            { key: "avgIoTime", color: "turquoise", label: "Avg I/O Time" },
            {
              key: "calls",
              color: "orange",
              label: "Calls",
              yAxis: "right",
              className: styles.newCalls,
            },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

const EnableFeaturesReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Disabled planner settings">
      <PanelVirtualTable data={issue.references}>
        <Column
          dataKey="name"
          label="Index"
          width={120}
          flexGrow={1}
          cellDataGetter={({ rowData }) => {
            return {
              name: rowData.name,
              path: rowData.url,
            };
          }}
          cellRenderer={({ cellData }) => {
            return (
              <Link to={cellData.path}>
                <strong>{cellData.name}</strong>
              </Link>
            );
          }}
        />
        <Column
          dataKey="name"
          label="Current Value"
          width={160}
          cellDataGetter={() => {
            return "off";
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const StatsReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Disabled tracking settings">
      <PanelVirtualTable data={issue.references}>
        <Column
          dataKey="name"
          label="Index"
          width={120}
          flexGrow={1}
          cellDataGetter={({ rowData }) => {
            return {
              name: rowData.name,
              path: rowData.url,
            };
          }}
          cellRenderer={({ cellData }) => {
            return (
              <Link to={cellData.path}>
                <strong>{cellData.name}</strong>
              </Link>
            );
          }}
        />
        <Column
          dataKey="name"
          label="Current Value"
          width={160}
          cellDataGetter={() => {
            return "off";
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const VacuumXminHorizonReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const { databaseTableVacuum } = useRoutes();

  type HeldBackBy = {
    type: string;
    xmin: number;
    assigned_at: number;
  };
  const heldBackBy = JSON.parse(issue.detailsJson).held_back_by as HeldBackBy[];
  const config = JSON.parse(issue.checkConfig.settingsJson);
  const thresholdHours = config["behind_hours"];
  const vacuumsList = issue.references.map((ref) => {
    const details = JSON.parse(ref.detailsJson);
    const table = ref.referent as IssueReferenceTable;
    return {
      name: ref.name,
      url: databaseTableVacuum(table.databaseId, table.id),
      oldest_xmin: details.oldest_xmin,
      not_removable: details.not_removable,
      autovacuum_count: details.autovacuum_count,
    };
  });
  return (
    <>
      <Panel title="Xmin Horizon Assignment Age Last 7 Days (in hours)">
        <XminHorizonGraph
          serverId={issue.server.humanId}
          startTs={moment().subtract(7, "day").unix()}
          endTs={moment().unix()}
          thresholdHours={thresholdHours}
        />
      </Panel>
      <Panel title="Identified Causes">
        <Grid
          className="grid-cols-[1fr_160px_400px]"
          data={heldBackBy}
          columns={[
            {
              field: "type",
              header: "Cause",
              renderer: function CauseCell({ fieldData }) {
                return (
                  <>
                    {labelXminHeldBackBy(fieldData)}
                    {fieldData === "backend" && heldBackBy.length > 1 && (
                      // Show special tip for backend when there are multiple causes
                      <Tip
                        content="Long-running transaction is likely led by other causes you can see in the list. Please check out other causes first."
                        className="ml-1"
                      />
                    )}
                  </>
                );
              },
            },
            {
              field: "xmin",
              header: "Xmin Horizon",
              renderer: ({ fieldData }) => formatNumber(fieldData),
            },
            {
              field: "assigned_at",
              header: "Xmin Assigned At",
              renderer: function XminAssingedAtCell({ fieldData }) {
                if (fieldData == null) {
                  return "-";
                }
                // takes ms
                const timeAgo = ` (${formatDurationPrecise(
                  (moment().unix() - fieldData) * 1000
                )} ago)`;
                return formatTimestampShort(moment.unix(fieldData)) + timeAgo;
              },
            },
          ]}
        />
      </Panel>
      <Panel title={`Blocked VACUUMs Last ${thresholdHours} Hours`}>
        <Grid
          className="grid-cols-[1fr_180px_240px_200px]"
          data={vacuumsList}
          defaultSortBy="not_removable"
          columns={[
            {
              field: "name",
              header: "Table",
              renderer: function TableCell({ rowData }) {
                return (
                  <Link to={rowData.url}>
                    <strong>{rowData.name}</strong>
                  </Link>
                );
              },
            },
            {
              field: "oldest_xmin",
              header: "Oldest Xmin",
              tip: "The xmin horizon. VACUUMs were only able to clean up dead rows until this point.",
              renderer: function OldestXminCell({ fieldData }) {
                if (fieldData === null) {
                  return "-";
                }
                return formatNumber(fieldData);
              },
            },
            {
              field: "not_removable",
              header: "Not Removable Dead Rows",
              tip: `In VACUUM log line, this shows up as "X are dead but not yet removable, oldest xmin: Y"`,
              renderer: function NotRemovableCell({ fieldData }) {
                if (fieldData === null) {
                  return "-";
                }
                return formatNumber(fieldData);
              },
              defaultSortOrder: "desc",
            },
            {
              field: "autovacuum_count",
              header: "Blocked VACUUM count",
              tip: "Number of VACUUMs that had not removable dead rows in the last 24 hours",
              renderer: function VacuumCountCell({ fieldData }) {
                if (fieldData === null) {
                  return "-";
                }
                return formatNumber(fieldData);
              },
            },
          ]}
          pageSize={5}
        />
      </Panel>
    </>
  );
};

const VacuumTxidWraparoundReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Transaction ID Utilization per Database">
      <FreezingStatsGraph serverId={issue.server.humanId} type="txid" />
    </Panel>
  );
};

const VacuumMxidWraparoundReferencesPanel: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  return (
    <Panel title="Multixact ID Utilization per Database">
      <FreezingStatsGraph serverId={issue.server.humanId} type="mxid" />
    </Panel>
  );
};

export default IssueReferencesPanel;
