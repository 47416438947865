import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import sortBy from "lodash/sortBy";

import { formatNumber, formatBytes } from "utils/format";
import { useRoutes } from "utils/routes";
import Loading from "components/Loading";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import PageContent from "components/PageContent";
import Panel from "components/Panel";

import {
  ServerSchemaOverview as ServerSchemaOverviewType,
  ServerSchemaOverviewVariables,
  ServerSchemaOverview_getDatabaseStats as DatabaseStats,
} from "./types/ServerSchemaOverview";
import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

const ServerSchemaOverview: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const { data, loading, error } = useQuery<
    ServerSchemaOverviewType,
    ServerSchemaOverviewVariables
  >(QUERY, {
    variables: {
      serverId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const secondaryTitle = (
    <input
      placeholder="Search..."
      type="text"
      name="filter[search]"
      className={styles.filterSearch}
      onChange={(evt) => setSearchTerm(evt.currentTarget.value)}
    />
  );

  return (
    <PageContent
      title="Schema Statistics"
      pageCategory="schema"
      pageName="index_server"
    >
      <Panel title="Databases" secondaryTitle={secondaryTitle}>
        <Table data={data} searchTerm={searchTerm} />
      </Panel>
    </PageContent>
  );
};

type RowDataType = {
  databaseId: string | null | undefined;
  databaseName: string | null | undefined;
} & DatabaseStats;

type TableProps = {
  data: ServerSchemaOverviewType;
  searchTerm?: string;
};

const Table: React.FunctionComponent<TableProps> = ({ data, searchTerm }) => {
  let tableData = data.getDatabaseStats.map(
    (inputData: DatabaseStats): RowDataType => {
      return {
        ...inputData,
        databaseId: (inputData.database && inputData.database.id) || null,
        databaseName:
          (inputData.database && inputData.database.datname) || null,
      };
    }
  );

  if (searchTerm) {
    tableData = tableData.filter(
      (di: RowDataType): boolean =>
        (di.database &&
          di.database.datname
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1) ||
        false
    );
  }

  tableData = sortBy(tableData, (b: RowDataType): number => -b.totalSize);

  return (
    <PanelVirtualTable
      data={tableData}
      initialSortBy="totalSize"
      initialSortDirection={SortDirection.DESC}
    >
      <Column
        dataKey="databaseName"
        label="Database"
        width={150}
        flexGrow={1}
        cellDataGetter={({ rowData }: { rowData: RowDataType }): RowDataType =>
          rowData
        }
        cellRenderer={({ cellData }) => <DatabaseNameCell rowData={cellData} />}
      />
      <Column
        dataKey="tableViewCount"
        label="Table + View Count"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={150}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }): React.ReactNode =>
          cellData === null ? "n/a" : formatNumber(cellData)
        }
      />
      <Column
        dataKey="dataSize"
        label="Data Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={150}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
      <Column
        dataKey="toastSize"
        label="TOAST Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={150}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
      <Column
        dataKey="indexSize"
        label="Index Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={150}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
      <Column
        dataKey="totalSize"
        label="Total Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={150}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
    </PanelVirtualTable>
  );
};

const DatabaseNameCell: React.FunctionComponent<{ rowData: RowDataType }> = ({
  rowData,
}) => {
  const { databaseTables } = useRoutes();
  return (
    <span>
      {rowData.database && !rowData.database.hidden && (
        <Link to={databaseTables(rowData.database.id)}>
          {rowData.database.datname}
        </Link>
      )}
      {rowData.database && rowData.database.hidden && rowData.database.datname}
    </span>
  );
};

export default ServerSchemaOverview;
