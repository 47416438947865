import React from "react";

type Props = {
  name: string;
  label: string;
  multiple?: boolean;
  required?: boolean;
  options: Array<Array<string>>;
  defaultValue?: string | string[];
  helpText?: string;
};

class FormSelectField extends React.Component<Props> {
  render() {
    const { name, label, multiple, required, options, defaultValue } =
      this.props;
    const id = name.replace("[", "_").replace("]", "").replace("[]", "");

    return (
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <div className="row">
          <div className="col-sm-6">
            <select
              multiple={multiple}
              name={name}
              id={id}
              className="form-control"
              required={required}
              defaultValue={defaultValue}
            >
              {options.map(
                (o: Array<string>): React.ReactNode =>
                  this.renderOption(o[0], o[1])
              )}
            </select>
          </div>
          {this.props.helpText && (
            <div className="help-block col-sm-6">{this.props.helpText}</div>
          )}
        </div>
      </div>
    );
  }

  renderOption(value: string, title: string): React.ReactNode {
    return (
      <option value={value} key={value}>
        {title}
      </option>
    );
  }
}

export default FormSelectField;
