import React from "react";
import Identicon from "components/Identicon";

const ExplainFingerprint: React.FunctionComponent<{
  explain: { fingerprint: string };
}> = ({ explain }) => {
  return (
    <span title={explain.fingerprint}>
      <Identicon identity={explain.fingerprint} />
      {explain.fingerprint.substring(0, 7)}
    </span>
  );
};

export default ExplainFingerprint;
