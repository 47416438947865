import React from "react";
import classNames from "classnames";
import hljs from "highlight.js/lib/core";
import sql from "highlight.js/lib/languages/sql";

type Props = {
  sql: string;
  inline?: boolean;
  nowrap?: boolean;
  className?: string;
};

// Keywords that should not be highlighted
const unhighlightedKeywords = [
  "oid",
  "date",
  "year",
  "month",
  "unique",
  "desc",
  "asc",
  "interval",
  "null",
  "not",
  "upsert",
  "name",
  "position",
  "is",
  "id",
  "cleanup",
  "query",
  "extract",
  "coalesce",
];

const SQL: React.FunctionComponent<Props> = ({ sql, ...rest }) => {
  // This component does not properly update its DOM manipulation when
  // the 'sql' prop changes. Fixing this properly is tricky and we're
  // moving toward SQLNew, so for now just force it to re-render when
  // that happens.
  return <SQLInternal key={sql} sql={sql} {...rest} />;
};

class SQLInternal extends React.Component<Props> {
  preRef: HTMLElement | null | undefined;

  componentDidMount() {
    if (this.preRef) {
      hljs.registerLanguage("sql", sql);
      this.preRef && hljs.highlightElement(this.preRef);

      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-keyword")
          .forEach((elem: HTMLElement) => {
            const keyword = elem.innerText && elem.innerText.toLowerCase();
            if (unhighlightedKeywords.indexOf(keyword) !== -1) {
              elem.className = "";
            } else {
              elem.className = "uppercase break-normal text-[#005d00]";
            }
          });

      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-string")
          .forEach((e: HTMLElement) => {
            e.className = "text-[#555]";
          });
      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-number")
          .forEach((e: HTMLElement) => {
            e.className = "text-[#555]";
          });
      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-aggregate")
          .forEach((e: HTMLElement) => {
            e.className = "text-inherit";
          });
      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-comment")
          .forEach((e: HTMLElement) => {
            e.className = "text-[#888]";
          });
      this.preRef &&
        this.preRef
          .querySelectorAll(".hljs-literal")
          .forEach((e: HTMLElement) => {
            e.className = "";
          });
    }
  }

  render() {
    let sql = this.props.sql;

    if (this.props.inline) {
      sql = sql.replace(/\s+/g, " ");
    } else {
      sql = sql
        .replace(/[^\S\n]+/g, " ")
        .replace(/\n\s/g, "\n")
        .replace(/\n{1,}/g, "\n");
    }

    return (
      <pre
        className={classNames(
          "border-none m-0 p-0 bg-none bg-transparent",
          !this.props.nowrap && "whitespace-pre-wrap",
          this.props.inline && "inline",
          this.props.className
        )}
        ref={(r: HTMLElement | null) => {
          this.preRef = r;
        }}
      >
        {sql}
      </pre>
    );
  }
}

export default SQL;
