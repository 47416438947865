---
title: 'Step 2: Create Monitoring User'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import Step02CreateMonitoringUserRDS from './_02_create_monitoring_user.mdx';

import GeneratePassword from "../../components/GeneratePassword"

<GeneratePassword>
  {password => <Step02CreateMonitoringUserRDS password={password} />}
</GeneratePassword>
