import React, { useEffect } from "react";

import { Flash, FlashLevel, useSetFlashes } from "components/WithFlashes";

type Props = {
  level: FlashLevel;
  msg: React.ReactNode;
};

const ShowFlash: React.FunctionComponent<Props> = ({ level, msg }) => {
  const setFlashes = useSetFlashes();
  useEffect(() => {
    const thisFlash = { level, msg };

    setFlashes((existing) => {
      if (existing.find(flashMatches(level, msg))) {
        return existing;
      }
      return existing.concat(thisFlash);
    });

    return () => {
      setFlashes((existing) => {
        return existing.filter((flash) => flash !== thisFlash);
      });
    };
  }, [setFlashes, level, msg]);

  return null;
};

function flashMatches(
  level: FlashLevel,
  msg: React.ReactNode
): (flash: Flash) => boolean {
  return (flash) =>
    flash.level === level && "msg" in flash && flash.msg === msg;
}

export default ShowFlash;
