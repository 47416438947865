import React, { useMemo } from "react";

import { ApolloClient, ApolloProvider, HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";

import { apolloAuthLink } from "utils/apolloAuthLink";
import { csrfToken } from "utils/csrf";
import { isTestEnv } from "utils/env";

type Props = {
  children: React.ReactElement;
  graphqlUri: string;
  enterpriseEdition: boolean;
};

const App: React.FunctionComponent<Props> = ({
  children,
  graphqlUri,
  enterpriseEdition,
}) => {
  const client = useMemo(() => {
    const trackedFetch = makeTrackedFetch();
    const httpLink = new HttpLink({
      uri: graphqlUri,
      // We use "same-origin" for Enterprise Edition since there may be non-pganalyze cookies that are
      // required by an internal connection reverse proxy
      credentials: enterpriseEdition ? "same-origin" : "omit",
      fetch: (uri, options) => trackedFetch(enterpriseEdition, uri, options),
    });
    return new ApolloClient({
      link: apolloAuthLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          GraphqlQuery: {
            queryType: true,
          },
          Bloat: {
            keyFields: false,
          },
          BloatReportIndex: {
            keyFields: false,
          },
          BloatReportTable: {
            keyFields: false,
          },
          CheckUpIssueGroup: {
            keyFields: false,
          },
          DatabasePartialList: {
            keyFields: false,
          },
          IssueNotification: {
            keyFields: false,
          },
          IssueReference: {
            keyFields: false,
          },
          LogAnalysisTempFileCreatedBucket: {
            keyFields: false,
          },
          QueryIndexCheckIndexedColumn: {
            keyFields: false,
          },
          QueryIndexCheckTable: {
            keyFields: false,
          },
          QueryTag: {
            keyFields: false,
          },
          SchemaTableAutovacuumSetting: {
            keyFields: false,
          },
          SchemaTableInlineStats: {
            keyFields: false,
          },
          SchemaTableInlineBloatStats: {
            keyFields: false,
          },
          SchemaTableBloatStats: {
            keyFields: false,
          },
          SchemaTableStats: {
            keyFields: false,
          },
          SchemaColumnStats: {
            keyFields: false,
          },
          VacuumRunLogDetails: {
            keyFields: false,
          },
          SchemaTableVacuumInfo: {
            keyFields: false,
          },
          WaitEventCount: {
            keyFields: false,
          },
          CollectorInfo: {
            keyFields: false,
          },
          SlackNotificationConfig: {
            keyFields: false,
          },
          PagerDutyNotificationConfig: {
            keyFields: false,
          },
          DatabaseIndexWriteOverhead: {
            keyFields: false,
          },
          VacuumCount: {
            keyFields: false,
          },
        },
      }),
    });
  }, [enterpriseEdition, graphqlUri]);

  if (!client) {
    return <>Loading...</>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;

type TrackingInfoType = {
  pageUrl: string;
  pageConnectStart: number;
  graphqlRequestedAt: number;
  graphqlRequestId: string | null | undefined;
  graphqlOperationName: string;
  graphqlVariables: any;
  graphqlResponseTimeMs: number | null | undefined;
  error: string | null | undefined;
  errorKind: string | null | undefined;
};

type TrackedFetchType = (
  enterpriseEdition: boolean,
  uri: RequestInfo | URL,
  options: RequestInit
) => Promise<Response>;

type TrackedFetchState = {
  queue: TrackingInfoType[];
};

export const makeTrackedFetch = (): TrackedFetchType => {
  const state: TrackedFetchState = { queue: [] };
  const reportTiming = () => {
    fetch("/graphql/timing", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
      body: JSON.stringify({ info: state.queue }),
    });
    state.queue = [];
  };

  const trackedFetch: TrackedFetchType = async (
    enterpriseEdition,
    uri,
    options
  ) => {
    const parsedBody = JSON.parse(options.body.toString());

    const trackingInfo: TrackingInfoType = {
      pageUrl: window.location.pathname,
      pageConnectStart: performance.timing.connectStart,
      graphqlRequestedAt: new Date().getTime(),
      graphqlRequestId: null,
      graphqlOperationName: parsedBody.operationName,
      graphqlVariables: parsedBody.variables,
      graphqlResponseTimeMs: null,
      error: null,
      errorKind: null,
    };

    return fetch(uri, options).then((response) => {
      // Ignore unauthorized errors, as they occur (and get retried) when the JWT token expires
      if (!response.ok && response.status == 401) {
        return response;
      }

      // Don't collect timing information in test environment - this is primarily to avoid
      // browser tests failing because of late requests that come after a test is finished
      if (isTestEnv) {
        return response;
      }

      // Don't collect timing information in Enterprise Edition
      if (enterpriseEdition) {
        return response;
      }

      trackingInfo.graphqlResponseTimeMs =
        new Date().getTime() - trackingInfo.graphqlRequestedAt;
      trackingInfo.graphqlRequestId = response.headers.get("x-request-id");
      if (!response.ok) {
        trackingInfo.error = response.statusText;
        trackingInfo.errorKind = "http";
      }

      if (state.queue.length === 0) {
        setTimeout(reportTiming, 2000);
      }
      state.queue.push(trackingInfo);

      return response;
    });
  };
  return trackedFetch;
};
