import React, { useCallback } from "react";

import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";

import Poller from "components/Poller";

import {
  CheckLogInsightsIntegrationStatus,
  CheckLogInsightsIntegrationStatusVariables,
} from "./types/CheckLogInsightsIntegrationStatus";

import QUERY from "./Query.graphql";

const POLL_INTERVAL_MS = 5000; // 5 seconds
const POLL_TIMEOUT_MS = 600_000; // 10 minutes

const LogInsightsIntegrationStatus: React.FunctionComponent<{
  serverId: string;
}> = ({ serverId }) => {
  const { serverSetupAutomatedExplain } = useRoutes();
  const [hasIntegrated, setIntegrated] = React.useState<boolean>(false);
  const handleResult = useCallback(
    (result: CheckLogInsightsIntegrationStatus) => {
      const integrated = result.getServerDetails?.integratedLogInsights;
      if (integrated) {
        setIntegrated(integrated);
      }
    },
    [setIntegrated]
  );

  if (!hasIntegrated) {
    return (
      <>
        <hr />
        <Poller<
          CheckLogInsightsIntegrationStatus,
          CheckLogInsightsIntegrationStatusVariables
        >
          query={QUERY}
          queryOpts={{
            variables: { serverId },
            pollInterval: POLL_INTERVAL_MS,
          }}
          onResult={handleResult}
          pollingMessage="Waiting for logs (this should take a few minutes after you've performed the test command)..."
          timeoutMessage="Your first log events are taking longer than expected."
          timeout={POLL_TIMEOUT_MS}
        />
      </>
    );
  }

  return (
    <>
      <hr />
      <p>
        <strong>Success - received your first log events!</strong>
      </p>
      <p>
        Your Log Insights setup is complete. You can view your log events
        through the Log Insights menu item on the left. The final setup step is
        configuring your system for Automated EXPLAIN:
      </p>
      <Link
        to={serverSetupAutomatedExplain(serverId)}
        className="btn btn-success"
      >
        Set Up Automated EXPLAIN
      </Link>
    </>
  );
};

export default LogInsightsIntegrationStatus;
