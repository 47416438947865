import React from "react";
import { ApolloError } from "@apollo/client";
import classNames from "classnames";

import Graph from ".";
import Loading from "components/Loading";

import styles from "./style.module.scss";

type Props = {
  noData?: boolean;
  error?: ApolloError;
  loading?: boolean;
  className?: string;
};

const GraphSection: React.FunctionComponent<Props> = ({
  children,
  noData = false,
  loading = false,
  error,
  className,
}) => {
  return (
    <div className={classNames(styles.sectionWrapper, className)}>
      {noData ? (
        <Graph data={{ "No Data": [] }} series={[]} />
      ) : loading || error ? (
        <Loading error={!!error} />
      ) : (
        children
      )}
    </div>
  );
};

export default GraphSection;
