import React from "react";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  QueryExplainsBlankSlate as QueryExplainsBlankSlateType,
  QueryExplainsBlankSlateVariables,
} from "./types/QueryExplainsBlankSlate";
import QUERY from "./Query.graphql";

import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

type Props = {
  serverId: string;
};

const QueryExplainsBlankSlate: React.FunctionComponent<Props> = ({
  serverId,
}) => {
  const { serverSetupLogInsights, serverSetupAutomatedExplain } = useRoutes();
  const { data, loading, error } = useQuery<
    QueryExplainsBlankSlateType,
    QueryExplainsBlankSlateVariables
  >(QUERY, { variables: { serverId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const systemType = data.getServerDetails.systemType;
  const autoExplainMinDurationDisabled =
    data.autoExplainMinDuration?.value === "-1";

  const logMinDurationStatementDisabled =
    data.logMinDurationStatement?.value === "-1";

  const sharedPreloadLibrariesSettingPresent = !!data.sharedPreloadLibraries;
  const loadedInSharedPreloadLibraries =
    data.sharedPreloadLibraries?.value?.includes("auto_explain");
  const logInsightsIntegrated = data.getServerDetails.integratedLogInsights;
  const prefixMissingUser = !data.logLinePrefix?.value?.includes("%u");
  const prefixMissingDb = !data.logLinePrefix?.value?.includes("%d");
  const prefixMissingBoth = prefixMissingUser && prefixMissingDb;

  const logLinePrefixMissing = prefixMissingBoth
    ? "user (%u) and database (%d)"
    : prefixMissingUser
    ? "user (%u)"
    : prefixMissingDb
    ? "database (%d)"
    : "";

  const useAutoexplain = [
    "amazon_rds",
    "google_cloudsql",
    "self_managed",
  ].includes(systemType);
  const explainThresholdSettingDisabled = useAutoexplain
    ? autoExplainMinDurationDisabled
    : logMinDurationStatementDisabled;
  const explainThresholdSettingName = useAutoexplain
    ? "auto_explain.log_min_duration"
    : "log_min_duration_statement";
  const explainThresholdSettingValue = useAutoexplain
    ? data.autoExplainMinDuration?.value
    : data.logMinDurationStatement?.value;
  const explainThresholdSet = explainThresholdSettingValue != null;

  const exceedBlurb = explainThresholdSet ? (
    <>
      {explainThresholdSettingValue} (your{" "}
      <code>{explainThresholdSettingName}</code> setting)
    </>
  ) : (
    <>
      your <code>{explainThresholdSettingName}</code> setting
    </>
  );

  const logInsightsSetupPath = serverSetupLogInsights(serverId);
  const explainSetupPath = serverSetupAutomatedExplain(serverId);

  return (
    <Panel title="No EXPLAIN Plans Collected">
      <PanelSection>
        <p>
          <strong>Error:</strong> No EXPLAIN plans collected in the last 7 days.
        </p>
        {explainThresholdSettingDisabled ? (
          <p>
            You must configure <code>{explainThresholdSettingName}</code> to
            capture EXPLAIN plans for query executions.
          </p>
        ) : (
          <p>
            Please note that only query executions that exceed {exceedBlurb}{" "}
            will produce an EXPLAIN plan.
          </p>
        )}
        <strong>Integration Checklist:</strong>
        <ul className={styles.integrationChecklist}>
          <li>
            {logInsightsIntegrated ? (
              <span>
                <FontAwesomeIcon icon={faCheckCircle} /> Log Insights is
                integrated
              </span>
            ) : (
              <span>
                <FontAwesomeIcon icon={faTimesCircle} /> Log Insights is not
                integrated -{" "}
                <Link to={logInsightsSetupPath}>Setup instructions</Link>
              </span>
            )}
          </li>
          <li>
            {logLinePrefixMissing ? (
              <span>
                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                <code>log_line_prefix</code> is missing user (<code>%u</code>)
                and/or database (<code>%d</code>)
              </span>
            ) : (
              <span>
                <FontAwesomeIcon icon={faCheckCircle} />{" "}
                <code>log_line_prefix</code>
                includes user (<code>%u</code>) and database (<code>%d</code>)
              </span>
            )}
          </li>
          {useAutoexplain && (
            <li>
              {sharedPreloadLibrariesSettingPresent ? (
                loadedInSharedPreloadLibraries ? (
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                    <code>shared_preload_libraries</code> has loaded
                    auto_explain
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                    <code>shared_preload_libraries</code> has not loaded
                    auto_explain
                  </span>
                )
              ) : (
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                  <code>shared_preload_libraries</code> setting not reported
                </span>
              )}
            </li>
          )}
          {explainThresholdSet && (
            <li>
              {explainThresholdSettingDisabled ? (
                <span>
                  <FontAwesomeIcon icon={faTimesCircle} />{" "}
                  <code>{explainThresholdSettingName}</code> is set to -1
                  (deactivated)
                </span>
              ) : (
                <span>
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  <code>{explainThresholdSettingName}</code> is set to{" "}
                  {explainThresholdSettingValue}
                </span>
              )}
            </li>
          )}
          {!useAutoexplain && (
            <li>
              <FontAwesomeIcon icon={faQuestionCircle} /> Ensure the{" "}
              <code>enable_log_explain</code> collector setting (or
              <code>PGA_ENABLE_LOG_EXPLAIN</code> environment variable if
              running in Docker) is set to <code>true</code>
            </li>
          )}
        </ul>
        <p>
          Learn more about{" "}
          <Link to={explainSetupPath}>
            how to set up EXPLAIN plan collection
          </Link>
          .
        </p>
      </PanelSection>
    </Panel>
  );
};

export default QueryExplainsBlankSlate;
