import React, { useState } from "react";

import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";
import QueryStatsTable from "components/QueryStatsTable";
import { SortDirection } from "types/graphql-global-types";
import QueryDataMissingHint from "components/QueryList/QueryDataMissingHint";
import { formatNumber } from "utils/format";

import {
  SchemaTableQueries as SchemaTableQueriesType,
  SchemaTableQueriesVariables,
} from "./types/SchemaTableQueries";
import {
  SchemaTableQueriesScans as SchemaTableQueriesScansType,
  SchemaTableQueriesScansVariables,
} from "./types/SchemaTableQueriesScans";
import { useDateRange } from "components/WithDateRange";
import PanelTitleSearch from "components/PanelTitleSearch";
import QUERY from "./Query.graphql";
import QUERY_SCANS from "./Query.scans.graphql";
import { useFeature } from "components/OrganizationFeatures";
import Grid from "components/Grid";
import SchemaTableScanDetails from "components/SchemaTableScanDetails";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  tableId: string;
}

const SchemaTableQueries: React.FunctionComponent<Props> = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const indexAdvisor = useFeature("indexAdvisor");
  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );
  return (
    <>
      {indexAdvisor && (
        <Panel title="Scans on Table">
          <SchemaTableScansContent {...props} />
        </Panel>
      )}
      <Panel title="Queries" secondaryTitle={titleSearch}>
        <SchemaTableQueriesContent {...props} searchTerm={searchTerm} />
      </Panel>
    </>
  );
};

const SchemaTableQueriesContent: React.FunctionComponent<
  Props & { searchTerm: string }
> = ({ databaseId, tableId, searchTerm }) => {
  const [{ from, to }] = useDateRange();
  const [sortOpts, setSortOpts] = useState<SortOptsType>({
    sortBy: "pctOfTotal",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaTableQueriesType,
    SchemaTableQueriesVariables
  >(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: from.unix(),
      endTs: to.unix(),
      offset: 0,
      limit: 100,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getQueryStatsForTable.length === 0) {
    return <QueryDataMissingHint databaseId={databaseId} />;
  }

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: SchemaTableQueriesType,
        { fetchMoreResult }: { fetchMoreResult: SchemaTableQueriesType }
      ): SchemaTableQueriesType => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getQueryStatsForTable: [
            ...prev.getQueryStatsForTable,
            ...fetchMoreResult.getQueryStatsForTable,
          ],
        });
      },
    });
  };

  return (
    <QueryStatsTable
      databaseId={databaseId}
      serverId={data.getServerDetails.id}
      queries={data.getQueryStatsForTable}
      fetchMoreData={fetchMoreData}
      sortOpts={sortOpts}
      setSortOpts={setSortOpts}
      searchTerm={null}
      compare={false}
      showingTable
    />
  );
};

const SchemaTableScansContent: React.FunctionComponent<Props> = ({
  databaseId,
  tableId,
}) => {
  const [{ from, to }] = useDateRange();

  const { data, loading, error } = useQuery<
    SchemaTableQueriesScansType,
    SchemaTableQueriesScansVariables
  >(QUERY_SCANS, {
    variables: {
      databaseId,
      tableId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (data.getSchemaTableScans.length === 0) {
    return <QueryDataMissingHint databaseId={databaseId} />;
  }

  const scanData = data.getSchemaTableScans.map((scan) => {
    const whereClause = scan.whereClauses.map((c) => c.clauseSql);
    const joinClause = scan.joinClauses.map((c) => c.clauseSql);
    const combinedSql = whereClause.concat(joinClause).join(" AND ");
    return {
      combinedSql: combinedSql,
      whereClause,
      joinClause,
      scanMethod: scan.genericPlanScanMethod,
      totalCost: scan.genericPlanTotalCost,
      scansPerMin: scan.avgCallsPerMinute,
      referencedQueries: scan.queries.map((q) => q.id),
    };
  });
  const sortedScanData = sortBy(scanData, [(scan) => -scan.scansPerMin]);

  return (
    <Grid
      className="grid-cols-[minmax(0,_5fr)_minmax(0,_2fr)_repeat(2,_minmax(0,_1fr))]"
      data={sortedScanData}
      columns={[
        {
          field: "combinedSql",
          header: "Scan Expression",
          tip: "Combined conditions on data fetched during this scan.",
          renderer: function ScanDetailsCell({ rowData }) {
            return (
              <SchemaTableScanDetails
                databaseId={databaseId}
                whereClause={rowData.whereClause}
                joinClause={rowData.joinClause}
                referencedQueries={rowData.referencedQueries}
              />
            );
          },
        },
        {
          field: "scanMethod",
          header: "Scan Method",
          title: true,
        },
        {
          field: "totalCost",
          header: "Cost",
          renderer: ({ fieldData }) => formatNumber(fieldData, 2),
        },
        {
          field: "scansPerMin",
          header: "Est. Scans / Min",
          renderer: ({ fieldData }) => formatNumber(fieldData, 4),
        },
      ]}
    />
  );
};

export default SchemaTableQueries;
