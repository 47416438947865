import React from "react";
import Icon from "components/Icon";

interface Props {
  tagKey: string;
  tagValue: string;
  githubRepository: string;
  githubBranch: string;
}

const TagValue: React.FunctionComponent<Props> = ({
  tagKey,
  tagValue,
  githubRepository,
  githubBranch,
}) => {
  if (tagKey === "line" && githubRepository && githubBranch) {
    const parts = tagValue.split(":", 3);
    if (parts.length !== 3) {
      return <span>{tagValue}</span>;
    }

    const href =
      githubRepository + "/blob/" + githubBranch + parts[0] + "#L" + parts[1];
    return (
      <span>
        <Icon kind="github" />{" "}
        <a href={href} target="_blank">
          {parts[0]}:{parts[1]}
        </a>
        :{parts[2]}
      </span>
    );
  }

  return <span>{tagValue}</span>;
};

export default TagValue;
