export function epochFromFullTransactionId(value: number): number {
  return Number(BigInt(value) >> BigInt(32));
}

export function xidFromFullTransactionId(value: number | null): number | null {
  if (value == null) {
    return null;
  }
  const epoch = epochFromFullTransactionId(value);
  return fullTransactionIdMinusEpoch(value, epoch);
}

export function fullTransactionIdMinusEpoch(
  value: number,
  epoch: number
): number {
  return Number(BigInt(value) - (BigInt(epoch) << BigInt(32)));
}
