import React from "react";
import classNames from "classnames";
import moment from "moment-timezone";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatPercent,
  formatTimestampShort,
} from "utils/format";

import {
  ExplainComparison as ExplainComparisonType,
  ExplainComparison_getQueryExplains as ExplainSummary,
  ExplainComparisonVariables,
} from "./types/ExplainComparison";

import { AnnotatedPlan } from "types/explain";
import ExplainFingerprint from "components/ExplainFingerprint";
import PanelTable from "components/PanelTable";

import { ExplainPlanType } from "..";

import QUERY from "./Query.graphql";

import styles from "./style.module.scss";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";

const ExplainComparison: React.FunctionComponent<{
  databaseId: string;
  explain: ExplainPlanType;
  plan: AnnotatedPlan;
  blockSize: number;
  onHide: () => void;
}> = ({ databaseId, blockSize, explain, plan, onHide }) => {
  const { error, loading, data } = useQuery<
    ExplainComparisonType,
    ExplainComparisonVariables
  >(QUERY, {
    variables: {
      queryId: explain.query.id,
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const explains = data.getQueryExplains;
  const explainsWithQuerySample = explains.filter((e) => e.querySample);
  return (
    <>
      <ExplainComparisonView
        ioBlocks={explain.totalSharedBlksRead}
        ioMs={explain.totalBlkReadTime}
        fingerprint={explain.fingerprint}
        plan={plan}
        current={explain}
        explains={explainsWithQuerySample}
        runtime={explain.querySample.runtimeMs}
        blockSize={blockSize}
        seenAt={explain.seenAt}
      />
      <a
        href=""
        onClick={(evt) => {
          evt.preventDefault();
          onHide();
        }}
      >
        Show Summary &amp; Insights
      </a>
    </>
  );
};

type Props = {
  fingerprint: string;
  runtime: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
  plan: AnnotatedPlan;
  explains: ExplainSummary[];
  seenAt: number;
  current: ExplainPlanType;
};

const ExplainComparisonView: React.FunctionComponent<Props> = ({
  plan,
  fingerprint,
  runtime,
  ioBlocks,
  blockSize,
  ioMs,
  explains,
  seenAt,
  current,
}) => {
  const root = plan.plan[0].Plan;
  const totCost =
    "Total Cost" in root ? formatNumber(root["Total Cost"]) : "N/A";

  // TODO
  //  * if this execution is not in the plans list, don't show comparisons
  //  * if this execution is the only plan with *this* fingerprint, don't show comparisons with--well basically with itself
  //  * if there is only one other plan with same fingerprint, don't show min/max
  //  * if there is only one other plan with *different* fingerprint, don't show min/max

  const thisPlanExplains = explains.filter(
    (e) => e.fingerprint === fingerprint
  );
  const thisPlanSummary = getExplainsStats(thisPlanExplains);

  const otherPlanExplains = explains.filter(
    (e) => e.fingerprint !== fingerprint
  );
  const otherPlanSummary = getExplainsStats(otherPlanExplains);

  const hasAnyOtherPlans = explains.length > 0;
  return (
    <PanelTable borders={true} className={styles.explainComparison}>
      {hasAnyOtherPlans && (
        <thead>
          <tr>
            <th />
            <th>This Execution</th>
            {thisPlanExplains.length > 0 && (
              <th colSpan={2}>
                This Plan{" · "}
                <ExplainFingerprint explain={current} />
              </th>
            )}
            {otherPlanExplains.length > 0 && <th colSpan={2}>Other Plans</th>}
          </tr>
          <tr>
            <th />
            <th>{formatTimestampShort(moment.unix(seenAt))}</th>
            {thisPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
            {otherPlanExplains.length > 0 && (
              <>
                <th>min</th>
                <th>max</th>
              </>
            )}
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          <th scope="row">Total Est. Cost</th>
          <td>{totCost}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(thisPlanSummary.totCost.min)}</td>
              <td>{formatNumber(thisPlanSummary.totCost.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatNumber(otherPlanSummary.totCost.min)}</td>
              <td>{formatNumber(otherPlanSummary.totCost.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Runtime</th>
          <td>{formatMs(runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>{formatMs(thisPlanSummary.runtime.min)}</td>
              <td>{formatMs(thisPlanSummary.runtime.max)}</td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>{formatMs(otherPlanSummary.runtime.min)}</td>
              <td>{formatMs(otherPlanSummary.runtime.max)}</td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">Read From Disk</th>
          <td>{formatBlocksBytes(ioBlocks, blockSize)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(thisPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.min, blockSize)}
              </td>
              <td>
                {formatBlocksBytes(otherPlanSummary.blksRead.max, blockSize)}
              </td>
            </>
          )}
        </tr>
        <tr>
          <th scope="row">I/O Read Time</th>
          <td>{formatIOReadTime(ioMs, ioMs / runtime)}</td>
          {thisPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.min,
                  thisPlanSummary.blkReadTimeFract.min
                )}
              </td>
              <td>
                {formatIOReadTime(
                  thisPlanSummary.blkReadTime.max,
                  thisPlanSummary.blkReadTimeFract.max
                )}
              </td>
            </>
          )}
          {otherPlanExplains.length > 0 && (
            <>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.min,
                  otherPlanSummary.blkReadTimeFract.min
                )}
              </td>
              <td>
                {formatIOReadTime(
                  otherPlanSummary.blkReadTime.max,
                  otherPlanSummary.blkReadTimeFract.max
                )}
              </td>
            </>
          )}
        </tr>
      </tbody>
    </PanelTable>
  );
};

type Range = {
  min: number;
  max: number;
};

type ExplainStats = {
  totCost: Range;
  runtime: Range;
  blksRead: Range;
  blkReadTime: Range;
  blkReadTimeFract: Range;
};

const getExplainsStats = (explains: ExplainSummary[]): ExplainStats => {
  const totCosts = explains.map((e) => e.totalCost);
  const runtimes = explains.map((e) => e.querySample.runtimeMs);
  const blksRead = explains.map((e) => e.totalSharedBlksRead);

  type BlkTimeReduceState = {
    min: number;
    max: number;
    minRuntime: number;
    maxRuntime: number;
  };
  const blkReadTime = explains.reduce<BlkTimeReduceState>(
    (state, curr) => {
      const result = { ...state };
      if (curr.totalBlkReadTime > state.max) {
        Object.assign(result, {
          max: curr.totalBlkReadTime,
          maxRuntime: curr.querySample.runtimeMs,
        });
      }
      if (curr.totalBlkReadTime < state.min) {
        Object.assign(result, {
          min: curr.totalBlkReadTime,
          minRuntime: curr.querySample.runtimeMs,
        });
      }
      return result;
    },
    { min: Infinity, max: -1, minRuntime: Infinity, maxRuntime: -1 }
  );

  const min = (values: number[]): number => {
    return Math.min.apply(null, values);
  };
  const max = (values: number[]): number => {
    return Math.max.apply(null, values);
  };

  return {
    totCost: {
      min: min(totCosts),
      max: max(totCosts),
    },
    runtime: {
      min: min(runtimes),
      max: max(runtimes),
    },
    blksRead: {
      min: min(blksRead),
      max: max(blksRead),
    },
    blkReadTime: {
      min: blkReadTime.min,
      max: blkReadTime.max,
    },
    blkReadTimeFract: {
      min: blkReadTime.min / blkReadTime.minRuntime,
      max: blkReadTime.max / blkReadTime.maxRuntime,
    },
  };
};

const formatBlocksBytes = (blocks: number, blockSize: number): string => {
  return `${formatBytes(blocks * blockSize)} · ${formatNumber(blocks)} blocks`;
};

const formatIOReadTime = (
  ioMs: number,
  ioFract: number
): React.ReactElement => {
  if (!ioMs) {
    return <>-</>;
  }

  return (
    <>
      <span>{formatMs(ioMs)}</span>
      <span>
        {" "}
        ·{" "}
        <span
          className={classNames({
            [styles.redHighlight]: ioFract > 0.5,
          })}
        >
          {formatPercent(ioFract)}
        </span>
      </span>
    </>
  );
};

export default ExplainComparison;
