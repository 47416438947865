import React from "react";

import Icon from "../Icon";

type Props = {
  permitted: boolean;
  restricted: boolean;
};

class PermissionIcon extends React.Component<Props> {
  render() {
    const { permitted, restricted } = this.props;

    if (permitted) {
      if (restricted) {
        return <Icon kind="dot-circle-o" />;
      } else {
        return <Icon kind="circle" />;
      }
    } else {
      return <Icon kind="circle-o" />;
    }
  }
}

export default PermissionIcon;
