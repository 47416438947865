import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";

import { formatNumber } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import Popover from "components/Popover";
import { useRoutes } from "utils/routes";

import {
  OrganizationOverviewServerTable_getSummaryStatsByServer,
  OrganizationOverviewServerTable_getSummaryStatsByServer_server_tags,
  OrganizationOverviewServerTable,
  OrganizationOverviewServerTableVariables,
} from "./types/OrganizationOverviewServerTable";
import QUERY from "./Query.graphql";
import { ServerTagsTable } from "components/ServerDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/pro-solid-svg-icons";

type RowDataType = {
  serverId: string;
  serverName: string;
  tags: OrganizationOverviewServerTable_getSummaryStatsByServer_server_tags[];
  databaseCount: number;
  slowQueries: number;
  unusedIndexes: number;
  warnings: number;
  criticals: number;
  status: string;
};

type Props = {
  organizationSlug: string;
};

const Table: React.FunctionComponent<Props> = ({ organizationSlug }) => {
  const popoverPortal = useRef();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [showInactive, setShowInactive] = useState(false);
  const { data, loading, error } = useQuery<
    OrganizationOverviewServerTable,
    OrganizationOverviewServerTableVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <>
      <div className="flex">
        <input
          type="checkbox"
          checked={showInactive}
          id="show_inactive"
          onChange={(evt) => setShowInactive(evt.target.checked)}
        />
        <label htmlFor="show_inactive">
          Show servers with no recent statistics data
        </label>
      </div>
      <PanelTitleSearch
        value={searchTerm}
        onChange={(newTerm: string) => {
          setSearchTerm(newTerm);
        }}
      />
    </>
  );

  let servers: Array<RowDataType> = data.getSummaryStatsByServer.map(
    (
      summary: OrganizationOverviewServerTable_getSummaryStatsByServer
    ): RowDataType => {
      return {
        serverId: summary.server.humanId,
        serverName: summary.server.name,
        status:
          (summary.server.hasRecentSnapshot &&
            ((summary.server.hasCriticalIssues && "critical") || "okay")) ||
          "no_data",
        tags: summary.server.tags,
        databaseCount: summary.databaseCount,
        slowQueries: summary.queriesSlownessCount,
        unusedIndexes: summary.unusedIndexesCount,
        warnings: summary.warningsCount,
        criticals: summary.criticalsCount,
      };
    }
  );

  if (searchTerm) {
    servers = servers.filter((di: RowDataType): boolean =>
      di.serverName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  if (!showInactive) {
    servers = servers.filter((s) => s.status != "no_data");
  }

  return (
    <Panel title="All Servers" secondaryTitle={titleSearch}>
      <div ref={popoverPortal} />
      <PanelVirtualTable
        data={servers}
        initialSortBy="serverName"
        initialSortDirection={SortDirection.ASC}
      >
        <Column
          dataKey="status"
          label=""
          width={10}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData == "okay" ? (
              <i
                className="circle okay"
                key="icon"
                title="No critical issues, recently received statistics data"
              />
            ) : cellData == "critical" ? (
              <i
                className="circle critical"
                key="icon"
                title="Critical issues found"
              />
            ) : (
              <i
                className="circle"
                key="icon"
                title="No recent statistics data"
              />
            )
          }
        />
        <Column
          dataKey="serverName"
          label="Server"
          width={190}
          flexGrow={1}
          cellDataGetter={({
            rowData,
          }: {
            rowData: RowDataType;
          }): RowDataType => rowData}
          cellRenderer={({ cellData }) => (
            <ServerNameCell rowData={cellData} portal={popoverPortal} />
          )}
        />
        <Column
          dataKey="databaseCount"
          label="# of Databases"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={120}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="slowQueries"
          label="Slow Queries"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={110}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="unusedIndexes"
          label="Unused Indexes"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={110}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="warnings"
          label="Warnings"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={110}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
        <Column
          dataKey="criticals"
          label="Criticals"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={110}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({ cellData }): React.ReactNode =>
            cellData === null ? "n/a" : formatNumber(cellData)
          }
        />
      </PanelVirtualTable>
    </Panel>
  );
};

const ServerNameCell: React.FunctionComponent<{
  rowData: RowDataType;
  portal?: React.RefObject<HTMLDivElement>;
}> = ({ rowData, portal }) => {
  const { server } = useRoutes();
  const { tags, serverId } = rowData;
  return (
    <span>
      <Link to={server(rowData.serverId)}>{rowData.serverName}</Link>
      {tags.length > 0 && (
        <Popover
          placement="bottom"
          wrapperClassName="ml-2 text-[12px]"
          content={<ServerTagsTable tags={tags} />}
          portal={portal?.current}
        >
          <Link to={server(serverId)}>
            <FontAwesomeIcon icon={faTags} />
          </Link>
        </Popover>
      )}
    </span>
  );
};

export default Table;
