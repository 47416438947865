import React, { useCallback, useState } from "react";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import {
  TestLocalCollectorOutput,
  TestLocalCollectorOutputVariables,
} from "./types/TestLocalCollectorOutput";
import Poller from "components/Poller";
import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";
import {
  TestLocalCollector as TestLocalCollectorType,
  TestLocalCollectorVariables,
} from "./types/TestLocalCollector";
import { useMutation } from "@apollo/client";
import Loading from "components/Loading";
import CopyCodeBlock from "components/CopyCodeBlock";

type Props = {
  serverId: string;
};

const TestLocalCollector: React.FunctionComponent<Props> = ({ serverId }) => {
  const [testLocalCollector, { called, loading, error }] = useMutation<
    TestLocalCollectorType,
    TestLocalCollectorVariables
  >(MUTATION);

  const handleTestButtonClick = () => {
    testLocalCollector({
      variables: {
        serverId,
      },
    });
  };

  if (error) {
    return <Loading error={!!error} />;
  }

  return (
    <Panel title="Collector Configuration Test">
      <PanelSection>
        {called && !loading ? (
          <CollectorTestOutput serverId={serverId} />
        ) : (
          <>
            <p>
              This server is monitored using the local pganalyze collector
              running inside the pganalyze Enterprise container.
            </p>
            <p>
              You can run a test (which uses the pganalyze collector{" "}
              <code>--test</code> command) to get debug information and check
              for potential setup issues:
            </p>
            {loading ? (
              <button className="btn btn-success" disabled={true}>
                Starting Collector Test...
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={handleTestButtonClick}
              >
                Start Collector Test
              </button>
            )}
          </>
        )}
      </PanelSection>
    </Panel>
  );
};

const CollectorTestOutput: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const [testOutput, setTestOutput] = useState<string | null>(null);

  const handleResult = useCallback(
    (data: TestLocalCollectorOutput) => {
      setTestOutput(data.getServerDetails.localCollectorTestOutput);
    },
    [setTestOutput]
  );

  if (testOutput == null) {
    return (
      <Poller<TestLocalCollectorOutput, TestLocalCollectorOutputVariables>
        query={QUERY}
        queryOpts={{
          variables: { serverId },
        }}
        onResult={handleResult}
        timeout={5 * 60 * 1000}
        pollingMessage="Waiting for collector test results (this may take up to 5 minutes)..."
        timeoutMessage="The test command took longer than expected. Check the Enterprise container logs for errors."
      />
    );
  }
  return (
    <>
      <p>
        Result of <code>pganalyze-collector --test</code> for this server:
      </p>
      <CopyCodeBlock>{testOutput}</CopyCodeBlock>
    </>
  );
};

export default TestLocalCollector;
