import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  Column,
  SortDirection,
  defaultTableHeaderRenderer,
} from "react-virtualized";

import DocsSnippet from "components/DocsSnippet";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelVirtualTable from "components/PanelVirtualTable";
import PanelTitleSearch from "components/PanelTitleSearch";
import { CHECK_OPTIONS, CheckOptionsType } from "components/CheckConfig";

import {
  CheckConfigList as CheckConfigListType,
  CheckConfigList_getCheckConfigs,
  CheckConfigListVariables,
} from "./types/CheckConfigList";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

type Props = {
  organizationSlug: string;
  checkGroupAndName: string;
};

type CheckConfigType = CheckConfigList_getCheckConfigs & Record<string, string>;

const CheckConfigList: React.FunctionComponent<Props> = ({
  organizationSlug,
  checkGroupAndName,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { serverCheckConfigure } = useRoutes();
  const { data, loading, error } = useQuery<
    CheckConfigListType,
    CheckConfigListVariables
  >(QUERY, {
    variables: {
      organizationSlug,
      checkGroupAndName: checkGroupAndName,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const titleSearch = (
    <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
  );

  const [groupName, checkName] = checkGroupAndName.split("/", 2);
  const options =
    (CHECK_OPTIONS[groupName] && CHECK_OPTIONS[groupName][checkName]) || [];

  const checkConfigs: CheckConfigType[] = data.getCheckConfigs
    .map((c) => ({
      serverName: c.server.name,
      ...JSON.parse(c.settingsJson),
      ...c,
    }))
    .filter((c) => {
      return (
        searchTerm === "" || c.serverName.toLowerCase().includes(searchTerm)
      );
    });

  return (
    <Panel title="Select Server To Configure" secondaryTitle={titleSearch}>
      <PanelVirtualTable
        data={checkConfigs}
        initialSortBy="serverName"
        initialSortDirection={SortDirection.ASC}
      >
        <Column
          dataKey="serverName"
          label="Server"
          width={200}
          flexGrow={1}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            cellData?: string;
            rowData: CheckConfigType;
          }): React.ReactNode => (
            <Link
              to={serverCheckConfigure(
                rowData.server.humanId,
                checkGroupAndName
              )}
            >
              {cellData}
            </Link>
          )}
        />
        <Column
          dataKey="enabled"
          label="Check Enabled?"
          width={150}
          cellRenderer={({
            cellData,
          }: {
            cellData?: boolean;
          }): React.ReactNode => (cellData ? "Yes" : "No")}
        />
        {options.map(
          (o: CheckOptionsType): React.ReactNode => (
            <Column
              key={o.key}
              dataKey={o.key}
              label={o.title}
              width={250}
              headerRenderer={(params): React.ReactNode => {
                params.label = (
                  <>
                    {params.label}{" "}
                    <DocsSnippet title={o.title} content={o.help} />
                  </>
                );
                // We have to use "as any" here because the type definition
                // is incorrect (the method accepts parameters, and the type
                // definition wrongly says it does not)
                return (defaultTableHeaderRenderer as any)(params);
              }}
            />
          )
        )}
      </PanelVirtualTable>
    </Panel>
  );
};

export default CheckConfigList;
