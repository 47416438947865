import React from "react";

import classNames from "classnames";

import Badge from "components/Badge";
import Icon from "components/Icon";

type Props = {
  status: string;
  className?: string;
};

const CheckStatusBadge: React.FunctionComponent<Props> = ({
  status,
  className,
}) => {
  const commonClassName = "text-[12px] font-medium px-1 py-0.5";
  if (status == "critical") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#fb7577] text-[#260303]",
          className
        )}
      >
        <Icon kind="exclamation-triangle" /> Critical
      </Badge>
    );
  } else if (status == "warning") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#ffce59] text-[#513a01]",
          className
        )}
      >
        <Icon kind="exclamation-triangle" /> Warning
      </Badge>
    );
  } else if (status == "okay") {
    return (
      <Badge
        className={classNames(
          commonClassName,
          "bg-[#a9cc62] text-[#223206]",
          className
        )}
      >
        <Icon kind="check-circle" /> Okay
      </Badge>
    );
  } else {
    return (
      <Badge className={classNames(commonClassName, className)}>
        <Icon kind="info-circle" /> Info
      </Badge>
    );
  }
};

export default CheckStatusBadge;
