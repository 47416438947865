import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import memoize from "memoize-one";

import moment, { Moment } from "moment-timezone";
import { useRouteSearchState, useUnmounted } from "utils/hooks";
import { findByLabel, findByKey } from "utils/dateRange";
import { RouteStateUpdate } from "utils/routeState";

export type DateRange = {
  from: Moment;
  to: Moment;
  label?: string;
  horizon?: moment.Duration;
  unsyncable: boolean;
  earliest?: Moment;
};

export type DateRangeUpdateOpts = Partial<
  Omit<DateRange, "unsyncable" | "earliest">
> & {
  frozen?: boolean;
};
export type SetDateRange = (rangeUpdate: DateRangeUpdateOpts) => void;

// Since a  default date range does not make sense in our case and the type
// system can't enforce "clients must consume this context only if nested"
// within a provider, we provide a different implementation that matches
// the normal types but will fail fast.
const failFastUpdateHandler = () => {
  throw new Error("must be used in a provider");
};
export const DateRangeContext = React.createContext<[DateRange, SetDateRange]>([
  {
    from: moment(),
    to: moment(),
    unsyncable: false,
  },
  failFastUpdateHandler,
]);

export const useDateRange: () => [DateRange, SetDateRange] = () => {
  return useContext(DateRangeContext);
};

type Props = {
  initialHorizon?: moment.Duration;
};

type RouteRangeUpdateOpts = Pick<RouteStateUpdate, "replace">;
type RouteRange = Pick<DateRange, "from" | "to" | "label">;
type SetRouteRange = (
  rangeUpdate: RouteRange,
  opts?: RouteRangeUpdateOpts
) => void;

export const encodeRange = ({ from, to, label }: RouteRange): string => {
  if (!label) {
    return `${from.unix()}-${to.unix()}`;
  }

  const period = findByLabel(label);
  if (!period) {
    console.error("could not find period", label);
    return "";
  }

  return period.key;
};

function invalidRange(reason: string): RouteRange {
  console.error("could not parse date range:", reason);
  const to = moment();
  const from = to.clone().subtract(24, "hours");
  return {
    from,
    to,
    label: "invalid date range",
  };
}

export const decodeRange = (encoded: string): RouteRange => {
  const period = findByKey(encoded);
  if (period) {
    const [from, to] = period.range;
    return {
      label: period.label,
      from,
      to,
    };
  }

  const explicitRange = encoded.split("-");
  if (explicitRange.length !== 2) {
    return invalidRange("expected two parts");
  }
  const [fromStr, toStr] = explicitRange;
  const fromUnix = parseInt(fromStr, 10);
  const toUnix = parseInt(toStr, 10);
  if (isNaN(fromUnix) || isNaN(toUnix)) {
    return invalidRange("expected numbers");
  }
  return {
    from: moment.unix(fromUnix),
    to: moment.unix(toUnix),
  };
};

export const useRouteRange = (): [
  RouteRange & { default?: boolean },
  SetRouteRange
] => {
  const memoizedDecodeRange = useMemo(() => {
    // N.B: we can't call memoize inline in useRouteState, since
    // that's re-evaluated every render, and will lead to a new
    // memoized function on every render. That isn't helpful. We
    // memoize to ensure that symbolic labels like '24h' are not
    // decoded to a marginally different range each new render.
    return memoize(decodeRange);
  }, []);
  const [explicitRouteState, setRouteState] = useRouteSearchState({
    key: "t",
    encode: encodeRange,
    decode: memoizedDecodeRange,
  });
  const routeState = useMemo(() => {
    if (explicitRouteState) {
      return explicitRouteState;
    }
    const to = moment();
    const from = to.clone().subtract(24, "hours");
    const label = "Last 24 hours";
    return {
      from,
      to,
      label,
      default: true,
    };
  }, [explicitRouteState]);
  return [routeState, setRouteState];
};

// Note that WithDateRange can stack: if the currently selected parent `from`
// is after the child's horizon (if any), the parent range will be updated as
// well. If it is not, the parent range is left untouched, but the parent is
// "frozen"--no longer responding to updates in the route.
export const WithDateRange: React.FunctionComponent<Props> = ({
  children,
  initialHorizon,
}) => {
  const [horizon, setHorizon] = useState(initialHorizon);
  const earliest = useMemo(() => {
    return moment().subtract(horizon);
  }, [horizon]);
  const [frozen, setFrozen] = useState(false);
  const [routeRange, setRouteRange] = useRouteRange();

  const [range, setRange] = useState<Pick<DateRange, "from" | "to" | "label">>({
    from: routeRange.from,
    to: routeRange.to,
    label: routeRange.label,
  });

  const [parentRange, setParentRange] = useDateRange();
  const hasParent = setParentRange !== failFastUpdateHandler;
  const overlapSlack = moment.duration(5, "minutes");
  const freezeParent =
    hasParent &&
    earliest &&
    parentRange.from.isBefore(earliest.clone().subtract(overlapSlack));
  const unmounted = useUnmounted();
  useEffect(() => {
    // If the "earliest" specified for this range would cut off the
    // parent range, we want to freeze the parent range so it stops
    // synching with the route (so that e.g., Links between this range
    // and the child range get correct date range parameters)
    let frozeParent = false;
    if (hasParent && freezeParent) {
      frozeParent = true;
      setParentRange({ frozen: true });
    }
    return () => {
      // So eslint complains here with:
      //
      //   warning: The ref value 'unmounted.current' will likely have changed
      //   by the time this effect cleanup function runs. If this ref points
      //   to a node rendered by React, copy 'unmounted.current' to a variable
      //   inside the effect, and use that variable in the cleanup function
      //
      // but neither of these solutions actually work, because we depend on the
      // changed value: this is set by useUnmounted above during the cleanup
      // phase of the useEffect it is built on, which runs right before our
      // effect cleanup here as the component is unmounting. Disable for now.
      //
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (unmounted.current && frozeParent) {
        // TODO: technically, if this happens without navigation, it's
        // not enough: we need to also setRouteRange to the parent's
        // original range. However, it does not happen without navigation
        // right now.
        setParentRange({ frozen: false });
      }
    };
  }, [hasParent, freezeParent, setParentRange, unmounted]);
  useEffect(() => {
    const rangeMatchesRoute =
      !routeRange ||
      (routeRange.label && range.label && routeRange.label === range.label) ||
      (routeRange.from.isSame(range.from) && routeRange.to.isSame(range.to));
    if (!frozen && !rangeMatchesRoute) {
      setRange(routeRange);
    }
  }, [range, routeRange, frozen]);
  const currFrom = range.from;
  useEffect(() => {
    // If the selected range extends past our earliest point, we need to reset
    // it to available data. We give some slack to ensure the largest period
    // available (e.g., 30d for a 30d horizon) is not reset by this.
    if (
      !frozen &&
      earliest &&
      earliest.clone().subtract(1, "minute").isAfter(currFrom)
    ) {
      setRouteRange(
        {
          from: earliest,
          to: moment(),
        },
        { replace: true }
      );
    }
  }, [frozen, earliest, currFrom, setRouteRange]);

  const updateRange = useCallback(
    (opts: DateRangeUpdateOpts): void => {
      const { from, to, label, horizon, frozen } = opts;
      if (frozen !== undefined) {
        setFrozen(frozen);
      }
      if (horizon) {
        setHorizon(horizon);
      }

      if (from && to) {
        setRouteRange({ from, to, label });
      }
    },
    [setRouteRange]
  );
  const rangeContextValue: [DateRange, SetDateRange] = useMemo(() => {
    const exportedRange = {
      ...range,
      earliest,
      unsyncable: freezeParent,
      frozen,
    };
    return [exportedRange, updateRange];
  }, [frozen, range, earliest, freezeParent, updateRange]);
  return (
    <DateRangeContext.Provider value={rangeContextValue}>
      {children}
    </DateRangeContext.Provider>
  );
};
