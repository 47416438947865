import React from "react";

import Page from "components/Page";
import PageContent from "components/PageContent";
import Panel from "components/Panel";

import styles from "./style.module.scss";

interface Props {
  serverInstall: boolean;
  backlinkTitle: string | null;
  backlinkURL: string | null;
  title: string;
  content: string;
  organizationSlug: string | null;
  pageTitle: string | null;
  docId: string;
}

const DocsPage: React.FunctionComponent<Props> = ({
  serverInstall,
  backlinkTitle,
  backlinkURL,
  pageTitle,
  title,
  content,
  docId,
}) => {
  const titleContent =
    (pageTitle && <span dangerouslySetInnerHTML={{ __html: title }} />) ||
    "Documentation";
  const legacyContent = (
    <PageContent
      title={pageTitle || title}
      pageCategory="docs"
      pageName="show"
      pageProps={{ docId: docId }}
      featureInfo={
        !serverInstall && backlinkTitle ? (
          <a href={backlinkURL}>&#10094; {backlinkTitle}</a>
        ) : null
      }
    >
      <div className={styles.container}>
        <Panel title={titleContent}>
          <div className={styles.content}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </Panel>
      </div>
    </PageContent>
  );

  return <Page legacyContent={legacyContent} flashes={[]} />;
};

export default DocsPage;
