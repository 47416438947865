import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { formatBytes, formatNumber } from "utils/format";
import ContactSupportLink from "components/ContactSupportLink";
import Icon from "components/Icon";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import Popover from "components/Popover";

import {
  IndexCheck as IndexCheckType,
  IndexCheckVariables,
  IndexCheck_getQueryIndexCheck_tables_table as SchemaTable,
  IndexCheck_getQueryIndexCheck_tables as IndexCheckTable,
  IndexCheck_getQueryIndexCheck_tables_columnsIndexed_column as SchemaColumn,
  IndexCheck_getQueryIndexCheck_tables_columnsIndexed_index as SchemaIndex,
} from "./types/IndexCheck";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";

type Props = {
  databaseId: string;
  queryId: string;
};

const IndexCheck: React.FunctionComponent<Props> = ({
  databaseId,
  queryId,
}) => {
  const { data, loading, error } = useQuery<
    IndexCheckType,
    IndexCheckVariables
  >(QUERY, { variables: { queryId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { tables, timedOut, failedToParse } = data.getQueryIndexCheck;

  return (
    <div>
      {tables.map((t) => (
        <TableIndexCheck key={t.table.id} databaseId={databaseId} check={t} />
      ))}
      {tables.length === 0 && !timedOut && !failedToParse && (
        <Panel title="Index Check Failed">
          <PanelSection>
            <p>
              <strong>Error:</strong> Index Check could not recognize any of the
              tables in the query.
            </p>
            <p>
              This sometimes happens when your tables don't have recent
              statistics. If this issue persists please contact support.
            </p>
          </PanelSection>
        </Panel>
      )}
      {timedOut && (
        <Panel title="Index Check Timed Out">
          <PanelSection>
            <strong>Warning:</strong> Due to complexity, only parts of this
            query could be analyzed.
          </PanelSection>
        </Panel>
      )}
      {failedToParse && (
        <Panel title="Index Check Failed">
          <PanelSection>
            <p>
              <strong>Error:</strong> Could not parse query.
            </p>
            <p>
              The query string might have been truncated. Please{" "}
              <ContactSupportLink>contact support</ContactSupportLink> to learn
              more.
            </p>
          </PanelSection>
        </Panel>
      )}
    </div>
  );
};

const TableIndexCheck: React.FunctionComponent<{
  databaseId: string;
  check: IndexCheckTable;
}> = ({ databaseId, check }) => {
  const { databaseTable } = useRoutes();
  return (
    <Panel
      title={
        <Link to={databaseTable(databaseId, check.table.id)}>
          {check.table.tableName}
        </Link>
      }
      key={check.table.id}
    >
      <PanelTable horizontal={true}>
        <tbody>
          <tr>
            <th>Table Size</th>
            <td>{formatBytes(check.table.sizeBytes)}</td>
            <th>Total Visible Rows</th>
            <td>{formatNumber(check.table.liveTuples)}</td>
          </tr>
        </tbody>
      </PanelTable>
      {(check.columnsIndexed.length > 0 ||
        check.columnsMissingIndex.length > 0) && (
        <PanelSection>
          <h5 className={styles.checkTitle}>Index Check</h5>
          <div className={styles.indexCheckOk}>
            {check.columnsIndexed.map((ci) => (
              <IndexCheckOk
                key={ci.column.name}
                index={ci.index}
                column={ci.column}
                databaseId={databaseId}
              />
            ))}
          </div>
          <div className={styles.indexCheckMissing}>
            {check.columnsMissingIndex.map((column) => (
              <IndexCheckMissing
                key={column.name}
                table={check.table}
                column={column}
                databaseId={databaseId}
              />
            ))}
          </div>
        </PanelSection>
      )}
    </Panel>
  );
};

const IndexCheckOk: React.FunctionComponent<{
  databaseId: string;
  column: SchemaColumn;
  index: SchemaIndex;
}> = ({ databaseId, index, column }) => {
  const { databaseIndex } = useRoutes();
  return (
    <Popover
      popupClassName={styles.popoverPopup}
      title={<span className={styles.popoverTitle}>{index.name}</span>}
      content={
        <span className={styles.popoverContent}>{index.definitionShort}</span>
      }
      key={column.name}
      placement="top"
    >
      <Link to={databaseIndex(databaseId, index.id)}>
        <Icon kind="check-circle" />
        <span className={styles.column}>{column.name}</span>
      </Link>
    </Popover>
  );
};

const IndexCheckMissing: React.FunctionComponent<{
  databaseId: string;
  table: SchemaTable;
  column: SchemaColumn;
}> = ({ databaseId, table, column }) => {
  const { databaseTable } = useRoutes();
  return (
    <Popover
      content="Column might be missing an Index"
      key={column.name}
      placement="top"
    >
      <Link to={databaseTable(databaseId, table.id)}>
        <Icon kind="info-circle" />
        <span className={styles.column}>{column.name}</span>
      </Link>
    </Popover>
  );
};

export default IndexCheck;
