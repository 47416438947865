import React from "react";
import classNames from "classnames";

type Props = {
  title: string;
  danger?: boolean;
  confirm?: string;
};

class FormSubmit extends React.Component<Props> {
  render() {
    return (
      <div className="form-group">
        <button
          className={classNames(
            "btn",
            this.props.danger ? "btn-danger" : "btn-success"
          )}
          data-confirm={this.props.confirm}
        >
          {this.props.title}
        </button>
      </div>
    );
  }
}

export default FormSubmit;
