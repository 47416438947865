import React from "react";
import { Data, Scale, defined, xValue } from "./util";

type Props = {
  width: number;
  height: number;
  data: Data;
  xScale: Scale;
};

const UndefinedRegions: React.FunctionComponent<Props> = ({
  height,
  data,
  xScale,
}) => {
  const arbitrarySeries = Object.values(data)[0];
  type RegionChunks = {
    chunks: { start: number; end: number }[];
    prevDefined: boolean | undefined;
    lastDefinedX: number | undefined;
  };
  const regions = arbitrarySeries.reduce<RegionChunks>(
    (state, curr, idx) => {
      const isFirstPoint = idx === 0;
      const isLastPoint = idx === arbitrarySeries.length - 1;
      const isDefined = defined(curr);
      const currX = xValue(curr);
      if (isFirstPoint) {
        return {
          ...state,
          lastDefinedX: currX,
          prevDefined: isDefined,
        };
      } else if (
        !state.prevDefined &&
        (isDefined || isLastPoint) &&
        state.lastDefinedX !== undefined
      ) {
        // if we still have an open undefined region, we need to close
        // it, regardless of whether the current point is defined
        return {
          chunks: state.chunks.concat({
            start: state.lastDefinedX,
            end: currX,
          }),
          lastDefinedX: currX,
          prevDefined: true,
        };
      } else {
        return {
          ...state,
          prevDefined: isDefined,
          lastDefinedX: isDefined ? currX : state.lastDefinedX,
        };
      }
    },
    {
      chunks: [],
      lastDefinedX: undefined,
      prevDefined: undefined,
    }
  );
  return (
    <>
      <UndefinedPattern id="pattern-slashes" />
      {regions.chunks.map(({ start, end }) => {
        const startX = xScale(start);
        const endX = xScale(end);
        return (
          <rect
            fill="url(#pattern-slashes)"
            key={start}
            opacity="0.5"
            x={startX}
            y={0}
            width={endX - startX}
            height={height}
          />
        );
      })}
    </>
  );
};

const UndefinedPattern: React.FunctionComponent<{ id: string }> = ({ id }) => {
  const width = 10;
  const height = 10;
  const strokeWidth = 2.5;
  return (
    <pattern
      id={id}
      width={width}
      height={height}
      patternUnits="userSpaceOnUse"
    >
      <rect width={width} height={height} fill="rgb(245, 245, 245)" />
      <line
        x1={0}
        y1={height / 2}
        x2={width / 2}
        y2={0}
        stroke="rgb(210, 210, 210)"
        strokeLinecap="square"
        strokeWidth={strokeWidth}
      />
      <line
        x1={width / 2}
        y1={height}
        x2={width}
        y2={height / 2}
        stroke="rgb(210, 210, 210)"
        strokeLinecap="square"
        strokeWidth={strokeWidth}
      />
    </pattern>
  );
};

export default UndefinedRegions;
