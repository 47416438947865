---
title: 'Step 1: Configure RDS Instance'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import PublicStepOne from './_01_public.mdx'
import InAppStepOne from './_01_in_app.mdx'

<PublicOnly>
  <PublicStepOne />
</PublicOnly>
<InAppOnly>
  <InAppStepOne />
</InAppOnly>
