// Types for structure as produced by QueryAnalysis / pg_plan

export type QueryAnalysis = {
  explain: PlanElement[];
  tables: QueryTableStats[];
  indexes: QueryIndexStats[];
};

export type QueryAnalysisError = {
  error: string;
};

export type QueryAnalysisResult = QueryAnalysis | QueryAnalysisError;

export type QueryTableStats = {
  id: string;
  database_id: string;
  name: string;
  schema_name: string;
  size_bytes: number;
  n_live_tup: number;
};

export type QueryIndexStats = {
  id: string;
  database_id: string;
  name: string;
  schema_name: string;
  table_id: number;
  size_bytes: number;
};

export type PlanElement = {
  "Query Valid": boolean;
  Plan: PlanNode;
  Scans: Scan[];
  Schema: SchemaRelation[];
};

export type RestrictionClause = {
  ID: number;
  Expression: string;
  Selectivity: number;
  Nodes: string;
};

export type PlanNode = {
  "Node ID": number;
  "Node Type": string;
  "Scan Direction": string;
  "Index Name": string;
  "Relation Name": string;
  Alias: string;
  "Startup Cost": number;
  "Total Cost": number;
  "Plan Rows": number;
  "Plan Width": number;
  "Index Cond": string;
  "Index Cond Nodes": string;
  "Parent Relationship": string;
  "Parallel Aware": string;
  Filter: string;
  "Filter Nodes": string;
  Plans?: PlanNode[];
};

export type ScanIndex = {
  "Index OID": string;
  "Matching Restriction Clauses": number[];
  "Matching Join Clauses": number[];
};

export type SchemaIndex = {
  "Index OID": string;
  Name: string;
  Hypothetical: boolean;
  Definition: string;
  Type: "btree";
  "Size Bytes": 0;
  Tuples: 2550;
  "Tree Height": 1;
};

export type SchemaRelation = {
  "Relation OID": string;
  "Relation Name": string;
  "Namespace Name": string;
  "Relation Size Bytes": number;
  "Relation Tuples": number;
  Indexes: SchemaIndex[];
};

export type ScanOption = {
  Plan: PlanNode;
  Indexes: ScanIndex[];
};

export type Scan = {
  "Node ID": number;
  "Relation OID": string;
  "Restriction Clauses": RestrictionClause[];
  "Join Clauses": RestrictionClause[];
  Plans: ScanOption[];
};

// We still care about error results from individual Index Advisor analyses,
// but the "happy path" goes through different infrastructure

export type IndexAdvisorResult = {
  error?: string;
};

function isTableScan(scan: Scan): boolean {
  return scan["Relation OID"] != null;
}

export function transformQueryAnalysis(
  analysis: QueryAnalysisResult
): IndexAdvisorResult {
  if ("error" in analysis) {
    return { error: analysis.error };
  }
  const rootPlan = analysis.explain?.[0];
  if (!rootPlan || !rootPlan.Scans || !rootPlan.Scans.some(isTableScan)) {
    return { error: "Unsupported statement type" };
  }

  return {};
}
