import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ContactSupportLink from "components/ContactSupportLink";
import ExpandableSQL from "components/ExpandableSQL";
import { useCurrentServer } from "components/WithCurrentOrganization";

import styles from "./style.module.scss";

const LOG_EXPLAIN_SOURCE = "STATEMENT_LOG_EXPLAIN_SOURCE";
const AUTO_EXPLAIN_EXPLAIN_SOURCE = "AUTO_EXPLAIN_EXPLAIN_SOURCE";

const QueryExplainError: React.FunctionComponent<{
  error: string;
  source: string;
  queryText?: string;
}> = ({ error, source, queryText }) => {
  const { humanId: serverId } = useCurrentServer();
  const { serverConfigSetting } = useRoutes();
  const isPermissionError =
    source === LOG_EXPLAIN_SOURCE && error.startsWith("pq: permission denied");
  const isSchemaError =
    source === LOG_EXPLAIN_SOURCE &&
    /^pq: relation ".+" does not exist/.test(error);
  const isFilteringError =
    source === AUTO_EXPLAIN_EXPLAIN_SOURCE &&
    error.startsWith(
      "EXPLAIN normalize failed: auto_explain format is not JSON"
    );

  // strip the 'pq: ' error from the prefix since that's not relevant and capitalize the first letter
  const explainError =
    (isPermissionError || isSchemaError) &&
    error.replace(/^pq: (\w)/, (_match, capture) => capture.toUpperCase());

  return (
    <Panel title="Failed to capture EXPLAIN plan">
      <PanelSection>
        {isPermissionError ? (
          <ErrorDetail error={explainError}>
            <p>
              Please ensure you have{" "}
              <a
                rel="noopener"
                href="https://github.com/pganalyze/collector#setting-up-log-explain-helper"
                target="_blank"
              >
                configured the <code>pganalyze.explain</code> helper function
              </a>{" "}
              or that the pganalyze database user has appropriate permissions on
              any tables, schemas, functions, and other database objects
              referenced in this query.
            </p>
          </ErrorDetail>
        ) : isSchemaError ? (
          <ErrorDetail error={explainError}>
            <p>
              This may be because the query references a temporary table that is
              not accessible to the separate collector EXPLAIN query, or because
              the Postgres <code>search_path</code> setting for the collector
              monitoring user is different than for the session running the
              query. In the latter case, you can run{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://www.postgresql.org/docs/current/sql-alteruser.html"
              >
                <code>ALTER USER pganalyze SET search_path ...</code>
              </a>{" "}
              to update the monitoring user search path.
            </p>
          </ErrorDetail>
        ) : isFilteringError ? (
          <ErrorDetail error={explainError}>
            <p>
              This is because you are using the collector setting{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://pganalyze.com/docs/collector/settings#pii-filtering-settings"
              >
                <code>filter_query_sample</code>
              </a>{" "}
              but{" "}
              <Link
                to={serverConfigSetting(serverId, "auto_explain.log_format")}
              >
                auto_explain.log_format
              </Link>{" "}
              is set to to a value other than <code>json</code>. This
              configuration is not supported. You can either change the format
              to <code>json</code> or disable <code>auto_explain</code>.
            </p>
          </ErrorDetail>
        ) : (
          <ErrorDetail error="Failed to capture EXPLAIN plan for this query execution">
            <p className={styles.otherError}>
              Please <ContactSupportLink>contact support</ContactSupportLink>.
            </p>
          </ErrorDetail>
        )}
      </PanelSection>
      {queryText && (
        <PanelSection>
          <ExpandableSQL sql={"EXPLAIN (VERBOSE, FORMAT JSON) " + queryText} />
        </PanelSection>
      )}
    </Panel>
  );
};

const ErrorDetail: React.FunctionComponent<{ error: string }> = ({
  error,
  children,
}) => {
  return (
    <>
      <p>
        <strong>Error:</strong> {error}
      </p>
      {children}
    </>
  );
};

export default QueryExplainError;
