import React from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useMutation, useQuery } from "@apollo/client";

import {
  ViewPermission,
  ModifyPermission,
  BillingPermission,
  APIPermission,
  ManagePermission,
} from "components/DocsSnippet";
import SetAlertPolicy from "components/SetAlertPolicy";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import PermissionIcon from "components/PermissionIcon";
import Loading from "components/Loading";
import CopyToClipboard from "components/CopyToClipboard";
import { useAppConfig } from "components/WithAppConfig";

import {
  MemberDetails as MemberDetailsType,
  MemberDetailsVariables,
  MemberDetails_getOrganizationMembershipDetails,
  MemberDetails_getOrganizationMembershipDetails_permissions,
} from "./types/MemberDetails";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";

import styles from "./style.module.scss";
import { useRoutes } from "utils/routes";
import { useAsyncActionFlash } from "components/WithFlashes";
import {
  DeleteOrganizationMemberMutation,
  DeleteOrganizationMemberMutationVariables,
} from "./types/DeleteOrganizationMemberMutation";

const MemberDetails: React.FunctionComponent = () => {
  const { slug: organizationSlug, id: membershipId } = useParams();
  const { organizationMemberEdit, organizationResendInvite } = useRoutes();
  const appConfig = useAppConfig();
  const { data, loading, error } = useQuery<
    MemberDetailsType,
    MemberDetailsVariables
  >(QUERY, {
    variables: { membershipId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const member = data.getOrganizationMembershipDetails;
  const { permissions, permittedToEdit } = member;

  const inviteUrl = member.invitePath
    ? window.location.origin + member.invitePath
    : null;

  return (
    <PageContent
      title={`Organization Member: ${
        (member.accepted && member.user.fullname) || member.email
      }`}
      pageCategory="organization-members"
      pageName="show"
    >
      <Panel title="Member Details">
        <PanelSection>
          <dl>
            <dt>Name:</dt>
            <dd>{(member.accepted && member.user.fullname) || "-"}</dd>
            <dt>Email:</dt>
            <dd>{member.email}</dd>
            <dt>Joined:</dt>
            <dd>{moment.unix(member.createdAt).format("YYYY-MM-DD")}</dd>
          </dl>
        </PanelSection>
        {!member.accepted && (
          <PanelSection>
            <p>This member has not accepted their invite yet.</p>
            {permittedToEdit ? (
              <>
                {appConfig.emailIntegrationAvailable && (
                  <p>
                    <Link
                      to={organizationResendInvite(organizationSlug, member.id)}
                      data-method="post"
                      title={`Resend invite to ${member.email}`}
                      className="btn btn-success"
                    >
                      Resend invite email
                    </Link>
                  </p>
                )}
                <strong>Share this invite URL with them:</strong>
                <CopyToClipboard
                  className="btn btn-small btn-link"
                  content={inviteUrl}
                  label="Copy to clipboard"
                />
              </>
            ) : (
              <p>
                You don't have the <strong>Manage</strong> permission needed to
                access member invites.
              </p>
            )}
            <pre>{inviteUrl}</pre>
          </PanelSection>
        )}
      </Panel>

      <SetAlertPolicy
        organizationSlug={organizationSlug}
        membershipId={member.id}
      />

      <Panel title="Assigned Roles & Permissions">
        <PanelTable>
          <thead>
            <tr>
              <th>Role</th>
              <th>Scope</th>
              <th className={styles.permission}>
                View <ViewPermission />
              </th>
              <th className={styles.permission}>
                Modify <ModifyPermission />
              </th>
              <th className={styles.permission}>
                Billing <BillingPermission />
              </th>
              <th className={styles.permission}>
                API <APIPermission />
              </th>
              <th className={styles.permission}>
                Manage <ManagePermission />
              </th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((p) => (
              <MemberPermission
                key={p.id}
                permission={p}
                organizationSlug={organizationSlug}
              />
            ))}
          </tbody>
        </PanelTable>
        {permittedToEdit && (
          <PanelSection>
            <Link
              to={organizationMemberEdit(organizationSlug, member.id)}
              className="btn btn-success"
            >
              Edit Assigned Roles
            </Link>
          </PanelSection>
        )}
      </Panel>
      <RemoveMembership member={member} organizationSlug={organizationSlug} />
    </PageContent>
  );
};

const RemoveMembership: React.FunctionComponent<{
  member: MemberDetails_getOrganizationMembershipDetails;
  organizationSlug: string;
}> = ({ member, organizationSlug }) => {
  const { permittedToRemove, userIsSelf } = member;

  const [
    deleteMember,
    { called: mutationCalled, loading: mutationLoading, error: mutationError },
  ] = useMutation<
    DeleteOrganizationMemberMutation,
    DeleteOrganizationMemberMutationVariables
  >(MUTATION);

  const { root, organizationMembers } = useRoutes();

  useAsyncActionFlash({
    called: mutationCalled,
    loading: mutationLoading,
    error: mutationError?.message,
    success: `Removed ${
      member.user ? member.user.fullname : "invited member"
    } from the organization`,
    successRedirect: userIsSelf
      ? root()
      : organizationMembers(organizationSlug),
  });

  if (!permittedToRemove && !userIsSelf) {
    return null;
  }

  if (!permittedToRemove && userIsSelf) {
    return (
      <Panel title="Leave Organization">
        <PanelSection>
          You cannot leave this organization as the only member with the{" "}
          <strong>Manage</strong> permission.
        </PanelSection>
      </Panel>
    );
  }

  const handleDelete = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (window.confirm(`Remove ${member.email} from this organization?`)) {
      deleteMember({ variables: { membershipId: member.id } });
    }
  };

  return (
    <Panel title={userIsSelf ? "Leave Organization" : "Removal"}>
      <form onSubmit={handleDelete}>
        <p>
          <i className="fa fa-warning" />
          &nbsp;&nbsp;
          {userIsSelf ? (
            <span>
              Leaving this organization will immediately revoke all your
              permissions and you will need a new invitation to join this
              organization again.
            </span>
          ) : (
            <span>
              Removing this member will remove all assigned roles from them and
              remove them from the organization.
            </span>
          )}
        </p>
        <p>
          <div className="form-group">
            <button className="btn btn-danger">
              {(userIsSelf && "Leave Organization") || "Remove Member"}
            </button>
          </div>
        </p>
      </form>
    </Panel>
  );
};

const MemberPermission: React.FunctionComponent<{
  permission: MemberDetails_getOrganizationMembershipDetails_permissions;
  organizationSlug: string;
}> = ({ permission, organizationSlug }) => {
  const {
    id,
    database,
    server,
    view,
    modify,
    billing,
    api,
    manage,
    restricted,
    role,
  } = permission;

  const { organizationRole } = useRoutes();
  return (
    <tr key={id}>
      <td>
        <Link to={organizationRole(organizationSlug, role.id)}>
          {role.name}
        </Link>
      </td>
      <td>
        {(database && "Database: " + database.displayName) ||
          (server && "Server: " + server.name) ||
          "All Databases/Servers"}
      </td>
      <td className={styles.permission}>
        <PermissionIcon permitted={view} restricted={restricted} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permitted={modify} restricted={restricted} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permitted={billing} restricted={restricted} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permitted={api} restricted={restricted} />
      </td>
      <td className={styles.permission}>
        <PermissionIcon permitted={manage} restricted={restricted} />
      </td>
    </tr>
  );
};

export default MemberDetails;
