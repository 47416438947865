import React from "react";

import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";

import classNames from "classnames";

import styles from "./style.module.scss";

const TabPanel: React.FunctionComponent = ({ children }) => {
  const hasTabs = React.Children.count(children) > 0;
  if (!hasTabs) {
    return null;
  }

  return <Tabs>{children}</Tabs>;
};

export const TabBar: React.FunctionComponent = ({ children }) => {
  return <div className={styles.tabBar}>{children}</div>;
};

export const TabContent: React.FunctionComponent = ({ children }) => {
  const isActive = usePanelState();
  return isActive ? (
    <div className={styles.activeTabContent}>{children}</div>
  ) : null;
};

export const Tab: React.FunctionComponent<{
  name: string;
  className?: string;
}> = ({ name, className }) => {
  const { onClick, isActive } = useTabState();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a
      href="#"
      className={classNames(
        styles.tab,
        {
          [styles.activeTab]: isActive,
        },
        className
      )}
      onClick={handleClick}
    >
      {name}
    </a>
  );
};

export default TabPanel;
