import React, { useState, useMemo } from "react";
import classNames from "classnames";
import { useLazyQuery } from "@apollo/client";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import Button from "components/Button";
import { formatPercent } from "utils/format";

import QUERY from "./Query.graphql";
import Loading from "components/Loading";
import sortBy from "lodash/sortBy";

const Slider = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (_: number) => void;
}) => (
  <input
    className="max-w-sm ml-2.5"
    type="range"
    min="0"
    max="100"
    value={Math.floor(value * 100)}
    onChange={(e) => {
      onChange(parseInt(e.target.value) / 100);
    }}
  />
);

const IndexSelection: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
}> = ({ databaseId, tableId }) => {
  // Max coverage strictness
  const [maxCov, setMaxCov] = useState(0.95);
  // Index write overhead strictness
  const [iwo, setIwo] = useState(1);
  // Minimize total possible indexes tolerance
  const [minPosInd, setMinPosInd] = useState(1);

  const options: any = {
    Constraints: {},
    Goals: [
      { Name: "Maximal Coverage", Strictness: maxCov, Options: null },
      { Name: "Minimal IWO", Strictness: iwo, Options: null },
      {
        Name: "Minimal Indexes (Possible Indexes Only)",
        Strictness: minPosInd,
        Options: null,
      },
    ],
  };

  // JSON Editor mode and value
  const [isJsonView, setIsJsonView] = useState(false);
  const [jsonOptions, setJsonOptions] = useState(
    JSON.stringify(options, null, 2)
  );

  const variables = {
    databaseId,
    tableId,
    options: isJsonView ? jsonOptions : JSON.stringify(options),
  };

  const [refetch, { data, loading, error }] = useLazyQuery(QUERY);

  const handleCalculateSelection = () => refetch({ variables });

  return (
    <div>
      <Panel
        title={
          <div>
            Index Selection Settings
            <label className="p-1 ml-6 align-middle text-sm font-semibold">
              <input
                type="checkbox"
                checked={isJsonView}
                onChange={() => setIsJsonView(!isJsonView)}
              />
              <span className="ml-1">JSON Editor</span>
            </label>
          </div>
        }
      >
        {isJsonView ? (
          <div className="p-2">
            <pre>
              <textarea
                rows={20}
                className="bg-transparent w-full"
                value={jsonOptions}
                onChange={(e) => setJsonOptions(e.target.value)}
              />
            </pre>
          </div>
        ) : (
          <PanelTable borders>
            <thead>
              <tr>
                <th className="width-2/5">Objective</th>
                <th className="width-1/5">Strictness</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. Maximum Coverage</td>
                <td style={{ display: "flex" }}>
                  <div style={{ width: "2.5em" }}>
                    {formatPercent(maxCov, 0)}
                  </div>
                  <Slider value={maxCov} onChange={setMaxCov} />
                </td>
              </tr>
              <tr>
                <td>2. Minimize Index Write Overhead</td>
                <td style={{ display: "flex" }}>
                  <div style={{ width: "2.5em" }}>{formatPercent(iwo, 0)}</div>
                  <Slider value={iwo} onChange={setIwo} />
                </td>
              </tr>
              <tr>
                <td>3. Minimize total number of possible indexes</td>
                <td style={{ display: "flex" }}>
                  <div style={{ width: "2.5em" }}>
                    {formatPercent(minPosInd, 0)}
                  </div>
                  <Slider value={minPosInd} onChange={setMinPosInd} />
                </td>
              </tr>
            </tbody>
          </PanelTable>
        )}
        <PanelSection>
          <Button onClick={handleCalculateSelection}>
            Calculate New Selection
          </Button>
        </PanelSection>
      </Panel>
      <Panel title="Index Selection Results">
        {loading || error ? (
          <Loading error={!!error} />
        ) : data ? (
          <IndexSelectionResults
            result={data.getSchemaTableIndexSelection.data}
          />
        ) : (
          <PanelSection>Calculate to start</PanelSection>
        )}
      </Panel>
    </div>
  );
};

const Card: React.FunctionComponent<{
  title?: string;
  secondaryTitle?: string;
  faded?: boolean;
  children?: React.ReactNode;
}> = ({ title, secondaryTitle, faded, children }) => (
  <div
    className={classNames(
      "rounded p-2.5 mx-0 my-2.5 mr-5 border border-solid border-neutral-200",
      faded && "opacity-40"
    )}
  >
    <div className="flex justify-between">
      {title && <div className="font-bold">{title}</div>}
      {secondaryTitle && <div className="font-bold">{secondaryTitle}</div>}
    </div>
    {children}
  </div>
);

type ScanOutput = {
  "Scan ID": string;
  "Sequential Scan Cost": number;
  "Existing Index Costs": {
    "Index OID": number;
    Cost: number | null;
  }[];
  "Possible Index Costs": {
    "Index OID": number;
    Cost: number | null;
  }[];
};

type IndexInformation = {
  "Index OID": number;
  Name: string;
  Type: string;
  Hypothetical: boolean;
  "Size Bytes": number;
  Tuples: number;
  "Tree Height": number;
  Definition: string;
};

type IndexType = {
  Index: IndexInformation;
  "Index Write Overhead": number;
};

type PossibleIndexType = IndexType & {
  Selected: boolean;
};

type InputType = {
  Scans: ScanOutput[];
};

// TODO: This is subject to change, and we don't use it at the moment.
type SelectionOutput = unknown;

type ExplainOutput = {
  "Existing Indexes": IndexType[];
  "Possible Indexes": PossibleIndexType[];
  Scans: ScanOutput[];
};

type OutputType = [SelectionOutput, ExplainOutput];

type ScanAnalysis = {
  outputByScanId: { [key: string]: ScanOutput };
  selectingScansByIndexOid: { [key: number]: string[] };
  alternateScansByIndexOid: { [key: number]: string[] };
  scanShortName: { [key: string]: string };
};

const IndexShortName: React.FunctionComponent<{
  indexOid: number;
  cost: number;
  existingIndexOids: Set<number>;
  selectedIndexOids: Set<number>;
  indexShortName: { [key: number]: string };
}> = ({
  indexOid,
  cost,
  existingIndexOids,
  selectedIndexOids,
  indexShortName,
}) => {
  const selectionStatusBorder = existingIndexOids.has(indexOid)
    ? "border-blue-400"
    : selectedIndexOids.has(indexOid)
    ? "border-green-400"
    : "border-gray-400";
  const selectionStatusColor = selectedIndexOids.has(indexOid)
    ? "text-black"
    : "text-gray-400";
  return (
    <span
      className={classNames(
        "inline-block rounded-full w-6 leading-5 text-xs mr-0.5 mt-0.5 text-center border-solid border",
        selectionStatusColor,
        selectionStatusBorder
      )}
      title={`Cost: ${cost}`}
    >
      {indexOid == 0 ? "Seq" : indexShortName[indexOid]}
    </span>
  );
};

const ScanShortName: React.FunctionComponent<{
  scanId: string;
  best: boolean;
  scanShortName: { [key: string]: string };
}> = ({ scanId, best, scanShortName }) => {
  const selectionStatusBorder = best ? "border-green-400" : "border-gray-400";
  const selectionStatusColor = best ? "text-black" : "text-gray-400";
  return (
    <span
      className={classNames(
        "inline-block rounded-full w-6 leading-5 text-xs mr-0.5 mt-0.5 text-center border-solid border",
        selectionStatusColor,
        selectionStatusBorder
      )}
    >
      {scanShortName[scanId]}
    </span>
  );
};

const Scan: React.FunctionComponent<{
  scan: ScanOutput;
  existingIndexOids: Set<number>;
  selectedIndexOids: Set<number>;
  indexShortName: { [key: number]: string };
  scanAnalysis: ScanAnalysis;
}> = ({
  scan,
  existingIndexOids,
  selectedIndexOids,
  indexShortName,
  scanAnalysis,
}) => {
  const scanId = scan["Scan ID"];
  const indexes = sortBy(
    [
      {
        "Index OID": 0,
        Cost: scanAnalysis.outputByScanId[scanId]["Sequential Scan Cost"],
      },
    ].concat(
      scanAnalysis.outputByScanId[scanId]["Existing Index Costs"].concat(
        scanAnalysis.outputByScanId[scanId]["Possible Index Costs"]
      )
    ),
    (s) => s["Cost"]
  );
  return (
    <Card
      title={scanId}
      secondaryTitle={scanAnalysis.scanShortName[scanId]}
      key={scanId}
    >
      <div className="m-0 font-mono overflow-hidden truncate whitespace-pre mb-2">
        {scan["Restriction Clauses"].concat(scan["Join Clauses"]).join("\n")}
      </div>
      {indexes.map((c) => (
        <IndexShortName
          key={c["Index OID"]}
          indexOid={c["Index OID"]}
          cost={c["Cost"]}
          existingIndexOids={existingIndexOids}
          selectedIndexOids={selectedIndexOids}
          indexShortName={indexShortName}
        />
      ))}
    </Card>
  );
};

const PossibleIndex: React.FunctionComponent<{
  index: IndexType;
  indexShortName: { [key: number]: string };
}> = ({ index, indexShortName }) => {
  return (
    <Card
      faded={!index["Selected"]}
      title={index["Index"]["Name"]}
      secondaryTitle={indexShortName[index["Index"]["Index OID"]]}
      key={index["Index OID"]}
    >
      {index["Index"]["Definition"].match(/USING (.*)/)[1]}
    </Card>
  );
};

const SelectedIndex: React.FunctionComponent<{
  index: IndexType;
  indexShortName: { [key: number]: string };
  scanAnalysis: ScanAnalysis;
}> = ({ index, indexShortName, scanAnalysis }) => {
  return (
    <Card
      title={index["Index"]["Name"]}
      secondaryTitle={indexShortName[index["Index"]["Index OID"]]}
      key={index["Index"]["Index OID"]}
    >
      <p className="overflow-auto break-words mb-0">
        {index["Index"]["Definition"]}
      </p>
      {(
        scanAnalysis.selectingScansByIndexOid[index["Index"]["Index OID"]] || []
      ).map((scanId) => (
        <ScanShortName
          key={scanId}
          scanId={scanId}
          best={true}
          scanShortName={scanAnalysis.scanShortName}
        />
      ))}
      {(
        scanAnalysis.alternateScansByIndexOid[index["Index"]["Index OID"]] || []
      ).map((scanId) => (
        <ScanShortName
          key={scanId}
          scanId={scanId}
          best={false}
          scanShortName={scanAnalysis.scanShortName}
        />
      ))}
    </Card>
  );
};

const analyzeScans: (
  scans: ScanOutput[],
  selectedIndexOids: Set<number>
) => ScanAnalysis = (scans, selectedIndexOids) => {
  const indexCostsByScanId: { [key: string]: { [key: number]: number } } = {};
  const analysis: ScanAnalysis = {
    outputByScanId: {},
    selectingScansByIndexOid: {},
    alternateScansByIndexOid: {},
    scanShortName: {},
  };

  scans.forEach((scan: ScanOutput, idx: number) => {
    analysis.scanShortName[scan["Scan ID"]] = "S" + (idx + 1);

    scan["Existing Index Costs"] = scan["Existing Index Costs"].filter((c) => {
      return !!c["Cost"];
    });
    scan["Possible Index Costs"] = scan["Possible Index Costs"].filter((c) => {
      return !!c["Cost"];
    });
    indexCostsByScanId[scan["Scan ID"]] = {};
    scan["Existing Index Costs"].forEach((c) => {
      indexCostsByScanId[scan["Scan ID"]][c["Index OID"]] = c["Cost"];
    });
    scan["Possible Index Costs"].forEach((c) => {
      indexCostsByScanId[scan["Scan ID"]][c["Index OID"]] = c["Cost"];
    });

    const indexCosts = Object.entries(indexCostsByScanId[scan["Scan ID"]]);

    let minCostIdx = -1;
    indexCosts.forEach(([indexOid, cost], idx: number) => {
      if (
        selectedIndexOids.has(Number(indexOid)) &&
        (minCostIdx == -1 || cost < indexCosts[minCostIdx][1])
      ) {
        minCostIdx = idx;
      }
    });

    if (minCostIdx != -1) {
      const selectingForIndexOid = indexCosts[minCostIdx][0];
      if (!analysis.selectingScansByIndexOid[selectingForIndexOid]) {
        analysis.selectingScansByIndexOid[selectingForIndexOid] = [];
      }
      analysis.selectingScansByIndexOid[selectingForIndexOid].push(
        scan["Scan ID"]
      );
    }

    indexCosts.forEach(([indexOid], idx: number) => {
      if (idx == minCostIdx) {
        return;
      }

      if (!analysis.alternateScansByIndexOid[indexOid]) {
        analysis.alternateScansByIndexOid[indexOid] = [];
      }
      analysis.alternateScansByIndexOid[indexOid].push(scan["Scan ID"]);
    });

    analysis.outputByScanId[scan["Scan ID"]] = scan;
  });

  return analysis;
};

const IndexSelectionResults: React.FunctionComponent<{
  result: string;
}> = ({ result }) => {
  const { input, output }: { input: InputType; output: OutputType[] } = useMemo(
    () => JSON.parse(result),
    [result]
  );
  const explain = output[1];

  const indexShortName = {};
  const existingIndexOids = new Set<number>();
  const selectedIndexOids = new Set<number>();
  const selectedIndexDetails = {};

  explain["Existing Indexes"].forEach((index: IndexType, idx: number) => {
    indexShortName[index["Index"]["Index OID"]] = "X" + (idx + 1);
    existingIndexOids.add(index["Index"]["Index OID"]);
    selectedIndexOids.add(index["Index"]["Index OID"]);
    selectedIndexDetails[index["Index"]["Index OID"]] = index;
  });
  explain["Possible Indexes"].forEach(
    (index: PossibleIndexType, idx: number) => {
      indexShortName[index["Index"]["Index OID"]] = "I" + (idx + 1);
      if (index["Selected"]) {
        selectedIndexOids.add(index["Index"]["Index OID"]);
        selectedIndexDetails[index["Index"]["Index OID"]] = index;
      }
    }
  );

  const scanAnalysis = analyzeScans(explain["Scans"], selectedIndexOids);

  return (
    <div className="pl-2">
      <PanelTable className="table-fixed mx-0 w-full">
        <thead>
          <tr>
            <th>Scans on Table</th>
            <th>Possible Indexes</th>
            <th>Selected Indexes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top w-[30%]">
              {input["Scans"].map((scan) => (
                <Scan
                  key={scan["Scan ID"]}
                  scan={scan}
                  existingIndexOids={existingIndexOids}
                  selectedIndexOids={selectedIndexOids}
                  indexShortName={indexShortName}
                  scanAnalysis={scanAnalysis}
                />
              ))}
            </td>
            <td className="align-top w-[30%]">
              {explain["Possible Indexes"].map((index: PossibleIndexType) => (
                <PossibleIndex
                  key={index["Index"]["Index OID"]}
                  index={index}
                  indexShortName={indexShortName}
                />
              ))}
            </td>
            <td className="align-top w-[30%]">
              {explain["Possible Indexes"]
                .filter((index: PossibleIndexType) => index["Selected"])
                .map((index: PossibleIndexType) => (
                  <SelectedIndex
                    key={index["Index"]["Index OID"]}
                    index={index}
                    indexShortName={indexShortName}
                    scanAnalysis={scanAnalysis}
                  />
                ))}
              {explain["Existing Indexes"]
                /* TODO: Once the existing indexes also have a Selected field in the model output, use it here */
                .map((index: IndexType) => (
                  <SelectedIndex
                    key={index["Index"]["Index OID"]}
                    index={index}
                    indexShortName={indexShortName}
                    scanAnalysis={scanAnalysis}
                  />
                ))}
            </td>
          </tr>
        </tbody>
      </PanelTable>
    </div>
  );
};

export default IndexSelection;
