import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Column } from "react-virtualized";
import { SortDirection } from "types/graphql-global-types";

import { formatBytes, formatNumber } from "utils/format";
import Loading from "components/Loading";
import PaginatedVirtualTable, {
  cellStyles,
} from "components/PaginatedVirtualTable";

import {
  SchemaIndexList,
  SchemaIndexListVariables,
  SchemaIndexList_getSchemaIndices,
  SchemaIndexList_getSchemaIndices_issues,
} from "./types/SchemaIndexList";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";
import { useFeature } from "components/OrganizationFeatures";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  searchTerm: string | null;
  databaseId: string;
}

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaIndex: SchemaIndexList_getSchemaIndices;
}> = ({ databaseId, schemaIndex }) => {
  const { databaseIndex } = useRoutes();
  return (
    <span>
      <Link to={databaseIndex(databaseId, schemaIndex.id)}>
        {schemaIndex.name}
      </Link>
      {schemaIndex.issues.map(
        (i: SchemaIndexList_getSchemaIndices_issues): React.ReactNode => (
          <Link
            to={databaseIndex(databaseId, schemaIndex.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        )
      )}
    </span>
  );
};

// Handle the mapping between the union type (react-virtualized) and enum in GraphQL
const sortDirectionStrToEnum: { [key in "ASC" | "DESC"]: SortDirection } = {
  ASC: SortDirection.ASC,
  DESC: SortDirection.DESC,
};
const sortDirectionEnumToStr: {
  [key in keyof typeof SortDirection]: "ASC" | "DESC";
} = {
  [SortDirection.ASC]: "ASC",
  [SortDirection.DESC]: "DESC",
};

const Table: React.FunctionComponent<Props> = (props) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const [sortOpts, setSortOpts] = useState<SortOptsType>({
    sortBy: "sizeBytes",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaIndexList,
    SchemaIndexListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: 50,
      filter: props.searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId: props.databaseId,
    },
  });
  if (loading || error || !data.getSchemaIndices) {
    return <Loading error={!!error} />;
  }

  const schemaIndices = data.getSchemaIndices;

  const fetchMoreData = (
    offset: number,
    limit: number,
    promiseResolver: () => void
  ) => {
    fetchMore({
      variables: {
        offset: offset,
        limit: limit,
      },
      updateQuery: (
        prev: SchemaIndexList,
        { fetchMoreResult }: { fetchMoreResult: SchemaIndexList }
      ): SchemaIndexList => {
        if (!fetchMoreResult) {
          return prev;
        }
        promiseResolver();
        return Object.assign({}, prev, {
          getSchemaIndices: [
            ...prev.getSchemaIndices,
            ...fetchMoreResult.getSchemaIndices,
          ],
        });
      },
    });
  };

  const handleSort = ({ sortBy, sortDirection }: SortOptsType) => {
    setSortOpts({
      sortBy,
      sortDirection: sortDirectionStrToEnum[sortDirection],
    });
  };

  return (
    <PaginatedVirtualTable
      data={schemaIndices}
      fetchMore={fetchMoreData}
      sortBy={sortOpts.sortBy}
      sortDirection={sortDirectionEnumToStr[sortOpts.sortDirection]}
      handleSort={handleSort}
    >
      <Column
        dataKey="name"
        label="Index Name"
        width={200}
        flexGrow={1}
        cellDataGetter={({
          rowData,
        }: {
          rowData: SchemaIndexList_getSchemaIndices;
        }): SchemaIndexList_getSchemaIndices => rowData}
        cellRenderer={({
          cellData,
        }: {
          cellData?: SchemaIndexList_getSchemaIndices;
        }): React.ReactNode => (
          <NameAndIssuesCell
            databaseId={props.databaseId}
            schemaIndex={cellData}
          />
        )}
      />
      <Column dataKey="tableName" label="Table Name" width={200} flexGrow={1} />
      <Column
        dataKey="sizeBytes"
        label="Index Size"
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        width={100}
        defaultSortDirection={SortDirection.DESC}
        cellRenderer={({ cellData }: { cellData?: number }): React.ReactNode =>
          cellData === null ? "n/a" : formatBytes(cellData)
        }
      />
      {hasIndexAdvisor && (
        <Column
          dataKey="writeOverhead"
          label="Write Overhead"
          className={cellStyles.number}
          headerClassName={cellStyles.numberHeader}
          width={120}
          defaultSortDirection={SortDirection.DESC}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode =>
            cellData == null ? "n/a" : formatNumber(cellData, 2)
          }
        />
      )}
    </PaginatedVirtualTable>
  );
};

export default Table;
