import React, { useState } from "react";
import { useParams } from "react-router-dom";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";
import Results from "./Results";

import { useQuery } from "@apollo/client";
import { useRouteHashState } from "utils/hooks";

import QUERY from "./Query.graphql";
import QueryTagBlankSlate from "components/QueryTagBlankSlate";
import Badge from "components/Badge";
import Icon from "components/Icon";

const QuerySearch: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <QuerySearchContent
      databaseId={databaseId}
      serverId={data.getServerDetails.humanId}
      tagsetKeys={data.getQueryTagKeys}
    />
  );
};

type ContentProps = {
  databaseId: string;
  serverId: string;
  tagsetKeys: string[];
};

const QuerySearchContent: React.FunctionComponent<ContentProps> = ({
  databaseId,
  serverId,
  tagsetKeys,
}) => {
  return (
    <PageContent title="Query Search" pageCategory="queries" pageName="search">
      {tagsetKeys.length === 0 ? (
        <QueryTagBlankSlate
          serverId={serverId}
          newFeatureBadge={
            <Badge title="New feature - feedback welcome!">
              <Icon kind="lightbulb-o" /> New
            </Badge>
          }
        />
      ) : (
        <QuerySearchPanel
          databaseId={databaseId}
          serverId={serverId}
          tagsetKeys={tagsetKeys}
        />
      )}
    </PageContent>
  );
};

type QuerySearchHashState = {
  searchTagKey: string;
  searchTagValue: string;
};

const QuerySearchPanel: React.FunctionComponent<ContentProps> = ({
  tagsetKeys,
  databaseId,
}) => {
  const [routeHashState, setRouteHashState] =
    useRouteHashState<QuerySearchHashState>({
      encode: (value: QuerySearchHashState): string => {
        return `qtk=${value.searchTagKey},qtv=${value.searchTagValue}`;
      },
      decode: (str: string): QuerySearchHashState => {
        const parts = str
          .split(",")
          .reduce((state: { [k: string]: string }, part: string) => {
            const [k, v] = part.split("=", 2);
            if (k && v) {
              state[k] = v;
            }
            return state;
          }, {});

        return {
          searchTagKey: parts.qtk,
          searchTagValue: parts.qtv,
        };
      },
    });

  const routeSearchKey = routeHashState?.searchTagKey || "";
  const routeSearchValue = routeHashState?.searchTagValue || "";
  const firstKey = tagsetKeys[0];
  const [formSearchKey, setFormSearchKey] = useState(
    routeSearchKey || firstKey
  );
  const [formSearchValue, setFormSearchValue] = useState(routeSearchValue);

  const handleSearch = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setRouteHashState({
      searchTagKey: formSearchKey,
      searchTagValue: formSearchValue,
    });
  };

  return (
    <Panel title="Search by Query Tag">
      <PanelSection>
        <form onSubmit={handleSearch}>
          <div className="form-group">
            <label htmlFor="query_tag_key" className="control-label">
              Key:
            </label>
            <select
              id="query_tag_key"
              value={formSearchKey}
              className="form-control"
              onChange={(evt) => {
                setFormSearchKey(evt.currentTarget.value);
              }}
            >
              {tagsetKeys.map((key) => (
                <option key={key}>{key}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="query_tag_value" className="control-label">
              Value:
            </label>
            <input
              type="text"
              value={formSearchValue}
              id="query_tag_value"
              className="form-control"
              onChange={(evt) => {
                setFormSearchValue(evt.currentTarget.value);
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="submit"
              value="Search"
              disabled={!formSearchKey || !formSearchValue}
              className="btn btn-primary"
            />
          </div>
        </form>
      </PanelSection>
      {routeSearchKey && routeSearchValue && (
        <Results
          databaseId={databaseId}
          searchKey={routeSearchKey}
          searchValue={routeSearchValue}
        />
      )}
    </Panel>
  );
};

export default QuerySearch;
