---
title: 'Step 2: Test and verify'
install_track_title: 'Automated EXPLAIN: Setup (Heroku)'
backlink_href: /docs/explain/setup
backlink_title: 'Automated EXPLAIN: Setup'
---

import VerifyExplainPublic from '../_verify_explain_public.mdx'

At this point, your pganalyze setup should be ready to collect EXPLAIN plans. Since
you may not have any slow queries in your system right now, we can generate a
synthetic slow query that we can use to confirm everything is working.

The collector includes a flag to do this for you automatically, based on your
`log_min_duration_statement`:

```
$ heroku run collector --test-explain --app [your-collector-app-name]
```

This will run an artificially slow query using the Postgres `pg_sleep` function. It
will produce output similar to the following:

```
Running collector --test-explain on ⬢ [your-collector-app-name]... up, run.3163 (Hobby)
2021/12/03 18:57:32 I Running collector test with pganalyze-collector 0.41.2
```

<PublicOnly>
  <VerifyExplainPublic />
</PublicOnly>
