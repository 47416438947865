import React, { useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import Loading from "components/Loading";
import PanelVirtualTable from "components/PanelVirtualTable";

import {
  VacuumHistory as VacuumHistoryType,
  VacuumHistoryVariables,
  VacuumHistory_getVacuumRuns_vacuumRuns,
} from "./types/VacuumHistory";
import { useDateRange } from "components/WithDateRange";
import { useRoutes } from "utils/routes";
import PanelTitleSearch from "components/PanelTitleSearch";
import QUERY from "./Query.graphql";

type VacuumRun = VacuumHistory_getVacuumRuns_vacuumRuns & {
  dbName: string;
  postgresRoleName?: string;
  schemaName?: string;
  tableName?: string;
  duration: number;
};

const VacuumHistory: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const { serverRole, serverVacuum } = useRoutes();
  const [{ from, to }] = useDateRange();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading, error } = useQuery<
    VacuumHistoryType,
    VacuumHistoryVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return (
      <Panel title="History">
        <Loading error={!!error} />
      </Panel>
    );
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );

  // We already tell the server to only send us finished vacuums, but ensure this here
  const vacuumRuns: VacuumRun[] = data.getVacuumRuns.vacuumRuns
    .filter((v) => !!v.vacuumEnd)
    .map((v) => ({
      dbName: v.databaseName,
      postgresRoleName: v.postgresRole?.name,
      schemaName: v.schemaTable?.schemaName ?? "unknown schema",
      tableName: v.schemaTable?.tableName ?? "unknown table",
      duration: v.vacuumEnd - v.vacuumStart,
      ...v,
    }))
    .filter((v) => {
      return (
        searchTerm === "" ||
        v.postgresRoleName?.toLowerCase().includes(searchTerm) ||
        v.schemaName?.toLowerCase().includes(searchTerm) ||
        v.tableName?.toLowerCase().includes(searchTerm)
      );
    });

  return (
    <Panel title="History" secondaryTitle={titleSearch}>
      <PanelVirtualTable
        data={vacuumRuns}
        initialSortBy="vacuumEnd"
        initialSortDirection={SortDirection.DESC}
      >
        {!databaseId && (
          <Column dataKey="dbName" label="Database" width={120} />
        )}
        <Column dataKey="schemaName" label="Schema" width={120} />
        <Column
          dataKey="tableName"
          label="Table"
          width={200}
          flexGrow={1}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            cellData?: string | null;
            rowData: VacuumRun;
          }): React.ReactNode => {
            return (
              <span>
                <Link to={serverVacuum(serverId, rowData.identity)}>
                  {cellData}
                </Link>
                {rowData.toast && " (TOAST)"}
              </span>
            );
          }}
        />
        <Column
          dataKey="duration"
          label="Duration"
          width={180}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode => moment.duration(cellData * 1000).humanize()}
        />
        <Column
          dataKey="vacuumEnd"
          label="Finished"
          width={220}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode => moment.unix(cellData).format("ll LTS z")}
        />
        <Column
          dataKey="postgresRoleName"
          label="Role / Autovacuum"
          width={160}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            cellData?: string | null;
            rowData: VacuumRun;
          }): React.ReactNode => {
            if (cellData && rowData.postgresRole) {
              return (
                <Link to={serverRole(serverId, rowData.postgresRole.id)}>
                  {cellData}
                </Link>
              );
            } else if (rowData.autovacuum) {
              return "autovacuum";
            } else {
              return "-";
            }
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

export default VacuumHistory;
