import React from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, Link } from "react-router-dom";

import { formatBytes } from "utils/format";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";

import {
  QueryDetailsGraph,
  QueryDetailsGraphVariables,
} from "./types/QueryDetailsGraph";
import { useDateRange } from "components/WithDateRange";
import { Data, InteractionPoint, defined } from "components/Graph/util";

import GraphSection from "components/Graph/GraphSection";
import { AreaSeries, LineSeries, ScatterSeries } from "components/Graph/Series";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import { useRoutes } from "utils/routes";

type Props = {
  databaseId: string;
  queryId: string;
  trackIoTiming: boolean;
};

const Graph: React.FunctionComponent<Props> = ({
  databaseId,
  queryId,
  trackIoTiming,
}) => {
  const { databaseQueryExplain, serverConfigSetting } = useRoutes();
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<
    QueryDetailsGraph,
    QueryDetailsGraphVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryId,
      startTs: newStartTs.unix(),
      endTs: newEndTs.unix(),
    },
  });

  const { humanId, integratedExplain } = data?.getServerDetails || {};
  let result: Data = data?.getQueryDetailStats as unknown as Data;
  const noData = !!result && result.noData != null;

  let explains: any[] = data?.getQueryExplains;
  if (explains) {
    // remove explains that happened when no other chart data is available
    explains = explains.filter((e) =>
      defined(result.calls.find((c) => c[0] === e.time))
    );
    result = {
      ...result,
      explains: explains.map((e) => [e.time, e.querySample.runtimeMs]),
    } as Data;
  }

  const navigate = useNavigate();
  const onClick = (point: InteractionPoint) => {
    if (!point) {
      return;
    }
    const d = point.nearby.find((d) => d.series === "explains");
    if (!d) {
      return;
    }
    const id = explains[d.index].humanId;
    navigate(databaseQueryExplain(databaseId, queryId, id));
  };

  return (
    <>
      <Panel title="Avg Time & Calls" key="runtime">
        <GraphSection noData={noData} loading={loading} error={error}>
          <DateRangeGraph
            data={result}
            onClick={onClick}
            axes={{
              left: {
                format: (y: number): string => y + " ms",
                tipFormat: (y: number): string => y.toFixed(1) + " ms",
              },
              right: {
                tipFormat: (y: number): string => y.toFixed(1) + "/min",
              },
            }}
            series={[
              { key: "avgTime", color: "yellowGreen", label: "Avg Total Time" },
              { key: "avgIoTime", color: "turquoise", label: "Avg I/O Time" },
              {
                key: "calls",
                color: "orange",
                label: "Calls",
                yAxis: "right",
                className: styles.newCalls,
              },
              {
                key: "explains",
                type: ScatterSeries,
                label: `EXPLAIN Plans (${
                  integratedExplain ? explains.length : "n/a"
                })`,
                tipLabel: "EXPLAIN",
                color: "violet",
              },
            ]}
          />
        </GraphSection>
      </Panel>
      <Panel title="Cache Hit Ratio" key="blks">
        <GraphSection noData={noData} loading={loading} error={error}>
          <DateRangeGraph
            data={result}
            axes={{
              left: {
                format: (y: number): string => y.toFixed(1) + " %",
              },
              right: {
                format: formatBytes,
              },
            }}
            series={[
              {
                key: "avgBlksHit",
                type: AreaSeries,
                color: "lime",
                label: "% From Buffer Cache",
                tipLabel: "% Cached",
                className: styles.blocksFromCache,
              },
              {
                key: "avgBlksRead",
                type: AreaSeries,
                color: "babyBlue",
                label: "% From Disk",
                tipLabel: "% Disk",
                className: styles.blocksFromDisk,
              },
              {
                key: "bytesLoaded",
                type: LineSeries,
                color: "grey",
                yAxis: "right",
                label: "Bytes Read From Disk (Per Call)",
                tipLabel: "Read From Disk",
              },
            ]}
          />
        </GraphSection>
      </Panel>
      <Panel
        title="CPU vs I/O Time"
        key="cpu_vs_io"
        secondaryTitle={
          <span>
            <strong>Hint:</strong> I/O time is overcounted for parallel query
            plans.
          </span>
        }
      >
        {trackIoTiming && (
          <GraphSection
            noData={noData}
            loading={loading}
            error={error}
            className={styles.trackIoTimingSection}
          >
            <DateRangeGraph
              data={result}
              axes={{
                left: {
                  format: (y: number): string => y.toFixed(1) + " %",
                },
              }}
              series={[
                {
                  key: "cpuTimePct",
                  className: styles.cpuTime,
                  type: AreaSeries,
                  color: "lightYellow",
                  label: "% CPU Time",
                },
                {
                  key: "ioTimePct",
                  className: styles.ioTime,
                  type: AreaSeries,
                  color: "babyBlue",
                  label: "% I/O Time",
                },
              ]}
            />
          </GraphSection>
        )}
        {!trackIoTiming && (
          <PanelSection>
            <p>
              <strong>Error:</strong> You don't have{" "}
              <strong>
                <Link to={serverConfigSetting(humanId, "track_io_timing")}>
                  track_io_timing
                </Link>
              </strong>{" "}
              enabled.
            </p>
            <p>
              Enable this setting in your PostgreSQL config to see a breakdown
              of CPU vs I/O time for your queries.
            </p>
          </PanelSection>
        )}
      </Panel>
    </>
  );
};

export default Graph;
