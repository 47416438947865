import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { ServerError, ServerParseError } from "@apollo/client";

import { csrfToken } from "utils/csrf";
import { jwtTokenExpired } from "utils/jwt";

// There are three ways we handle authentication refresh and errors here:
//
// 1) After the JWT refresh time expires, we refresh the JWT token
// 2) In case the server returns an unauthorized error for a GraphQL action,
//    we refresh the JWT token
// 3) In case the JWT refresh action returns any error, we reload the page

let authorization: string | null;

const withToken = setContext(async (_, { headers }) => {
  if (!authorization || jwtTokenExpired(authorization)) {
    const response = await fetch("/users/jwt", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    });

    // If the JWT request fails, it may have happened because the server-side
    // session got invalidated - a reload is necessary to display the login form
    if (!response.ok) {
      document.location.reload();
    }

    authorization = response.headers.get("authorization");
  }

  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

const isAuthError = (
  networkError: Error | ServerError | ServerParseError | undefined
): boolean => {
  return (
    networkError != null &&
    networkError.name === "ServerError" &&
    "statusCode" in networkError && // https://github.com/apollographql/apollo-link/issues/300#issuecomment-518445337
    networkError.statusCode === 401
  );
};

const resetToken = onError(({ networkError }) => {
  if (isAuthError(networkError)) {
    authorization = null;
  }
});

export const apolloAuthLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: isAuthError,
  },
}).concat(withToken.concat(resetToken));
