import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";

import { formatMs, formatNumber, formatPercent } from "utils/format";
import { useRoutes } from "utils/routes";

import Panel from "components/Panel";
import PanelVirtualTable, { cellStyles } from "components/PanelVirtualTable";
import SQL from "components/SQL";

import {
  IssueDetail_getIssue as IssueType,
  IssueDetail_getIssue_references_referent_Query,
} from "../types/IssueDetail";
import FilterSearch from "components/FilterSearch";

const IndexAdvisorAffectedQueries: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const issueQueries = issue.references
    .filter((ref) => ref.kind === "Query" && !!ref.referent)
    .map<IssueQuery>((ref) => {
      const details = JSON.parse(ref.detailsJson);
      return {
        id: (ref.referent as IssueDetail_getIssue_references_referent_Query).id,
        normalizedQuery: details.normalized_query,
        truncatedQuery: details.truncated_query,
        avgTime: details.avg_time,
        pctOfTotal: details.pct_of_total,
        callsPerMinute: details.calls_per_minute,
      };
    })
    .filter((q) => {
      return (
        searchTerm === "" ||
        q.normalizedQuery.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  return (
    <Panel
      title={`Affected Queries (${issueQueries.length})`}
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <AffectedQueriesTable
        databaseId={issue.databaseId}
        queries={issueQueries}
      />
    </Panel>
  );
};

type IssueQuery = {
  id: string;
  normalizedQuery: string;
  truncatedQuery: string;
  avgTime: number;
  pctOfTotal: number;
  callsPerMinute: number;
};

const AffectedQueriesTable: React.FunctionComponent<{
  databaseId: string;
  queries: IssueQuery[];
}> = ({ databaseId, queries }) => {
  const { databaseQuery } = useRoutes();
  return (
    <PanelVirtualTable
      data={queries}
      pageSize={5}
      initialSortBy="pctOfTotal"
      initialSortDirection={SortDirection.DESC}
    >
      <Column
        dataKey="id"
        label="Query ID"
        width={60}
        style={{ textAlign: "right" }}
        cellRenderer={({ cellData }) => {
          return (
            <Link to={databaseQuery(databaseId, cellData)}>#{cellData}</Link>
          );
        }}
      />
      <Column
        dataKey="truncatedQuery"
        label="Query"
        width={300}
        flexGrow={1}
        cellRenderer={({ cellData }) => {
          return cellData ? (
            <SQL inline nowrap sql={cellData} />
          ) : (
            "<query text not available>"
          );
        }}
      />
      <Column
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        dataKey="avgTime"
        label="Avg Time (ms)"
        width={140}
        cellRenderer={({ cellData }) =>
          cellData == null ? "n/a" : formatMs(cellData)
        }
      />
      <Column
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        dataKey="callsPerMinute"
        label="Calls / Min"
        width={140}
        cellRenderer={({ cellData }) =>
          cellData == null ? "n/a" : formatNumber(cellData, 4)
        }
      />
      <Column
        className={cellStyles.number}
        headerClassName={cellStyles.numberHeader}
        dataKey="pctOfTotal"
        label="% All Runtime"
        width={140}
        cellRenderer={({ cellData }) =>
          cellData == null ? "n/a" : formatPercent(cellData / 100)
        }
      />
    </PanelVirtualTable>
  );
};

export default IndexAdvisorAffectedQueries;
