import React from "react";

import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import * as AmazonRDSAutoExplainCheck from "../../../../docs/explain/setup/amazon_rds/01_auto_explain_check.mdx";
import * as AmazonRDSEnableAutoExplain from "../../../../docs/explain/setup/amazon_rds/02_enable_auto_explain.mdx";
import * as AmazonRDSReviewSettings from "../../../../docs/explain/setup/amazon_rds/03_review_settings.mdx";
import * as AmazonRDSTestAndVerify from "../../../../docs/explain/setup/amazon_rds/04_test_and_verify.mdx";

import * as SelfManagedAutoExplainCheck from "../../../../docs/explain/setup/self_managed/01_auto_explain_check.mdx";
import * as SelfManagedEnableAutoExplain from "../../../../docs/explain/setup/self_managed/02_enable_auto_explain.mdx";
import * as SelfManagedReviewSettings from "../../../../docs/explain/setup/self_managed/03_review_settings.mdx";
import * as SelfManagedTestAndVerify from "../../../../docs/explain/setup/self_managed/04_test_and_verify.mdx";

import * as GCPAutoExplainCheck from "../../../../docs/explain/setup/google_cloud_sql/01_auto_explain_check.mdx";
import * as GCPEnableAutoExplain from "../../../../docs/explain/setup/google_cloud_sql/02_enable_auto_explain.mdx";
import * as GCPReviewSettings from "../../../../docs/explain/setup/google_cloud_sql/03_review_settings.mdx";
import * as GCPTestAndVerify from "../../../../docs/explain/setup/google_cloud_sql/04_test_and_verify.mdx";

import * as HerokuEnableLogExplain from "../../../../docs/explain/setup/heroku/01_enable_log_explain.mdx";
import * as HerokuTestAndVerify from "../../../../docs/explain/setup/heroku/02_test_and_verify.mdx";

import * as CrunchyBridgeCreateHelperFunctions from "../../../../docs/explain/setup/crunchy_bridge/01_create_helper_functions.mdx";
import * as CrunchyBridgeEnableLogExplain from "../../../../docs/explain/setup/crunchy_bridge/02_enable_log_explain.mdx";
import * as CrunchyBridgeTestAndVerify from "../../../../docs/explain/setup/crunchy_bridge/03_test_and_verify.mdx";

import * as LogExplainCreateHelperFns from "../../../../docs/explain/setup/log_explain/01_create_helper_functions.mdx";
import * as LogExplainEnableLogExplain from "../../../../docs/explain/setup/log_explain/02_enable_log_explain.mdx";
import * as LogExplainTestAndVerify from "../../../../docs/explain/setup/log_explain/03_test_and_verify.mdx";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";
import DocSteps, { StepType } from "components/DocSteps";
import DocsPanel from "components/DocsPanel";
import DocsMDXProvider from "components/DocsMDXProvider";
import AutomatedExplainIntegrationStatus from "components/AutomatedExplainIntegrationStatus";

import styles from "./style.module.scss";

import QUERY from "./Query.graphql";
import {
  ServerSetupAutomatedExplainDocs as ServerSetupAutomatedExplainDocsType,
  ServerSetupAutomatedExplainDocsVariables,
} from "./types/ServerSetupAutomatedExplainDocs";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

const ServerSetupAutomatedExplainDocs: React.FunctionComponent = () => {
  const { serverId } = useParams();
  const { data, loading, error } = useQuery<
    ServerSetupAutomatedExplainDocsType,
    ServerSetupAutomatedExplainDocsVariables
  >(QUERY, {
    variables: { serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  if (!data.getServerDetails.integratedLogInsights) {
    return <LogInsightsRequired serverId={serverId} />;
  }

  const systemType = data.getServerDetails?.systemType;
  const currentSettings = [
    data.sharedPreloadLibrariesSetting,
    data.autoExplainLogFormatSetting,
    data.autoExplainLogMinDurationSetting,
    data.autoExplainLogAnalyzeSetting,
    data.autoExplainLogBuffersSetting,
    data.autoExplainLogTimingSetting,
    data.autoExplainLogTriggersSetting,
    data.autoExplainLogVerboseSetting,
    data.autoExplainLogNestedStatementsSetting,
    data.autoExplainLogSampleRateSetting,
  ].reduce<{ [key: string]: string }>((settings, curr) => {
    if (!curr) {
      return settings;
    }
    settings[curr.name] = curr.value;
    return settings;
  }, {});
  const autoExplainEnabled =
    currentSettings.shared_preload_libraries?.includes("auto_explain");
  const pgMajorVersion = data.getServerDetails?.postgresMajorVersion;
  const configFromEnv = data.getServerDetails?.configFromEnv;
  const apiKey = data.getApiKeys?.[0]?.token;

  return (
    <DocsMDXProvider>
      <Routes>
        <Route
          index
          element={<RedirectToFirstStep systemType={systemType} />}
        />
        <Route path="amazon_rds">
          <Route
            path="01_auto_explain_check"
            element={
              <MDXPageContent
                mdx={AmazonRDSAutoExplainCheck}
                autoExplainEnabled={autoExplainEnabled}
              />
            }
          />
          <Route
            path="02_enable_auto_explain"
            element={
              <MDXPageContent
                mdx={AmazonRDSEnableAutoExplain}
                settings={currentSettings}
              />
            }
          />
          <Route
            path="03_review_settings"
            element={
              <MDXPageContent
                mdx={AmazonRDSReviewSettings}
                settings={currentSettings}
                pgMajorVersion={pgMajorVersion}
              />
            }
          />
          <Route
            path="04_test_and_verify"
            element={
              <MDXPageContent mdx={AmazonRDSTestAndVerify}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="google_cloud_sql">
          <Route
            path="01_auto_explain_check"
            element={
              <MDXPageContent
                mdx={GCPAutoExplainCheck}
                autoExplainEnabled={autoExplainEnabled}
              />
            }
          />
          <Route
            path="02_enable_auto_explain"
            element={
              <MDXPageContent
                mdx={GCPEnableAutoExplain}
                settings={currentSettings}
              />
            }
          />
          <Route
            path="03_review_settings"
            element={
              <MDXPageContent
                mdx={GCPReviewSettings}
                settings={currentSettings}
                pgMajorVersion={pgMajorVersion}
              />
            }
          />
          <Route
            path="04_test_and_verify"
            element={
              <MDXPageContent mdx={GCPTestAndVerify}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="self_managed">
          <Route
            path="01_auto_explain_check"
            element={
              <MDXPageContent
                mdx={SelfManagedAutoExplainCheck}
                autoExplainEnabled={autoExplainEnabled}
              />
            }
          />
          <Route
            path="02_enable_auto_explain"
            element={
              <MDXPageContent
                mdx={SelfManagedEnableAutoExplain}
                settings={currentSettings}
              />
            }
          />
          <Route
            path="03_review_settings"
            element={
              <MDXPageContent
                mdx={SelfManagedReviewSettings}
                settings={currentSettings}
                pgMajorVersion={pgMajorVersion}
              />
            }
          />
          <Route
            path="04_test_and_verify"
            element={
              <MDXPageContent mdx={SelfManagedTestAndVerify}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="heroku_postgres">
          <Route
            path="01_enable_log_explain"
            element={
              <MDXPageContent
                mdx={HerokuEnableLogExplain}
                settings={currentSettings}
              />
            }
          />
          <Route
            path="02_test_and_verify"
            element={
              <MDXPageContent mdx={HerokuTestAndVerify}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="crunchy_bridge">
          <Route
            path="01_create_helper_functions"
            element={
              <MDXPageContent
                mdx={CrunchyBridgeCreateHelperFunctions}
                settings={currentSettings}
              />
            }
          />
          <Route
            path="02_enable_log_explain"
            element={
              <MDXPageContent
                mdx={CrunchyBridgeEnableLogExplain}
                settings={currentSettings}
                apiKey={apiKey}
              />
            }
          />
          <Route
            path="03_test_and_verify"
            element={
              <MDXPageContent mdx={CrunchyBridgeTestAndVerify} apiKey={apiKey}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
        <Route path="log_explain">
          <Route
            path="01_create_helper_functions"
            element={<MDXPageContent mdx={LogExplainCreateHelperFns} />}
          />
          <Route
            path="02_enable_log_explain"
            element={
              <MDXPageContent
                mdx={LogExplainEnableLogExplain}
                configFromEnv={configFromEnv}
              />
            }
          />
          <Route
            path="03_test_and_verify"
            element={
              <MDXPageContent mdx={LogExplainTestAndVerify}>
                <AutomatedExplainIntegrationStatus serverId={serverId} />
              </MDXPageContent>
            }
          />
        </Route>
      </Routes>
    </DocsMDXProvider>
  );
};

const RedirectToFirstStep: React.FunctionComponent<{
  systemType: string;
}> = ({ systemType }) => {
  const { pathname } = useLocation();
  const installTrack = getInstallTrack(systemType);
  const steps = getAutoExplainSteps(installTrack);
  if (steps.length === 0) {
    return <Loading error />;
  }
  const firstStep = steps[0][1];
  let instructionsPath = pathname;
  if (!instructionsPath.endsWith("/")) {
    instructionsPath += "/";
  }
  instructionsPath += `${installTrack}/${firstStep}`;
  return <Navigate to={instructionsPath} replace />;
};

type MDXComponentType<P> = {
  default: React.ComponentType<P>;
  frontmatter?: Record<string, any>;
};

type MDXPageContentProps<P> = {
  mdx: MDXComponentType<P>;
  systemType: string;
  children: React.ReactChild;
} & P;

const MDXPageContent: React.FunctionComponent<MDXPageContentProps<any>> = ({
  mdx,
  children,
  ...rest
}) => {
  const ChildComponent = mdx.default;
  const componentTitle = mdx.frontmatter?.install_track_title;
  const match = useMatch("/servers/:id/setup/explain/:installTrack/:step");
  const installTrack = match?.params?.installTrack;
  const step = match?.params?.step;

  const panelTitle = (
    <DocSteps steps={getAutoExplainSteps(installTrack)} step={step} />
  );

  return (
    <PageContent
      title={componentTitle ?? "Set Up Automated EXPLAIN"}
      pageCategory="docs"
      pageName="show"
    >
      <DocsPanel title={panelTitle}>
        <ChildComponent {...rest} />
        {children}
      </DocsPanel>
    </PageContent>
  );
};

const getInstallTrack = (systemType: string): string => {
  switch (systemType) {
    case "heroku":
      return "heroku_postgres";
    case "google_cloudsql":
      return "google_cloud_sql";
    case "amazon_rds":
    case "self_managed":
    case "crunchy_bridge":
      return systemType;
    default:
      return "log_explain";
  }
};

const getAutoExplainSteps = (installTrack: string): StepType[] => {
  switch (installTrack) {
    case "amazon_rds":
    case "self_managed":
    case "google_cloud_sql":
      return [
        ["Check for auto_explain", "01_auto_explain_check"],
        ["Enable auto_explain", "02_enable_auto_explain"],
        ["Review settings", "03_review_settings"],
        ["Test and verify", "04_test_and_verify"],
      ];
    case "heroku_postgres":
      return [
        ["Enable log-based EXPLAIN", "01_enable_log_explain"],
        ["Test and verify", "02_test_and_verify"],
      ];
    case "crunchy_bridge":
      return [
        ["Create helper functions", "01_create_helper_functions"],
        ["Enable log-based EXPLAIN", "02_enable_log_explain"],
        ["Test and verify", "03_test_and_verify"],
      ];
    case "log_explain":
      return [
        ["Create helper functions", "01_create_helper_functions"],
        ["Enable log-based EXPLAIN", "02_enable_log_explain"],
        ["Test and verify", "03_test_and_verify"],
      ];
    default:
      return [];
  }
};

const LogInsightsRequired: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const { serverSetupLogInsights } = useRoutes();
  return (
    <PageContent
      title="Set Up Automated EXPLAIN"
      pageCategory="docs"
      pageName="show"
    >
      <Panel
        title={
          <span className={styles.logInsightsRequiredTitle}>
            <FontAwesomeIcon icon={faExclamationCircle} /> Log Insights required
          </span>
        }
      >
        <PanelSection>
          <p>
            <Link to={serverSetupLogInsights(serverId)}>Log Insights</Link> must
            be configured before you can set up Automated EXPLAIN.
          </p>
        </PanelSection>
      </Panel>
    </PageContent>
  );
};

export default ServerSetupAutomatedExplainDocs;
