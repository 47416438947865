import React from "react";

import styles from "./style.module.scss";

export interface QueryTagType {
  key: string;
  value: string | null;
}

interface Props {
  tags: Array<QueryTagType>;
}

const QueryTags: React.FunctionComponent<Props> = ({ tags }) => {
  if (tags.length == 0) {
    return null;
  }

  return (
    <div className={styles.tagList}>
      {tags.map((tag) => (
        <span className={styles.tag} key={tag.key}>
          <span className={styles.tagKey}>{tag.key}</span>
          <span className={styles.tagValue}>{tag.value}</span>
        </span>
      ))}
    </div>
  );
};

export default QueryTags;
