import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AutoSizer, Table, Column } from "react-virtualized";

import Loading from "components/Loading";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { formatBytes, formatPercent } from "utils/format";

import {
  BloatReport as BloatReportType,
  BloatReportVariables,
} from "./types/BloatReport";
import QUERY from "./Query.graphql";
import { Link } from "react-router-dom";
import { useRoutes } from "utils/routes";

const BloatReport: React.FunctionComponent = () => {
  const { databaseId } = useParams();
  const { data, loading, error } = useQuery<
    BloatReportType,
    BloatReportVariables
  >(QUERY, {
    variables: {
      databaseId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const { tables, indices } = data.getBloatReport;

  return (
    <PageContent title="Bloat Report" pageCategory="reports" pageName="bloat">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Panel title="Tables">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    headerHeight={25}
                    height={400}
                    rowCount={tables.length}
                    rowGetter={({ index }) => tables[index]}
                    rowHeight={25}
                    width={width}
                  >
                    <Column
                      dataKey="database"
                      label="Database"
                      width={90}
                      cellRenderer={({ cellData }) =>
                        (cellData && cellData.datname) || "Unknown"
                      }
                    />
                    <Column
                      dataKey="schema_name"
                      label="Schema"
                      width={90}
                      cellRenderer={({ cellData }) => cellData || "Unknown"}
                    />
                    <Column
                      dataKey="table"
                      label="Table"
                      flexGrow={1}
                      width={200}
                      cellDataGetter={({ rowData }: { rowData: any }): any =>
                        rowData
                      }
                      cellRenderer={({ cellData }) => (
                        <TableName table={cellData} />
                      )}
                    />
                    <Column
                      dataKey="total_bytes"
                      label="Table Size (Bytes)"
                      width={130}
                      cellRenderer={({ cellData }) => formatBytes(cellData)}
                    />
                    <Column
                      dataKey="bloat_bytes"
                      label="Estimated Bloat (Bytes)"
                      width={160}
                      cellRenderer={({ cellData }) => formatBytes(cellData)}
                    />
                    <Column
                      dataKey="pct_bloat"
                      label="Bloat Percent"
                      width={130}
                      cellRenderer={({ cellData }) => formatPercent(cellData)}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Panel>
            <Panel title="Indexes">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    headerHeight={25}
                    height={400}
                    rowCount={indices.length}
                    rowGetter={({ index }) => indices[index]}
                    rowHeight={25}
                    width={width}
                  >
                    <Column
                      dataKey="database"
                      label="Database"
                      width={90}
                      cellRenderer={({ cellData }) =>
                        (cellData && cellData.datname) || "Unknown"
                      }
                    />
                    <Column
                      dataKey="schema_name"
                      label="Schema"
                      width={90}
                      cellRenderer={({ cellData }) => cellData || "Unknown"}
                    />
                    <Column
                      dataKey="index"
                      label="Index"
                      flexGrow={1}
                      width={200}
                      cellDataGetter={({ rowData }: { rowData: any }): any =>
                        rowData
                      }
                      cellRenderer={({ cellData }) => (
                        <IndexName index={cellData} />
                      )}
                    />
                    <Column
                      dataKey="total_bytes"
                      label="Index Size (Bytes)"
                      width={130}
                      cellRenderer={({ cellData }) => formatBytes(cellData)}
                    />
                    <Column
                      dataKey="bloat_bytes"
                      label="Estimated Bloat (Bytes)"
                      width={160}
                      cellRenderer={({ cellData }) => formatBytes(cellData)}
                    />
                    <Column
                      dataKey="pct_bloat"
                      label="Bloat Percent"
                      width={130}
                      cellRenderer={({ cellData }) => formatPercent(cellData)}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Panel>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Panel title="About This Report">
          <PanelSection>
            <p>
              Bloat is part of the everyday life of your database. Put simply,
              it refers to excessive space in a table thats not being used,
              caused by data that was deleted or updated. This space will
              typically be reclaimed as part of new rows that are being inserted
              - they will simply use the dead space that already exists, rather
              than allocating additional space.
            </p>
            <p>
              Sometimes bloat can become a problem however, and will slow down
              operations. All tables and indexes you see on this page have been
              found using fast estimate queries, and try to give you a bit of an
              overview on what you might want to take a look at. It is
              recommended you verify this data using pgstattuple, and then look
              into either VACUUM FULL, REINDEX, or pg_repack to try reducing
              excessive bloat.
            </p>
          </PanelSection>
        </Panel>
      </div>
    </PageContent>
  );
};

const TableName: React.FunctionComponent<{ table: any }> = ({ table }) => {
  const { databaseTable } = useRoutes();
  if (table.table_id) {
    return (
      <span>
        <Link to={databaseTable(table.database_id, table.table_id)}>
          {table.table_name}
        </Link>
      </span>
    );
  } else if (table.table_name) {
    return <span>{table.table_name}</span>;
  } else {
    return <span>Unknown</span>;
  }
};

const IndexName: React.FunctionComponent<{ index: any }> = ({ index }) => {
  const { databaseIndex } = useRoutes();
  if (index.index_id) {
    return (
      <span>
        <Link to={databaseIndex(index.database_id, index.index_id)}>
          {index.index_name}
        </Link>
      </span>
    );
  } else if (index.index_name) {
    return <span>{index.index_name}</span>;
  } else {
    return <span>Unknown</span>;
  }
};

export default BloatReport;
