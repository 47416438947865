import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Column, SortDirection } from "react-virtualized";
import { useQuery } from "@apollo/client";

import { formatBytes } from "utils/format";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelVirtualTable from "components/PanelVirtualTable";

import {
  VacuumRunning as VacuumRunningType,
  VacuumRunningVariables,
  VacuumRunning_getVacuumRuns_vacuumRuns,
} from "./types/VacuumRunning";
import VacuumDetailsGraph from "components/VacuumDetailsGraph";
import {
  adaptVacuumDetailData,
  phaseLabel,
} from "components/VacuumDetailsGraph/util";
import { useRoutes } from "utils/routes";
import QUERY from "./Query.graphql";

type VacuumRunType = VacuumRunning_getVacuumRuns_vacuumRuns & {
  dbName: string;
  postgresRoleName?: string;
  schemaName?: string;
  tableName?: string;
  maxBytesReadPerSec: number | null;
  maxBytesWrittenPerSec: number | null;
};

const getMaxReadSpeed = (maxBytesReadPerSec: number | null): string => {
  if (!maxBytesReadPerSec) {
    return "-";
  }

  return formatBytes(maxBytesReadPerSec) + "/s";
};

const getMaxWriteSpeed = (maxBytesWrittenPerSec: number | null): string => {
  if (!maxBytesWrittenPerSec) {
    return "-";
  }

  return formatBytes(maxBytesWrittenPerSec) + "/s";
};

const VacuumGraph: React.FunctionComponent<{
  vacuumRun: VacuumRunType;
}> = ({ vacuumRun }) => {
  const { details } = vacuumRun;
  return (
    <div style={{ alignSelf: "center", height: "25px" }}>
      <VacuumDetailsGraph
        blockSize={details.heapBlksTotalBytes / details.heapBlksTotal}
        data={adaptVacuumDetailData(details.stats, details.heapBlksTotal)}
        small
      />
    </div>
  );
};

const VacuumRunning: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const { serverVacuum, serverRole } = useRoutes();
  const { data, loading, error } = useQuery<
    VacuumRunningType,
    VacuumRunningVariables
  >(QUERY, { variables: { serverId, databaseId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const vacuumRuns: Array<VacuumRunType> = data.getVacuumRuns.vacuumRuns.map(
    (v: VacuumRunType): VacuumRunType => {
      return {
        dbName: v.databaseName,
        postgresRoleName: v.postgresRole?.name,
        schemaName: v.schemaTable?.schemaName ?? "unknown schema",
        tableName: v.schemaTable?.tableName ?? "unknown table",
        maxBytesReadPerSec:
          (v.details.costDelayMs !== 0 &&
            (1000 / v.details.costDelayMs) *
              (v.details.costLimit / v.details.costPageMiss) *
              v.details.blockSize) ||
          null,
        maxBytesWrittenPerSec:
          (v.details.costDelayMs !== 0 &&
            (1000 / v.details.costDelayMs) *
              (v.details.costLimit / v.details.costPageDirty) *
              v.details.blockSize) ||
          null,
        ...v,
      };
    }
  );

  return (
    <Panel
      title="Currently Running"
      secondaryTitle={moment
        .unix(data.getVacuumRuns.lastCollectedAt)
        .format("ll LTS z")}
    >
      <PanelVirtualTable
        data={vacuumRuns}
        initialSortBy="vacuumEnd"
        initialSortDirection={SortDirection.DESC}
      >
        {!databaseId && (
          <Column dataKey="dbName" label="Database" width={120} />
        )}
        <Column dataKey="schemaName" label="Schema" width={100} />
        <Column
          dataKey="tableName"
          label="Table"
          width={150}
          flexGrow={1}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            cellData?: string | null;
            rowData: VacuumRunType;
          }): React.ReactNode => {
            return (
              <span>
                <Link to={serverVacuum(serverId, rowData.identity)}>
                  {cellData || "(unknown)"}
                </Link>
                {rowData.toast && " (TOAST)"}
              </span>
            );
          }}
        />
        <Column dataKey="backendPid" label="PID" width={80} />
        <Column
          dataKey="progress"
          label="Progress"
          width={300}
          cellDataGetter={({
            rowData,
          }: {
            rowData: VacuumRunType;
          }): VacuumRunType => rowData}
          cellRenderer={({
            cellData,
          }: {
            cellData?: VacuumRunType;
          }): React.ReactNode => <VacuumGraph vacuumRun={cellData} />}
        />
        <Column
          dataKey="currentPhase"
          label="Current Phase"
          width={160}
          cellDataGetter={({ rowData }: { rowData: VacuumRunType }): number =>
            (rowData.details.stats[rowData.details.stats.length - 1] || [])[1]
          }
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode => phaseLabel(cellData)}
        />
        <Column
          dataKey="vacuumStart"
          label="Running Since"
          width={130}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number;
          }): React.ReactNode =>
            moment
              .duration(
                moment
                  .unix(data.getVacuumRuns.lastCollectedAt)
                  .diff(moment.unix(cellData))
              )
              .humanize()
          }
        />
        <Column
          dataKey="maxBytesReadPerSec"
          label="Max Read Speed"
          width={150}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number | null;
          }): React.ReactNode => getMaxReadSpeed(cellData)}
        />
        <Column
          dataKey="maxBytesWrittenPerSec"
          label="Max Write Speed"
          width={150}
          cellRenderer={({
            cellData,
          }: {
            cellData?: number | null;
          }): React.ReactNode => getMaxWriteSpeed(cellData)}
        />
        <Column
          dataKey="postgresRoleName"
          label="Role / Autovacuum"
          width={180}
          cellRenderer={({
            cellData,
            rowData,
          }: {
            cellData?: string | null;
            rowData: VacuumRunType;
          }): React.ReactNode => {
            if (cellData && rowData.postgresRole) {
              return (
                <Link to={serverRole(serverId, rowData.postgresRole.id)}>
                  {cellData}
                </Link>
              );
            } else if (rowData.autovacuum) {
              return "autovacuum";
            } else {
              return "-";
            }
          }}
        />
      </PanelVirtualTable>
    </Panel>
  );
};

export default VacuumRunning;
