import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import PageFlash from "components/PageFlash";
import { useRouteTopLevelReference } from "utils/useRouteTopLevelReference";
import { OrganizationFeatures } from "components/OrganizationFeatures";
import { usePrevious } from "utils/hooks";
import { useRoutes } from "utils/routes";
import Help from "components/Help";
import Icon from "components/Icon";
import LoadingErrorBoundary from "components/LoadingErrorBoundary";
import SyncMetricsHorizon from "components/SyncMetricsHorizon";
import PageOnboardingNav from "components/PageOnboardingNav";
import PageUserNav from "components/PageUserNav";
import {
  useCurrentOrganization,
  useCurrentServer,
} from "components/WithCurrentOrganization";

import NavItems from "./NavItems";
import OrganizationSelect from "../PageLayout/OrganizationSelect";
import styles from "./style.module.scss";
import WarnIfPastDue from "components/WarnIfPastDue";
import WarnIfServerOverage from "components/WarnIfServerOverage";
import StatusNotice from "components/StatusNotice";

type Props = {
  content: React.ReactNode;
};

const PageLayout: React.FunctionComponent<Props> = ({ content }) => {
  const { root } = useRoutes();
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen((navState) => !navState);
  };
  const location = useLocation();
  const lastLocation = usePrevious(location);
  useEffect(() => {
    if (location !== lastLocation) {
      setNavOpen(false);
    }
  }, [location, lastLocation]);

  const { humanId: serverId } = useCurrentServer(true) || {};
  const { databaseId } = useRouteTopLevelReference();

  const currentOrganization = useCurrentOrganization(true);
  const organizationSlug = currentOrganization?.slug;

  return (
    <OrganizationFeatures>
      <div className={styles.page}>
        <Help />
        <SyncMetricsHorizon />
        <WarnIfPastDue />
        <WarnIfServerOverage />
        <StatusNotice />
        <div className={styles.logoAndNav}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <h1>
                <Link to={root()}>
                  <span>pganalyze</span>
                </Link>
              </h1>
            </div>
            <a
              href=""
              className={styles.navOpenBtn}
              onClick={(evt) => {
                evt.preventDefault();
                toggleNav();
              }}
            >
              <Icon kind={navOpen ? "times" : "bars"} />
            </a>
          </div>
          <div className={navOpen ? styles.navOpen : styles.navClosed}>
            <ul className={styles.menu}>
              <OrganizationSelect />
              <NavItems
                organizationSlug={organizationSlug}
                serverId={serverId}
                databaseId={databaseId}
              />
            </ul>
            <div>
              <PageOnboardingNav />
              <PageUserNav className={styles.userNav} />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <PageFlash />
          <LoadingErrorBoundary>{content}</LoadingErrorBoundary>
        </div>
      </div>
    </OrganizationFeatures>
  );
};

export default PageLayout;
