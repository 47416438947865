import React, { useEffect, useState } from "react";

import { BLOCK_SIZE } from "utils/s3Decryption";

import { LogLineType } from "types/LogLineType";
import EncryptedLogLine from "./EncryptedLogLine";
import LogLineContent from "./LogLineContent";
import { useUnmounted } from "utils/hooks";

type Props = {
  logLine: LogLineType;
  serverId: string;
  databaseId?: string;
};

type CipherState = {
  keyId: string;
  cipherText: ArrayBuffer;
  cipherKey: string;
  cipherIv: string;
};

const LogLine: React.FunctionComponent<Props> = ({
  logLine,
  serverId,
  databaseId,
}) => {
  const isUnmounted = useUnmounted();
  const [plainText, setPlainText] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [cipherState, setCipherState] = useState<CipherState | null>(null);

  const logfile = logLine.logFileLocation;
  const rangeLower = logLine.byteContentStart;
  // Load at most 1000 bytes per line - for now thats good enough and prevents slowness for overly long lines
  const rangeUpper = Math.min(logLine.byteRangeEnd, rangeLower + 1000);

  useEffect(() => {
    const additionalStartBytes = rangeLower % BLOCK_SIZE;
    const rangeParam =
      "bytes=" + (rangeLower - additionalStartBytes) + "-" + rangeUpper;
    const fetchParams = { headers: new Headers({ Range: rangeParam }) };

    const fetchContent = async () => {
      const res = await fetch(logfile, fetchParams);
      if (isUnmounted.current) {
        return;
      }
      if (!res.ok) {
        setError("Error retrieving logs: " + res.statusText);
        return;
      }

      const wrapAlgo = res.headers.get("x-amz-meta-x-amz-wrap-alg");
      if (!wrapAlgo) {
        const text = await res.text();
        if (isUnmounted.current) {
          return;
        }
        setPlainText(text);
        return;
      }

      const cipherText = await res.arrayBuffer();
      if (isUnmounted.current) {
        return;
      }

      const cekAlg = res.headers.get("x-amz-meta-x-amz-cek-alg");
      const matDesc = res.headers.get("x-amz-meta-x-amz-matdesc");
      const keyId = JSON.parse(matDesc)["kms_cmk_id"];

      if (wrapAlgo != "kms" || cekAlg != "AES/GCM/NoPadding") {
        setError("Error retrieving logs: Encryption algorithm not supported");
        return;
      }

      setCipherState({
        keyId: keyId,
        cipherText: cipherText,
        cipherKey: res.headers.get("x-amz-meta-x-amz-key-v2"),
        cipherIv: res.headers.get("x-amz-meta-x-amz-iv"),
      });
    };

    fetchContent().catch(() => {
      if (isUnmounted.current) {
        return;
      }
      setError("Error retrieving logs: please contact support");
    });
  }, [isUnmounted, logfile, rangeLower, rangeUpper]);

  if (error) {
    return <>{error}</>;
  }
  if (!plainText && !cipherState) {
    return <>Fetching...</>;
  }
  if (plainText) {
    return (
      <LogLineContent
        content={plainText}
        logLine={logLine}
        serverId={serverId}
        databaseId={databaseId}
      />
    );
  }

  return (
    <EncryptedLogLine
      logLine={logLine}
      serverId={serverId}
      databaseId={databaseId}
      {...cipherState}
    />
  );
};

export default LogLine;
