import React from "react";

import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import Loading from "components/Loading";

import IndexAdvisorIssueSummaryCard from "components/IndexAdvisorIssueSummaryCard";

import QUERY from "./Query.graphql";

import { IssueDetail_getIssue as IssueType } from "../types/IssueDetail";
import {
  IndexAdvisorRelatedIssues as IndexAdvisorRelatedIssuesQuery,
  IndexAdvisorRelatedIssuesVariables,
  IndexAdvisorRelatedIssues_getRelatedIssues as RelatedIssueType,
} from "./types/IndexAdvisorRelatedIssues";

const IndexAdvisorRelatedIssues: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const { data, loading, error } = useQuery<
    IndexAdvisorRelatedIssuesQuery,
    IndexAdvisorRelatedIssuesVariables
  >(QUERY, {
    variables: {
      issueId: issue.id,
      relatedBy: ["database", "table"],
    },
  });

  const relatedIssueCount = data?.getRelatedIssues?.length;
  return (
    <Panel title={`Other Issues On Table (${relatedIssueCount ?? "?"})`}>
      {loading || error ? (
        <Loading error={!!error} />
      ) : (
        <RelatedIssuesContent issues={data.getRelatedIssues} />
      )}
    </Panel>
  );
};

const RelatedIssuesContent: React.FunctionComponent<{
  issues: RelatedIssueType[];
}> = ({ issues }) => {
  if (issues.length === 0) {
    return <PanelSection>No other issues found on this table</PanelSection>;
  }

  return (
    <>
      {issues.map((issue, i) => {
        const cellStyle = {
          borderBottom: i < issues.length - 1 ? "1px solid #E8E8EE" : undefined,
        };

        return (
          <div key={issue.id} style={cellStyle}>
            <IndexAdvisorIssueSummaryCard issue={issue} />
          </div>
        );
      })}
    </>
  );
};

export default IndexAdvisorRelatedIssues;
