import React from "react";
import sortBy from "lodash/sortBy";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Panel from "components/Panel";
import Form from "components/Form";
import FormSelectField from "components/FormSelectField";
import FormSubmit from "components/FormSubmit";
import Loading from "components/Loading";

import {
  MemberEdit as MemberEditType,
  MemberEditVariables,
  MemberEdit_getOrganizationRoles as OrganizationRoleType,
} from "./types/MemberEdit";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

const MemberEdit: React.FunctionComponent = () => {
  const { slug: organizationSlug, id: membershipId } = useParams();
  const { organizationMember } = useRoutes();
  const { data, loading, error } = useQuery<
    MemberEditType,
    MemberEditVariables
  >(QUERY, {
    variables: { organizationSlug, membershipId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const member = data.getOrganizationMembershipDetails;
  const roles = sortBy(
    data.getOrganizationRoles,
    (k: OrganizationRoleType): number => k.updatedAt
  );

  return (
    <PageContent
      title={`Organization Member: ${
        (member.accepted && member.user.fullname) || member.inviteEmail
      }`}
      pageCategory="organization-members"
      pageName="edit"
    >
      <Panel title="Edit Membership">
        <Form
          action={organizationMember(organizationSlug, member.id)}
          method="patch"
        >
          <FormSelectField
            name="membership[role_ids][]"
            required={true}
            multiple={true}
            label="Assigned Roles"
            options={roles.map(
              (r: OrganizationRoleType): Array<string> => [r.id, r.name]
            )}
            defaultValue={member.roles.map((r: { id: string }): string => r.id)}
            helpText="Select one or multiple roles to apply to this member. Each role may contain multiple permissions, check the Roles page for details."
          />
          <FormSubmit title="Save" />
        </Form>
      </Panel>
    </PageContent>
  );
};

export default MemberEdit;
