import React, { useState } from "react";

import {
  InteractionPoint,
  ScreenLocation,
  interactionPointsEqual,
} from "./util";

type Props = {
  width: number;
  height: number;
  mapLocationToInteractionPoint: (loc: ScreenLocation) => InteractionPoint;
  onHover?: (target: InteractionPoint | undefined) => void;
  onClick?: (target: InteractionPoint) => void;
  onRangeSelected?: (from: InteractionPoint, to: InteractionPoint) => void;
  children: (
    hover: InteractionPoint | undefined,
    rangeEndpoint: InteractionPoint | undefined
  ) => React.ReactNode;
};

const Mouse: React.FunctionComponent<Props> = ({
  width,
  height,
  mapLocationToInteractionPoint,
  onHover,
  onClick,
  onRangeSelected,
  children,
}) => {
  const [hover, setHover] = useState<InteractionPoint | undefined>(undefined);
  const [rangeEndpoint, setRangeEndpoint] = useState<
    InteractionPoint | undefined
  >(undefined);

  const handleMouseMove = (e: React.MouseEvent<SVGGElement>) => {
    const currHover = mapLocationToInteractionPoint(getMouse(e));
    onHover && onHover(currHover);
    setHover(currHover);
  };

  const handleMouseLeave = () => {
    setHover(undefined);
    setRangeEndpoint(undefined);
    onHover && onHover(undefined);
  };

  const handleMouseUp =
    (onClick || onRangeSelected) &&
    ((e: React.MouseEvent<SVGGElement>) => {
      const mouse = getMouse(e);
      const clickPoint = mapLocationToInteractionPoint(mouse);
      if (
        !rangeEndpoint ||
        !onRangeSelected ||
        interactionPointsEqual(rangeEndpoint, clickPoint)
      ) {
        onClick && onClick(clickPoint);
        setRangeEndpoint(undefined);
        return;
      }

      // If a user has clicked and is dragging while hovering, the rangeEndpoint will
      // indicate where that click occurred. N.B.: that click may be before or after
      // the hover position, so we normalize the range.
      const [start, end] =
        rangeEndpoint.bottom.domain < clickPoint.bottom.domain
          ? [rangeEndpoint, clickPoint]
          : [clickPoint, rangeEndpoint];

      onRangeSelected(start, end);
      setRangeEndpoint(undefined);
    });

  const handleMouseDown =
    onRangeSelected &&
    ((e: React.MouseEvent<SVGGElement>) => {
      const clickSlice = mapLocationToInteractionPoint(getMouse(e));
      setRangeEndpoint(clickSlice);
    });

  return (
    <>
      <rect
        cursor={onClick ? "pointer" : onRangeSelected ? "crosshair" : undefined}
        width={width}
        height={height}
        fill="none"
        stroke="none"
        pointerEvents="all"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      {children(hover, rangeEndpoint)}
    </>
  );
};

const getMouse = (e: React.MouseEvent<SVGGElement>): ScreenLocation => {
  const dims = e.currentTarget.getBoundingClientRect();
  return { x: e.clientX - dims.left, y: e.clientY - dims.top };
};

export default Mouse;
