import MonitoringUserSetupInstructions from "../../components/MonitoringUserSetupInstructions";

We recommend creating a separate monitoring user on your PostgreSQL database for pganalyze.

As an RDS superuser, connect to the database you will be monitoring and run the following (we've
generated a random password for you, but you can replace it with one of your choosing):

<MonitoringUserSetupInstructions password={props.password} />

Note that it is important you run these as RDS superuser in order to pass down the full access to statistics tables.

Write down the username and password of the monitoring user, we will use it in the last step of this tutorial.

---

Next we continue by setting up the IAM policy:

<Link className="btn btn-success" to="03_setup_iam_policy">
  Proceed to Step 3: Setup IAM Policy
</Link>
