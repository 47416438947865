import React from "react";

import classNames from "classnames";

const UsageBar: React.FunctionComponent<{
  usage: number;
  limit: number;
}> = ({ usage, limit }) => {
  const usageRatio = usage / limit;
  const cappedUsageRatio = Math.min(usageRatio, 1);

  const usageBgColor = usageRatio <= 1 ? "bg-[#43962A]" : "bg-[#C22426]";
  const usageBarSharedClasses = "h-2 my-1 w-full rounded-md";

  if (cappedUsageRatio >= 1) {
    return <div className={classNames(usageBgColor, usageBarSharedClasses)} />;
  }
  return (
    <div className={classNames("relative bg-gray-200", usageBarSharedClasses)}>
      <div
        className={classNames(
          usageBgColor,
          "absolute top-0 left-0 h-full rounded-l-md"
        )}
        style={{ width: `${100 * cappedUsageRatio}%` }}
      />
    </div>
  );
};

export default UsageBar;
