import React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  kind: string;
  title?: string;
  spin?: boolean;
  big?: boolean;
  className?: string;
};

const Icon: React.FunctionComponent<Props> = ({
  kind,
  title,
  spin,
  big,
  className,
}) => {
  return (
    <i
      className={classNames(
        "fa",
        `fa-${kind}`,
        spin && styles.spin,
        big && styles.big,
        className
      )}
      title={title}
    />
  );
};

export default Icon;
