import {
  UserPreferences,
  StoredUserPreferences,
  convertPreferences,
  LatestUserPreferences,
} from "./preferences";

export type LocalStorageSchema = {
  preferences: {
    current: UserPreferences;
    known: StoredUserPreferences;
  };
};

export const Converters: {
  preferences: {
    latest: LatestUserPreferences["version"];
    convert: (stored: StoredUserPreferences) => UserPreferences;
  };
} = {
  preferences: {
    // Note that we need to specify the version here (and in any other converters)
    // separately from its declaration in the LatestUserPreferences type itself,
    // since the type information is erased at runtime. However, setting the type
    // of this field to LatestUserPreferences["version"] allows us to verify that
    // we are in sync with the latest value (we'll get a compile-time error here
    // if we neglect to update it).
    latest: 1,
    convert: convertPreferences,
  },
} as const;
