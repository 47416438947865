import React from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";

import { useRoutes } from "utils/routes";

import { cellStyles } from "components/PaginatedVirtualTable";

import Panel from "components/Panel";
import { isCategory } from "../util";
import IssueSummaryBadge from "../IssueSummaryBadge";

type IssueType = {
  checkGroupAndName: string;
  severity: string;
};

type IssueCountsType = {
  checkGroupAndName: string;
  newCount: number;
  resolvedCount: number;
};

type IssueSummaryType = {
  [k: string]: {
    count: number;
    maxSeverity: string;
  };
};

function maxSeverity(severity: string, ...otherSeverities: string[]): string {
  return otherSeverities.reduce((maxSoFar, curr) => {
    if (curr == "critical" || (curr === "warning" && maxSoFar !== "critical")) {
      return curr;
    } else {
      return maxSoFar;
    }
  }, severity);
}

const InsightStatus: React.FunctionComponent<{
  summary: IssueSummaryType;
  checkGroupAndName: string;
  title: string;
}> = ({ summary, checkGroupAndName, title }) => {
  const checkSummary = summary[checkGroupAndName];
  const issueCount = checkSummary?.count ?? 0;
  return (
    <span className="whitespace-nowrap w-max">
      <IssueSummaryBadge
        className="mr-2"
        severity={checkSummary?.maxSeverity}
      />
      {title}
      {issueCount > 0 && ` (${issueCount})`}
    </span>
  );
};

function summarizeIssueCategory(issues: IssueType[]): IssueSummaryType {
  return issues.reduce((counts, curr) => {
    if (curr.checkGroupAndName in counts) {
      const currMetrics = counts[curr.checkGroupAndName];
      currMetrics.count += 1;
      currMetrics.maxSeverity = maxSeverity(
        curr.severity,
        currMetrics.maxSeverity
      );
    } else {
      counts[curr.checkGroupAndName] = {
        count: 1,
        maxSeverity: curr.severity,
      };
    }
    return counts;
  }, {});
}

const VacuumAdvisorOverview: React.FunctionComponent<{
  serverId: string;
  issues: IssueType[];
  issueCounts: IssueCountsType[];
}> = ({ serverId, issues, issueCounts }) => {
  const {
    serverVacuumsBloat,
    serverVacuumsPerformance,
    serverVacuumsFreezing,
  } = useRoutes();
  const bloatIssues = issues.filter(isCategory("bloat"));
  const bloatCounts = issueCounts.filter(isCategory("bloat"));
  const bloatNew = bloatCounts.reduce((s, c) => s + c.newCount, 0);
  const bloatResolved = bloatCounts.reduce((s, c) => s + c.resolvedCount, 0);
  const bloatIssuesByCategory = summarizeIssueCategory(bloatIssues);

  const performanceIssues = issues.filter(isCategory("performance"));
  const performanceCounts = issueCounts.filter(isCategory("performance"));
  const performanceNew = performanceCounts.reduce((s, c) => s + c.newCount, 0);
  const performanceResolved = performanceCounts.reduce(
    (s, c) => s + c.resolvedCount,
    0
  );
  const performanceIssuesByCategory = summarizeIssueCategory(performanceIssues);

  const freezingIssues = issues.filter(isCategory("freezing"));
  const freezingCounts = issueCounts.filter(isCategory("freezing"));
  const freezingNew = freezingCounts.reduce((s, c) => s + c.newCount, 0);
  const freezingResolved = freezingCounts.reduce(
    (s, c) => s + c.resolvedCount,
    0
  );
  const freezingIssuesByCategory = summarizeIssueCategory(freezingIssues);

  return (
    <Panel
      title={`Insights for Server (${issues.length})`}
      secondaryTitle="Based on table activity in last 7 days"
    >
      <div className="grid grid-cols-[minmax(80px,_min-content)_1fr_200px_200px] items-center">
        {/* headings */}
        <HeadingCell className="col-start-1 col-end-3">Insight</HeadingCell>
        <HeadingCell className={cellStyles.numberHeader}>
          New Last 7 Days
        </HeadingCell>
        <HeadingCell className={cellStyles.numberHeader}>
          Resolved Last 7 Days
        </HeadingCell>
        {/* Bloat */}
        <div className="text-right text-4xl m-5 pr-2">
          <Link to={serverVacuumsBloat(serverId)}>{bloatIssues.length}</Link>
        </div>
        <div className="my-5">
          <Link
            className="block mb-2 text-base"
            to={serverVacuumsBloat(serverId)}
          >
            Bloat
          </Link>
          <div className="grid grid-cols-[repeat(auto-fit,300px)]">
            <InsightStatus
              checkGroupAndName="vacuum/insufficient_vacuum_frequency"
              title="Insufficient VACUUM Frequency"
              summary={bloatIssuesByCategory}
            />
            <InsightStatus
              checkGroupAndName="vacuum/xmin_horizon"
              title="VACUUM Blocked By Xmin Horizon"
              summary={bloatIssuesByCategory}
            />
          </div>
          <a
            className="block mt-2"
            target="_blank"
            href="https://pganalyze.com/docs/vacuum-advisor/bloat"
          >
            Learn more in documentation
          </a>
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {bloatNew}
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {bloatResolved}
        </div>
        {/* Freezing */}
        <div className="text-right text-4xl m-5 pr-2">
          <Link to={serverVacuumsFreezing(serverId)}>
            {freezingIssues.length}
          </Link>
        </div>
        <div className="my-5">
          <Link
            className="block mb-2 text-base"
            to={serverVacuumsFreezing(serverId)}
          >
            Freezing
          </Link>
          <div className="grid grid-cols-[repeat(auto-fit,300px)]">
            <InsightStatus
              checkGroupAndName="vacuum/txid_wraparound"
              title="Approaching TXID Wraparound"
              summary={freezingIssuesByCategory}
            />
            <InsightStatus
              checkGroupAndName="vacuum/mxid_wraparound"
              title="Approaching MXID Wraparound"
              summary={freezingIssuesByCategory}
            />
          </div>
          <a
            className="block mt-2"
            target="_blank"
            href="https://pganalyze.com/docs/vacuum-advisor/freezing"
          >
            Learn more in documentation
          </a>
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {freezingNew}
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {freezingResolved}
        </div>
        {/* Performance */}
        <div className="text-right text-4xl m-5 pr-2">
          <Link to={serverVacuumsPerformance(serverId)}>
            {performanceIssues.length}
          </Link>
        </div>
        <div className="my-5">
          <Link
            className="block mb-2 text-base"
            to={serverVacuumsPerformance(serverId)}
          >
            Performance
          </Link>{" "}
          <div className="grid grid-cols-[repeat(auto-fit,300px)]">
            <InsightStatus
              checkGroupAndName="vacuum/inefficient_index_phase"
              title="Inefficient Index Phase"
              summary={performanceIssuesByCategory}
            />
          </div>
          <a
            className="block mt-2"
            target="_blank"
            href="https://pganalyze.com/docs/vacuum-advisor/performance"
          >
            Learn more in documentation
          </a>
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {performanceNew}
        </div>
        <div className={classNames(cellStyles.number, "my-5 mx-2")}>
          {performanceResolved}
        </div>
      </div>
    </Panel>
  );
};

const HeadingCell: React.FunctionComponent<{
  className?: string;
}> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        "py-[5px] px-[10px] text-[#555555] shadow-[0px_1px_0px_#CCCCCC] all-small-caps whitespace-nowrap overflow-hidden text-ellipsis",
        className
      )}
    >
      {children}
    </div>
  );
};

export default VacuumAdvisorOverview;
