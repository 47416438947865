---
title: 'Step 1: Create helper functions'
install_track_title: 'Automated EXPLAIN: Setup (Crunchy Bridge)'
backlink_href: /docs/explain/setup
backlink_title: 'Automated EXPLAIN: Setup'
---

import { MonitoringUserExplain } from "../../../components/MonitoringUserSetupInstructions";

We generally recommend using [auto_explain](https://pganalyze.com/docs/explain/setup/auto_explain) to collect
EXPLAIN plans automatically, but Crunchy Bridge does not yet support adding the required `auto_explain` module to
`shared_preload_libraries`, so we offer an alternative, log-based, EXPLAIN mechanism.

The log-based EXPLAIN mechanism works by watching your logs for slow queries (according to `log_min_duration_statement`)
and running EXPLAIN for each one. This has some limitations (most notably, we only run EXPLAIN, not EXPLAIN ANALYZE), but still
provides valuable insights. Learn more in our [Log-based EXPLAIN overview](https://pganalyze.com/docs/explain/setup/log_explain).

<MonitoringUserExplain username="application" />

<Link className='btn btn-success' to='02_enable_log_explain'>
  Proceed to Step 2: Configure collector
</Link>
