import React from "react";
import { useQuery } from "@apollo/client";
import sumBy from "lodash/sumBy";
import pluralize from "pluralize";

import { formatPercent } from "utils/format";
import Badge from "components/Badge";
import Icon from "components/Icon";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import BlankSlate from "components/QueryTagBlankSlate";

import TagValue from "./TagValue";

import {
  QueryTagOverview as QueryTagOverviewType,
  QueryTagOverviewVariables,
} from "./types/QueryTagOverview";
import QUERY from "./Query.graphql";

import styles from "./style.module.scss";

interface Props {
  organizationSlug: string;
  databaseId: string;
  queryId: string;
}

const QueryTagOverview: React.FunctionComponent<Props> = ({
  organizationSlug,
  databaseId,
  queryId,
}) => {
  const { data, loading, error } = useQuery<
    QueryTagOverviewType,
    QueryTagOverviewVariables
  >(QUERY, { variables: { organizationSlug, databaseId, queryId } });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const summaries = data.getQueryTagSummaryForQuery;
  const { githubRepository, githubBranch } = data.getOrganizationDetails;

  const newFeatureBadge = (
    <Badge title="New feature - feedback welcome!">
      <Icon kind="lightbulb-o" /> New
    </Badge>
  );

  if (summaries.length == 0) {
    return (
      <BlankSlate
        serverId={data.getServerDetails.humanId}
        newFeatureBadge={newFeatureBadge}
      />
    );
  }

  return (
    <Panel title={<span>Query Tags {newFeatureBadge}</span>}>
      <PanelTable borders={true}>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th className={styles.samplesColumnHeader}>% of All Samples</th>
          </tr>
        </thead>
        <tbody>
          {summaries.map((summary) => {
            const sampleCount = sumBy(summary.values, (v) => v.count);
            let values = summary.values;
            if (values.length > 5) {
              const otherLength = values.slice(4).length;
              const otherSampleCount = sumBy(values.slice(4), (v) => v.count);
              values = summary.values.slice(0, 4);
              values.push({
                __typename: null,
                value: `${otherLength} other values…`,
                count: otherSampleCount,
              });
            }

            return values.map((value, idx) => {
              const badgeTitle = `${
                value.count
              } of ${sampleCount} total ${pluralize(
                "sample",
                sampleCount,
                false
              )}`;

              return (
                <tr key={summary.key + value.value}>
                  {idx == 0 && <td rowSpan={values.length}>{summary.key}</td>}
                  <td className={styles.valueColumn}>
                    <TagValue
                      tagKey={summary.key}
                      tagValue={value.value}
                      githubRepository={githubRepository}
                      githubBranch={githubBranch}
                    />
                  </td>
                  <td className={styles.samplesColumn}>
                    {formatPercent(value.count / sampleCount, 1)}
                    <br />
                    <Badge title={badgeTitle}>
                      {pluralize("sample", value.count, true)}
                    </Badge>
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </PanelTable>
    </Panel>
  );
};

export default QueryTagOverview;
