---
title: 'Step 5: Configure the Collector'
install_track_title: Installation Guide (Aiven)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import styles from '../../style.module.scss'

import imgLogoUbuntu from '../../images/logo_ubuntu.png'
import imgLogoRedhat from '../../images/logo_redhat.png'
import imgLogoDocker from '../../images/docker.png'

export const SelectCollectorPlatform = () => {
  return (
    <React.Fragment>
      <div className={styles.installChoice}>
        <Link className={styles.installChoiceStep} to="05_configure_the_collector_package">
          <img src={imgLogoUbuntu} />
          Ubuntu &amp; Debian
        </Link>
        <Link className={styles.installChoiceStep} to="05_configure_the_collector_package">
          <img src={imgLogoRedhat} />
          RHEL, CentOS &amp; Fedora
        </Link>
      </div>
      <div className={styles.installChoice}>
        <Link
          className={styles.installChoiceStep}
          to="05_configure_the_collector_package"
        >
          <img src={imgLogoAL2} />
          Amazon Linux 2
        </Link>
        <Link
          className={styles.installChoiceStep}
          to="05_configure_the_collector_docker"
        >
          <img src={imgLogoDocker} />
          Docker
        </Link>
      </div>
    </React.Fragment>
  )
}

To continue, we need to know where your collector is running:

<SelectCollectorPlatform />

<br />
If your collector runs somewhere else, <a href="mailto:support@pganalyze.com">
  let us know
</a> & we're happy to help.
