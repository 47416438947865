import React from "react";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Loading from "components/Loading";

import PlanSummaryPanel from "../PlanSummaryPanel";
import ServerUsagePanel from "../ServerUsagePanel";
import InvoiceList from "../InvoiceList";
import PlanHighlights from "../PlanHighlights";
import { Currency } from "utils/currency";

import { BillingPlanInfoFragment_planInfo as PlanInfo } from "../PlanInfo/types/BillingPlanInfoFragment";
import {
  BillingOverview as BillingOverviewType,
  BillingOverviewVariables,
} from "./types/BillingOverview";

import QUERY from "./Query.graphql";

const Overview: React.FunctionComponent<{
  planInfo: PlanInfo;
}> = ({ planInfo }) => {
  const { slug: organizationSlug } = useParams();

  const { loading, error, data } = useQuery<
    BillingOverviewType,
    BillingOverviewVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const billing = data.getOrganizationBilling;
  const { createdAt, serverWithRecentDataCount, serverLimit } =
    data.getOrganizationDetails;

  return (
    <OverviewContent
      organizationSlug={organizationSlug}
      planInfo={planInfo}
      billingInfo={billing}
      orgCreatedAt={createdAt}
      serverLimit={serverLimit}
      serverCount={serverWithRecentDataCount}
    />
  );
};

const OverviewContent: React.FunctionComponent<{
  organizationSlug: string;
  planInfo: PlanInfo;
  billingInfo: BillingOverviewType["getOrganizationBilling"];
  orgCreatedAt: number;
  serverLimit: number;
  serverCount: number;
}> = ({
  planInfo,
  billingInfo,
  orgCreatedAt,
  serverLimit,
  serverCount,
  organizationSlug,
}) => {
  // If we're on a trial or an expired trial (that did *not* lapse into the legacy "lite" plan),
  // we pretend we're on the plan the trial is for: the latest scale plan. Otherwise, we should
  // be able to find our plan in the planChoices list.
  const isOrWasScaleTrial =
    planInfo.isTrial || (planInfo.expiredTrial && !planInfo.supportsLitePlan);

  const currentPlan = billingInfo.planChoices.find((p) => {
    return isOrWasScaleTrial ? p.id === "scale_v3" : p.isCurrent;
  });
  // Show an Upgrade CTA button instead of 'compare plans' if this is
  //  - an active trial
  //  - an expired trial
  //  - an active lite plan
  //  - a canceled subscription
  const showPlanComparisonAsUpgrade =
    planInfo.isTrial ||
    planInfo.expiredTrial ||
    (!planInfo.activeSubscription && planInfo.supportsLitePlan) ||
    planInfo.canceledSubscription;

  const {
    subscriptionNextChargeAmount,
    subscriptionNextChargeCurrency,
    subscriptionNextChargeAt,
    subscriptionBilledManually,
    subscriptionInterval,
    subscriptionCardType,
    subscriptionCardLast4,
    subscriptionCardExpMonth,
    subscriptionCardExpYear,
  } = billingInfo;
  if (!currentPlan && !subscriptionBilledManually) {
    return null;
  }

  const showSummaryPanel = !subscriptionBilledManually;
  const showServerUsage =
    subscriptionBilledManually || currentPlan.id !== "enterprise_cloud";
  const showInvoiceList = !subscriptionBilledManually;
  const extraServersAction = showPlanComparisonAsUpgrade
    ? "omit"
    : subscriptionBilledManually
    ? "contact"
    : planInfo.supportsExtraServers
    ? "adjust"
    : "upgrade";

  return (
    <>
      <div className="mb-4">
        <PlanHighlights
          organizationSlug={organizationSlug}
          planInfo={planInfo}
          serverCount={serverCount}
          serverLimit={serverLimit}
          nextChargeAmount={subscriptionNextChargeAmount}
          nextChargeCurrency={subscriptionNextChargeCurrency as Currency}
          nextChargeDueDate={subscriptionNextChargeAt}
          subscriptionBilledManually={subscriptionBilledManually}
          subscriptionInterval={subscriptionInterval}
          subscriptionCardType={subscriptionCardType}
          subscriptionCardLast4={subscriptionCardLast4}
          subscriptionCardExpMonth={subscriptionCardExpMonth}
          subscriptionCardExpYear={subscriptionCardExpYear}
        />
      </div>
      {showSummaryPanel && (
        <PlanSummaryPanel
          organizationSlug={organizationSlug}
          planName={
            isOrWasScaleTrial
              ? "Scale plan (Trial)"
              : `${currentPlan.name} plan`
          }
          planMarketingLines={currentPlan.marketingLines}
          upgradeMarketingLines={currentPlan.notIncludedMarketingLines}
          showPlanComparisonAsUpgrade={showPlanComparisonAsUpgrade}
          subscriptionCancelationEffectiveAt={
            planInfo.subscriptionCancelationPending
              ? planInfo.subscriptionCancelationEffectiveAt
              : null
          }
        />
      )}
      {showServerUsage && (
        <ServerUsagePanel
          organizationSlug={organizationSlug}
          usage={serverCount}
          limit={serverLimit}
          extraServersAction={extraServersAction}
          subscriptionPlanId={planInfo.subscriptionPlanId}
        />
      )}
      {showInvoiceList && (
        <InvoiceList
          invoices={billingInfo.invoices}
          organizationCreatedAt={orgCreatedAt}
        />
      )}
    </>
  );
};

export default Overview;
