---
title: Get Started
---

import styles from './style.module.scss'

import imgLogoAiven from './images/logo_aiven.png'
import imgLogoAws from './images/logo_aws.svg'
import imgLogoAzure from './images/logo_microsoft_azure.png'
import imgLogoGcp from './images/logo_google_cloud_platform.png'
import imgLogoPgsql from './images/logo_postgres.svg'
import imgLogoHeroku from './images/logo_heroku.svg'
import imgLogoCrunchyBridge from './images/logo_crunchy_bridge.svg'

import PublicOverview from './_install_public.mdx'

<PublicOnly>
  <PublicOverview />
</PublicOnly>

To continue please select in which environment you are running your Postgres database:

<div className={styles.installChoice}>
  <Link
    className={styles.installChoiceStep}
    to="install/amazon_rds/01_configure_rds_instance"
  >
    <img src={imgLogoAws} />
    Amazon RDS &amp; Aurora
  </Link>
  <Link
    className={styles.installChoiceStep}
    to="install/self_managed/00_choose_setup_method"
  >
    <img src={imgLogoPgsql} />
    <p>
      Self-Managed
    </p>
    <small style={{ marginTop: "-15px", fontSize: "12px" }}>
      VM / Container / On-Premise
    </small>
  </Link>
</div>
<div className={styles.installChoice}>
  <Link
    className={styles.installChoiceStep}
    to="install/heroku_postgres/01_deploy_the_collector"
  >
    <img src={imgLogoHeroku} />
    Heroku Postgres
  </Link>
  <Link
    className={styles.installChoiceStep}
    to="install/crunchy_bridge/01_deploy_the_collector"
  >
    <img src={imgLogoCrunchyBridge} />
    Crunchy Bridge
  </Link>
  <Link
    className={styles.installChoiceStep}
    to="install/aiven/01_create_monitoring_user"
  >
    <img src={imgLogoAiven} />
    Aiven
  </Link>
  {/*<Link className="install-choice--step" to="install/heroku_postgres/01_deploy_the_collector">
    <img src="/images/docs/logo_aiven.png" />
    Aiven
  </Link>
  <Link className="install-choice--step" to="install/heroku_postgres/01_deploy_the_collector">
    <img src="/images/docs/logo_digital_ocean.png" />
    Digital Ocean
  </Link>
  <Link className="install-choice--step" to="install/heroku_postgres/01_deploy_the_collector">
    <img src="/images/docs/logo_enterprise_db.png" style={{padding: "8px"}} />
    EnterpriseDB
  </Link>*/}
</div>
<div className={styles.installChoice}>
  <Link
    className={styles.installChoiceStep}
    to="install/azure_database/01_configure_azure_instance"
  >
    <img
      src={imgLogoAzure}
      style={{ padding: "5px" }}
    />
    Azure Database for PostgreSQL
  </Link>
  <Link
    className={styles.installChoiceStep}
    to="install/google_cloud_sql/01_create_monitoring_user"
  >
    <img src={imgLogoGcp} />
    Google Cloud SQL and AlloyDB
  </Link>
</div>

<PublicOnly>
  <div>
    <br />
    Additional installation notes:
    <ul>
      <li>
        <Link to="install/01_enabling_pg_stat_statements">Enable pg_stat_statements</Link>
      </li>
    </ul>
  </div>
</PublicOnly>
