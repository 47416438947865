import React from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import { useDateRange } from "components/WithDateRange";

import QUERY from "./Query.graphql";
import {
  FreezingStatsGraphVariables,
  FreezingStatsGraph as FreezingStatsGraphType,
  FreezingStatsGraph_getServerFreezingStats as FreezingStatsGraphDataType,
} from "./types/FreezingStatsGraph";

import DateRangeGraph from "components/Graph/DateRangeGraph";
import { Data, Datum, SeriesConfig } from "components/Graph/util";
import PanelTable from "components/PanelTable";
import {
  formatCountShorthand,
  formatNumber,
  formatPercent,
} from "utils/format";
import { ThresholdSeries } from "components/Graph/Series";
import { ConfigSettingDocsSnippet } from "components/DocsSnippet";

const MAX_XID_AGE = 2_000_000_000;
type GraphType = "txid" | "mxid";

const FreezingStatsGraph: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
  type: GraphType;
}> = ({ serverId, databaseId, type }) => {
  const [{ from: start, to: end }] = useDateRange();

  const { data, loading, error } = useQuery<
    FreezingStatsGraphType,
    FreezingStatsGraphVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
      startTs: start.unix(),
      endTs: end.unix(),
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const stats = data.getServerFreezingStats;

  if (!stats) {
    return null;
  }

  switch (type) {
    case "txid":
      return <FreezingStatsTxidGraph stats={stats} serverId={serverId} />;
    case "mxid":
      return <FreezingStatsMxidGraph stats={stats} serverId={serverId} />;
  }
};

const FreezingStatsTxidGraph: React.FunctionComponent<{
  serverId: string;
  stats: FreezingStatsGraphDataType;
}> = ({ serverId, stats }) => {
  if (!stats) {
    return null;
  }

  const ageXidGraphData: Data = {};
  const ageXidSeries: SeriesConfig[] = [];

  stats.xidDatabases.forEach((d) => {
    ageXidGraphData[d.id] = d.age as unknown as Datum[];
    ageXidSeries.push({
      key: d.id,
      label: d.datname,
    });
  });
  if (ageXidSeries.length > 0) {
    const first = ageXidSeries[0].key;
    const key = "avThreshold";
    const data = ageXidGraphData[first].map((d: Datum): Datum => {
      return [d[0], stats.autovacuumFreezeMaxAge];
    });
    ageXidGraphData[key] = data;
    ageXidSeries.push({
      type: ThresholdSeries,
      key: key,
      label: "Threshold for anti-wraparound vacuum",
    });
    if (stats.vacuumFailsafeAge) {
      const key = "fsThreshold";
      const data = ageXidGraphData[first].map((d: Datum): Datum => {
        return [d[0], stats.vacuumFailsafeAge];
      });
      ageXidGraphData[key] = data;
      ageXidSeries.push({
        type: ThresholdSeries,
        key: key,
        label: "Threshold for failsafe vacuum",
      });
    }
  }
  return (
    <>
      <PanelTable horizontal borders equalWidth>
        <tbody>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                serverId={serverId}
                configName={"autovacuum_freeze_max_age"}
              />
            </th>
            <td>
              {formatNumber(stats.autovacuumFreezeMaxAge)} (
              {formatPercent(stats.autovacuumFreezeMaxAge / MAX_XID_AGE)})
            </td>
            <th>
              <ConfigSettingDocsSnippet
                serverId={serverId}
                configName={"vacuum_failsafe_age"}
              />
            </th>
            <td>
              {stats.vacuumFailsafeAge ? (
                <>
                  {formatNumber(stats.vacuumFailsafeAge)} (
                  {formatPercent(stats.vacuumFailsafeAge / MAX_XID_AGE)})
                </>
              ) : (
                <>N/A (Available in Postgres 14 and later)</>
              )}
            </td>
          </tr>
        </tbody>
      </PanelTable>
      <div className="border-t pr-[10px]">
        <DateRangeGraph
          axes={{
            left: {
              format: (y: number): string => formatPercent(y / MAX_XID_AGE, 0),
              tipFormat: (y: number): string =>
                formatPercent(y / MAX_XID_AGE, 2) +
                " (" +
                formatCountShorthand(y, 1) +
                ")",
            },
          }}
          data={ageXidGraphData}
          series={ageXidSeries}
        />
      </div>
    </>
  );
};

const FreezingStatsMxidGraph: React.FunctionComponent<{
  serverId: string;
  stats: FreezingStatsGraphDataType;
}> = ({ serverId, stats }) => {
  if (!stats) {
    return null;
  }

  const ageMxidGraphData: Data = {};
  const ageMxidSeries: SeriesConfig[] = [];

  stats.mxidDatabases.forEach((d) => {
    ageMxidGraphData[d.id] = d.age as unknown as Datum[];
    ageMxidSeries.push({
      key: d.id,
      label: d.datname,
    });
  });
  if (ageMxidSeries.length > 0) {
    const first = ageMxidSeries[0].key;
    const key = "avThreshold";
    const data = ageMxidGraphData[first].map((d: Datum): Datum => {
      return [d[0], stats.autovacuumMultixactFreezeMaxAge];
    });
    ageMxidGraphData[key] = data;
    ageMxidSeries.push({
      type: ThresholdSeries,
      key: key,
      label: "Threshold for anti-wraparound vacuum",
    });
    if (stats.vacuumMultixactFailsafeAge) {
      const key = "fsThreshold";
      const data = ageMxidGraphData[first].map((d: Datum): Datum => {
        return [d[0], stats.vacuumMultixactFailsafeAge];
      });
      ageMxidGraphData[key] = data;
      ageMxidSeries.push({
        type: ThresholdSeries,
        key: key,
        label: "Threshold for failsafe vacuum",
      });
    }
  }

  return (
    <>
      <PanelTable horizontal borders equalWidth>
        <tbody>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                serverId={serverId}
                configName={"autovacuum_multixact_freeze_max_age"}
              />
            </th>
            <td>
              {formatNumber(stats.autovacuumMultixactFreezeMaxAge)} (
              {formatPercent(
                stats.autovacuumMultixactFreezeMaxAge / MAX_XID_AGE
              )}
              )
            </td>
            <th>
              <ConfigSettingDocsSnippet
                serverId={serverId}
                configName={"vacuum_multixact_failsafe_age"}
              />
            </th>
            <td>
              {stats.vacuumMultixactFailsafeAge ? (
                <>
                  {formatNumber(stats.vacuumMultixactFailsafeAge)} (
                  {formatPercent(
                    stats.vacuumMultixactFailsafeAge / MAX_XID_AGE
                  )}
                  )
                </>
              ) : (
                <>N/A (Available in Postgres 14 and later)</>
              )}
            </td>
          </tr>
        </tbody>
      </PanelTable>
      <div className="border-t pr-[10px]">
        <DateRangeGraph
          axes={{
            left: {
              format: (y: number): string => formatPercent(y / MAX_XID_AGE, 0),
              tipFormat: (y: number): string =>
                formatPercent(y / MAX_XID_AGE, 2) +
                " (" +
                formatCountShorthand(y, 1) +
                ")",
            },
          }}
          data={ageMxidGraphData}
          series={ageMxidSeries}
        />
      </div>
    </>
  );
};

export default FreezingStatsGraph;
