import React from "react";

import Loading from "components/Loading";

import classNames from "classnames";

import styles from "./style.module.scss";

type Props = {
  className?: string;
};

const PanelTileGroup: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames(styles.panelTileGroup, className)}>
      {children}
    </div>
  );
};

type TileProps = {
  title: string;
  className?: string;
  loading?: boolean;
};

export const PanelTile: React.FunctionComponent<TileProps> = ({
  title,
  className,
  children,
  loading,
}) => {
  return (
    <div className={classNames(styles.panelTile, className)}>
      <h4 className={styles.panelTileHeading}>{title}</h4>
      {loading ? <Loading small /> : children}
    </div>
  );
};

export const TileMainText: React.FunctionComponent = ({ children }) => {
  return (
    <div className="text-gray-900 text-3xl my-1 whitespace-nowrap min-w-fit">
      {children}
    </div>
  );
};

export const TileLine: React.FunctionComponent = ({ children }) => {
  return <div className="leading-loose">{children}</div>;
};

export default PanelTileGroup;
