import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { useFeature } from "components/OrganizationFeatures";
import ConnectionGraph from "components/ConnectionGraph";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import UpgradeRequired from "components/UpgradeRequired";
import WaitEventGraph from "components/WaitEventGraph";

import {
  BackendList as BackendListType,
  BackendListVariables,
} from "./types/BackendList";
import {
  BackendListLastActivity,
  BackendListLastActivityVariables,
} from "./types/BackendListLastActivity";

import Activity from "./Activity";
import QUERY from "./Query.graphql";
import QUERY_LAST_ACTIVITY from "./QueryLastActivity.graphql";

import styles from "./style.module.scss";
import { useDateRange, WithDateRange } from "components/WithDateRange";
import DateRangeBar from "components/DateRangeBar";
import { retention } from "utils/limits";
import { useRoutes } from "utils/routes";
import { useTimeout } from "utils/hooks";

interface BackendListLastSnapshotActivityProps {
  databaseId: string;
  serverId: string;
}

const POLL_INTERVAL_MS = 5000; // 5 seconds
const AUTOMATIC_PAUSE_INTERVAL_MS = POLL_INTERVAL_MS * 12 * 5; // 5 minutes

const BackendListLastSnapshotActivity: React.FunctionComponent<BackendListLastSnapshotActivityProps> =
  ({ serverId, databaseId }) => {
    const [paused, setPaused] = usePauseAfter(AUTOMATIC_PAUSE_INTERVAL_MS);
    const { data, previousData, loading, error, startPolling, stopPolling } =
      useQuery<BackendListLastActivity, BackendListLastActivityVariables>(
        QUERY_LAST_ACTIVITY,
        {
          variables: { serverId },
        }
      );

    useEffect(() => {
      if (paused) {
        stopPolling();
      } else {
        startPolling(POLL_INTERVAL_MS);
      }
    }, [paused, startPolling, stopPolling]);

    const timestamp =
      data?.getServerDetails?.lastActivitySnapshotAt ??
      previousData?.getServerDetails?.lastActivitySnapshotAt;
    if ((loading && !data?.getServerDetails) || error) {
      return <Loading error={!!error} />;
    }

    const resume = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      evt.preventDefault();
      setPaused(false);
    };

    return (
      <Panel
        title="Connection Traces"
        secondaryTitle={timestamp && moment.unix(timestamp).format("ll LTS z")}
      >
        {paused && (
          <div className={styles.activityInfo}>
            <i className="fa fa-exchange" /> Automatic refresh paused.{" "}
            <a href="" onClick={resume}>
              Resume
            </a>
          </div>
        )}
        {timestamp && (
          <Activity
            databaseId={databaseId}
            timestamp={timestamp}
            silentRefresh
            setPaused={setPaused}
          />
        )}
        {!timestamp && <PanelSection>No data available</PanelSection>}
      </Panel>
    );
  };

function usePauseAfter(ms: number): [boolean, React.Dispatch<boolean>] {
  const [paused, setPaused] = useState(false);
  const [lastUnpaused, setLastUnpaused] = useState(Date.now());
  useEffect(() => {
    setLastUnpaused(Date.now());
  }, [paused, setLastUnpaused]);
  useTimeout(
    () => {
      setPaused(true);
    },
    ms,
    [
      setPaused,
      lastUnpaused /* NB: only here to re-trigger the timeout when necessary */,
    ]
  );

  return [paused, setPaused];
}

interface BackendListActivityProps {
  databaseId: string;
  serverId: string;
  selectedTimestamp: null | number;
  setSelectedTimestamp: (value: number) => void;
}

const BackendListActivity: React.FunctionComponent<BackendListActivityProps> =
  ({ databaseId, serverId, selectedTimestamp, setSelectedTimestamp }) => {
    const [{ to }] = useDateRange();
    // If date range's "to" value is more than 10 minutes apart from the current time,
    // set "to" as a selected time so that the Connection Traces (Activity) will show
    // the data around "to" time
    if (!selectedTimestamp && moment().diff(to, "minutes") > 10) {
      setSelectedTimestamp(to.unix());
    }

    if (!selectedTimestamp) {
      return (
        <BackendListLastSnapshotActivity
          serverId={serverId}
          databaseId={databaseId}
        />
      );
    }

    const jumpToNow = (
      evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      evt.preventDefault();
      setSelectedTimestamp(null);
    };

    return (
      <Panel
        title="Connection Traces"
        secondaryTitle={moment.unix(selectedTimestamp).format("ll LTS z")}
      >
        <div className={styles.activityInfo}>
          <i className="fa fa-exchange" /> Showing historic state for{" "}
          {moment.unix(selectedTimestamp).format("ll LTS z")}.{" "}
          <a href="" onClick={jumpToNow}>
            Jump to now
          </a>
        </div>
        <Activity
          databaseId={databaseId}
          timestamp={selectedTimestamp}
          silentRefresh={false}
        />
      </Panel>
    );
  };

interface Props {
  tab: string;
}

const BackendList: React.FunctionComponent<Props> = ({ tab }) => {
  const { databaseId } = useParams();
  const [selectedTimestamp, setSelectedTimestamp] = React.useState(null);
  const { databaseBackends, databaseWaitEvents } = useRoutes();

  const hasActivitySnapshotsFeature = useFeature("activitySnapshots");
  const { data, loading, error } = useQuery<
    BackendListType,
    BackendListVariables
  >(QUERY, { variables: { databaseId } });
  if ((loading && !data?.getServerDetails) || error) {
    return <Loading error={!!error} />;
  }

  const server = data.getServerDetails;
  const database = data.getDatabaseDetails;

  const handleClick = (timestamp: Date) => {
    setSelectedTimestamp(moment(timestamp).unix());
  };

  const featureNav = (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseBackends(databaseId)}>
        Connection States
      </PageNavLink>
      <PageNavLink to={databaseWaitEvents(databaseId)}>Wait Events</PageNavLink>
    </PageSecondaryNavigation>
  );

  return (
    <WithDateRange initialHorizon={retention.connections}>
      <PageContent
        title="Connections"
        pageControls={<DateRangeBar feature="Connections" />}
        windowTitle={`Connections: ${database.datname} / ${server.name}`}
        pageCategory="backends"
        pageName="index"
        featureNav={featureNav}
      >
        {(hasActivitySnapshotsFeature || tab == "backends") && (
          <Panel title="History">
            {tab == "backends" && (
              <ConnectionGraph databaseId={databaseId} onClick={handleClick} />
            )}
            {tab == "wait_events" && (
              <WaitEventGraph databaseId={databaseId} onClick={handleClick} />
            )}
          </Panel>
        )}
        {hasActivitySnapshotsFeature && (
          <BackendListActivity
            databaseId={databaseId}
            serverId={server.humanId}
            selectedTimestamp={selectedTimestamp}
            setSelectedTimestamp={setSelectedTimestamp}
          />
        )}
        {!hasActivitySnapshotsFeature && tab == "wait_events" && (
          <UpgradeRequired feature="Wait Event Monitoring" panelOnly={true} />
        )}
        {!hasActivitySnapshotsFeature && tab == "backends" && (
          <UpgradeRequired feature="Connection Tracing" panelOnly={true} />
        )}
      </PageContent>
    </WithDateRange>
  );
};

export default BackendList;
