import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading";
import Panel from "components/Panel";

import QUERY from "./Query.graphql";
import {
  FreezingStatsGridVariables,
  FreezingStatsGrid as FreezingStatsGridType,
  FreezingStatsGrid_getServerFreezingStatsByTable_tableStats as FreezingStatsGridItemType,
} from "./types/FreezingStatsGrid";
import Grid, { GridColumn, NoDataGridContainer } from "components/Grid";
import {
  formatDurationPrecise,
  formatFullTransactionID,
  formatNumber,
  formatTimestampLong,
} from "utils/format";
import moment from "moment";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";
import Tip from "components/Tip";
import { faClock } from "@fortawesome/pro-regular-svg-icons";

const FreezingStatsGrid: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const { databaseTableVacuum } = useRoutes();
  const [xidSearchTerm, setXidSearchTerm] = useState("");
  const [mxidSearchTerm, setMxidSearchTerm] = useState("");
  const { data, loading, error } = useQuery<
    FreezingStatsGridType,
    FreezingStatsGridVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
    },
  });
  if (loading || error) {
    return (
      <Panel title="Oldest Unfrozen Transaction ID Age Stats by Table">
        <Loading error={!!error} />
      </Panel>
    );
  }

  const stats = data.getServerFreezingStatsByTable;

  if (!stats) {
    return (
      <Panel title="Oldest Unfrozen Transaction ID Age Stats by Table">
        <NoDataGridContainer>
          Freezing stats data is not available. Please make sure to use the
          collector version v0.48.0 or above.
        </NoDataGridContainer>
      </Panel>
    );
  }

  const secondaryTitleTimestamp = (
    <span className="mr-[10px]">
      {moment.unix(stats.lastCollectedAt).format("ll LTS z")}
    </span>
  );

  const xidData = stats.tableStats
    .filter((xid) => !!xid.frozenxidAge)
    .filter(makeFilter(xidSearchTerm, "tableName", "datname"));

  const mxidData = stats.tableStats
    .filter((mxid) => !!mxid.minmxidAge)
    .filter(makeFilter(mxidSearchTerm, "tableName", "datname"));

  const xidColumns: GridColumn<
    FreezingStatsGridItemType,
    keyof FreezingStatsGridItemType
  >[] = [
    {
      field: "schemaName",
      header: "Schema",
    },
    {
      field: "tableName",
      header: "Table",
      renderer: function TableNameCell({
        fieldData,
        rowData,
      }: {
        fieldData: string;
        rowData: FreezingStatsGridItemType;
      }) {
        return (
          <Link
            to={databaseTableVacuum(
              rowData.databaseId.toString(),
              rowData.tableId.toString()
            )}
          >
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "frozenxidAge",
      header: "Oldest Unfrozen XID Age",
      renderer: function FrozenXidAgeCell({
        fieldData,
        rowData,
      }: {
        fieldData: number;
        rowData: FreezingStatsGridItemType;
      }) {
        const aboveAutoVacuum = fieldData > stats.autovacuumFreezeMaxAge;
        const additionalInfo = [
          {
            title: "Oldest Unfrozen XID",
            value: formatFullTransactionID(rowData.frozenXid),
          },
          {
            title: "Oldest Unfrozen XID was assigned at",
            value: rowData.frozenxidAssignedAt
              ? `${formatDurationPrecise(
                  (stats.lastCollectedAt - rowData.frozenxidAssignedAt) * 1000
                )} ago`
              : "n/a",
          },
        ];
        return (
          <AgeCell
            aboveAutoVacuum={aboveAutoVacuum}
            ageValue={formatNumber(fieldData)}
            additionalInfo={additionalInfo}
          />
        );
      },
      style: "number",
      defaultSortOrder: "desc",
      tip: "The age of the oldest unfrozen XID, in transactions. How many transactions happened since the oldest unfrozen XID.",
    },
    {
      field: "lastVacuumAt",
      header: "Last Vacuum At",
      renderer: function LastVacuumAtCell({
        rowData,
      }: {
        rowData: FreezingStatsGridItemType;
      }) {
        const lastVacuumAt = lastAnyVacuumAt(rowData);

        return lastVacuumAt == 0
          ? "n/a"
          : formatTimestampLong(moment.unix(lastVacuumAt));
      },
      tip: "Last time at which this table was vacuumed manually or by the autovacuum daemon, whichever is later.",
      toSortable: ({ rowData }: { rowData: FreezingStatsGridItemType }) =>
        lastAnyVacuumAt(rowData),
    },
  ];

  const mxidColumns: GridColumn<
    FreezingStatsGridItemType,
    keyof FreezingStatsGridItemType
  >[] = [
    {
      field: "schemaName",
      header: "Schema",
    },
    {
      field: "tableName",
      header: "Table",
      renderer: function TableNameCell({ fieldData, rowData }) {
        return (
          <Link
            to={databaseTableVacuum(
              rowData.databaseId.toString(),
              rowData.tableId.toString()
            )}
          >
            {fieldData}
          </Link>
        );
      },
    },
    {
      field: "minmxidAge",
      header: "Oldest Unfrozen MXID Age",
      renderer: function MinmxidAgeCell({ fieldData, rowData }) {
        const aboveAutoVacuum =
          fieldData > stats.autovacuumMultixactFreezeMaxAge;

        const additionalInfo = [
          {
            title: "Oldest Unfrozen MXID",
            value: formatNumber(rowData.minMxid),
          },
          {
            title: "Oldest Unfrozen MXID was assigned at",
            value: rowData.minmxidAssignedAt
              ? `${formatDurationPrecise(
                  (stats.lastCollectedAt - rowData.minmxidAssignedAt) * 1000
                )} ago`
              : "n/a",
          },
        ];
        return (
          <AgeCell
            aboveAutoVacuum={aboveAutoVacuum}
            ageValue={formatNumber(fieldData)}
            additionalInfo={additionalInfo}
          />
        );
      },
      style: "number",
      defaultSortOrder: "desc",
      tip: "The age of the oldest unfrozen MXID, in transactions. How many multi transactions happened since the oldest unfrozen MXID.",
    },
    {
      field: "lastVacuumAt",
      header: "Last Vacuum At",
      renderer: function LastVacuumAtCell({ rowData }) {
        const lastVacuumAt = lastAnyVacuumAt(rowData);

        return lastVacuumAt == 0
          ? "n/a"
          : formatTimestampLong(moment.unix(lastVacuumAt));
      },
      tip: "Last time at which this table was vacuumed manually or by the autovacuum daemon, whichever is later.",
      toSortable: ({ rowData }) => lastAnyVacuumAt(rowData),
    },
  ];

  let gridColsClass = "grid-cols-[160px_1fr_300px_180px]";
  if (databaseId == null) {
    gridColsClass = "grid-cols-[160px_160px_1fr_300px_180px]";
    const dbColumn = {
      field: "datname",
      header: "Database",
    } as const;
    xidColumns.unshift(dbColumn);
    mxidColumns.unshift(dbColumn);
  }

  return (
    <>
      <Panel
        title={`Oldest Unfrozen Transaction ID Age Stats by Table (${xidData.length})`}
        secondaryTitle={
          <>
            {secondaryTitleTimestamp}
            <FilterSearch
              initialValue={xidSearchTerm}
              onChange={setXidSearchTerm}
            />
          </>
        }
      >
        {xidData.length > 0 ? (
          <Grid
            className={gridColsClass}
            data={xidData}
            defaultSortBy="frozenxidAge"
            columns={xidColumns}
            pageSize={10}
          />
        ) : (
          <NoDataGridContainer>
            No Transaction ID information for this server{" "}
            <Tip content="Some Transaction ID information is not collected if the server is not primary." />
          </NoDataGridContainer>
        )}
      </Panel>
      <Panel
        title={`Oldest Unfrozen Multixact ID Age Stats by Table (${mxidData.length})`}
        secondaryTitle={
          <>
            {secondaryTitleTimestamp}
            <FilterSearch
              initialValue={mxidSearchTerm}
              onChange={setMxidSearchTerm}
            />
          </>
        }
      >
        {mxidData.length > 0 ? (
          <Grid
            className={gridColsClass}
            data={mxidData}
            defaultSortBy="minmxidAge"
            columns={mxidColumns}
            pageSize={10}
          />
        ) : (
          <NoDataGridContainer>
            No Multixact ID information for this server{" "}
            <Tip content="Multixact IDs are used to support row locking by multiple transactions. It is normal to have no data if your database is not using multixact IDs." />
          </NoDataGridContainer>
        )}
      </Panel>
    </>
  );
};

interface AdditionalInfo {
  title: string;
  value: string;
}

const AgeCell: React.FunctionComponent<{
  aboveAutoVacuum: boolean;
  ageValue: string;
  additionalInfo?: AdditionalInfo[];
}> = ({ aboveAutoVacuum, ageValue, additionalInfo }) => {
  const additionalInfoContent = additionalInfo.map((info, i) => {
    return (
      <div className="font-sans" key={i}>
        <div className="font-medium">{info.title}:</div>
        <div>{info.value}</div>
      </div>
    );
  });
  return (
    <>
      {aboveAutoVacuum && (
        <Popover
          popupClassName="font-sans"
          content="Anti-wraparound autovacuum expected since age is above threshold"
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="mr-1"
            color="#C22426"
          />
        </Popover>
      )}
      <span className={aboveAutoVacuum ? "text-[#C22426]" : ""}>
        {ageValue}
      </span>
      {additionalInfo && (
        <Popover content={additionalInfoContent} popupClassName="font-sans">
          <FontAwesomeIcon icon={faClock} className="ml-1" color="#959697" />
        </Popover>
      )}
    </>
  );
};

function lastAnyVacuumAt(rowData: FreezingStatsGridItemType): number {
  return Math.max(rowData.lastVacuumAt ?? 0, rowData.lastAutovacuumAt ?? 0);
}

export default FreezingStatsGrid;
