import * as React from "react";

import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-identicon-sprites";

import styles from "./style.module.scss";

type Props = {
  identity: string;
};

const Identicon: React.FunctionComponent<Props> = ({ identity }) => {
  const svg = createAvatar(style, {
    seed: identity,
    background: "#fff",
    colorLevel: 500,
    colors: [
      "blue",
      "blueGrey",
      "brown",
      "cyan",
      "deepPurple",
      "grey",
      "indigo",
      "lightBlue",
      "lightGreen",
    ],
  });
  return (
    <div
      className={styles.iconStyle}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

export default Identicon;
