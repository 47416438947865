import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import Form from "components/Form";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import {
  CheckConfig as CheckConfigType,
  CheckConfigVariables,
} from "./types/CheckConfig";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

export type CheckOptionsType = {
  key: string;
  title: string;
  help: string;
  validate?: (value: string) => string /* (error) */ | undefined;
};

function percentageValidator(val: string): string | undefined {
  // We could just check parseInt / isNaN but that accepts trailing junk
  if (!/^\d+$/.test(val)) {
    return "must be a positive integer";
  }
  const intVal = parseInt(val, 10);
  if (intVal < 1 || intVal > 100) {
    return "must be between 1 - 100 percent";
  }
  return undefined;
}

export const CHECK_OPTIONS: {
  [a: string]: {
    [a: string]: Array<CheckOptionsType>;
  };
} = {
  queries: {
    slowness: [
      {
        key: "threshold_ms",
        title: "Runtime Threshold (ms)",
        help: "Any query that runs slower than this on average will create an issue",
      },
      {
        key: "minimum_calls",
        title: "Calls Threshold (count)",
        help: "Only queries with at least this number of calls in the last 24h will be considered",
      },
    ],
  },
  connections: {
    active_query: [
      {
        key: "warning_max_query_age_secs",
        title: "Threshold (seconds) - Warning",
        help: "This check will create a warning when a query is active longer than this threshold",
      },
      {
        key: "critical_max_query_age_secs",
        title: "Threshold (seconds) - Critical",
        help: "This check will create a critical issue when a query is active longer than this threshold",
      },
    ],
    idle_transaction: [
      {
        key: "warning_max_idle_tx_age_secs",
        title: "Threshold (seconds) - Warning",
        help: "This check will create a warning when a transaction is idle longer than this threshold",
      },
      {
        key: "critical_max_idle_tx_age_secs",
        title: "Threshold (seconds) - Critical",
        help: "This check will create a critical issue when a transaction is idle longer than this threshold",
      },
    ],
    blocking_query: [
      {
        key: "blocked_count",
        title: "Blocked Queries Threshold (count)",
        help: "This check will create an issue when a query is blocking at least this many other queries",
      },
      {
        key: "warning_blocked_age_secs",
        title: "Blocked Age Threshold (seconds) - Warning",
        help: "This check will create a warning when at least one blocked query has been running for longer than this threshold. Setting this lower than the Active Queries check's threshold is recommended",
      },
      {
        key: "critical_blocked_age_secs",
        title: "Blocked Age Threshold (seconds) - Critical",
        help: "This check will create a critical issue when at least one blocked query has been running for longer than this threshold. Setting this lower than the Active Queries check's threshold is recommended",
      },
    ],
  },
  index_advisor: {
    missing_index: [],
  },
  schema: {
    index_invalid: [],
    index_unused: [
      {
        key: "unused_days",
        title: "Unused Threshold (days)",
        help: "This check will create an issue for any index that has not been scanned in this many days (must be between 1 and 35)",
        validate: (val: string) => {
          // We could just check parseInt / isNaN but that accepts trailing junk
          if (!/^\d+$/.test(val)) {
            return "must be a positive integer";
          }
          const intVal = parseInt(val, 10);
          if (intVal < 1) {
            return "must be at least 1 day";
          }
          if (intVal > 35) {
            return "must be 35 days or less";
          }
          return undefined;
        },
      },
    ],
  },
  settings: {
    enable_features: [],
    fsync: [],
    shared_buffers: [],
    stats: [],
    work_mem: [],
  },
  system: {
    storage_space: [
      {
        key: "critical_pct",
        title: "Critical %",
        help: "This check will create a critical issue when this threshold is exceeded",
      },
      {
        key: "warning_pct",
        title: "Warning %",
        help: "This check will create a warning when this threshold is exceeded",
      },
      {
        key: "base_threshold_gigabytes",
        title: "Base Threshold (Gigabytes)",
        help: "This check will not run if disk space is still at least this number of gigabytes",
      },
    ],
  },
  replication: {
    high_lag: [
      {
        key: "warning_threshold_mb",
        title: "Warning Threshold (MB)",
        help: "This check will create a warning when replication lag is higher than this number of megabytes",
      },
      {
        key: "critical_threshold_mb",
        title: "Critical Threshold (MB)",
        help: "This check will create a critical issue when replication lag is higher than this number of megabytes",
      },
    ],
    follower_missing: [
      {
        key: "expected_count",
        title: "Expected # of Followers",
        help: "This check will create a critical issue if there are not at least this many replication followers present",
      },
    ],
  },
  vacuum: {
    inefficient_index_phase: [
      {
        key: "threshold_count",
        title: "Autovacuum Runs Threshold (count)",
        help: "This check will create an issue when there are at least this many autovacuum runs in the last 24h with multiple index scan phases",
      },
    ],
    xmin_horizon: [
      {
        key: "behind_hours",
        title: "Behind Threshold (hours)",
        help: "This check will create an issue when the xmin horizon on the server was assigned more than this many hours ago",
        validate: (val: string) => {
          // We could just check parseInt / isNaN but that accepts trailing junk
          if (!/^\d+$/.test(val)) {
            return "must be a positive integer";
          }
          const intVal = parseInt(val, 10);
          if (intVal < 1) {
            return "must be at least 1 hour";
          }
          return undefined;
        },
      },
    ],
    insufficient_vacuum_frequency: [
      {
        key: "notify_pct",
        title: "Avoidable Row Growth % Threshold",
        help: "This check will create an issue when avoidable row growth exceeds this percentage of total rows in the last 7 days",
      },
      {
        key: "notify_mb",
        title: "Avoidable Row Growth Size Threshold (MB)",
        help: "This check will create an issue when avoidable row growth exceeds this number of megabytes accumulated in the last 7 days",
      },
    ],
    txid_wraparound: [
      {
        key: "warning_threshold_pct",
        title: "Threshold % - Warning",
        help: "This check will create a warning when transaction ID space utilization is above this threshold",
        validate: percentageValidator,
      },
      {
        key: "critical_threshold_pct",
        title: "Threshold % - Critical",
        help: "This check will create a critical issue when transaction ID space utilization is above this threshold",
        validate: percentageValidator,
      },
    ],
    mxid_wraparound: [
      {
        key: "warning_threshold_pct",
        title: "Threshold % - Warning",
        help: "This check will create a warning when multixact ID space utilization is above this threshold",
        validate: percentageValidator,
      },
      {
        key: "critical_threshold_pct",
        title: "Threshold % - Critical",
        help: "This check will create a critical issue when multixact space ID utilization is above this threshold",
        validate: percentageValidator,
      },
    ],
  },
};

type Props = {
  serverId: string;
  checkGroupAndName: string;
};

const CheckConfig: React.FunctionComponent<Props> = ({
  serverId,
  checkGroupAndName,
}) => {
  const { serverCheck } = useRoutes();
  // We track validation errors as fields are updated, but to avoid disrupting the user's
  // flow, we only present the errors once the user has attempted to submit the form.
  const [errors, setErrors] = useState<{
    [option: string]: string /* (error) */;
  }>({});
  const [submissionErrors, setSubmissionErrors] = useState<{
    [option: string]: string /* (error) */;
  }>({});
  const { data, loading, error } = useQuery<
    CheckConfigType,
    CheckConfigVariables
  >(QUERY, {
    variables: {
      serverId,
      checkGroupAndName: checkGroupAndName,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const [checkGroup, checkName] = checkGroupAndName.split("/", 2);
  const { enabled, settingsJson } = data.getCheckConfig;
  const settings = JSON.parse(settingsJson);
  const options = CHECK_OPTIONS[checkGroup]?.[checkName] ?? [];

  const handleSubmit = (): boolean => {
    const currErrors = { ...errors };
    const hasErrors = Object.values(currErrors).some(Boolean);
    if (hasErrors) {
      setSubmissionErrors(currErrors);
      return false;
    }
    return true;
  };

  return (
    <>
      <Panel title="Configure Check">
        <PanelSection>
          <p>
            The following configuration applies to{" "}
            <strong>{data.getServerDetails.name}</strong>:
          </p>
          <Form
            action={serverCheck(serverId, checkGroupAndName)}
            insideSection
            method="patch"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <label>
                <input type="hidden" name="check_config[enabled]" value="0" />
                <input
                  type="checkbox"
                  name="check_config[enabled]"
                  value="1"
                  defaultChecked={enabled}
                />{" "}
                Enabled
              </label>
              <div className="row">
                <div className="col-sm-9">
                  <div className="help-block">
                    Determines whether this check runs at all. Per-user
                    notification preferences can be configured using Alert
                    Policies.
                  </div>
                </div>
              </div>
            </div>
            {options.map((o: CheckOptionsType): React.ReactNode => {
              function handleValidationError(err: string | undefined) {
                setErrors((errs) => {
                  return {
                    ...errs,
                    [o.key]: err,
                  };
                });
              }
              return (
                <CheckOption
                  key={o.key}
                  option={o}
                  validationError={submissionErrors[o.key]}
                  onValidationError={handleValidationError}
                  defaultValue={settings[o.key]}
                />
              );
            })}
            <div className="form-group">
              <button className="btn btn-success">Save</button>
            </div>
          </Form>
        </PanelSection>
      </Panel>
    </>
  );
};

const CheckOption: React.FunctionComponent<{
  option: CheckOptionsType;
  defaultValue: string;
  validationError: string | undefined;
  onValidationError: (error: string) => void;
}> = ({ option, defaultValue, validationError, onValidationError }) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const validationError = option.validate?.(e.currentTarget.value);
    onValidationError(validationError);
  }

  return (
    <div className="form-group" key={option.key}>
      <label htmlFor={option.key}>{option.title}</label>
      <div className="row flex items-center">
        <div className="col-sm-1">
          <input
            id={option.title}
            name={`check_config[settings][${option.key}]`}
            type="text"
            className="form-control"
            defaultValue={defaultValue}
            onChange={handleChange}
          />
        </div>
        {validationError && (
          <span className="text-[#C22426]">
            <FontAwesomeIcon icon={faExclamationCircle} /> {validationError}
          </span>
        )}
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="help-block">{option.help}</div>
        </div>
      </div>
    </div>
  );
};

export default CheckConfig;
