import React from "react";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import flowRight from "lodash/flowRight";
import zipObject from "lodash/zipObject";

import Panel from "components/Panel";
import { csrfTag } from "utils/csrf";
import withLoading from "decorators/withLoading";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

type SettingsType = {
  pagerduty: boolean;
  immediate: boolean;
  issueCount: number;
  checkName: string;
};

type Props = {
  name: string;
  title: string;
  comingSoon?: boolean;
  settingsByName: {
    [a: string]: SettingsType;
  };
};

// Only checks with critical issues can have immediate notifications
const CHECKS_WITH_CRITICALS = [
  "connections/active_query",
  "connections/idle_transaction",
  "connections/blocking_query",
  "system/storage_space",
  "settings/fsync",
];

const Setting: React.FunctionComponent<Props> = ({
  name,
  title,
  comingSoon,
  settingsByName,
}) => {
  const setting = settingsByName[name];
  const { immediate = false } = setting || {};

  return (
    <tr>
      {comingSoon && (
        <td className={styles.immediateCheckbox}>
          <input type="checkbox" disabled />
        </td>
      )}
      {!comingSoon && CHECKS_WITH_CRITICALS.indexOf(name) !== -1 && (
        <td className={styles.immediateCheckbox}>
          <input
            type="checkbox"
            name={`settings[${name}][immediate]`}
            value="1"
            defaultChecked={immediate}
          />
        </td>
      )}
      {!comingSoon && CHECKS_WITH_CRITICALS.indexOf(name) === -1 && (
        <td className={styles.immediateCheckbox}>-</td>
      )}
      <td>
        {title}
        {comingSoon && " *"}
      </td>
    </tr>
  );
};

type AlertsProps = {
  data: {
    getAlertPolicyDetails: {
      id: string;
      policyName: string;
      assignedCount: number;
      settings: Array<SettingsType>;
    };
    refetch: () => void;
  };
  organizationSlug: string;
};

const AlertPolicy: React.FunctionComponent<AlertsProps> = ({
  data,
  organizationSlug,
}) => {
  const { id, policyName, assignedCount, settings } =
    data.getAlertPolicyDetails;
  const settingsByName = zipObject(
    settings.map((s: SettingsType): string => s.checkName),
    settings
  );
  const { organizationAlertPolicy } = useRoutes();

  return (
    <Panel title={`Alert Policy: ${policyName}`}>
      <form
        className={classNames("form-horizontal", styles.editPolicyForm)}
        action={organizationAlertPolicy(organizationSlug, id)}
        method="post"
      >
        {csrfTag()}
        <input type="hidden" name="_method" value="patch" />
        <table className={styles.table}>
          <thead>
            <tr>
              <th colSpan={2}>
                <strong>Immediate Email Alerts</strong>
                <span className={styles.headerHint}>
                  Sends an email when a critical issue is discovered for
                  specific checks
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Query Performance
              </td>
            </tr>
            <Setting
              name="queries/slowness"
              title="New Slow Queries"
              settingsByName={settingsByName}
            />
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Connections
              </td>
            </tr>
            <Setting
              name="connections/active_query"
              title="Active Queries"
              settingsByName={settingsByName}
            />
            <Setting
              name="connections/idle_transaction"
              title="Idle Transactions"
              settingsByName={settingsByName}
            />
            <Setting
              name="connections/blocking_query"
              title="Blocking Queries"
              settingsByName={settingsByName}
            />
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Server Events
              </td>
            </tr>
            <Setting
              name="system/storage_space"
              title="Out of Disk Space"
              settingsByName={settingsByName}
            />
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Tables / Indexes
              </td>
            </tr>
            <Setting
              name="schema/index_invalid"
              title="Invalid Indexes"
              settingsByName={settingsByName}
            />
            <Setting
              name="schema/index_unused"
              title="Unused Indexes"
              settingsByName={settingsByName}
            />
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Config Settings
              </td>
            </tr>
            <Setting
              name="settings/fsync"
              title="Disabled fsync"
              settingsByName={settingsByName}
            />
            <Setting
              name="settings/shared_buffers"
              title="Too small shared_buffers"
              settingsByName={settingsByName}
            />
            <Setting
              name="settings/work_mem"
              title="Too small work_mem"
              settingsByName={settingsByName}
            />
            <tr>
              <td className={styles.checkSectionName} colSpan={2}>
                Replication
              </td>
            </tr>
            <Setting
              name="high_replication_lag"
              title="High Replication Lag"
              settingsByName={settingsByName}
            />
            <Setting
              name="high_availability_degraded"
              title="High Availability (HA) Degraded"
              comingSoon
              settingsByName={settingsByName}
            />
          </tbody>
        </table>
        <div className={styles.comingSoon}>* coming soon</div>
        <hr />
        <div className="radio">
          <label>
            <input type="radio" name="apply" value="existing" defaultChecked />{" "}
            Apply change to this policy{" "}
            {assignedCount == 0 && "(affects nobody)"}
            {assignedCount == 1 && "(affects 1 organization member)"}
            {assignedCount > 1 &&
              `(affects ${assignedCount} organization members)`}
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="apply" value="new" /> Create new policy
            named
          </label>
          <input
            type="text"
            className="form-control input-sm"
            name="policy_name"
          />
        </div>
        <button type="submit" className="btn btn-success">
          Save Policy "{policyName}"
        </button>
      </form>
    </Panel>
  );
};

export default flowRight(graphql(QUERY), withLoading)(AlertPolicy);
